<div [ngClass]="{ 'divLeft':!isDialog}" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div>
    <div style="display: flex; width: 100%; justify-content: space-between;">
      <h5>Manage Hotel Profiles</h5>
      <div style="display: flex;">
        <button mat-icon-button style="margin: 0em 0 0 0.5em; " (click)="openThirdPartyVendorComponent()"> <i class="material-icons-outlined" style="font-size:x-large; ">arrow_circle_left</i> </button>
        <button mat-icon-button *ngIf="isDialog" (click)="openInNew()">  <i class="material-icons" style="font-size:x-large;  ">open_in_new</i> </button>
        <button mat-icon-button *ngIf="isDialog" mat-dialog-close (click)="clickClose()"><i class="material-icons" style="font-size: x-large;">close </i></button>

      </div>
    </div>
    <div>
      <form *ngIf="hotellist_form" [formGroup]="hotellist_form">
        <div>
          <div style="background-color: #f8f8f8; border-radius: 0.25em; padding: 0.5em 1em 0em 1em; width: 90em">
            <div style="display: flex">
              <div style="margin-right:0.5em">
                <label for="hotelName" style="font-size:small">Hotel Name</label>
                <div style="margin-left: 0em; margin-top:-0.35em">
                  <input type="text" formControlName="hotelName" class="form-control inputBox" style="width:13em;" (change)="resetSearch($event)" />
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="hotel_chain_select" style="font-size:small">Hotel Chain</label>
                <div style="margin-top:-0.35em">
                  <select *ngIf="f" formControlName="hotel_chain_select" class="form-control form-control-sm dropdown" (change)="resetSearch($event)">
                    <option value=""></option>
                    <option *ngFor="let h of hotelChainList" [ngValue]="h.hotelChainGUID">{{h.hotelChainName}}</option>
                  </select>
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="airport" style="font-size:small;">Airport (ICAO/IATA)</label>
                <div style="margin-left: 0em; margin-top:-0.35em">
                  <input type="text" formControlName="airport" maxlength="4 " class="form-control inputBox" style="width:9em;" (change)="resetSearch($event)" />
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="airport" style="font-size:small;">Distance in NM: {{distanceFromAirport}}</label>
                <div style="margin-left: 0em; margin-top:-0.35em">
                  <mat-slider style="margin-top:-.75em"  (mousedown)="$event.stopPropagation()"  (change)="resetSearch($event)"  tickInterval="20" step="5" min="5" max="150" color="primary" [(ngModel)]="distanceFromAirport" [ngModelOptions]="{standalone: true}" ></mat-slider>
                </div>
              </div>
              
              <div style="margin-right:0.5em">
                <label for="city" style="font-size:small">City</label>
                <div style="margin-left: 0em; margin-top:-0.35em">
                  <input type="text" formControlName="city" class="form-control inputBox" style="width:12em;" (change)="resetSearch($event)" />
                </div>
              </div>
              <div style="margin-right:0.5em">
                <label for="country_select" style="font-size:small">Country</label>
                <div style="margin-top:-0.35em">
                  <select *ngIf="f" formControlName="country_select" class="form-control form-control-sm dropdown" (change)="resetSearch($event)">
                    <option value=""></option>
                    <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group" style="margin-left: 0.3125em;margin-top:1.25em">
                <button [disabled]="loading" class="btn btn-high" mat-button style="width: 5em;" (click)="getSearchResults()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Search
                </button>
              </div>
              <div class="form-group" style="margin-left: 1em;margin-top:1.5em; display: flex">
                <!--<input type="checkbox" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)" />-->
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="includeDisabledRecords" (change)="includeDisabledRecordsOnChange($event)"></mat-checkbox>
                <div style="margin-top:-0.25em"><label for="includeDisabledRecords" style="font-size:small;">&nbsp;Include disabled records</label></div>
              </div>

            </div>
            <div *ngIf="msg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:forestgreen; font-size: small">&nbsp;{{msg}}</label></div>
            <div *ngIf="errMsg!=''" class="inline-block" style="margin:0; display:flex;margin-top:-1em;"><label style="color:red; font-size: small">{{errMsg}}</label></div>
          </div>
        </div>
        <div *ngIf="totalRecords>0" style="float:right;font-size:small;">Total Records: {{totalRecords}}</div>
        <div style="width:90em">
          
          <div style="margin-top:1em">
            
            <div [ngClass]="{ 'dialogDiv':isDialog}"  style="width:90em">

              <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
                <tr>
                  <ng-container matColumnDef="hotelName">
                    <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Hotel</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.hotelName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="hotelChainName">
                    <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Hotel Chain</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.hotelChainName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Address</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="white-space:pre-wrap; text-align:left !important">{{element.streetAddress}}</td>
                  </ng-container>
                  <ng-container matColumnDef="near_airport">
                    <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Nearest<span style="display:block">Airport</span></div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.icao}}</td>
                  </ng-container>
                  <ng-container matColumnDef="distance">
                    <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Distance<span style="display:block">Miles</span></div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                  </ng-container>
                  <ng-container matColumnDef="ron">
                    <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Past Year<span style="display:block">RON</span></div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.ron}}</td>
                  </ng-container>
                  <ng-container matColumnDef="remarks">
                    <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Remarks</div>
                    </th>
                    <td mat-cell *matCellDef="let element"><a *ngIf="element.remarks.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.remarks}}">{{ element.remarks.substring(0,14)+'...' }}</a><span *ngIf="element.remarks.length<=15">{{element.remarks}}</span></td>
                  </ng-container>
                  <ng-container matColumnDef="disabled">
                    <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Disabled</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.isActive? '&nbsp;': 'Yes'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                      <div style="margin-top:-0.25em;">Action</div><div><a mat-button class="btn btn-high grid" style="align-self:center" (click)="clickEdit($event, null);">Add</a></div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <a mat-button class="btn btn-high grid" style="align-self:center">
                        Edit
                      </a>
                    </td>
                  </ng-container>
                </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickEdit($event, row);"></tr>
              </table>
            </div>
          </div>
          <!--<div>
            <mat-paginator style="border: none;min-width:80em;" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSizeOptions]="[10, 15, 20, 25, 30]" [pageSize]="pageSize"
                           [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
            </mat-paginator>
          </div>-->
        </div>
      </form>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>
