import { FlightFollowingModel } from "./flight-following.model";
import { GroundStopTaskDocumentModel } from "./ground-stop-task-document.model";
import { PersonModel } from "./person.model";
import { TripLegModel } from "./trip-leg.model";
import { VendorModel } from "./vendor.model";

export class GroundStopTaskModel {
  groundStopGUID: string;
  groundStopTaskGUID: string;
  serviceTypeID: number;
  serviceTypeDescription: string;
  serviceTypeIDList: string;
  checkVendorAssignment: boolean;
  serviceClassDescription: string;
  serviceClassDescriptionAbbr: string;
  serviceClassID: number;
  taskStatusGUID: string;
  taskStatusDescription: string;
  taskStatusFontColor: string;
  taskStatusBackgroundColor: string;
  vendorName: string;
  vendorGUID: string;
  accountingStatusGUID: string;
  accountingStatusGUID_TP: string;
  serviceDate: string;
  leadTime_Min: string;
  dueDate_Min: string;
  leadTime_Min_Value: number;
  dueDate_Min_Value: number;
  lead_DateTimeDisplay: string;
  due_DateDisplay: string;
  hotelGUID: string;
  countryGUID: string;
  fuelSupplierGUID: string;
  fuelIntoPlaneAgentGUID: string;
  flightPlanRecallNo: string;
  overflightPermitOrderNo: number;
  vendorConfirnationReference: string;
  fpiConfirmationReference: string;
  countryName: string;
  publicNote: string;
  vendorNote: string;
  internalNote: string;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  documentCount: number;
  isNotCompleted: boolean;
  cancelled: boolean;
  hasService: boolean;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  hotelName: string;
  accountingStatusDescription: string;
  accountingStatusDescription_TP: string;
  fuelSupplierName: string;
  fuelIntoPlaneAgentName: string;
  textDisplay: string;
  vendorRequired: boolean;
  serviceSubTitle: string;
  groundStopTaskDocumentList: GroundStopTaskDocumentModel[];
  hasTaskDocument: boolean;
  serviceArrangementID: number;
  serviceArrangementDescription: string;
  applyAllGroundStops: boolean;
  tripCodeGUID: string;
  customerGUID: string;
  flightFollowingList: FlightFollowingModel[];
  importantNote: string;
  weatherNote: string;
  notamsNote: string;
  pointOfContact: string;
  flightBriefGroundStopGUIDs: string[];
  groundStopGUIDList: string;
  isRevisionRequired: boolean;
  hasTaskAudit: boolean;
  revisionCounter: number;
  weatherChartIds: string[];
  selected: boolean;
  prevICAO: string;
  icao: string;
  nextICAO: string;
  departureDateTime: string;
  arrivalDateTime: string;
  aircraftGUID: string;
  registration: string;
  picName: string;
  picGUID: string;
  tripCode: string;
  customerName: string;
  airportCountryName: string;
  strikeOutService: boolean;
  tripTaskStatusGUIDList: string;
  isRevisionRequiredValue: number;
  includeDisabledTask: boolean;
  serviceType_LeadTime: string;
  serviceType_LeadTime_Min: string;
  serviceType_DueDate: string;
  serviceType_DueDate_Min: string;
  serviceType_CalculateFrom: string;
  assignMe: string;
  isAssignMe: boolean;
  errMsg: string = '';
  loading: boolean = false;
  departureICAO: string;
  arrivalICAO: string;
  personList: PersonModel[];
  ete: string;
  panelOpenState: boolean;
  noteCount: number;
  terminalForecast: string;
  metars: string;
  sigmets: string;
  airmets: string;
  pireps: string;
  tropicalAdvisory: string;
  vaAdvisory: string;
  departureNotams: string;
  destinationNotams: string;
  etpNotams: string;
  fdcNotams: string;
  trackMessage: string;
  tripLegList: TripLegModel[];
  nextGroundStopGUID: string;
  serviceLocation: string;
  flightBriefFormat: string;
  synopsis: string;
  turbulence: string;
  customerReference: string;
  customerTripID: string;
  taskType: string;
  businessRulesID: number;
  disabled: boolean;
  avoidLocation: boolean;
  legNumber: number;
  departureGroundStopGUID: string;
  arrivalGroundStopGUID: string;
  arrivalDateTimePassed: boolean;
  notRequired: boolean;
  defaultEmailTo: string;
  defaultEmailCc: string;
  messageInCount: number;
  messageOutCount: number;
  immediateActionRequired: boolean;
  departureOnHold: boolean;
  arrivalOnHold: boolean;
  originalTaskStatusDescription: string;
  requiresServiceDate: boolean;
  serviceTime: string;
  taskLabel: string;
  routeNote: string;
  defaultEmail: string;
  relatedGroundStopGUID: string;
  tfrNotams: string;
  showVendorToClient: boolean;
  hasFeedback: boolean;
  intoPlaneAgent: string;
  fQExpirationDate: string;
  fuelQuoteGUID: string;
  departureDateTimeUTC: Date;
  arrivalDateTimeUTC: Date;
  dueDateUTC: Date;
  fQExpirationDateUTC: Date;
  isExclude_ClientView: boolean;
  formType: number;
  isGTSBillable: boolean;
  isThirdPartyVisible: boolean;
  taskAudit: string;
  isAttachmentIcon: boolean;
  isInternalAttachmentIcon: boolean;
  isPublicNoteIcon: boolean;
  isInternalNoteIcon: boolean;
  isEmailIcon: boolean;
  countryID: number;
  airportID: number;
  airportName: string;
  latitude: number;
  longitude: number;
  iata: string;
  farTypeID: number;
  farTypeDescription: string;
  handlerGUID: string;
  ssnguid: string;
  assignMeOps: string;
  isAssignMeOps: boolean;
  
  additionalDescription: string;
  relatedAirportICAO: string;
}

