import { Component } from '@angular/core';

@Component({
  selector: 'app-team-metrics-bi-dashboard',
  templateUrl: './team-metrics-bi-dashboard.component.html',
  styleUrls: ['./team-metrics-bi-dashboard.component.css']
})
export class TeamMetricsBiDashboardComponent {

}
