import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SignInModel } from "../models/sign-in.model";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { IdentityRequestModel } from "../models/identity-request.model";
import { AuthenticateResponseModel } from '../models/authenticate-response.model';
import { ResponseModel } from '../models/response.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserModel } from "../models/user.model";
import { GlobalConstant } from "../common-utility/global-constant";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { SessionExpirationAlertDialogComponent } from "../common-utility/session-expiration-alert-dialog.component";
import { Console } from "console";

@Injectable()
export class AuthenticateService {
  signIn: Subject<SignInModel>;
  //signedIn: boolean;
  refreshTokenHandler: any;
  popUpSessionAlert: boolean = false;
  constructor(private readonly http: HttpClient, private readonly _router: Router, private readonly _dialog: MatDialog) {
    this.signIn = new Subject();
  }

  login(username: string, customername: string, pwdexpirationdate: string, usertype: string, isadmin: boolean, forceChangePwd: string, firstname: string, isA: string, isAB: string) {
    var obj = new SignInModel();
    obj.username = username;
    obj.customerName = customername;
    obj.login = true;
    //obj.pwdExpirationDate = pwdexpirationdate;
    obj.userType = usertype;
    obj.isAdmin = isadmin;
    obj.forceChangePwd = forceChangePwd;
    obj.firstName = firstname;
    obj.isA = isA;
    obj.isAB = isAB;
    this.signIn.next(obj);
    //this.signedIn = true;
    //this.refreshTokenHandler=setInterval(() => { this.refreshToken(); }, 60000); // 60 second
    this.setupRefreshInterval();
    //if (localStorage.getItem('kmsi') == null) {
    //  //this.refreshTokenHandler = setInterval(() => { this.refreshToken(); }, 300000); // 5 minutes
    //  this.refreshTokenHandler = setInterval(() => { this.refreshToken(); }, 60000); // 1 minutes
    //}
  }

  logout() {
    var obj = new SignInModel();
    obj.username = '';
    obj.customerName = '';
    obj.pwdExpirationDate = '';
    obj.login = false;
    obj.userType = '';
    obj.isAdmin=false;
    obj.forceChangePwd = '';
    obj.firstName = '';
    obj.isA = '';
    obj.isAB = '';
    this.signIn.next(obj);
    //this.signedIn = false;
    //if (localStorage.getItem('kmsi') == null) {
    //console.log(this.refreshTokenHandler);
      clearInterval(this.refreshTokenHandler);
    //}
  }

  getSignIn(): Subject<SignInModel> {
    return this.signIn;
  }

  isLogin(): boolean {
    if (localStorage.getItem('token') != null) {
      return true;
    }
    else {
      return false;
    }
  }

  setupRefreshInterval() {
    //if (localStorage.getItem('kmsi') == null) {
    //  //console.log(this.refreshTokenHandler);
      clearInterval(this.refreshTokenHandler);
    this.refreshTokenHandler = setInterval(() => { this.refreshToken(); }, 60000); // 1 minutes
    //this.refreshToken();
    //}
  }

  authenticate(username: string, password: string, keepMeSignedIn: boolean, pin:string,pinToken:string ): Observable<ResponseModel<AuthenticateResponseModel>> {

    const result = new Observable<ResponseModel<AuthenticateResponseModel>>(ob => {
      var request = new UserModel();
      request.username = username;
      request.password = password;
      request.keepMeSignedIn = keepMeSignedIn;
      request.pin = pin;
      if (pinToken == 'null')
      pinToken = null;
      request.pinToken = pinToken;
      this.authenticateEndPoint<ResponseModel<AuthenticateResponseModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {

          localStorage.setItem('token', response.model.token);
          localStorage.setItem('refreshToken', response.model.refreshToken);
          localStorage.setItem('pinToken', response.model.pinToken);
          localStorage.setItem('signInKey', response.model.signInKey);
          localStorage.setItem('un', response.model.username);
          localStorage.setItem('ut', response.model.userType.substring(0, 1));
          localStorage.setItem('expiry', response.model.expiry.toString());
          localStorage.setItem('signInTime', Date.now().toString());
          localStorage.setItem('lastAccessTime', Date.now().toString());
          //localStorage.setItem('maxIdleTime', GlobalConstant.maxIdleTime.toString());
          localStorage.setItem('maxIdleTime', response.model.idleTime.toString());
          localStorage.setItem('cn', response.model.customerName);
          localStorage.setItem('ua', response.model.isAdmin ? '1' : '0');
          localStorage.setItem('fcp', String(response.model.forceChangePwd));
          localStorage.setItem('fn', String(response.model.firstName));
          localStorage.setItem('ln', String(response.model.lastName));

          if (response.model.userPermissionContent != null) {
            localStorage.setItem('up.isA', response.model.userPermissionContent.isA);
            localStorage.setItem('up.isB', response.model.userPermissionContent.isB);
            localStorage.setItem('up.isAB', response.model.userPermissionContent.isAB);
            localStorage.setItem('up.mPro', String(response.model.userPermissionContent.grantAccessToManageManifestProfiles));
            localStorage.setItem('up.cPro', String(response.model.userPermissionContent.grantAccessCompanyProfiles));
            localStorage.setItem('up.acPro', String(response.model.userPermissionContent.grantAccessAircraftProfiles));
            localStorage.setItem('up.submitTrip', String(response.model.userPermissionContent.grantAccessSubmitTripsandRequestQuotes));
            localStorage.setItem('up.msg', String(response.model.userPermissionContent.grantAccessToMessages));
            localStorage.setItem('up.cEvent', String(response.model.userPermissionContent.grantAccessToManageCompanyEvents));
            localStorage.setItem('up.tripAlert', String(response.model.userPermissionContent.grantAccessCreateTripAlerts));
            localStorage.setItem('up.inv', String(response.model.userPermissionContent.grantAccessToInvoices));
            localStorage.setItem('up.news', String(response.model.userPermissionContent.grantAccessToHeadlineNews));
            localStorage.setItem('up.releaseEvent', String(response.model.userPermissionContent.grantAccessToReleaseEvents));
            localStorage.setItem('up.lockTrip', String(response.model.userPermissionContent.grantAccessToLockedTrips));
            localStorage.setItem('up.bRule', String(response.model.userPermissionContent.grantAccessToCompanyBusinessRules));
            localStorage.setItem('up.vPro', String(response.model.userPermissionContent.grantAccessToVendorProfiles));
            localStorage.setItem('up.pii', String(response.model.userPermissionContent.grantAccessToPIIData));
            localStorage.setItem('up.cGrp', String(response.model.userPermissionContent.grantAccessToAccountGrouping));
            localStorage.setItem('up.mGrp', String(response.model.userPermissionContent.grantAccessToManageAccountGrouping));
            localStorage.setItem('up.allMsg', String(response.model.userPermissionContent.grantAccessToAllMessages));
            localStorage.setItem('up.apis', String(response.model.userPermissionContent.grantAccessToAPISCredentials));
            localStorage.setItem('up.fuel', String(response.model.userPermissionContent.grantAccessToFuel));
            localStorage.setItem('up.airportDoc', String(response.model.userPermissionContent.grantAccessToAirportDocuments));
            localStorage.setItem('up.tClose', String(response.model.userPermissionContent.grantAccessToTripCloseout));
            localStorage.setItem('up.bill', String(response.model.userPermissionContent.grantAccessSubmitToBilling));
            localStorage.setItem('up.msgTemplates', String(response.model.userPermissionContent.grantAccessToMessageTemplates));
            localStorage.setItem('up.help', String(response.model.userPermissionContent.grantAccessToHelpGuides));
            localStorage.setItem('up.acct', String(response.model.userPermissionContent.grantAccessToAccountingDashboard));
          }
          var ua = navigator.userAgent;
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|Macintosh|CriOS/i.test(ua)) {
            localStorage.setItem('mobile', '1');
          }
          else {
            localStorage.setItem('mobile', '0');
          }

          setTimeout(() => {
            this.login(response.model.username, response.model.customerName, response.model.passwordExpirationDate, response.model.userType, response.model.isAdmin, String(response.model.forceChangePwd), response.model.firstName, response.model.userPermissionContent.isA, response.model.userPermissionContent.isAB);
          }, 500);


          //console.log(localStorage.getItem('token'));
          ob.next(response);


        }
        else {
          ob.next(response);
        }
      });
    });

    return result;
  }

  //refreshTokenOld() {
  //  var now = Date.now();
  //  var idleTime = Number(localStorage.getItem('lastAccessTime')) + Number(localStorage.getItem('maxIdleTime')) * 60000;
  //  var refreshTokenTime = Number(localStorage.getItem('signInTime')) + (Number(localStorage.getItem('expiry')) - 10) * 60000;
  //  //var refreshTokenTime = Number(localStorage.getItem('signInTime')) + (Number(localStorage.getItem('expiry')) - 55) * 60000;

  //  if (now > refreshTokenTime) {
  //      var request = new IdentityRequestModel();
  //      request.refreshToken = localStorage.getItem('refreshToken');
  //      this.refreshTokenEndPoint<ResponseModel<AuthenticateResponseModel>>(request).subscribe(response => {
  //        if (response != null && response.code == "200") {
  //          localStorage.setItem('token', response.model.token);
  //          localStorage.setItem('refreshToken', response.model.refreshToken);
  //          localStorage.setItem('signInTime', Date.now().toString());
  //        }
  //      })
  //  }

  //  if (now > idleTime) {
  //    //this._router.navigate(['/user-accounts/logout']);
  //    this.signOut();
  //    //this._router.navigate(['']);
  //  }
  //}

  refreshToken() {
    var now = Date.now();
    
    var idleTime = Number(localStorage.getItem('lastAccessTime')) + Number(localStorage.getItem('maxIdleTime')) * 60000;
    var refreshTokenTime = Number(localStorage.getItem('signInTime')) + 2 * 60000;
    
    if (now > refreshTokenTime && localStorage.getItem('kmsi') == null) {
      var request = new IdentityRequestModel();
      request.refreshToken = localStorage.getItem('refreshToken');
      this.refreshTokenEndPoint<ResponseModel<AuthenticateResponseModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          localStorage.setItem('token', response.model.token);
          localStorage.setItem('refreshToken', response.model.refreshToken);
          localStorage.setItem('signInTime', Date.now().toString());
          localStorage.setItem('expiry', response.model.expiry.toString());

          if (response.model.userPermissionContent != null) {
            localStorage.setItem('up.isA', response.model.userPermissionContent.isA);
            localStorage.setItem('up.isB', response.model.userPermissionContent.isB);
            localStorage.setItem('up.isAB', response.model.userPermissionContent.isAB);
            localStorage.setItem('up.mPro', String(response.model.userPermissionContent.grantAccessToManageManifestProfiles));
            localStorage.setItem('up.cPro', String(response.model.userPermissionContent.grantAccessCompanyProfiles));
            localStorage.setItem('up.acPro', String(response.model.userPermissionContent.grantAccessAircraftProfiles));
            localStorage.setItem('up.submitTrip', String(response.model.userPermissionContent.grantAccessSubmitTripsandRequestQuotes));
            localStorage.setItem('up.msg', String(response.model.userPermissionContent.grantAccessToMessages));
            localStorage.setItem('up.cEvent', String(response.model.userPermissionContent.grantAccessToManageCompanyEvents));
            localStorage.setItem('up.tripAlert', String(response.model.userPermissionContent.grantAccessCreateTripAlerts));
            localStorage.setItem('up.inv', String(response.model.userPermissionContent.grantAccessToInvoices));
            localStorage.setItem('up.news', String(response.model.userPermissionContent.grantAccessToHeadlineNews));
            localStorage.setItem('up.releaseEvent', String(response.model.userPermissionContent.grantAccessToReleaseEvents));
            localStorage.setItem('up.lockTrip', String(response.model.userPermissionContent.grantAccessToLockedTrips));
            localStorage.setItem('up.bRule', String(response.model.userPermissionContent.grantAccessToCompanyBusinessRules));
            localStorage.setItem('up.vPro', String(response.model.userPermissionContent.grantAccessToVendorProfiles));
            localStorage.setItem('up.pii', String(response.model.userPermissionContent.grantAccessToPIIData));
            localStorage.setItem('up.cGrp', String(response.model.userPermissionContent.grantAccessToAccountGrouping));
            localStorage.setItem('up.mGrp', String(response.model.userPermissionContent.grantAccessToManageAccountGrouping));
            localStorage.setItem('up.allMsg', String(response.model.userPermissionContent.grantAccessToAllMessages));
            localStorage.setItem('up.apis', String(response.model.userPermissionContent.grantAccessToAPISCredentials));
            localStorage.setItem('up.fuel', String(response.model.userPermissionContent.grantAccessToFuel));
            localStorage.setItem('up.airportDoc', String(response.model.userPermissionContent.grantAccessToAirportDocuments));
            localStorage.setItem('up.tClose', String(response.model.userPermissionContent.grantAccessToTripCloseout));
            localStorage.setItem('up.bill', String(response.model.userPermissionContent.grantAccessSubmitToBilling));
            localStorage.setItem('up.msgTemplates', String(response.model.userPermissionContent.grantAccessToMessageTemplates));
            localStorage.setItem('up.help', String(response.model.userPermissionContent.grantAccessToHelpGuides));
            localStorage.setItem('up.acct', String(response.model.userPermissionContent.grantAccessToAccountingDashboard));
          }
        }
        else {
          this.signOut();
        }

      })
    }
    else {    
      this.checkIsActiveUserLogin<ResponseModel<string>>().subscribe(response => {
        if (response != null && response.code == "200" && (response.model == localStorage.getItem('un') || response.model =="exception")) {
          
        }
        else {
          this.signOut();
        }

      })
      
    }

    if (localStorage.getItem('kmsi') == null) {
      if (now > idleTime) {
        this.signOut();
      }
      else {
        if ((idleTime - now) <= (5 * 60000) && this.popUpSessionAlert == false) {
          //let n: number = (idleTime - now) / 60000;
          this.openSessionExpirationDialog(5);
        }
      }
    }

  }

  openSessionExpirationDialog(expMin: number) {
    this.popUpSessionAlert = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "30em";//"1000px";
    dialogConfig.minHeight = "20em";//"500px";
    //dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { expirationMinutes: expMin  };

    const dialogRef = this._dialog.open(SessionExpirationAlertDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var request = new IdentityRequestModel();
        request.refreshToken = localStorage.getItem('refreshToken');
        this.refreshTokenEndPoint<ResponseModel<AuthenticateResponseModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            localStorage.setItem('token', response.model.token);
            localStorage.setItem('refreshToken', response.model.refreshToken);
            localStorage.setItem('signInTime', Date.now().toString());
            this.updateAccessTime();
            this.popUpSessionAlert = false;
          }
        })
      }
      else {
        this.popUpSessionAlert = false;
        this._router.navigate(['/user-accounts/logout']);
      }
    });
  }

  signOut() {
    this._dialog.closeAll();
    var request = new IdentityRequestModel();
    request.username = localStorage.getItem('un');
    request.signInKey = localStorage.getItem('signInKey');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('signInKey');
    localStorage.removeItem('un');
    localStorage.removeItem('ut');
    localStorage.removeItem('expiry');
    localStorage.removeItem('signInTime');
    localStorage.removeItem('lastAccessTime');
    localStorage.removeItem('maxIdleTime');
    localStorage.removeItem('cn');
    //localStorage.removeItem('pwdExpirationDate');
    localStorage.removeItem('kmsi');
    localStorage.removeItem('ua');
    localStorage.removeItem('fcp');
    localStorage.removeItem('fn');
    localStorage.removeItem('ln');
    localStorage.removeItem('tun');

    localStorage.removeItem('up.mPro');
    localStorage.removeItem('up.cPro');
    localStorage.removeItem('up.acPro');
    localStorage.removeItem('up.submitTrip');
    localStorage.removeItem('up.msg');
    localStorage.removeItem('up.cEvent');
    localStorage.removeItem('up.tripAlert');
    localStorage.removeItem('up.inv');
    localStorage.removeItem('up.news');
    localStorage.removeItem('up.releaseEvent');
    localStorage.removeItem('up.lockTrip');
    localStorage.removeItem('up.bRule');
    localStorage.removeItem('up.vPro');
    localStorage.removeItem('up.pii'); 
    localStorage.removeItem('up.cGrp');
    localStorage.removeItem('up.mGrp');
    localStorage.removeItem('up.isA');
    localStorage.removeItem('up.isB');
    localStorage.removeItem('up.isAB');
    localStorage.removeItem('up.allMsg');
    localStorage.removeItem('up.apis');
    localStorage.removeItem('up.fuel');
    localStorage.removeItem('up.airportDoc');
    localStorage.removeItem('up.tClose');
    localStorage.removeItem('up.bill');
    localStorage.removeItem('up.msgTemplates');
    localStorage.removeItem('up.help');
    localStorage.removeItem('up.acct');
    this.logout();
    //debugger;
    this.signOutEndPoint<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {

      }
      else {

      }
    })

    this._router.navigate(['']);
  }

  // will use on calling api
  updateAccessTime() {
    localStorage.setItem('lastAccessTime', Date.now().toString());
    //localStorage.removeItem("pwcp");
  }

  refreshTokenEndPoint<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/refreshToken", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  authenticateEndPoint<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/authenticate", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  signOutEndPoint<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/logout", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  forceLogout() {
    this.signOut();
    this._router.navigate(['']);
  }

  authUsername<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/authUsername", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  handleError(errorCode: number) {
    if (errorCode == 401) {
      this.forceLogout();
    }
  }

  getCurrentTimeNumber() {
    return (new Date()).getTime();
  }

  checkIsActiveUserLogin<T>(): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/checkIsActiveUserLogin", "", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

}
