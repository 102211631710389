<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="fuel_task_queue_form" [formGroup]="fuel_task_queue_form">
    <div class="divLeft">
      <div style="display:flex">
        <div style="background-color:#f8f8f8; padding-left:0.25em; padding-right:1.25em; border: 0.0625em lightgrey solid ;border-radius: 0.25em;width:93em">
          <div style="display: flex; font-size: small">
            <div style="margin-right:1em">
              <div style="margin-right:1em"><h5>Fuel Task Queue </h5></div>
              <div style="display: flex;margin-top:-0.25em">
                <div style="margin-right:1em">
                  <div><label for="service_type" style="font-weight:bold">Display</label></div>
                  <div style="margin-top:-0.25em">


                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="service_type"
                               type="radio"
                               name="service_type"
                               value="56,57,101" (change)="serviceTypeChange2($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em;white-space: nowrap">Both</div>
                    </div>

                    
                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="service_type"
                               type="radio"
                               name="service_type"
                               value="57,101" (change)="serviceTypeChange2($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em;white-space: nowrap ">Fuel Quote</div>
                    </div>


                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="service_type"
                               type="radio"
                               name="service_type"
                               value="56" (change)="serviceTypeChange2($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em; white-space: nowrap">Fuel Release</div>
                    </div>




                  </div>
                </div>
                <div style="margin-right:1em">
                  <div><label for="date_type" style="font-weight:bold">Filter by dates (z)</label></div>
                  <div style="margin-top:-0.25em">
             
                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="date_type"
                               type="radio"
                               name="date_type"
                               value="arrivaldate" (change)="dateTypeChange($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em; white-space: nowrap">Arrival Date</div>
                    </div>
                    
                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="date_type"
                               type="radio"
                               name="date_type"
                               value="requote" (change)="dateTypeChange($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em;white-space: nowrap ">New Quotes & Re-Quotes</div>
                    </div>

                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="date_type"
                               type="radio"
                               name="date_type"
                               value="duedate" (change)="dateTypeChange($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em;white-space: nowrap ">Task Due Date</div>
                    </div>




                    <div style="display: flex">
                      <div style="margin-right:0.25em">
                        <input formControlName="date_type"
                               type="radio"
                               name="date_type"
                               value="departuredate" (change)="dateTypeChange($Event)" [attr.disabled]="f.filterTripRevision.value==true?'':null" />
                      </div>
                      <div style="margin-top:-0.2em;white-space: nowrap">Departure Date</div>
                    </div> 



                  </div>
                </div>
                <div style="margin-top:1em">
                  <div style="margin-top:0.25em; display: flex">
                    <div style="font-size: small;font-weight:bold; white-space:nowrap">Enter a Date Range</div>
                    <!-- <div style="font-size: x-small; margin-top:0.25em"> (MM/DD/YYYY)</div> -->
                  </div>
                  <div style="display: flex;margin-top:-1.25em">
                    <mat-date-range-input [rangePicker]="picker" style="display: flex; margin-top:0.5em; width:10.5em !important" [disabled]="f.filterTripRevision.value==true?'':null">
                      <input matStartDate formControlName="startDate" #startDate placeholder="Start date" maxlength="10" [ngClass]="{ 'is-invalid1': isValidStartDate==false,  'is-valid1': isValidStartDate==true }" (dateChange)="dateOnchange('S', $event)" style="font-size:small;margin-top:0em; margin-right:1em;background-color: white; border-radius:0.25em;padding-left:0.125em;padding-right:0.125em; width: 11em !important">
                      <input matEndDate formControlName="endDate" #endDate placeholder="End date" maxlength="10" [ngClass]="{ 'is-invalid1': !isValidEndDate,  'is-valid1': isValidEndDate }" (dateChange)="dateOnchange('E', $event)" style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em; width: 6em !important ">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker" style="margin-top:0.25em; margin-left:-0.7em"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </div>
                  <div style="display:flex">
                    <div *ngIf="f.startDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:-0.5em">
                      <div *ngIf="f.startDate.errors.required">Start Date is required.</div>
                      <div *ngIf="f.startDate.errors.isValidDate==false">Invalid start date.</div>
                    </div>
                    <div *ngIf="f.endDate.errors" class="small alert alert-danger p-0" style="width:max-content;margin-top:-0.5em">
                      <div *ngIf="f.endDate.errors.required">End Date is required.</div>
                      <div *ngIf="f.endDate.errors.isValidDate==false">Invalid end date.</div>
                    </div>
                  </div>
                  <!-- <div style="display:flex; font-size: small; margin-top:-0.25em">
                    <div>
                      <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="startTime" maxlength="5" [ngClass]="{ 'is-invalid': f.startTime.errors }" style="background-color: white; width:4.5em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray" (change)="timeChange('S', $event)" />
                    </div>
                    <div style="margin-left:0.25em; margin-right:0.25em">-</div>
                    <div>
                      <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="endTime" maxlength="5" [ngClass]="{ 'is-invalid': f.endTime.errors }" style="background-color: white; width: 4.5em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray" (change)="timeChange('E', $event)" />
                    </div>
                  </div>
                  <div *ngIf="f.startTime.errors">
                    <div *ngIf="f.startTime.errors.pattern" style="background-color: #f9d2de;margin-left: 0em; padding-left:0.25em;margin-top:0em; width:8em; font-size:small">Invalid start time</div>
                  </div>
                  <div *ngIf="f.endTime.errors">
                    <div *ngIf="f.endTime.errors.pattern" style="background-color: #f9d2de;margin-left: 0em; padding-left:0.25em;margin-top:0em; width:8em; font-size:small">Invalid end time</div>
                  </div> -->
                </div>

              </div>
            </div>

            
            
            <div style="margin-right: 2em; margin-top: 0em; ">
              <div style="margin-top:0.5em; margin-right:0.25em; width:11em;" >
                <div><label for="date_type" style="font-weight:bold; margin-top: -0.5em; ">Filter by Task Status </label></div>

                <button [disabled]="f.filterTripRevision.value==true?'':null" type="button" mat-button class="btn-low" style="min-width:9em;" [ngClass]="selectedStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTaskStatusFilter()">Task Status{{selectedStatusCountText}}</button>
              </div>
              <div *ngIf="showTaskStatusFilter" style="position:absolute;float: left; min-width: 15em; margin-top: 0.2em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                <div style="margin-top:0.25em;" class="mat-checkbox-smaller">
                  <div style="display:flex; justify-content:space-between">
                    <div>
                      <mat-checkbox style="height:1.5em;  line-height:1.5em !important;"
                                    [checked]="allCompleteStatus"
                                    [indeterminate]="someCompleteStatus()"
                                    (change)="selectAllTaskStatus($event.checked)">Select All</mat-checkbox>
                    </div>
                    <div style="float:right" (click)="showTaskStatusFilter=!showTaskStatusFilter"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div class=" border-bottom">
                    <div *ngFor="let ts of taskStatusList; let i=index" style="margin-top:0.25em" class="mat-checkbox-smaller">
                      <div style="font-size: small;width:17em !important" title="{{ts.taskStatusDescription}}">
                        <mat-checkbox (click)="$event.stopPropagation()" [checked]="ts.selected" (change)="onTaskStatusChange($event, ts)" style="height:1.5em;  line-height:1.5em !important;background-color: white">
                          <div style="margin-top:0.1em;margin-left:0em; height:1.3em; padding:0em;display:flex ">
                            <div style="border-radius: 50%; width: 1em; height: 1em; border: black solid 0.0625em;" [style.background-color]="ts.hexColor"></div>
                            <div style="margin-top:-0.25em; margin-left:0.25em; white-space:nowrap">{{ts.taskStatusDescription.substr(0,24)}}</div>
                          </div>
                        </mat-checkbox>
                      </div>


                      
                    </div>
                  </div>
                  <div class="d-flex justify-content-between pt-2">
                    <div>
                      <button class="btn-low  mr-1" mat-button (click)="clickResetTaskStatus()">RESET</button>
                      <button class="btn-high mr-3" mat-button (click)="clickDoneTaskStatus()">DONE</button>
                    </div>
                    <div>
                      <button class="btn-med" mat-button (click)="clickSaveSelections('Status')">SAVE SELECTIONS</button>
                    </div>
                  </div>
                </div>
              </div>
     
              

            </div>
       
            
            <div style="margin-right:1em">
              <div style="margin-top:0.5em;font-weight:bold;">Search By</div>
              <div>
                


                <div>
                  <div style="margin-left: 0em; margin-top:0.25em">
                    <select *ngIf="f" formControlName="filter_by" (change)="onfilterTypeChange()" [disabled]="f.filterTripRevision.value==true?'':null" style="width: 22em; font-size: small;border-radius:0.25em;border-color:lightgrey">
                      <option value="customerName" selected>Client Name</option>
                      <option value="tripCode">TripID</option>
                      <option value="customerReference">Client Trip#</option>
                      <option value="registration">Tail #</option>
                      <option value="icao">Airport</option>
                      <!-- <option value="vendorName">Vendor Name</option> -->
                      <!-- <option value="overFlightCountry">Overflight Country</option> -->
                      <option value="airportCountryName"> Country</option>
                      <option value="fuelSupplierName">Fuel Supplier Name</option>
                      <option value="intoPlaneAgent"> IntoPlaneAgent</option>
                    </select>
                  </div>
                </div>

                <div style="margin-left: 0em; margin-top:0.5em">
                  <!-- <input type="text" formControlName="text_search" class="form-control" [disabled]="f.filterTripRevision.value==true?'':null" style="width:11.5em;height:1.5625em; padding-left:0.125em;font-size: small" /> -->

                  <select (change)="onSearchByOptionChange( )" formControlName="text_search" style="width: 22em; font-size: small;border-radius:0.25em;border-color:lightgrey " >
                    <option value="" >All</option>
                    <option  *ngFor=" let search of searchByList ; "  [value]="search" >{{search}}</option>
                  </select>
                </div>


              </div>

            </div>
            <div style="margin-right:1em">
              <div style="margin-top:0.5em;font-weight:bold;">Include</div>
              <div style="display: flex;flex-direction:column;margin-left:-.65em">
                <mat-slide-toggle *ngIf="userType=='internal'" (click)="$event.stopPropagation()" formControlName="includeDemo" style="transform:scale(.8);font-size:medium" color="primary">Demo Trips</mat-slide-toggle>
                <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="includeClosedBilledRecords" [disabled]="f.filterTripRevision.value==true?'':null" style="transform:scale(.8);font-size:medium" color="primary">Closeout Trips</mat-slide-toggle>
                <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="hideOnHold" (change)="hideOnHoldChange($event)" style="transform:scale(.8);font-size:medium" color="primary">Hide On Hold Tasks</mat-slide-toggle>
              </div>
            </div>
            <!-- <div style="display: flex; flex-direction: column; margin-top: 1.5em; margin-left: -.65em">
              <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="includeTBATask" [disabled]="f.filterTripRevision.value==true?'':null" style="transform:scale(.8);font-size:medium" color="primary">TBA Schedules</mat-slide-toggle>
              <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="filterTripRevision" (change)="filterTripRevisionChange($event)" style="transform:scale(.8);font-size:medium" color="primary">Show Only - All Trip Revisions</mat-slide-toggle>
            </div> -->
            <div style="margin-right:1em">

              <div style="margin-top:2em; display: flex">
                <div>
                  
                    <div style="margin-right:0.5em">
                      <button type="button" [disabled]="loading" mat-button class="btn-high" (click)="clickSubmit()">
                        <div style="display:flex">
                          <div style="margin-top: 0.25em">
                            <i class="material-icons" style="font-size: larger;cursor: pointer;">search</i>
                          </div>
                          <div>
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Search
                          </div>
                        </div>
                      </button>
                    </div>
                    
                    <div>
                      <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height: 2.1em;  width: 6.3em;  margin-top: 0.5em;" >
                        <div style="display: flex">
                          <div style="margin-top: 0.25em">
                            <i class="material-icons" style="font-size: larger;cursor: pointer; ">refresh</i>
                          </div>
                          <div>
                            Refresh
                          </div>
                        </div>
                      </button>
                    </div>

                    <div>
                      <button type="button" mat-button class="btn-low" (click)="clickReset()" style="height: 2.1em;  width: 6.3em;  margin-top: 0.5em;" >
                        <div style="display: flex">
                          <div style="margin-top: 0.25em">
                            <i class="material-icons" style="font-size: larger;cursor: pointer; ">restart_alt</i>
                          </div>
                          <div>
                            Reset
                          </div>
                        </div>
                      </button>
                    </div>
                </div>
                <div >
                  <button mat-button class="btn-med" style="margin-left: 0.5em;height: 2.1em;"  (click)="clickRoute('fuelmenu/fuel-menu')"> <i class="material-icons" style="font-size: medium;cursor: pointer; ">arrow_back</i>  Return to Fuel Dashboard</button>
                  
                  <div *ngIf="recordList.length != 0" style="margin-top:3.5em;margin-left: 7.3em; ">{{recordList.length}} Records Found</div>
                  <div style="width:10em;margin-left: 3em;"><app-spin *ngIf="showSpin"></app-spin></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div></div>
      </div>

      <div style="width: 93em">
        <!-- <div style="display:flex; justify-content:space-between">
          <div></div>
          <div style="display: flex; ">
            <div style="display: flex; " *ngIf="totalRecords>minPageSize">
              <div style="font-size: small; font-weight: bold; margin-top: 1.25em; margin-right: 0.25em">
                Records per page:
              </div>
              <div style="margin-top:0.85em">

                
                <mat-select formControlName="custom_page_size_select1" style="font-size:small; padding:0em 0em 0em 0em; border-color: lightgray" class="form-control-sm dropdown" (selectionChange)="customPageSizeChange($event,1)">
                  <mat-option *ngFor="let cus of customPageSizeOptionList" [value]="cus.pageSizeValue">{{cus.pageSizeText}}</mat-option>
                </mat-select>
              </div>
            </div>
            <div style="width:21em">
              <mat-paginator style="border: none; font-weight: bold; color: black; padding: 0em 0em 0em 0em" #paginator *ngIf="recordList && totalPageNumber>=1" [pageSize]="pageSize" [length]="totalRecords"
                             [hidePageSize]="true" [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
              </mat-paginator>
            </div>
          </div>
        </div> -->

        <!-- [ngClass]="currentScreenSize" -->
        <!-- <div  style="   width: min-content;  overflow-y: auto;"   >
                  </div> -->

          <table mat-table *ngIf="recordList"  matSort matSortActive="leadtime_duedate"  (matSortChange)="sortData($event)"  [dataSource]="recordList" class="example-container mat-elevation-z8">
            <tr>
              <ng-container matColumnDef="blank1">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" style="border-bottom:none">&nbsp;</th>
              </ng-container>
              <ng-container matColumnDef="departure">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none">
                  <div style="display:flex; margin-left:5.5em; margin-top:0.25em">
                    <div style="margin-right:0.25em"><i class="material-icons" style="border: black 0.0625em solid; font-size:large">flight_land</i></div>
                    <div style="margin-top:-0.25em;margin-right:1.5em">Arrival</div>
                  </div>
                </th>
              </ng-container>
              
              <ng-container matColumnDef="arrival">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" style="border-bottom:none">
                  <div style="display:flex; margin-left:0em; margin-top:0.25em">
                    
                    <div style="margin-right:0.25em"><i class="material-icons" style="border: black 0.0625em solid; font-size:large">flight_takeoff</i></div>
                    <div style="margin-top:-0.25em;">Departure</div>
                  </div>
                </th>
              </ng-container><ng-container matColumnDef="blank2">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="1" style="border-bottom:none">&nbsp;</th>
              </ng-container>
              <ng-container matColumnDef="blank3">
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" style="border-bottom:none">&nbsp;</th>
              </ng-container>
            </tr>
            <tr>
              <ng-container matColumnDef="leadtime_duedate">
                <th mat-sort-header="leadtime_duedate"  mat-header-cell *matHeaderCellDef style="width:8%; text-align:left !important">
                  <div style="display:flex">
                    <div style="margin-top:-0.25em;" *ngIf="date_type=='leadtime'">Lead Time</div>
                    <div style="margin-top:-0.25em;" *ngIf="date_type!='leadtime'">Due Date</div>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  {{date_type=='leadtime'?element.lead_DateTimeDisplay:element.due_DateDisplay}}
                </td>
              </ng-container>
              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef style="width:13%; text-align:left !important">
                  <div style="margin-top:-0.25em; margin-left:1.55em">Service</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;">
                  <div style="display: flex">
                    <div *ngIf="element.isRevisionRequiredValue==1" style="margin-top:0.25em; cursor: pointer;" (click)="clickTripRevisionRequired($event, element)"><i class="material-icons-two-tone icon_orange" style="font-size: medium">notifications_active</i></div>
                    <div *ngIf="element.isRevisionRequiredValue==2" style="margin-top:0.25em; cursor: pointer;" (click)="clickTripRevisionRequired($event, element)"><i class="material-icons-two-tone icon_red" style="font-size: medium">notifications_active</i></div>
                    <div *ngIf="element.isRevisionRequiredValue==0" style="margin-top:0.25em; width:1.265em">&nbsp;</div>
                    <div *ngIf="element.immediateActionRequired" style="margin-top:0.25em; " title="Immediate action required"><i class="material-icons-two-tone icon_red" style="font-size: medium">alarm_on</i></div>
                    <div style="border-radius: 50%; width: 0.9em; height: 0.9em; border: black solid 0.0625em;margin-top:0.5em; margin-right:0.25em" [style.background-color]="element.taskStatusBackgroundColor" title="{{element.taskStatusDescription}}"></div>
                    <div style="display: flex;">
                      <div>
                        <div style="display: flex;height:1.5em;margin-top:0.12em" class="divHover">
                          <div title="{{element.serviceTypeDescription}}" *ngIf="element.strikeOutService" (click)="clickServiceType($event,element)" style="cursor: pointer;cursor: pointer;  color:dodgerblue;  font-size: small; text-decoration: underline; " [style.font-weight]="element.serviceTypeID==34 || element.serviceTypeID==79?'bold':''"><s style="color:red"><span class="textHover">{{element.serviceTypeDescription.substr(0,19)}}</span></s></div>
                          <div title="{{element.serviceTypeDescription}}" *ngIf="!element.strikeOutService" (click)="clickServiceType($event,element)" style="cursor: pointer; cursor: pointer;   color:dodgerblue; font-size: small; text-decoration: underline; " [style.font-weight]="element.serviceTypeID==34 || element.serviceTypeID==79?'bold':''">{{element.serviceTypeDescription.substr(0,19)}}</div>
                          <div *ngIf="element.hasTaskDocument" style="margin-top:0.25em"><i class="material-icons" style="font-size: medium; transform: rotate(-45deg);">attachment</i></div>
                        </div>

                        <!-- <div *ngIf="element.countryName!=''" style="margin-top: -0.25em; color: darkblue" title="{{element.countryName}}">{{element.countryName.substr(0,19)}}</div> -->
                      <div *ngIf="element.additionalDescription!='' && element.additionalDescription!=null" style="margin-top: -0.25em; color: darkblue" title="{{element.additionalDescription}}">{{element.additionalDescription.substr(0,19)}}</div>
                        <div *ngIf="element.fpiConfirmationReference!='' && element.fpiConfirmationReference!=null && element.tripLegList.length==0" style="margin-top: -0.25em; color: darkblue" title="{{element.fpiConfirmationReference}}">{{element.fpiConfirmationReference.substr(0,19)}}</div>
                        <!-- <div *ngFor="let t of element.tripLegList" style="margin-left:0em">
    <div>{{t.departureICAO+ ' - '+t.nextArrivalICAO}}</div>
  </div> -->
                        <!-- <div *ngIf="element.serviceDate!='' || element.serviceTime!=''" style="margin-top: -0.25em; color: darkblue">{{element.serviceDate | date:'dd-MMM'}}  {{element.serviceTime!=''? element.serviceTime + ' Z' : ''}}</div> -->

                      </div>
                      <div></div>
                    </div>
                  </div>

                </td>
              </ng-container>

              <ng-container matColumnDef="requotedate">
                <th  mat-sort-header="requotedate"  mat-header-cell *matHeaderCellDef style="width:6%; text-align:left !important">
                  Re-Quote
                </th>
                <!-- <td   mat-cell *matCellDef="let element"   [ngClass]="(hasTaskDocument && date_type == 'requote')?'colorCell':''"  style="text-align: left !important;" (click)="clickOpen($event, element)">
                  <div  >{{element.fqExpirationDate}}</div>
                </td> -->


                <!-- element.hasTaskDocument && f.date_type.value == 'requote' -->
                <td     mat-cell *matCellDef="let element"  [ngClass]="(checkRequoteExpiry(element.fqExpirationDateUTC, element.arrivalDateTimeUTC, element.hasTaskDocument))?'colorCell':''"  style=" text-align: left !important;" (click)="clickOpen($event, element)">
                  <div  *ngIf="element.hasTaskDocument"  >{{element.fqExpirationDate}}</div>
                </td>
              </ng-container>



              <ng-container matColumnDef="arricao">
                <th mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                  Airport
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;  " (click)="clickOpen($event, element)">
                  <div style="margin-top:-0.25em; cursor: pointer;  color:dodgerblue;  font-size: small; text-decoration: underline; "   (click)="openAirportDetailsByIcao($event, element)">{{element.arrivalICAO}}</div>

                  <div *ngIf="element.arrivalOnHold" style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top: -0.3em; text-align: center; font-weight: normal">On Hold</div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="arrdatetime">
                <th  mat-sort-header="arrdatetime"  mat-header-cell *matHeaderCellDef style="width:9%; text-align:left !important">
                  Date / Time (Z)
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;" (click)="clickOpen($event, element)">
                  {{element.arrivalDateTime}}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="ete">
                <th mat-header-cell *matHeaderCellDef style="width:3%; text-align:left !important">
                  ETE
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  {{element.ete}}
                </td>
              </ng-container> -->

              <!-- <ng-container matColumnDef="depicao">
                <th mat-header-cell *matHeaderCellDef style="width:4%; text-align:left !important">
                  ICAO
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; font-weight: bold" (click)="clickOpen($event, element)">
                  <div style="margin-top:-0.25em;">{{element.departureICAO}}</div>

                  <div *ngIf="element.departureOnHold" style="border-radius: 0.5em; width: 5em; height: 1.25em; border: gray solid 0.0625em;background-color: pink">
                    <div style="margin-top: -0.3em; text-align: center; font-weight: normal">On Hold</div>
                  </div>
                </td>
              </ng-container> -->
              <ng-container matColumnDef="depdatetime">
                <th  mat-sort-header="depdatetime"  mat-header-cell *matHeaderCellDef style="width:9%; text-align:left !important">
                  Date / Time (Z)
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important;" (click)="clickOpen($event, element)">
                  {{element.departureDateTime}}
                </td>
              </ng-container>

            


              
              <ng-container matColumnDef="fsAndIPA">
                <th mat-header-cell *matHeaderCellDef style="width:18%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Fuel Supplier & IPA</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">

                  <div  >{{element.fuelSupplierName}}</div>
                  <div  >{{element.intoPlaneAgent}}</div>
                  <!-- {{element.customerName.substring(0,18)}}{{element.customerName.length>19 ? '...':''}} -->
                </td>
              </ng-container>
              <ng-container matColumnDef="registration">
                <th mat-header-cell *matHeaderCellDef style="width:7%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Registration</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  {{element.registration}}
                </td>
              </ng-container>




              <ng-container matColumnDef="tripCode">
                <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Trip Code</div>
                  <div style="margin-top:-0.25em;">Client Account</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  <a  href="javascript:void(0)"  style="cursor: pointer; color:dodgerblue; font-size: small; text-decoration: underline; " >{{element.tripCode}}</a>
                  <div style="margin-top:-0.25em;" matTooltip="{{element.customerName.length>19 ? element.customerName:''}}">{{element.customerName.substring(0,18)}}{{element.customerName.length>19 ? '...':''}}</div>
                  <!-- <div>{{element.customerReference}}</div> -->

                </td>
              </ng-container>

              <ng-container matColumnDef="internalNote">
                <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Internal Notes</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  <div *ngIf="element.internalNote.length>15" style="cursor:pointer" matTooltipClass="tooltipLineBreak" matTooltip="{{element.internalNote}}">{{ element.internalNote.substring(0,14)+'...' }}</div>
                  <div *ngIf="element.internalNote.length<=15">{{element.internalNote}}</div>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="clientRef">
                <th mat-header-cell *matHeaderCellDef style="width:6%; text-align:left !important">
                  <div style="margin-top:-0.25em;">Client Trip#</div>
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important" (click)="clickOpen($event, element)">
                  <div>{{element.customerTripID}}</div>
                </td>
              </ng-container> -->
              <!-- <ng-container matColumnDef="assigned">
                <th mat-header-cell *matHeaderCellDef style="width:8%">
                  <div>Assigned</div>
                </th>
                <td mat-cell *matCellDef="let element" (click)="clickOpen($event, element)">
                  {{element.assignMe}}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width:10%">
                  <div>Open</div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div style="margin-top:0.25em; display:flex; margin-left:2em; white-space:nowrap">
                    <!-- <div style="margin-right:0.25em">
                      <a mat-button class="btn-high grid" style="align-self:center;padding-left:0.25em !important; padding-right:0.25em !important" [disabled]="element.loading==true" (click)="clickAssignMe($event, element);">
                        <span *ngIf="element.loading" class="spinner-border spinner-border-sm mr-1" style="color:white"></span>
                        {{element.assignMe==''?'Assign Me':'Clear'}}
                      </a>
                    </div> -->
                    <div>
                      <a mat-button class="btn-low grid" style="align-self: center; padding-left: 0.25em !important; padding-right: 0.25em !important" (click)="clickOpen($event, element);">
                        Open
                      </a>
                    </div>
                  </div>
                  <div *ngIf="element.errMsg!=null && element.errMsg!=''" style="color:red">{{element.errMsg}}</div>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn2; sticky: true"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>

        

        
      </div>
      
    </div>
  </form>
</div>
