import { Component, OnInit } from "@angular/core";
import { TripModel } from "../models/trip.model";
import { AuthenticateService } from "../services/authenticate.service";
import { ResponseModel } from "../models/response.model";
import { TripRevisionModel } from "../models/trip-revision.model";
import { GroundStopService } from "../services/ground-stop.service";

@Component({
  selector: 'recent-trips-client',
  templateUrl: './recent-trips-client.component.html',
  styleUrls: ['./recent-trips-client.component.css'],
})

export class RecentTripsClientComponent implements OnInit {
  recordList: TripRevisionModel[]=[];
  filteredRecordList: TripModel[] = [];
  displayedColumn: string[];
  displayedColumn1: string[] = [ 'trip_code', 'route', 'open'];
  totalRecordsText: string;
  showSpin: boolean = false;
  queryHr: number;
  queryHrText: string;
  orgRecordList: TripRevisionModel[] = []; 
  errMsg: string;
  msg: string;
  user_type: string = "";
  
  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopService: GroundStopService) {

  }
  
  ngOnInit() {
    this.queryHr = 8;
    this.displayedColumn = this.displayedColumn1;

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.user_type = "internal";
        break;
      case "c":
        this.user_type = "customer";
        break;
      case "v":
        this.user_type = "vendor";
        break;
      default:
        this.user_type = "";
        break;
    }  

    this.getData();
  }

  getData() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripRevisionModel();
    request.pastHours = this.queryHr;
    request.includeDemo = false;
    // request.showMobileOnly = this.f.showMobileOnly.value == true ? this.f.showMobileOnly.value :false;
    // if (this.f.includeDemo.value == true) {
    //   request.includeDemo = this.f.includeDemo.value;
    // }
    // if (this.f.showMobileOnly.value == true) {
    //   request.showMobileOnly = this.f.showMobileOnly.value;
    // }
   
    this._groundStopService.getTripRevisionByHours<ResponseModel<TripRevisionModel[]>>(request).subscribe(response => {
      this.recordList = [];
      this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.orgRecordList = response.model;
          //this.recordList = response.model;
          this.setRecordList();
          //if (this.recordList.length > 1) {
          //  this.totalRecordsText = this.recordList.length.toString() + " Records Found";
          //}
          //else {
          //  this.totalRecordsText = this.recordList.length.toString() + " Record Found";
          //}

        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;
      
      }
      else {
        if (response.code == "401") {
          
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    });
  }

  setRecordList() {
    this.recordList = [];
    this.filteredRecordList = [];
    switch (this.user_type) {
      case "customer":
        this.recordList = this.orgRecordList.filter(x=>x.isExternal==true);
        this.recordList.filter(x => x.userName.toLowerCase() == localStorage.getItem('un').toLowerCase())[0]?.customerList.forEach(customer => {
          customer.tripList.forEach(trip => {
            trip.customerName = customer.customerName;
            this.filteredRecordList.push(trip);
          })
        })
        break;
      case "internal":
        this.recordList = this.orgRecordList.filter(x => x.isExternal == false);
        break;
      default:
        this.recordList = this.orgRecordList;
        break;
    }
    if (this.recordList.length > 1) {
      this.totalRecordsText = this.recordList.length.toString() + " Records Found";
    }
    else {
      this.totalRecordsText = this.recordList.length.toString() + " Record Found";
    }
  }

  clickOpenTrip(e: any, item: TripModel) {
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }
}