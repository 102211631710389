import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { CountryModel } from "../models/country.model";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonService } from "../services/common.service";
import { ResponseModel } from "../models/response.model";
import { VendorModel } from "../models/vendor.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { GroundStopAdvancedService } from "../services/ground-stop-advanced.service";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { GroundStopService } from "../services/ground-stop.service";
import { HotelModel } from "../models/hotel.model";
import { BusinessRulesService } from "../services/business-rules-service";
import { TripTaskStatusModel } from "../models/trip-task-status.model";
import { ServiceArrangementModel } from "../models/service-arrangement.model";
import { AuthenticateService } from "../services/authenticate.service";
import { EmailInternalDialogComponent } from "../messages/email-internal-dialog.component";
import { GroundStopClientService } from "../services/ground-stop-client.service";
import { AircraftTripModel } from "../models/aircraft-trip.model";
import { ActivatedRoute } from "@angular/router";
import { AccountingStatusModel } from "../models/accounting-status.model";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { FileModel } from "../models/file.model";
import { GlobalConstant } from "../common-utility/global-constant";
import { CustomValidators } from "../common-utility/custom.validators";
import { GroundStopTaskDocumentModel } from "../models/ground-stop-task-document.model";
import { TripMessageCenterComponent } from "../ground-stops/trip-message-center.component";
import { VendorDetailsComponent } from "../vendors/vendor-details.component";
import { TemplateService } from "../services/template.service";
import { VendorService } from "../services/vendor.service";
import { UtilityFunctions } from "../common-utility/utility.functions";
import { BusinessRulesModel } from "../models/business-rules.model";
import { BusinessRulesEditComponent } from "../business-rules/business-rules-edit.component";
import { TripAuditDialogComponent } from "../ground-stops/trip-audit-dialog.component";
import { CountryDetailsComponent } from "../country/country-details.component";
import { CountryDocumentModel } from "../models/country-document.model";
import { CountryService } from "../services/country.service";
import { CountryContentDocModel } from "../models/country-content-doc.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TaskCloseConfirmationDialog } from "./task-close-confirmation-dialog.component";
import { AirportService } from "../services/airport.service";
import { AirportModel } from "../models/airport.model";
import { AirportDetailsComponent } from "../airports/airport-details.component";
import { ConfirmDialogComponent } from "../common-utility/confirm-dialog.component";
import { DialogService } from "../services/dialog.service";
import { DatePipe } from "@angular/common";
import { DateTimeObjModel } from "../models/dateTimeObj.model";
import { AirportLocatorComponent } from "../airports/airport-locator.component";
import { ReturnObjModel } from "../models/return-obj.model";
import { AirportBriefComponent } from "../trip-planner/airport-brief.component";

const datepipe: DatePipe = new DatePipe('en-US');

@Component({
  selector: 'app-slot-arrival-task',
  templateUrl: './slot-arrival-task.component.html',
  styleUrls: ['./slot-arrival-task.component.css']
})

export class SlotArrivalTaskComponent implements OnInit {
  slot_arrival_task_edit_form: UntypedFormGroup;
  countryList: CountryModel[];
  vendorList: VendorModel[];
  gsVendorList: VendorModel[];
  fboList: VendorModel[];
  taskStatusList: TripTaskStatusModel[];
  serviceArrangementList: ServiceArrangementModel[];
  groundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  registration: string;
  tripCode: string = "";
  icao: string;
  airportID: number = 0;
  nextICAO: string;
  prevICAO: string;
  arrivalDateTime: string = "";
  departureDateTime: string = "";
  arrivalDateTimeLocal: string = "";
  departureDateTimeLocal: string = "";
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  selectedOverflightCountry: CountryModel;
  selectedOverflightCountryGUID: string;
  isRevisionRequired: boolean = false;
  vendorReq: boolean;
  lead_timeChanged: boolean = false;
  due_dateChanged: boolean = false;
  due_datetime: string;
  leadTime_Min_Value: number = 0;
  dueDate_Min_Value: number = 0;
  methodOfPayment: string = "";
  hasTaskAudit: boolean;
  errMsg2: string = '';
  serviceTypeDesc: string;
  isActive: number;
  immediateAction: number = 0;
  nextGroundStopGUID: string;
  aircraftGUID: string;
  customerGUID: string;
  clientName: string;
  depGroundStopGUID: string = "";
  selectedTaskStatusGUID: string;
  selectedTaskStatusDescription: string;
  leg: string;
  isModified: boolean = false;
  serviceTypeID: number;
  farTypeID: number;
  defaultEmail: string;
  lockdownTrip: boolean = false;
  lead_time: string = "";
  due_date: string = "";
  submitted: boolean = false;
  loading: boolean = false;
  showSuccessMsg: boolean = false;
  slot_time: string;
  selectedFBOGUID: string;
  selectedLandingVendorGUID: string = "";
  selectedLandingCountryGUID: string = "";
  selectedServiceArrangementID: number;
  permit_order_number: string = "";
  confirmation_number: string;
  public_notes: string = "";
  slot_date: Date;
  airport_notes: string = "";
  internal_notes: string = "";
  onHoldText: string = '';
  tripCodeType: string;
  selectedAcctStatusGUID: string;
  selectedAcctStatusDescription: string;
  selectedAcctStatusTPGUID: string;
  selectedAcctStatusTPDescription: string;
  acctStatusList: AccountingStatusModel[];
  acctStatus_TPList: AccountingStatusModel[];
  uploader: FileUploader;
  finishAllUploads: boolean = true;
  fileList: FileModel[] = [];
  errMsg: string = "";
  maxFileSize: number;
  maxFileUploadNumber: number;
  allowedFileTypeList: string[];
  totalMessageAttachmentFileSize: number = 0;
  allowedFileType: string;
  maxTempDocId: number = 0;
  taskDocumentList: GroundStopTaskDocumentModel[] = [];
  allTaskDocumentList: GroundStopTaskDocumentModel[] = [];
  selectedGroundStopList: GroundStopModel[] = [];
  avoidLocationMsg: string;
  avoidBusinessRuleID: number;
  hasOverflightPermitDocument: boolean;
  avoidList: any;
  avoidLocation: boolean;
  canChangeBilling: boolean = false;
  businessRulesID: number;
  modifiedBy: string = "";
  modifiedDate: string = "";
  slotRequired: string = "";
  showDocuments: boolean = true;
  methodOfPaymentColor: string = "";
  validityTime: string = "";
  requiredDocumentCount: number = 0;
  hasExistingDocument: boolean = false;
  hasModifiedDocuments: boolean = false;
  taskColor: string = "";
  isRevisionRequiredValue: number = 0;
  serviceLocation: string;
  showSpin: boolean = true;
  showDeletedDocuments: boolean = false;
  latitude: number;
  longitude: number;
  airportName: string;
  departureFARTypeDescription: string;
  countryID: number;
  iata: string;
  nextArrivalDateTime: string;
  prevDepartureDateTime: string;
  prevDepartureDateTimeLocal: string;
  nextArrivalDateTimeLocal: string;
  nextAirportID: number;
  prevAirportID: number;

  @ViewChild('airportNotesWrapper') airportNotesTemplate: TemplateRef<any>;
  @ViewChild('publicNotesWrapper') publicNotesTemplate: TemplateRef<any>;
  @ViewChild('internalNotesWrapper') internalNotesTemplate: TemplateRef<any>;
  @ViewChild('slot_date') slot_dateRef: ElementRef;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  slotHexColor: string;
  grantAccessToTripCloseout: boolean = false;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _dialogRef: MatDialogRef<SlotArrivalTaskComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _commonService: CommonService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private readonly _groundStopService: GroundStopService,
    private readonly _businessRulesService: BusinessRulesService,
    private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog,
    private readonly _groundStopClientService: GroundStopClientService,
    private readonly _route: ActivatedRoute,
    private readonly _templateService: TemplateService,
    private readonly _vendorService: VendorService,
    private readonly _countryService: CountryService,
    private readonly _airportService: AirportService,
    private readonly _dialogService: DialogService,
    private renderer: Renderer2) {
    this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.depGroundStopGUID = _data.depGroundStopGUID;
    this.clientName = _data.clientName;
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
  }

  ngOnInit() {

    if (localStorage.getItem('up.tClose') == 'true') {
      this.grantAccessToTripCloseout = true;
    }
    else {
      this.grantAccessToTripCloseout = false;
      // this._dialogService.openAlertDialog("Permission denied.", "OK");

      // return;
    }

    this.showSpin = true;
    this.countryList = [];
    this.vendorList = [];
    this.serviceArrangementList = [];
    this.airport_notes = "";
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.isActive = 1;
    this.leadTime_Min_Value = 0;
    this.dueDate_Min_Value = 0;
    this.tripCodeType = this._route.snapshot.paramMap.get('tType');
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] !== null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          gs = responses[0].model;

          this.registration = gs.registration;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.prevICAO = gs.prevICAO;
          this.nextICAO = gs.nextICAO;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.aircraftGUID = gs.aircraftGUID;
          this.farTypeID = gs.farTypeID;
          this.lockdownTrip = gs.lockdownTrip;
          this.iata = gs.iata;
          this.departureFARTypeDescription = gs.departureFARTypeDescription;
          this.countryID = gs.countryID;
          this.latitude = gs.latitude;
          this.longitude = gs.longitude;
          this.airportName = gs.airportName;
          this.gsVendorList = gs.vendorList;
          this.prevDepartureDateTime = gs.prevDepartureDateTimeUTC;
          this.prevDepartureDateTimeLocal = gs.prevDepartureDateTimeLocal;
          this.nextArrivalDateTime = gs.nextArrivalDateTimeUTC
          this.nextArrivalDateTimeLocal = gs.nextArrivalDateTimeLocal;
          this.airportID = gs.airportID
          this.nextAirportID = gs.nextAirportID;
          this.prevAirportID = gs.prevAirportID;
          this.getAirportNotes(this.icao);
        }
      }
      if (responses[1].code == "200") {
        this.taskStatusList = responses[1].model;
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let tempacctStatusList: AccountingStatusModel[];
          tempacctStatusList = [];
          tempacctStatusList = responses[2].model;
          //this.acctStatusList = responses[2].model;
          this.acctStatusList = tempacctStatusList.filter(x => x.isGTSFlag == true);
          this.acctStatus_TPList = tempacctStatusList.filter(x => x.isGTSFlag != true);
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] !== null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let gst = new GroundStopTaskModel();
          gst = responses[3].model;

          this.due_datetime = gst.due_DateDisplay;
          this.selectedServiceArrangementID = gst.serviceArrangementID;
          this.lead_time = gst.leadTime_Min;
          this.due_date = gst.dueDate_Min;
          this.confirmation_number = gst.fpiConfirmationReference;
          this.public_notes = gst.publicNote;
          this.internal_notes = gst.internalNote;
          if (gst.serviceDate != "")
            this.slot_date = new Date(gst.serviceDate);
          this.slot_time = gst.serviceTime;
          this.businessRulesID = gst.businessRulesID;
          this.hasTaskAudit = gst.hasTaskAudit;
          this.modifiedBy = gst.modifiedBy;
          this.modifiedDate = gst.modifiedDate;
          this.serviceTypeID = gst.serviceTypeID;
          this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
          if (this.isRevisionRequiredValue != 0) {
            this.isRevisionRequired = true;
          }

          this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
          this.selectedAcctStatusGUID = gst.accountingStatusGUID;
          this.selectedAcctStatusTPGUID = gst.accountingStatusGUID_TP.toLowerCase();
          if (this.selectedAcctStatusGUID != "")
            this.selectedAcctStatusGUID = this.selectedAcctStatusGUID.toLowerCase();

          this.selectedAcctStatusDescription = this.acctStatusList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusGUID)[0]?.accountingStatusDescription;
          this.selectedTaskStatusDescription = this.acctStatus_TPList.filter(x => x.accountingStatusGUID === this.selectedAcctStatusTPGUID)[0]?.accountingStatusDescription;

          if (gst.taskStatusDescription == "Client Setup") {
            this.selectedAcctStatusDescription = this.selectedTaskStatusDescription = "Do Not Bill";
          }

          if (localStorage.getItem('up.lockTrip') == 'true') {
            this.canChangeBilling = true;
          }
          else {
            this.canChangeBilling = false;
          }

          this.serviceLocation = gst.serviceLocation;
          if (gst.serviceLocation == 'D' && gst.departureOnHold) {
            this.onHoldText = "On Hold";
          }
          else {
            if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
              this.onHoldText = "On Hold";
            }
          }
          if (gst.isActive == true)
            this.isActive = 1;
          else
            this.isActive = 0;
          if (gst.immediateActionRequired) {
            this.immediateAction = 1;
          }
          else {
            this.immediateAction = 0;
          }

          this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.selectedTaskStatusGUID)[0].hexColor;

          this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
          if (this.isRevisionRequiredValue != 0) {
            this.isRevisionRequired = true;
          }
        }
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model.length > 0) {
            this.allTaskDocumentList = responses[4].model;
            this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[5].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[6] != null) {
        if (responses[6].code == "200") {
          this.countryList = responses[6].model;
          //this.selectedOverflightCountry = this.countryList.find(v => v.countryGUID === this.selectedOverflightCountryGUID);

          let c = new CountryModel();
          c.countryGUID = '00000000-0000-0000-0000-000000000000';
          c.countryName = 'Not Required';
          this.countryList.push(c);
          if (this.selectedOverflightCountryGUID != "" && this.selectedOverflightCountryGUID != '00000000-0000-0000-0000-000000000000')
            this.hasOverflightPermitDocument = this.countryList.find(x => x.countryGUID == this.selectedOverflightCountryGUID).hasOverflightPermitDocument;
        }
      }
      if (responses[7].code == "200") {
        this.vendorList = responses[7].model;
      }
      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[7].message == "") {
          this.fboList = responses[8].model;
          if (this.selectedFBOGUID != "") {
            this.methodOfPayment = this.fboList.find(x => x.vendorGUID == this.selectedFBOGUID).vendorMethodOfPaymentDescription;
          }
        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[12] !== null) {
        if (responses[12].code == "200") {
          this.serviceArrangementList = responses[12].model;
        }
      }
      if (responses[13] !== null) {
        if (responses[13].code == "200") {
          this.selectedGroundStopList = responses[13].model;
          this.selectedGroundStopList = this.selectedGroundStopList.filter(v => v.nextGroundStopGUID != '');
          this.selectedGroundStopList = this.selectedGroundStopList.filter(v => v.groundStopTypeDescription != 'Contingency');
        }
      }
      if (responses[15] != null) {
        if (responses[15].code == "200" && responses[15].message == "") {
          this.avoidList = responses[15].model;
        }
        else {
          if (responses[15].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      this.showSpin = false;
    });

  //  this.initControls();
  }

  initControls() {
    this.slot_arrival_task_edit_form = this._formBuilder.group({
      isRevisionRequired: [this.isRevisionRequired],
      permit_order_number: [this.permit_order_number],
      confirmation_number: [this.confirmation_number],
      public_note: [this.public_notes],
      internal_note: [this.internal_notes],
      slot_date: [this.slot_date],
      slot_time: [this.slot_time],
      country_overflight_note: [this.airport_notes],
      task_status_select: [this.selectedTaskStatusGUID],
      acct_status_select: [this.selectedAcctStatusGUID],
      acct_status_tp_select: [this.selectedAcctStatusTPGUID],
      service_arrangement_landing_select: [this.selectedServiceArrangementID],
      record_status: this.isActive,
      immediateAction: this.immediateAction,
      lead_time: [this.lead_time, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      due_date: [this.due_date, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      document: [null],
    });
  }

  get f() { return this.slot_arrival_task_edit_form.controls; }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    let getCountryList = of(null);

    request.icao = this.icao
    request.serviceTypeDescription = this.serviceTypeDesc;
    let getFBOResponse = of(null);
    // if (this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 88 && this.vendorReq == true) {
    //   //getFBOResponse = this._groundStopAdvancedService.getFBOListByAirport(request);
    //   getFBOResponse = this._groundStopAdvancedService.getFBOListByAirportServiceType(request);
    // }
    // else {
    //   getFBOResponse = of(null);
    // }

    let getVendorResponse = this._groundStopAdvancedService.getVendorListForPermitByAirportCountry(request);

    let getfpRecallNumberResponse;
    getfpRecallNumberResponse = this._groundStopAdvancedService.getFlightPlanRecallNumberListBygsId(req2);

    let req3 = new HotelModel();
    //req3.countryGUID = this.countryGUID;
    req3.icao = this.icao;
    req3.distanceFromAirport = 50;

    let getHotelResponse = of(null);
    // if (this.serviceTypeID == 7 || this.serviceTypeID == 20) {
    //   //getHotelResponse = this._commonService.getHotelList(req3);
    //   getHotelResponse = this._commonService.getHotelListByAirport(req3);
    // }
    // else {
    //   getHotelResponse = of(null);
    // }

    let getFuelSupplierResponse = of(null);
    // if (this.serviceTypeID == 56) {
    //   getFuelSupplierResponse = this._groundStopAdvancedService.getFuelSupplierListByAirport(request);
    // }
    // else {
    //   getFuelSupplierResponse = of(null);
    // }

    let getServiceArrangementResponse;
    if (this.vendorReq == true) {
      getServiceArrangementResponse = this._groundStopAdvancedService.getServiceArrangementList();
    }
    else {
      getServiceArrangementResponse = of(null);
    }

    let getGroundStopList = of(null);
    // if (this.serviceTypeID == 34 || this.serviceTypeID == 79 || this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87 || this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID == 91) {
    //   let request = new GroundStopModel;
    //   request.tripCodeGUID = this.tripCodeGUID;
    //   request.groundStopGUID = this.groundStopGUID;
    //   request.customerGUID = this.customerGUID;
    //   getGroundStopList = this._groundStopService.getGroundStopPairListBytId(request); //this._groundStopService.getGroundStopListForFlightBrief(request);
    // }
    // else {
    //   getGroundStopList = of(null);
    // }

    let getSSNVendorResponse = this._groundStopService.getSSNVendorIDbygsID(this.groundStopGUID);
    let brRequest = new GroundStopModel();
    brRequest.tripCodeGUID = this.tripCodeGUID;
    brRequest.groundStopGUID = this.groundStopGUID;
    let getAvoidList = this._businessRulesService.getBusinessRulesLocationsToAvoidByTripCodeGUID(brRequest);

    let getCustomsResponse = of(null);
    // if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) {
    //   request.serviceTypeIDList = this.serviceTypeID.toString();
    //   getCustomsResponse = this._groundStopAdvancedService.getVendorListForCustomsByAirport(request);
    // }
    // else {
    //   getCustomsResponse = of(null);
    // }

    let getTripResponse;
    let tripRequest = new AircraftTripModel();
    tripRequest.aircraftGUID = this.aircraftGUID;
    tripRequest.tripCodeType = this.tripCodeType;
    tripRequest.startDateText = localStorage.getItem('trip.sd');
    tripRequest.endDateText = localStorage.getItem('trip.ed');
    tripRequest.tripCodeGUID = this.tripCodeGUID;
    getTripResponse = this._groundStopClientService.getTripBytId(tripRequest);

    return forkJoin([getGSResponse, getTaskStatusResponse, getAcctStatusResponse, getTaskResponse, getTaskDocResponse,
      getAllowedDocTypeResponse, getCountryList, getVendorResponse, getFBOResponse, getfpRecallNumberResponse, getHotelResponse,
      getFuelSupplierResponse, getServiceArrangementResponse, getGroundStopList,
      getSSNVendorResponse, getAvoidList, getCustomsResponse]);

  }

  clickClose() {
    let returnObj = new ReturnObjModel();
    returnObj.isModified = this.isModified;
    returnObj.groundStopGUIDList = [];
    
    if ((this.slot_arrival_task_edit_form.dirty && !this.isModified) || (this.hasModifiedDocuments && !this.isModified)) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.clickSave();
        }
        else {
          this._dialogRef.close(returnObj);
        }

        this.hasModifiedDocuments = false;
      });
    }
    else {
      this._dialogRef.close(returnObj);
    }
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.depGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: taskGroundStopGUID, v: this._authService.getCurrentTimeNumber(),
      fromTaskEditDaialog: true, clientName: this.clientName, tripCode: this.tripCode, leg: this.leg, serviceDesc: this.serviceTypeDesc, requiredGsId: this.groundStopGUID,
      servcieStatus: this.taskStatusList.filter(s => s.taskStatusGUID == this.selectedTaskStatusGUID)[0].taskStatusDescription, farTypeID: this.farTypeID, defaultEmail: this.defaultEmail, lockdownTrip: this.lockdownTrip, icao: this.icao
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.isModified = true;
      }
    });
  }

  updateNotes(event: any, isPublic: boolean) {
    if (isPublic)
      this.public_notes = event;
    else
      this.internal_notes = event;
  }

  taskStatusChange(e: any, item: TripTaskStatusModel) {
    this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.f.task_status_select.value.toLowerCase())[0].hexColor;
    this.selectedTaskStatusGUID = this.f.task_status_select.value.toLowerCase();
  }

  closeNotesPopup(index: number) {
    switch (index) {
      case 0:
        this.f.public_note.setValue(this.public_notes);
        break;
      case 1:
        this.f.internal_note.setValue(this.internal_notes);
        break;
      case 2:
        this.f.airport_note.setValue(this.airport_notes);
    }
  }

  openVendorDetails() {
    let vendorID;

    if (this.f.vendor_select.value == null || this.f.vendor_select.value == "" || this.f.vendor_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
      return;
    vendorID = this.f.vendor_select.value;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: vendorID,
      icao: this.icao,
      gsID: this.groundStopGUID
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }

  clickTripMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "105em";//"1000px";
    dialogConfig.height = "45em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID,
      registration: this.registration, selectedGroundStopList: this.selectedGroundStopList, tripCodeText: this.tripCode,
      groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, serviceLocation: this.serviceLocation, includeActiveStopOnly: false,
      lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }
    });
  } 

  clickSave(callFrom: string = "") {
    this._authService.updateAccessTime();
    this.errMsg = "";

    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    if (this.f.task_status_select.value.toLowerCase() == "ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8") {
      if ((this.f.slot_date.value == "" || this.f.slot_date.value == null) || (this.f.slot_time.value == "" || this.f.slot_time.value == null)) {
        this.errMsg = "Slot date/time is required."
        return;
      }
    }

    if (this.slot_dateRef.nativeElement.value !== "") {
      if (this.f.slot_date.value != "" && this.f.slot_date.value != null && this.f.slot_date.value != undefined) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.slot_date.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.slot_date.setErrors({ isValidDate: f1 });

        }
      }
      else {
        this.f.slot_date.setErrors({ isValidDate: false });

      }
    }

    if (this.f.slot_time.errors) {
      this.errMsg = "Slot Time must be in HH:mm format.";
    }

    if (this.slot_arrival_task_edit_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.f.lead_time.value != "" && this.leadTime_Min_Value == 0) {
      this.calculateLeadTime_DueDate('l', this.f.lead_time.value);
    }
    if (this.f.due_date.value != "" && this.dueDate_Min_Value == 0) {
      this.calculateLeadTime_DueDate('d', this.f.due_date.value);
    }

    let request = new GroundStopTaskModel();

    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeDescription = this.serviceTypeDesc;
    request.vendorGUID = "";
    request.countryGUID = "";
    request.modifiedBy = this.modifiedBy;
    request.modifiedDate = this.modifiedDate;

    request.flightPlanRecallNo = "";

    request.overflightPermitOrderNo = 0;

    //request.flightPlanRecallNo = this.f.fp_recall_number_select.value;

    request.hotelGUID = "";
    request.fuelSupplierGUID = "";
    request.fuelIntoPlaneAgentName = "";
    // if (this.serviceTypeID == 56) {
    //   request.fuelSupplierGUID = this.f.intoplane_agent_select.value;
    //   if (request.fuelSupplierGUID != "")
    //     request.fuelIntoPlaneAgentName = this.intoPlaneAgentList.find(x => x.vendorGUID.toLowerCase() == request.fuelSupplierGUID.toLowerCase()).intoPlaneAgent;
    // }

    request.isActive = true;
    if (this.f.record_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.taskStatusGUID = this.f.task_status_select.value;
    request.accountingStatusGUID = this.f.acct_status_select.value;
    request.accountingStatusGUID_TP = this.f.acct_status_tp_select.value;
    request.leadTime_Min = this.f.lead_time.value;
    request.leadTime_Min_Value = this.leadTime_Min_Value;
    request.fpiConfirmationReference = this.f.confirmation_number.value;
    request.serviceDate = this.slot_dateRef.nativeElement.value;
    request.serviceTime = this.f.slot_time.value;
    request.dueDate_Min = this.f.due_date.value;
    request.dueDate_Min_Value = this.dueDate_Min_Value;
    request.serviceArrangementID = 0;
    if (this.vendorReq) {
      switch (this.serviceTypeID) {
        case 3: // overflight permit
        case 5: // landing permit
        case 26: // charter
        case 88:
          request.serviceArrangementID = Number(this.f.service_arrangement_landing_select.value);
          break;
        default:
          request.serviceArrangementID = Number(this.f.service_arrangement_handling_select.value);
          break;
      }
    }
    else {
      if (this.serviceTypeID == 56) {
        request.serviceArrangementID = Number(this.f.service_arrangement_fuel_select.value);
      }
    }
    if (this.f.isRevisionRequired.value == true) {
      request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    }
    else {
      request.isRevisionRequiredValue = 0;//this.f.isRevisionRequired.value;
    }

    request.revisionCounter = 0; //Number(this.f.revision_counter.value);
    request.publicNote = this.f.public_note.value;
    //request.vendorNote = this.f.vendor_note.value;
    request.internalNote = this.f.internal_note.value;
    // request.importantNote = this.f.important_note.value;
    // request.weatherNote = this.f.weather_note.value;
    // request.notamsNote = this.f.notams_note.value;
    // request.routeNote = this.f.route_note.value;

    request.groundStopTaskDocumentList = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = "";
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      });
    }

    // request.terminalForecast = this.f.terminal_note.value;
    // request.metars = this.f.metars_note.value;
    // request.sigmets = this.f.sigmets_note.value;
    // request.airmets = this.f.airmets_note.value;
    // request.pireps = this.f.pireps_note.value;
    // request.tropicalAdvisory = this.f.tropical_note.value;
    // request.vaAdvisory = this.f.va_note.value;
    // request.departureNotams = this.f.departure_note.value;
    // request.destinationNotams = this.f.destination_note.value;
    // request.etpNotams = this.f.etp_note.value;
    // request.fdcNotams = this.f.fdc_note.value;
    // request.trackMessage = this.f.track_note.value;
    // request.tfrNotams = this.f.tfr_notams.value;
    // request.synopsis = this.f.synopsis_note.value;
    // request.turbulence = this.f.turbulence_note.value;
    // request.immediateActionRequired = false;
    if (this.f.immediateAction.value == 1) {
      request.immediateActionRequired = true;
    }
    this.loading = true;


    request.isAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive).length > 0)
    ) ? true : false;
    request.isInternalAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive).length > 0)
    ) ? true : false;
    request.isPublicNoteIcon = (request.publicNote.trim() != '' && request.publicNote.trim() != null) ? true : false;
    request.isInternalNoteIcon = (request.internalNote.trim() != '' && request.internalNote.trim() != null) ? true : false;


    if (this.selectedTaskStatusGUID?.toLowerCase() == 'ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8'
      || this.selectedTaskStatusGUID?.toLowerCase() == '6aaa0374-7279-4edb-84cb-927918a85584'
      || this.selectedTaskStatusGUID?.toLowerCase() == '483d7593-d276-443c-bf25-bba400265727') {
      request.immediateActionRequired = false;
    }


    this._groundStopAdvancedService.updateGroundStopTaskBygstId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getTaskDocumentList();
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          if (callFrom != "flightBrief") {
            this.showSuccessMsg = true;
            this.modifiedBy = localStorage.getItem('un');
            this.modifiedDate = Date.now().toString();
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose();
            }, 1000); //change to 1 second
          }
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

    if (this.fileList.length == 0 && this.taskDocumentList.length > 0) {
      this.taskDocumentList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.documentName;
        obj.fileExtension = x.fileExtension;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = x.groundStopTaskDocumentGUID;
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      })

      this._groundStopAdvancedService.updateExistingDocumentsBygstId<ResponseModel<boolean>>(request).subscribe(response => {

      })
    }
  }

  exportNote(format: string, isPublic: boolean) {
    this.errMsg2 = "";
    let s: string = "";
    if (isPublic) {
      s = this.f.public_note.value;
    }
    else {
      s = this.f.internal_note.value;
    }

    let docName = this.registration + ' ' + this.icao + '-' + this.nextICAO + ' ' + this.serviceTypeDesc + ' ' + datepipe.transform(new Date().toUTCString(), 'MMddyy', "UTC");
    this._templateService.exportNotes(this.serviceTypeDesc.toUpperCase(), s, format, docName).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();

    });
  }

  clickCopyNote(isPublic: boolean) {
    let s: string = "";
    if (isPublic) {
      s = this.f.public_note.value;
    }
    else {
      s = this.f.internal_note.value;
    }

    let sNew: string = "";

    if (s != "") {
      var s1 = s.split('\n');
      for (var i = 0; i < s1.length; i++) {
        if (s1[i].trim() == "") {
          s1[i] = " ";
        }
        sNew += s1[i] + "\n";
      }

    }
    else {
      sNew = s;
    }
    return sNew;

    //return s;
  }

  toggleInternalFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isInternal = !file.isInternal;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isInternal = !document.isInternal;

    this.hasModifiedDocuments = true;
  }

  toggleRemoveFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isActive = !file.isActive;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isActive = !document.isActive;

    this.hasModifiedDocuments = true;
  }

  getTaskDocumentList() {
    let request = new GroundStopTaskModel()
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.allTaskDocumentList = [];
        this.allTaskDocumentList = response.model;
        if (this.allTaskDocumentList.filter(x => x.isActive).length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new GroundStopTaskDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.groundStopTaskDocumentGUID = "";
            obj.isInternal = x.isInternal;
            obj.isActive = x.isActive;
            this.allTaskDocumentList.push(obj);
          });
        }
        if (this.showDeletedDocuments)
          this.taskDocumentList = this.allTaskDocumentList.slice()
        else
          this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;
  }

  openAirportDetails(type: string) {
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    //dialogConfig.width = "78em";//"1000px";
    //dialogConfig.height = "38.75em";//"500px";
    //dialogConfig.disableClose = true;
    //dialogConfig.hasBackdrop = false;

    //dialogConfig.data = {
    //  searchKey: airportCode
    //};

    //const dialogRef = this._dialog.open(AirportLocatorComponent, dialogConfig);
    let airport = new AirportModel();
    airport.airportID = type=="D"? this.serviceLocation=='D'? this.airportID:this.nextAirportID : this.serviceLocation=='D'?this.nextAirportID:this.prevAirportID;    
    airport.icao = this.icao;
    //airport.iata = this.iata;
    //airport.countryId = this.countryID,
    //  airport.airportName = this.airportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: airport.airportID,
      icao: this.icao,
      customerGUID: this.customerGUID,
      farTypeID: this.farTypeID,
      farType: this.departureFARTypeDescription,
      route: '',
      handlerGUID: this.gsVendorList.find(x => x.vendorRoleDescription == 'Handler')?.vendorGUID,
      ssnGUID: this.gsVendorList.find(x => x.vendorRoleDescription == 'SSN')?.vendorGUID,
      callFrom: 'slots'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.lead_timeChanged = true;
      if (this.f.lead_time.errors) {
        return;
      }
      this.lead_timeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTime_Min_Value = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.due_dateChanged = true;
      if (this.f.due_date.errors) {
        return;
      }
      this.due_dateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDate_Min_Value = 0;
    }
  }

  async getAirportNotes(icao: string) {
   
    await this._airportService.getAirportNotesByICAONoteType<ResponseModel<AirportModel>>(icao, 60).subscribe(response => {
      this.airport_notes = response.model[0].airportNote;
    })
  }

  innerHtmlClick(e: any) {
    var element = document.getElementById(e.target.id);

    if (e.target.id.includes('vendor-comm')) {
      var link = element.getAttribute('data-address');
      window.open(link, '_blank');
    }
    else {
      var containerPath = element.getAttribute('data-containerPath');
      var containerName = element.getAttribute('data-containerName');
      var mimeType = element.getAttribute('data-mimeType');

      this.previewDocument(containerName, containerPath, mimeType);
    }
  }

  clickOpenInNew(index: number) {
    switch (index) {
      case 1:
        this._dialog.open(this.publicNotesTemplate);
        break;
      case 2:
        this._dialog.open(this.internalNotesTemplate);
        break;
      case 3:
        this._dialog.open(this.airportNotesTemplate);
        break;
    }
  }

  previewDocument(containerName: string, containerPath: string, mimeType: string) {
    let countryDoc: CountryDocumentModel = new CountryDocumentModel();
    countryDoc.containerName = containerName;
    countryDoc.containerPath = containerPath;

    this._countryService.downloadCountryDoc<ResponseModel<string>>(countryDoc).subscribe(dc => {
      if (dc.code == "200" && dc.model != '') {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(dc.model);
        let file = new Blob([byteArray], { type: mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  }

  openAirportNotesDetailed() {
    //this.icao = a.icao;
    // this.countryID = a.countryID;

    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    //dialogConfig.hasBackdrop = false;
    //// dialogConfig.minWidth = '60%';
    //// dialogConfig.maxWidth = '60%';
    //// dialogConfig.width = "58em";
    //// dialogConfig.maxHeight = '43em';
    //dialogConfig.panelClass = "custom-dialog-container";

    //dialogConfig.data = {
    //  icao: this.icao,
    //  groundStopGUID: this.groundStopGUID,
    //  tripCodeGUID: this.tripCodeGUID,
    //  customerGUID: this.customerGUID
    //};

    //setTimeout(() => {
    //  const dialogRef = this._dialog.open(AirportDetailsComponent, dialogConfig);
    //  dialogRef.afterClosed().subscribe(result => {

    //  });

    //}, 250);
    let airport = new AirportModel();
    airport.airportID = this.airportID;
    airport.latitude = this.latitude;
    airport.longitude = this.longitude;
    airport.icao = this.icao;
    airport.iata = this.iata;
    airport.countryId = this.countryID,
      airport.airportName = this.airportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: this.airportID,
      icao: this.icao,
      customerGUID: this.customerGUID,
      farTypeID: this.farTypeID,
      farType: this.departureFARTypeDescription,
      route: '',
      handlerGUID: this.gsVendorList.find(x => x.vendorRoleDescription == 'Handler')?.vendorGUID,
      ssnGUID: this.gsVendorList.find(x => x.vendorRoleDescription == 'SSN')?.vendorGUID,
      callFrom: 'slots'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTime_Min_Value = totalmin;
      }
      if (type == 'd') {
        this.dueDate_Min_Value = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTime_Min_Value = totalmin;
        }
        if (type == 'd') {
          this.dueDate_Min_Value = totalmin;
        }
        if (this.arrivalDateTime != "") {
          let dt = new Date(this.arrivalDateTime);
          dt.setMinutes(dt.getMinutes() - totalmin);
          if (dt != null) {
            let m = dt.getMonth() + 1;
            let tempMin = dt.getMinutes();
            let stempMin = "";
            if (tempMin < 10) {
              stempMin = "0" + tempMin.toString();
            }
            else {
              stempMin = tempMin.toString();
            }
            let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
            //if (type == 'l') {
            //  this.f.lead_datetime.setValue(sdt);             
            //}
            //if (type == 'd') {
            //  this.f.due_datetime.setValue(sdt);
            //  this.f.due_datetime.setErrors(null);
            //}
          }
        }
        else {
          if (this.departureDateTime != "") {
            let dt = new Date(this.departureDateTime);
            dt.setMinutes(dt.getMinutes() - totalmin);
            if (dt != null) {
              let m = dt.getMonth() + 1;
              let tempMin = dt.getMinutes();
              let stempMin = "";
              if (tempMin < 10) {
                stempMin = "0" + tempMin.toString();
              }
              else {
                stempMin = tempMin.toString();
              }
              let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
              //if (type == 'l') {
              //  this.f.lead_datetime.setValue(sdt);              
              //}
              //if (type == 'd') {
              //  this.f.due_datetime.setValue(sdt);
              //  this.f.due_datetime.setErrors(null);
              //}
            }
          }
        }
      }
    }
  }

  clickPromoteBusinessRule() {
    let br = new BusinessRulesModel();

    br.serviceTypeID = this.serviceTypeID;
    br.customerGUID = this.customerGUID.toLowerCase();
    br.aircraftGUIDList = this.aircraftGUID;
    br.icao = this.icao;
    br.vendorGUID = "";
    br.taskStatusGUID = this.f.task_status_select.value.toLowerCase();
    br.vendorGUID = this.f.vendor_select.value.toLowerCase();
    br.countryGUID = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: 0, brData: br, title: "Add Business Rule from Task" };
    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  clickRemoveDocument(item: GroundStopTaskDocumentModel) {
    this.errMsg = "";
    this.isModified = true;
    this.hasModifiedDocuments = true;
    if (item.groundStopTaskDocumentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new GroundStopTaskDocumentModel();
      request.isActive = !item.isActive;
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.getTaskDocumentList();
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
          this.fileList.splice(index, 1);
        }
      }
      );
      this.taskDocumentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
            this.taskDocumentList.splice(index, 1);
          }
        }
      )
    }
  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }


  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }

        }
        else {

          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);

        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  clickCopyNotes() {
    const element = document.getElementById('airport_notes');
    if (element) {
      return element.innerText.trim();
    }
  }

  importFile(event: any) {
    this.errMsg = "";

    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.hasModifiedDocuments = true;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new GroundStopTaskDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            obj.isInternal = item.isInternal;
            obj.isActive = true;
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.isActive = true;
            obj2.groundStopTaskDocumentGUID = "";
            this.taskDocumentList.push(obj2);
            this.allTaskDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  timeOnchange(e: any) {
    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.f.slot_time.setValue(timeObj.timeString);
      if (!timeObj.isValidDateTime) {
        this.f.slot_time.setErrors({ isValidDate: false })
      }
    }
  }

  toggleDeleted() {
    this.showDeletedDocuments = !this.showDeletedDocuments;
    if (this.showDeletedDocuments)
      this.taskDocumentList = this.allTaskDocumentList.slice()
    else
      this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();

  }
}
