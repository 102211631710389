import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef, Renderer2} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service';
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { IdentityService } from '../services/identity.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { PrivacyPolicyDialogComponent } from '../common-utility/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from '../common-utility/terms-of-use-dialog.component';
import { ContactUsComponent } from '../common-utility/contact-us.component';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MenuService } from '../services/menu.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.css']
})

export class UsernameComponent implements OnInit, AfterViewInit {
  usernameForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  //saveUsername: boolean;
  //username: string;
  request: IdentityRequestModel;
  errMsg: string;
  hide: boolean = true;
  maintenanceMessage: string = "";
  @ViewChild('usernameRef') usernameRef: ElementRef;
  showHelp: boolean = false;
  websiteEnvironment: string = "";
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder, private readonly _el: ElementRef, private readonly _authService: AuthenticateService,
    private readonly _identityService: IdentityService, private readonly _dialog: MatDialog, private readonly _titleService: Title,
    private readonly _menuService: MenuService, private readonly _commonService: CommonService, private sanitized: DomSanitizer  ) {

  }

  ngOnInit() {
    var username = localStorage.getItem('un');
    var token = localStorage.getItem('token');
    if (username != null && username != '' && token != null && token != '') {
      switch (localStorage.getItem('ut')) {
        case 'C':
          this._router.navigate(['/current-events-client/current-events-dashboard']);
          break;
        case 'I':
          this._router.navigate(['/current-events/current-events-dashboard']);
          break;
        default:
          this._router.navigate(['/common-utility/blank']);
          break;
      }
      
      return;
    }

    this.usernameForm = this.formBuilder.group({
      username: ['', Validators.required]
    });

    this.getWebsiteEnviroment();

    this._identityService.getMaintenanceMessage<ResponseModel<string>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.maintenanceMessage = response.model;
        }
      }
    })

  }
  getHTML(data: any) {
    return this.sanitized.bypassSecurityTrustHtml(data);
  }
  getWebsiteEnviroment() {
    this._menuService.getWebsiteEnviroment<ResponseModel<string>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.websiteEnvironment = response.model;      
      }

    })
  }

  ngAfterViewInit() {
    //this.usernameRef.nativeElement.focus();
    setTimeout(() => {
      this.usernameRef.nativeElement.focus();
    }, 500);
  }

  get f() { return this.usernameForm.controls; }

  onSubmit() {
    
    this.submitted = true;
    
    if (this.usernameForm.invalid) {
      return;
    }

    localStorage.setItem('tun', this.f.username.value);
    this._router.navigate(['/user-accounts/password']);
    //this.loading = true;
   
    //this.request = new IdentityRequestModel();
    //this.request.username = this.f.username.value;
    
    //this._authService.authUsername<ResponseModel<UserModel>>(this.request).subscribe(response => {
    //  if (response != null && response.code == "200") {
    //    if (response.model != null) {
    //      //this.errMsg = response.model.username;
    //      localStorage.setItem('tun', response.model.username);
    //      this._router.navigate(['/user-accounts/password']);
    //      //this._router.navigate(['/user-accounts/password', { username: response.model.username }]);
    //    }
    //    else {
    //      if (response.message != "") {
    //        this.errMsg = response.message;
    //      }
    //      else {
    //        this.errMsg = "Invalid Username";
    //      }
    //    }
    //  }
    //  else {
    //    this.errMsg = "Error to validate username.";
    //  }

    //  this.loading = false;
    //});

  }

  //openTerm() {
  //  window.open("../../assets/term.html","_blank");
  //}

  clickGetMyUsername() {
    this._router.navigate(['/user-accounts/get-username']);
  }

  //testSendEmail() {
  //  this._identityService.testSendEmail<ResponseModel<boolean>>().subscribe(response => {
  //    if (response != null && response.code == "200") {
  //      if (response.model == true) {
  //        alert('email sent');
  //      }
  //      else {
  //        alert('email not sent');
  //      }
  //    }
  //  });

  //}

  openPrivacy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
   
    this._dialog.open(PrivacyPolicyDialogComponent, dialogConfig);

  }

  openTerm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this._dialog.open(TermsOfUseDialogComponent, dialogConfig);

  }

  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      isInternal: false
    };
    this._dialog.open(ContactUsComponent, dialogConfig);

  }

  openCompanyMarketPage() {
    window.open('https://flightprointernational.com', '_blank');
  }
}

