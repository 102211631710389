import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DialogService } from '../services/dialog.service';
import { PrivacyPolicyDialogComponent } from '../common-utility/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from '../common-utility/terms-of-use-dialog.component';
import { ContactUsComponent } from '../common-utility/contact-us.component';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-mfa-entry',
  templateUrl: './mfa-entry.component.html',
  styleUrls: ['./mfa-entry.component.css']
})

export class MfaEntryComponent implements OnInit, AfterViewInit {
  mfaForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  //saveUsername: boolean;
  keepMeSignedIn: boolean;
  username: string;
  user: UserModel;
  errMsg: string = "";
  hide: boolean = true;
  showDetails: boolean = false;
  @ViewChild('passwordRef') passwordRef: ElementRef;
  websiteEnvironment: string = "";
  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialogService: DialogService, private readonly _dialog: MatDialog, private readonly _menuService: MenuService) {

  }

  ngOnInit() {
    if (localStorage.getItem('kmsi') == null)
      this.keepMeSignedIn = false;
    else
      this.keepMeSignedIn = Boolean(localStorage.getItem('kmsi'));

    //this.username = this._route.snapshot.paramMap.get('username');
    this.username = localStorage.getItem('tun');

    this.mfaForm = this.formBuilder.group({
      //username: this.username,
      pin: ['', Validators.required]
    });
    this.showDetails = false;

    this.getWebsiteEnviroment();

  }

  ngAfterViewInit() {
    this.passwordRef.nativeElement.focus();
  }

  getWebsiteEnviroment() {
    this._menuService.getWebsiteEnviroment<ResponseModel<string>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.websiteEnvironment = response.model;
      }

    })
  }

  get f() { return this.mfaForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.mfaForm.invalid) {
      return;
    }


    this.loading = true;
    this.user = new UserModel();
    this.user.username = this.username; //this.f.username.value;
    this.user.pin = this.f.pin.value;
    this.user.pinToken = localStorage.getItem('pinToken');
    if (localStorage.getItem('kmsi') == "true")
      this.user.keepMeSignedIn = true;
    else
      this.user.keepMeSignedIn = false;
   
    this._authService.authenticate(this.user.username, this.user.password, this.user.keepMeSignedIn, this.user.pin,this.user.pinToken).subscribe(response => {
      if (response.code == "200") {
        if (response.model.forceChangePwd == true) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              reset: true
            }
          };
          this._router.navigate(['/user-accounts/change-password'], navigationExtras);
        }
        else {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              v: this._authService.getCurrentTimeNumber()
            }
          };
     

            switch (localStorage.getItem('ut')) {
              case 'C':
                this._router.navigate(['/current-events-client/current-events-dashboard']);
                break;
              case 'I':
                this._router.navigate(['/current-events/current-events-dashboard']);
                break;
              default:
                this._router.navigate(['/common-utility/blank']);
                break;
            }

          
        }
      }
      else {
        if (response.message != "") {
          if (response.message == "Value cannot be null. (Parameter 'providedPassword')") {
            this._router.navigate(['/user-accounts/password']);
          }
          else
          this.errMsg = response.message;
        }
        else
          this.errMsg = "Invalid user";
      }
      this.loading = false;
    });


  }

  clickCloseDetails() {
    this.showDetails = false;
  }
  //clickDetailDialog() {
  //  this._dialogService.openAlertDialog("dgdfgdf");
  //}
  //openAlertDialog(msg) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.disableClose = false;
  //  dialogConfig.width = "500px";
  //  dialogConfig.height = "200px";

  //  dialogConfig.data = { dialogTitle: "Alert", message: msg };
  //  this.dialog.open(AlertDialogComponent, dialogConfig);
  //}

  //clickDetails() {
  //  this.showDetails = !this.showDetails;
  //  return;
  //}

  clickChange() {
    this._router.navigate(['/user-accounts/username']);
  }

  clickForgotPwd() {
    //this._router.navigate(['/user-accounts/reset-password', { username: this.username }]);
    this._router.navigate(['/user-accounts/reset-password']);
  }

  //openTerm() {
  //  window.open("../../assets/term.html", "_blank");
  //}

  openPrivacy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this._dialog.open(PrivacyPolicyDialogComponent, dialogConfig);

  }

  openTerm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    this._dialog.open(TermsOfUseDialogComponent, dialogConfig);

  }


  openContactUs() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      isInternal: false
    };
    this._dialog.open(ContactUsComponent, dialogConfig);

  }

  openCompanyMarketPage() {
    window.open('https://flightprointernational.com', '_blank');
  }

}

