<div style="height: 100%; width: 100%;" [ngClass]="isDialog? 'greyBack':''" cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isDialog">
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div style="margin-left:1em">
      <div style="display: inline-flex;">
        <div class="material-icons-outlined" style="margin-top: 0.15em; font-size: 1.8em;">
          schedule
        </div>
        <div style="margin-left: 0.3em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Daylight Savings Dashboard</h3>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button *ngIf="isDialog" (click)="openGlobalSettingsDashboardComponent()"> <i
          class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button *ngIf="isDialog" mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div>
  </div>

  <form [formGroup]="airport_day_light_saving_form">
    <div style="margin-top:0em; padding-left: 1em; padding-right:1em">
      <div style="background-color: white; padding: 1em; width: 100%; font-size: small;">
        <div style="display: flex; align-items: center; gap: 10px; margin-left: -1em; margin-bottom: 1em;">
          <div class="status-container">
            <div *ngFor="let status of statuses; let i = index" [class.selected]="status.selected"
              (click)="toggleStatus(i)" class="status-item">
              <span *ngIf="status.selected">&#10003;</span> {{ status.displayName }}
            </div>
          </div>
        </div>
        <div style="display: inline-flex; width: 100%; justify-content: space-between;">
          <div style="display: inline-flex;">
            <div style="display: inline-flex;">
              <div style="margin-left: 0.75em; margin-top: -1.2em;">
                ICAO / Country / State / Province
                <div class="input-group searchbar" style="height: 2em; margin-top: 0.2em; background-color: #eaf0fd;">
                  <div>
                    <span style="font-size: x-small; background-color: #eaf0fd;line-height:1;"
                      class="input-group-text material-icons-outlined searchbarleft pointer" cdkOverlayOrigin
                      #trigger="cdkOverlayOrigin">search</span>
                  </div>

                  <input formControlName="airport_search"
                    style="width:12em;height:1.5em; padding: 0em; font-size: small;"
                    class="form-control form-control-sm searchbarinput"
                    [ngStyle]="{'background-color' : f.airport_search.disabled ? '#e9ecef' : '#eaf0fd'}"
                    (keyup)="applyFilters($event)" (mousedown)="$event.stopPropagation()" />
                </div>
              </div>

              <div style="margin-left: 1em; margin-top: -1.2em;">
                Country
                <select class="form-control" formControlName="country"
                  style="width:12em;height:2em; padding: 0em; margin-top: 0.2em; font-size: small;"
                  [ngStyle]="{'background-color' : f.country.disabled ? '#e9ecef' : '#eaf0fd'}"
                  (change)="changeCountry($event)">
                  <option value="" selected>-----</option>
                  <option *ngFor="let country of countryList" [value]="country.countryName">{{country.countryName}}
                  </option>
                </select>
              </div>
              <div style="margin-left: 1em; margin-top: -1.2em;">
                State/Province
                <select class="form-control" formControlName="state"
                  style="width:12em;height:2em; padding: 0em; margin-top: 0.2em; font-size: small;"
                  [ngStyle]="{'background-color' : f.state.disabled ? '#e9ecef' : '#eaf0fd'}"
                  (change)="changeState($event)">
                  <option value="" selected>-----</option>
                  <option *ngFor="let state of stateProvinceList" [value]="state.stateProvinceName">
                    {{state.stateProvinceName}}</option>
                </select>
              </div>
              <div style="margin-left: 1em; margin-top: -1.2em;">
                ICAO
                <select class="form-control" formControlName="icao"
                  style="width:5em;height:2em; padding: 0em; margin-top: 0.2em; font-size: small;"
                  [ngStyle]="{'background-color' : f.state.disabled ? '#e9ecef' : '#eaf0fd'}"
                  (change)="changeIcao($event)">
                  <option value="" selected>-----</option>
                  <option *ngFor="let airport of airportList" [value]="airport.icao">
                    {{airport.icao}}</option>
                </select>
              </div>
              <div style="margin-top: 0.15em;">
              <button  class="btn-med" (click)="refreshData()" style="color: gray; margin: 0.3em 0 0em 0.5em; max-width: 2em !important;
              border-radius: 4px; height: 2em;"  matTooltipClass="tooltipLineBreak" matTooltip="Refresh" >
               <i class="material-icons"  style="font-size: x-large; cursor: pointer; margin: 0em 0 -0.2em -0.5em;  ">refresh</i></button>

             <button  class="btn-med" (click)="clickReset()" style="color: gray; margin: 0.3em 0 0em 0.5em; max-width: 2em !important;
              border-radius: 4px; height: 2em;"  matTooltipClass="tooltipLineBreak" matTooltip="Reset" >
               <i class="material-icons"  style="font-size: x-large; cursor: pointer; margin: 0em 0 -0.2em -0.5em;  ">restart_alt</i></button>
              </div>
            </div>
            <app-spin *ngIf="showSpin"></app-spin>
          </div>
        </div>
      </div>

      <div
        style="display: flex; justify-content: flex-end; align-items: center; padding-right: 1em; background-color: white !important;">
        <span style="font-size: small;">Total Records: {{totalRecords}}</span>
      </div>

      <div style="overflow-y: auto; height: 36em; width: 100%; padding-left: 1em;    background-color: white;">
        <table class="example-container" mat-table [dataSource]="filteredRecordList" style="width: 100%;">
          <tr mat-row>
            <ng-container matColumnDef="countryName">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                Country
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; color:black;"
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex;" (click)="editDayLightSavingTime(element, i)">
                  {{element.countryName}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="stateProvinceName">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                State Province
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; color:black;"
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex;" (click)="editDayLightSavingTime(element, i)">
                  {{element.stateProvinceName ?? '-'}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="icao">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                ICAO
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; color:black;"
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex;" (click)="editDayLightSavingTime(element, i)">{{element.icao ??
                  '-'}}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                Start Date
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; color:black;"
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex;" (click)="editDayLightSavingTime(element, i)">
                  {{element.formattedStartDate}} L</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: center;">
                End Date
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; color:black;"
                [ngClass]="{'row-highlight': selectedRowIndex === i}">
                <div style="display: inline-flex;" (click)="editDayLightSavingTime(element, i)">
                  {{element.formattedEndDate}} L</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="option">
              <th mat-header-cell *matHeaderCellDef style="width:3%">
                <div>Option</div>
                <div style="margin-top:0em"><a mat-button class="btn-high" style="align-self:center; cursor: pointer;"
                    (click)="editDayLightSavingTime(element,i);">Add</a>
                </div>
              </th>
              <td mat-cell *matCellDef="let element; let i = index"
                style="align-content: center; text-align: center; cursor: pointer; width:3%"
                [ngClass]="{'row-highlight': selectedRowIndex === i}"><button mat-button class="btn-low"
                  (click)="editDayLightSavingTime(element, i)">Edit</button>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 1em; margin-left: 1em; margin-top: 0.5em;">
      <button *ngIf="isDialog" mat-button class="btn-low" (click)="clickClose(true)"
        style="background-color: grey; color: white;">
        <div style="display: flex; align-items: center;">
          <i class="material-icons" style="font-size: medium; margin-right: 0.25em;">close</i>
          <span>Close</span>
        </div>
      </button>
      <button mat-button class="btn-low" (click)="exportData()">Export To Excel</button>
    </div> 
  </form>
</div>