import { Component, Inject, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AirportService } from '../services/airport.service';
import { CountryModel } from "../models/country.model";
import { StateProvinceModel } from "../models/state-province.model";
import { ReturnObjModel } from '../models/return-obj.model';
import { IcaoModel } from '../models/icao.model';
import { DialogService } from '../services/dialog.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { AirportDayLightModel } from '../models/airport-daylight-saving.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-airport-daylightsaving-edit',
  templateUrl: './airport-daylightsaving-edit.component.html',
  styleUrls: ['./airport-daylightsaving-edit.component.css']
})

export class AirportDayLightSavingEditComponent implements OnInit {
  airport_daylightsaving_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string = '';
  msg: string;
  showSpin: boolean = true;
  controlValueChanged: boolean = false;
  dayLightSavingTimeID: number;
  countryID: number;
  stateProvinceID: number;
  countryList: CountryModel[] = [];
  DaylightSavingList: AirportDayLightModel[] =[];
  stateProvinceList: StateProvinceModel[] = [];
  airportList: IcaoModel[] = [];
  selectedCountry: string = '';
  selectedStateProvince: string = '';
  icao: string;
  airportID: number;
  isActive: boolean;
  startDate: string;
  endDate: string;
  showSuccessMsg: boolean = false;
  selectedIcao: string = '';
  successMsg: string = '';
  recordList: any[] = [];
  isAdd: boolean = false;
  modifiedBy: string;
  modifiedDate: string;
  isDisableRecord: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(
    private readonly _dialogRef: MatDialogRef<AirportDayLightSavingEditComponent>,
    private readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _airportService: AirportService,
        private readonly _dialogService: DialogService,
  ) {
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.initForm();
    this.getAllData().subscribe(responses => {
      if (responses[0]?.code == "200") {
        this.countryList = responses[0].model;
      }
      this.executeFucntionsInOrder();
      this.showSpin = false;
    });

  }

  async executeFucntionsInOrder(){
    await this.setStateDropdown();
      this.populateFormWithData();
  }

  async setStateDropdown(): Promise<void> {
    const selectedCountry = this.countryList.find(country => country.countryName === this.selectedCountry);
    if (selectedCountry) {
      const countryGUID = selectedCountry.countryGUID;
      this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>(countryGUID).subscribe(response => {
        this.stateProvinceList = response?.model || [];
      });
    } else {
      this.stateProvinceList = [];
    }
  }

  initForm() {
    this.airport_daylightsaving_edit_form = this._formBuilder.group({
      dayLightSavingTimeID: [this.dayLightSavingTimeID ?? null],
      countryID: [this.countryID ?? null],
      stateProvinceID: [this.stateProvinceID ?? null],
      countryName: [this.selectedCountry ?? '', Validators.required],
      stateProvinceName: [this.selectedStateProvince ?? ''],
      icao: [this.selectedIcao ?? ''],
      airportID: [this.airportID ?? null],
      startDate: [this.startDate ?? '', Validators.required],
      startTime: ['', [Validators.required, Validators.pattern(/^([01]\d|2[0-3]):([0-5]\d)$/)]],
      endDate: [this.endDate ?? '', Validators.required],
      endTime: ['', [Validators.required, Validators.pattern(/^([01]\d|2[0-3]):([0-5]\d)$/)]],
      isActive: [this.isActive],
      isDisableRecord: [this.isDisableRecord]
    });

    this.airport_daylightsaving_edit_form.valueChanges.subscribe(() => {
      this.controlValueChanged = this.airport_daylightsaving_edit_form.dirty;
    });
  }

  getAllData(): Observable<any[]> {
    const getCountryList = this._commonService.getCountryList();
    this.selectedCountry = this.data.countryName;
    this.stateProvinceID = this.data.stateProvinceID;
    return forkJoin([getCountryList]);
  }

  populateFormWithData() {
    if (this.data) {
      if(this.data.daylightSavingTimeID == 0) this.isAdd = true;
      this.dayLightSavingTimeID = this.data.daylightSavingTimeID ?? 0;
      this.selectedCountry = this.data.countryName ?? '';
      this.countryID = this.data.countryID ?? 0;
      this.selectedStateProvince = this.data.stateProvinceName ?? '';
      this.stateProvinceID = this.data.stateProvinceID ?? 0;
      this.selectedIcao = this.data.icao ?? '';
      this.airportID = this.data.airportID ?? 0;
      const formattedStartDate = this.data.startDate ?? ''; 
      const formattedStartTime = this.data.startTime ?? ''; 
      const formattedEndDate = this.data.endDate ?? '';
      const formattedEndTime = this.data.endTime ?? '';
      this.modifiedBy = this.data.modifiedBy ?? '';
      this.modifiedDate = this.data.modifiedDate ?? '';
      this.airport_daylightsaving_edit_form.patchValue({
            dayLightSavingTimeID: this.dayLightSavingTimeID,
            countryName: this.selectedCountry,
            countryID: this.countryID,
            stateProvinceID: this.stateProvinceID,
            stateProvinceName: this.selectedStateProvince,
            icao: this.selectedIcao,
            airportID: this.airportID,
            startDate: formattedStartDate,
            startTime: formattedStartTime,  
            endDate: formattedEndDate,
            endTime: formattedEndTime,
            isDisableRecord: this.isAdd ? true: !this.data.isActive
      });
    }
  }

  onCountryChange(event: any) {
    this.selectedCountry = event.target.value;
    const selectedCountry = this.countryList.find(country => country.countryName === this.selectedCountry);
    if (selectedCountry) {
      this.airport_daylightsaving_edit_form.controls['countryID'].setValue(selectedCountry.countryID);
      this.countryID = selectedCountry.countryID;
      const countryGUID = selectedCountry.countryGUID;

      this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>(countryGUID).subscribe(response => {
        this.stateProvinceList = response?.model || [];
      });
    } else {
      this.stateProvinceList = [];
    }
  }

  onStateChange(event: any) {
    this.selectedStateProvince = event.target.value;
    const selectedStateProvince = this.stateProvinceList.find(state => state.stateProvinceName === this.selectedStateProvince);
    if(selectedStateProvince){
      this.airport_daylightsaving_edit_form.controls['stateProvinceID'].setValue(selectedStateProvince.stateProvinceID);
      this.stateProvinceID = selectedStateProvince.stateProvinceID;
    } else {
      this.airport_daylightsaving_edit_form.controls['stateProvinceID'].setValue(0);
      this.stateProvinceID = 0;
    }
  }

  onIcaoChange(event: any){
    this.selectedIcao = event.target.value;
  }

  
  timeChange(dateType: string, e: any) {
    let inputValue = e.target.value.trim(); 

    if (!inputValue) {
        if (dateType == "S") {
            this.f.startTime.setErrors(null);
            this.f.startTime.setValue('');
        } else if (dateType == "E") {
            this.f.endTime.setErrors(null);
            this.f.endTime.setValue('');
        }
        return;
    }

    let formattedTime = inputValue;
    if (/^\d{1}$/.test(formattedTime)) {
        formattedTime = `0${formattedTime}:00`;
    }
    else if (/^\d{2}$/.test(formattedTime)) {
        formattedTime = `${formattedTime}:00`;
    }
    else if (/^\d{3}$/.test(formattedTime)) {
        formattedTime = `0${formattedTime[0]}:${formattedTime.slice(1)}`;
    }
    else if (/^\d{4}$/.test(formattedTime)) {
        formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(2)}`;
    }
    else if (/^\d{1}:\d{2}$/.test(formattedTime)) {
        formattedTime = `0${formattedTime}`;
    }

    let timeObj = new DateTimeObjModel();
    timeObj.timeString = formattedTime;
    timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj)

    if (!timeObj.isValidDateTime) {
        if (dateType == "S") this.f.startTime.setErrors({ pattern: true });
        if (dateType == "E") this.f.endTime.setErrors({ pattern: true });
    } else {
        if (dateType == "S") this.f.startTime.setValue(timeObj.timeString);
        if (dateType == "E") this.f.endTime.setValue(timeObj.timeString);
        e.target.value = timeObj.timeString; 
    }
}


clickSave() {
  this._authService.updateAccessTime();
  this.submitted = true;
  this.errMsg = '';

  if(this.f.isDisableRecord.value == true || this.f.isDisableRecord.value == 'true'){
    this.airport_daylightsaving_edit_form.controls['isActive'].setValue(false);
    }
  if(this.f.isDisableRecord.value == false || this.f.isDisableRecord.value == 'false'){
    this.airport_daylightsaving_edit_form.controls['isActive'].setValue(true);
  }

  if (this.airport_daylightsaving_edit_form.invalid) {
    return;
  }

  const formData = this.airport_daylightsaving_edit_form.value;
  formData.startDate = `${formData.startDate}T${formData.startTime}:00`;
  formData.endDate = `${formData.endDate}T${formData.endTime}:00`;

  this._airportService.saveDaylightSavingData(formData).subscribe(
    (response: any) => {
      console.log("API Response:", response);
      const res = Array.isArray(response) ? response[0] : response;

      if (res.code === 200) { 
        this._dialogRef.close({ refresh: true }); 
      } else {
        this.errMsg = res.message;
        setTimeout(() => { this.errMsg = ''; }, 2000);
      }
    },
    (error: any) => {
      console.error("API Error:", error);
      this.errMsg = error.error?.Message || "Failed to save data.";
      setTimeout(() => { this.errMsg = ''; }, 2000);
    }
  );
} 

clickClose() {
  let response = new ReturnObjModel();
  response.refresh = true;
  if (this.controlValueChanged) {
    this.confirmDialogRef = this._dialogService.openConfirmDialogLeft("Do you want to save all changes before close the page?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.clickSave();
      }
      else {
        this._dialogRef.close(response);
      }
    });
  } else {
    this._dialogRef.close(response);  
  }
}


  get f() { 
    return this.airport_daylightsaving_edit_form.controls;
 }
}
