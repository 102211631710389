import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { UserModel } from "../models/user.model";
//import { ResponseModel } from '../models/response.model';
import { IdentityRequestModel } from "../models/identity-request.model";
import { GlobalConstant } from '../common-utility/global-constant';
import { AuthenticateService } from "./authenticate.service";

@Injectable()
export class IdentityService {
  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {

  }

  registerUser<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });
    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/registerUser", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  }

  changePassword<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/changePassword", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  validatePassword<T>(user: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/validatePassword", user, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  //generatePassword<T>(): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.get<T>(GlobalConstant.baseUrl + "Identity/generatePassword", {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status);return throwError(error.message) }));

  //}

  resetPassword<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/resetPassword", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status);return throwError(error.message) }));
  }

  resetPasswordByAdmin<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/resetPasswordByAdmin", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUser<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUser", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUsersByKeyword<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUsersByKeyword", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  updateUser<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/updateUser", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getApplications<T>(): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getApplications", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  checkUsername<T>(user: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/checkUsername", user, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUsername<T>(user: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUsername", user, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUserTypes<T>(): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getUserTypes", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getInternalEmailEndsWith<T>(): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getInternalEmailEndsWithList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getPwdExpirationPolicyInDays<T>(): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getPasswordExpirationPolicyInDays", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUsersByUsernameEmail<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUsersByUsernameEmail", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUserPermissionByConditions<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUserPermissionByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getUserWithAnyStatusByUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getUserWithAnyStatusByUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getRegistrationListByCustomerGUIDUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getRegistrationListByCustomerGUIDUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  saveUserAircraftAccessByUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/saveUserAircraftAccessByUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getPersonListByCustomerGUIDUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/getPersonListByCustomerGUIDUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  saveUserPersonAccessByUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/saveUserPersonAccessByUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  saveUserRestrictionAccessByUserId<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/saveUserRestrictionAccessByUserId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  getCheckPastPasswordNumber<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getCheckPastPasswordNumber", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  }

  checkEmail<T>(request: UserModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/checkEmail", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  }

  generateUsername<T>(request: UserModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/generateUsername", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  }

  getMinPasswordLength<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getMinPasswordLength", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  }


  //getActiveUserRestrictionsByuId<T>(): Observable<T> {
  //  //debugger;
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getActiveUserRestrictionsByuId", {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));

  //}

  //testSendEmail<T>(): Observable<T> {
  //  var headerOptions = new HttpHeaders({
  //    'Content-Type': 'application/json',
  //    'Authorization': 'bearer ' + localStorage.getItem('token'),
  //    'Cache-Control': 'no-cache, no-store, must-revalidate',
  //    //'Pragma': 'no-cache',
  //    'Expires': '-1'
  //  });

  //  return this.http.get<T>(GlobalConstant.baseUrl + "Identity/testSendEmail", {
  //    headers: headerOptions
  //  }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  //}

  saveUserSignature<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/saveUserSignature", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  saveUserProfile<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/saveUserProfile", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }


  getMaintenanceMessage<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<T>(GlobalConstant.baseUrl + "Identity/getMaintenanceMessage", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  sendPasswordRecoveryMessage<T>(request: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/sendPasswordRecoveryMessage", request, {
      headers: headerOptions,
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  decryptPIN<T>(user: UserModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token')
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/decryptPIN", user, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

  resetPIN<T>(request: IdentityRequestModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "Identity/resetPIN", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { this._authService.handleError(error.status); return throwError(error.message) }));
  }

}
