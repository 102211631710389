import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first, groupBy, takeUntil } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription, TimeInterval } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { CustomerModel } from '../models/customer.model';
import { TripLegModel } from '../models/trip-leg.model';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopTaskEditDialogComponent } from './ground-stop-task-edit-dialog.component';
import { FlightFollowingEditDialogComponent } from './flight-following-edit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from './task-revision-required-edit-dialog.component';
import { UserModel } from '../models/user.model';
import { IdentityService } from '../services/identity.service';
import { IdentityRequestModel } from '../models/identity-request.model';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { SuccessMessageComponent } from '../common-utility/success-message.component';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { CustomPageSizeOptionModel } from '../models/custom-page-size-option.model';
import { Title } from '@angular/platform-browser';
import { OverFlightPermitTaskComponent } from './overflight-permit-task.component';
import { LandingPermitPrivateTaskComponent } from './landing-permit-private-task.component';
import { FuelTaskEditDialogComponent } from '../fuel-quote/fuel-task-edit-dialog.component';
import { SlotArrivalTaskComponent } from './slot-arrival-task.component';
import { InternalGroundStopTaskEditDialogComponent } from './internal-ground-stop-task-edit-dialog.component';
import { InternalPublicGroundStopTaskEditDialogComponent } from './internal-public-ground-stop-task-edit-dialog.component';
import { FlightPlanTaskComponent } from './flight-plan-task.component';
import { UkGarTaskComponent } from './uk-gar-task.component'; 
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


interface RecordType {
  customerName?: string;
  tripCode?: string;
  customerTripId?: string;
  registration?: string;
  vendorName?: string;
  fpiConfirmationReference?: string;
  icao?: string;
  airportCountryName?: string;
  ovfCountryName?: string;
  hotelName?: string;
}

@Component({
  selector: 'app-task-queuelist',
  templateUrl: './task-queuelist.component.html',
  styleUrls: ['./task-queuelist.component.css'],
  //providers: [
  //  { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  //],
})
 

export class TaskQueuelistComponent implements OnInit, OnDestroy {
  userType: string;
  customerGUID: string;
  task_queue_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  v: number = this._authService.getCurrentTimeNumber();
  totalRecordsText: string;
  recordList: GroundStopTaskModel[];
  originalRecordList: GroundStopTaskModel[];
  filteredRecordList: GroundStopTaskModel[];
  //displayedColumn: string[] = ['service', 'businessStop', 'leadtime', 'duedate','arrival', 'departure','registration','picName','tripCode', 'clientName', 'action'];
  //displayedColumn: string[] = ['leadtime_duedate', 'service', 'registration', 'departure','ete', 'arrival',  'internalNote', 'tripCode','clientRef', 'assigned', 'action'];
  displayedColumn: string[] = ['leadtime_duedate', 'service', 'registration', 'depicao', 'depdatetime', 'ete',
    'arricao', 'arrdatetime', 'internalNote', 'tripCode', 'clientRef', 'assignedOps', 'assignedWx',  'action'];
  displayedColumn2: string[] = ['blank1', 'departure', 'blank2', 'arrival', 'blank3']
  date_type: string;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  serviceClassList: ServiceClassModel[];
  selectedServiceClassList: ServiceClassModel[];
  orgServiceTypeList: ServiceTypeModel[];
  serviceTypeList: ServiceTypeModel[];
  userServiceTypeList: string;
  selectedServiceTypeList: ServiceTypeModel[];
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  taskStatusList: TripTaskStatusModel[];
  userTaskStatusList: string;
  selectedTaskStatusList: TripTaskStatusModel[];
  text_search: string;
  filter_by: string;
  includeClosedBilledRecords: boolean;
  includeDemo: boolean = false;
  showServiceFilter: boolean = false;
  selectedServiceCountText: string = "";
  showTaskStatusFilter: boolean = false;
  selectedStatusCountText: string = "";
  //selectedTaskStatusTitle: string;
  upPII: boolean = false;
  totalRecords: number = 0;
  pageSize: number = 50;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customPageSizeOptionList: CustomPageSizeOptionModel[] = [];
  minPageSize: number = 50;
  maxPageSize: number = 100;
  hideOnHold: boolean = true;
  showOnHold: boolean = false;
  immediateActionOps: boolean = false;
  immediateActionWx: boolean = false;
  excludeImmediateActionOps: boolean = false;
  excludeImmediateActionWx: boolean = false;
  showAssignMe: boolean = false;
  subcription: Subscription;
  refreshTasks: any;
  tooltip: string = 'Double Click To Remove';
  isDateChanged: boolean = false;
  statuses = [
    { label: 'Task Due Date', selected: true, value: 'duedate' },
    { label: 'Task Lead Time', selected: false, value: 'leadtime' },
    { label: 'Trip Itinerary', selected: false, value: 'tripdate' } 
  ];

  searchByList: string[] = [];
  isSearchTypeChanged: boolean = false;
  currentScreenSize: string;
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xLarge'],
  ]);
  destroyed = new Subject<void>();
 
  customerList: string[] = [];
  tripCodeList: string[] = [];
  clientTripIdList: string[] = [];
  registrationList: string[] = [];
  vendorList: string[] = [];
  confirmationNumberList: string[] = [];
  airportList: string[] = [];
  countryList: string[] = [];
  ovfCountryList: string[] = [];
  hotelList: string[] = [];


  showAdditionalFilters: boolean = false;
  selectedFilterString: string = '';
  selectedFilterType: string = '';
  selectedFilterValue: string = '';
  

  filteredCustomerName: string = '';
  filteredTripCode: string = '';
  filteredClientTripId: string = '';
  filteredRegistration: string = '';
  filteredVendor: string = '';
  filteredConfirmationNumber: string = '';
  filteredAirport: string = '';
  filteredCountry: string = '';
  filteredOvfCountry: string = '';
  filteredHotel: string = '';


  filterCriteria: any = {
    customerName: '',
    tripCode: '',
    customerTripId: '',
    registration: '',
    vendorName: '',
    fpiConfirmationReference: '',
    icao: '',
    airportCountryName: '',
    countryName: '',
    hotelName: ''
  };

  //orgRecordList: GroundStopTaskModel[] = [];
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _identityService: IdentityService, private _snackBar: MatSnackBar, private _titleService: Title, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'unknown';
          }
        }
      });
  }
    ngOnDestroy(): void {
      this.subcription.unsubscribe();
      clearInterval(this.refreshTasks);
    }

  getAllData(): Observable<any[]> {
    //let getClientListResponse;
    //if (this.userType == "internal") {
    //  getClientListResponse = this._groundStopService.getClientListWithTrip();     
    //}
    //else {
    //  getClientListResponse = of(null);

    //}
    let getServiceClass = this._groundStopAdvancedService.getServiceClassList();
    let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);

    return forkJoin([getServiceClass, getService, getTaskStatusResponse, userResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.totalRecordsText = '';
    this.selectedFilterType = '';
    this.selectedFilterValue = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.recordList = [];
    this.filteredRecordList = [];
    this.originalRecordList = [];
    this.date_type = 'duedate'; //'leadtime';  
    this.customerGUID = "";
    /*this.startDate = new Date();*/
    //this.startDate.setDate(this.startDate.getDate() - 2);
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 7);
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 24 * 60 * 60 * 1000;
    let hendnumber: number = 96 * 60 * 60 * 1000;
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;
    let m1: number = dt1.getMinutes();
    let m1s: string = m1.toString();
    if (m1 < 10) {
      m1s = "0" + m1.toString();
    }
    //this.startTime = dt1.getHours().toString() + ":" + m1s;
    let m2: number = dt2.getMinutes();
    let m2s: string = m2.toString();
    if (m2 < 10) {
      m2s = "0" + m2.toString();
    }
    //this.endTime = dt2.getHours().toString() + ":" + m2s;
    this.startTime = "0:00";
    this.endTime = "23:59";
    this.serviceClassList = [];
    this.selectedServiceClassList = [];
    this.orgServiceTypeList = [];
    this.serviceTypeList = [];
    this.selectedServiceTypeList = [];
    this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    this.filter_by = "clientName";
    this.text_search = "";
    this.customPageSizeOptionList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.serviceClassList = responses[0].model;
          this.serviceClassList = this.serviceClassList.filter(x => x.serviceClassID != 0);
          //this.serviceClassList.forEach(x => {
          //  x.selected = true;
          //});
          this.setServiceClassList();
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.orgServiceTypeList = responses[1].model;
          this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
          this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
          //this.serviceTypeList.forEach(x => {
          //  x.selected = true;
          //  x.serviceClassSelected = true;
          //});
          this.setServiceTypeList(false);
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.taskStatusList = responses[2].model;
          this.taskStatusList.forEach(x => {
            if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
              x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
              x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
              x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
              x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
              x.selected = true;
            }

          });

          this.setTaskStatusList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let user = responses[3].model;
          this.userServiceTypeList = user.taskQueueServiceIDList;
          if (user.taskQueueServiceIDList != "") {
            this.serviceTypeList.forEach(x => {
              if (("," + user.taskQueueServiceIDList + ",").indexOf("," + x.serviceTypeID.toString() + ",") > -1) {
                x.selected = true;
              }
            });
            this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
          }
          this.userTaskStatusList = user.taskQueueStatusList;
          if (user.taskQueueStatusList != "") {
            this.taskStatusList.forEach(x => {
              if ((user.taskQueueStatusList).indexOf(x.taskStatusGUID) > -1) {
                x.selected = true;
              }
              else {
                x.selected = false;
              }
            });
            this.setTaskStatusList();
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      if (this.selectedServiceTypeList != null) {
        if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
          this.selectedServiceCountText = ": All";
        else {
          if (this.selectedServiceTypeList.length == 0)
            this.selectedServiceCountText = "";
          else
            this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
        }
      }
      else
        this.selectedServiceCountText = ": All";
      if (this.selectedTaskStatusList != null) {
        if (this.selectedTaskStatusList.length == this.taskStatusList.length)
          this.selectedStatusCountText = ": All";
        else {
          if (this.selectedTaskStatusList.length == 0)
            this.selectedStatusCountText = "";
          else
            this.selectedStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
        }
      }
      else
        this.selectedStatusCountText = ": All";
      this.getData();

      this.setupRefreshInterval();

    });


  }

  initControls() {
    this.task_queue_form = this._formBuilder.group({
      date_type: [this.date_type],
      startDate: [this.startDate, Validators.required],
      endDate: [this.endDate, Validators.required],
      startTime: [this.startTime],
      endTime: [this.endTime],
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeClosedBilledRecords: [this.includeClosedBilledRecords],
      includeDemo: [false],
      filterTripRevision: [false],
      includeTBATask: [false],
      custom_page_size_select1: [this.pageSize],
      custom_page_size_select2: [this.pageSize],
      hideOnHold: [this.hideOnHold],
      showOnHold: [this.showOnHold],
      immediateActionOps: [this.immediateActionOps],
      immediateActionWx: [this.immediateActionWx],
      excludeImmediateActionOps: [this.excludeImmediateActionOps],
      excludeImmediateActionWx: [this.excludeImmediateActionWx], 
      showAssignMe: [this.showAssignMe],
      
       filteredCustomerName: [this.filteredCustomerName],
      filteredTripCode: [this.filteredTripCode],
      filteredClientTripId: [this.filteredClientTripId],
      filteredRegistration: [this.filteredRegistration],
      filteredVendor: [this.filteredVendor],
      filteredConfirmationNumber: [this.filteredConfirmationNumber],
      filteredAirport: [this.filteredAirport],
      filteredCountry: [this.filteredCountry],
      filteredOvfCountry: [this.filteredOvfCountry],
      filteredHotel: [this.filteredHotel] 


    });

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
  }

  get f() { return this.task_queue_form.controls; }

  dateTypeChange(e: any) {
    this.getData();
  }

  dateOnchange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
          this.isDateChanged = true;
        }
      }
      else {
        this.isValidStartDate = true;
        this.isDateChanged = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
          this.isDateChanged = true;
        }
      }
      else {
        this.isValidEndDate = true;
        this.isDateChanged = true;
      }
    }
    
  }
  onInputChange(e: any) {
    this.isDateChanged = true;
    
  }

  timeChange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target.value == "") {
        this.f.startTime.setErrors(null);
        this.f.startTime.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        if (!timeObj.isValidDateTime) {
          this.f.startTime.setErrors({ pattern: true });
        }
        else {
          this.f.startTime.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;
        }
      }
    }
    if (dateType == "E") {
      if (e.target.value == "") {
        this.f.endTime.setErrors(null);
        this.f.endTime.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        if (!timeObj.isValidDateTime) {
          this.f.endTime.setErrors({ pattern: true });
        }
        else {
          this.f.endTime.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;
        }
      }
    }
  }

  onServiceClassChange(e: any, item: ServiceClassModel) {
    item.selected = e.checked;
    this.setServiceClassList();
    if (this.selectedServiceClassList.length == 0) {
      this.orgServiceTypeList.forEach(x => {
        x.serviceClassSelected = false;
        x.selected = false;
      });
      this.setServiceTypeList(false);
    }
    else {
      this.orgServiceTypeList.forEach(x => {
        if (item.serviceClassID == x.serviceClassID) {
          x.serviceClassSelected = item.selected;
          x.selected = false;// item.selected;
        }
      });
      this.setServiceTypeList(true);
    }
    //this.checkServiceTypeArray = new FormControl(this.selectedServiceTypeList); // for mat-select


  }

  onServiceTypeChange(e: any, item: ServiceTypeModel) {
    //item.selected = e.source.selected;
    item.selected = e.checked;
    //this.setServiceTypeList();
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.updateAllComplete(item.filterGroup);

  }

  setServiceClassList() {
    this.selectedServiceClassList = this.serviceClassList.filter(u => u.selected == true);
  }

  setServiceTypeList(selected: boolean) {
    if (this.selectedServiceClassList.length == 0) {
      this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
      this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
      this.serviceTypeList.forEach(x => {
        x.selected = false;
        x.serviceClassSelected = false;
      });
    }
    else {
      this.serviceTypeList = this.orgServiceTypeList.filter(u => u.serviceClassSelected == selected);
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.updateAllComplete(1);
    this.updateAllComplete(2);
  }

  setTaskStatusList() {
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
    //this.selectedTaskStatusTitle = this.selectedTaskStatusList.map(function (item) { return item.taskStatusDescription }).join(",");

    this.updateAllCompleteStatus();
  }

  onTaskStatusChange(e: any, item: TripTaskStatusModel) {

    //if (this.matCheckInitialLoad == false) {
    item.selected = e.checked;
    this.setTaskStatusList();
    //}
  }

  filterTripRevisionChange(e: any) {
    if (e.checked) {
      this.serviceClassList.forEach(x => {
        x.selected = false;
      });
      this.setServiceClassList();
      this.setServiceTypeList(false);
      this.taskStatusList.forEach(x => {
        x.selected = false;
      });
      this.setTaskStatusList();
      this.f.text_search.setValue('');
      this.task_queue_form.get('text_search').disable();
      this.task_queue_form.get('filter_by').disable();
    }
    else {
      this.task_queue_form.get('text_search').enable();
      this.task_queue_form.get('filter_by').enable();
    }
  }

  clickServiceType(e: any, gstItem: GroundStopTaskModel) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "54em";
    dialogConfig.hasBackdrop = false;
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      dialogConfig.minHeight = "37em";
    }
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;

    let leg: string = "";
    leg = gstItem.departureICAO;
    leg += "-" + gstItem.arrivalICAO;

    dialogConfig.data = {
      groundStopGUID: gstItem.groundStopGUID, tripCodeGUID: gstItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: gstItem.countryGUID, icao: gstItem.icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: gstItem.customerGUID, leg: leg, nextGroundStopGUID: gstItem.arrivalGroundStopGUID,
      depGroundStopGUID: gstItem.departureGroundStopGUID
    };

    //if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70 && gstItem.serviceTypeID != 44 && gstItem.serviceTypeID != 45 && gstItem.serviceTypeID != 46
    //  && gstItem.serviceTypeID != 48 && gstItem.serviceTypeID != 49 && gstItem.serviceTypeID != 60 && gstItem.serviceTypeID != 61 && gstItem.serviceTypeID != 62) {

    let dialogRef;
    switch (gstItem.formType) {
      case 1: //ops legacy
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 2: //OpsOverflightPermitForm
        dialogRef = this._dialog.open(OverFlightPermitTaskComponent, dialogConfig);
        break;
      case 3: //OpsSlotForm
        dialogRef = this._dialog.open(SlotArrivalTaskComponent, dialogConfig);
        break;
      case 4: //InternalOnly
        dialogRef = this._dialog.open(InternalGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 5: //InternalPublic
        dialogRef = this._dialog.open(InternalPublicGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 7: //FuelTaskForm
        dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);
        break;
      case 8: //landing
        dialogRef = this._dialog.open(LandingPermitPrivateTaskComponent, dialogConfig);
        break;
      case 9: //flight plan
        dialogConfig.minWidth = "75em";
        dialogRef = this._dialog.open(FlightPlanTaskComponent, dialogConfig);
        break;
      case 10: //gar
        dialogRef = this._dialog.open(UkGarTaskComponent, dialogConfig);
        break;
      default:
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });


  }

  clickTripRevisionRequired(e: any, gstItem: GroundStopTaskModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, groundStopGUID: gstItem.groundStopGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, isRevisionRequiredValue: gstItem.isRevisionRequiredValue,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TaskRevisionRequiredEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getData();

      }

    });

  }

  getData() {
    this._authService.updateAccessTime();
    if (this.subcription)
      this.subcription.unsubscribe();
    if (this.task_queue_form.invalid == true) {
      return;
    }
    this.showServiceFilter = false;
    this.showTaskStatusFilter = false;
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripModel();
    request.searchDateType = this.f.date_type.value;
    request.startDate = this.f.startDate.value;
    request.startDateText = CustomValidators.formatDateToMMDDYYYY(request.startDate);
    //let templocal1: Date = new Date(this.f.startDate.value);
    //let temputc1: Date;
    //temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
    //request.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);

    request.endDate = this.f.endDate.value;
    request.endDateText = CustomValidators.formatDateToMMDDYYYY(request.endDate);
    //let templocal2: Date = new Date(this.f.endDate.value);
    //let temputc2: Date;
    //temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
    //request.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);

    if (this.f.startTime.value == "") {
      request.startDateText = request.startDateText + " 0:00"
    }
    else {
      request.startDateText = request.startDateText + " " + this.f.startTime.value;
    }
    if (this.f.endTime.value == "") {
      request.endDateText = request.endDateText + " 23:59"
    }
    else {
      request.endDateText = request.endDateText + " " + this.f.endTime.value;
    }
    request.serviceTypeIDList = "";
    if (this.selectedServiceTypeList.length > 0) {
      request.serviceTypeIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    }
    else {
      if (this.selectedServiceClassList.length > 0) {
        request.serviceTypeIDList = Array.prototype.map.call(this.serviceTypeList, s => s.serviceTypeID).toString();
      }
    }

    request.tripTaskStatusGUIDList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    request.textSearch = this.f.text_search.value;
    request.filterBy = this.f.filter_by.value;
    if (this.f.includeClosedBilledRecords.value == true) {
      request.tripCloseOutID = 0;
    }
    else {
      request.tripCloseOutID = 1;
    }
    request.includeDemo = false;
    if (this.f.includeDemo.value == true) {
      request.includeDemo = this.f.includeDemo.value;
    }
    request.filterTripRevision = false;
    if (this.f.filterTripRevision.value == true) {
      request.filterTripRevision = this.f.filterTripRevision.value;
    }
    request.includeTBATask = false;
    if (this.f.includeTBATask.value == true) {
      request.includeTBATask = this.f.includeTBATask.value;
    }
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    // request.hideOnHold = this.hideOnHold;
    request.hideOnHold = !this.showOnHold;
    
    this.setImmediateActionFilter();

    if (this.f.excludeImmediateActionOps.value == true) {
      this.immediateActionOps = false; 
    }
    if (this.f.excludeImmediateActionWx.value == true) { 
      this.immediateActionWx = false;
    }
 
    
    request.immediateActionOps = this.immediateActionOps;
    request.immediateActionWx = this.immediateActionWx;
    request.showAssignMe = this.showAssignMe;



    //request.totalRecords = this.totalRecords;
    this.subcription = this._groundStopAdvancedService.getTaskQueueListForDashboardByConditionsPage<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
      /*this.orgRecordList = [];*/
      this.originalRecordList = [];
      this.recordList = [];
      this.filteredRecordList = []; 

      this.totalRecordsText = "";
      this.totalRecords = 0;
      this.totalPageNumber = 0;
      this.date_type = request.searchDateType;
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.originalRecordList = response.model;
          // this.recordList = this.applySearchFilter();
          this.recordList = response.model;
          
          this.getAllFilters();

          this.filteredRecordList = response.model; 
 
          this.applyFilters();
          this.updateRecordCount();
        }
        else {
          this.totalRecordsText = "No record found";
        }

        this.showSpin = false;
        //this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText });
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;

    });

  }
  getAllFilters() { 
    this.customerList = [...new Set(this.originalRecordList.map(record => record.customerName?.trim()).filter(name => name))].sort();
    this.tripCodeList = [...new Set(this.originalRecordList.map(record => record.tripCode?.trim()).filter(name => name))].sort();
    this.clientTripIdList = [...new Set(this.originalRecordList.map(record => record.customerTripID?.trim()).filter(id => id))].sort();
    this.registrationList = [...new Set(this.originalRecordList.map(record => record.registration?.trim()).filter(reg => reg))].sort();
    this.vendorList = [...new Set(this.originalRecordList.map(record => record.vendorName?.trim()).filter(vendor => vendor))].sort();
    this.confirmationNumberList = [...new Set(this.originalRecordList.map(record => record.fpiConfirmationReference?.trim()).filter(number => number))].sort();
    // this.airportList = [...new Set(this.originalRecordList.map(record => record.icao?.trim()).filter(airport => airport))].sort();
    this.countryList = [...new Set(this.originalRecordList.map(record => record.airportCountryName?.trim()).filter(country => country))].sort();
    this.ovfCountryList = [...new Set(this.originalRecordList.map(record => record.countryName?.trim()).filter(ovfCountry => ovfCountry))].sort();
    this.hotelList = [...new Set(this.originalRecordList.map(record => record.hotelName?.trim()).filter(hotel => hotel))].sort();
    
    this.airportList = [
      ...new Set(
        this.originalRecordList
          .flatMap(record => [record.departureICAO?.trim(), record.arrivalICAO?.trim()]) // Extract both values
          .filter(airport => airport) // Remove undefined/null values
      )
    ].sort();
  }
   

  resetAdditionalFilters() {


    this.filterCriteria = {
      customerName: '',
      tripCode: '',
      customerTripId: '',
      registration: '',
      vendorName: '',
      fpiConfirmationReference: '',
      icao: '',
      airportCountryName: '',
      countryName: '',
      hotelName: ''
    };

    this.selectedFilterType = '';
    this.selectedFilterValue = '';

  }

  calculateFilterText() {
    const filters = {
      "Customer Name": this.f.filteredCustomerName.value,
      "Trip Code": this.f.filteredTripCode.value,
      "Customer Trip ID": this.f.filteredClientTripId.value,
      "Registration": this.f.filteredRegistration.value,
      "Vendor": this.f.filteredVendor.value,
      "FPI Confirmation Reference": this.f.filteredConfirmationNumber.value,
      "Airport": this.f.filteredAirport.value,
      "Country": this.f.filteredCountry.value,
      "Overflight Country": this.f.filteredOvfCountry.value,
      "Hotel": this.f.filteredHotel.value,
    };

    const activeFilter = Object.entries(filters).find(([label, value]) => value !== '');

    this.selectedFilterType = activeFilter ? `${activeFilter[0]}` : "";
    this.selectedFilterValue = activeFilter ? ` ${activeFilter[1]}` : "";

  
    // alert(this.selectedFilterString);

  }

  onCustomerChange(customerName: string) { 
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('customerName', customerName);
    this.calculateFilterText();
  }

  onTripCodeChange(tripCode: string) {
    this.f.filteredCustomerName.setValue(''); 
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('tripCode', tripCode);
    this.calculateFilterText();
  }

  onClientTripIdChange(clientTripId: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue(''); 
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('customerTripID', clientTripId);
    this.calculateFilterText();
  }

  onRegistrationChange(registration: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue(''); 
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('registration', registration);
    this.calculateFilterText();
  }

  onVendorChange(vendor: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue(''); 
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('vendorName', vendor);
    this.calculateFilterText();
  }

  onConfirmationNumberChange(confirmationNumber: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue(''); 
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('fpiConfirmationReference', confirmationNumber);
    this.calculateFilterText();
  }

  onAirportChange(airport: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue(''); 
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    if (airport) {
      this.recordList = this.originalRecordList.filter(x => x.departureICAO?.trim().toLowerCase() === airport.trim().toLowerCase() || x.arrivalICAO?.trim().toLowerCase() === airport.trim().toLowerCase());
    } else {
      this.recordList = [...this.originalRecordList];
    }
    this.updateRecordCount();
    this.calculateFilterText();
  }

  onCountryChange(country: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue(''); 
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('airportCountryName', country);
    this.calculateFilterText();
  }

  onOvfCountryChange(ovfCountry: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue(''); 
    this.f.filteredHotel.setValue('');
    this.resetAdditionalFilters()
    this.onFilterChange('countryName', ovfCountry);
    this.calculateFilterText();
  }
 
  onHotelChange(hotel: string) {
    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue(''); 
    this.resetAdditionalFilters()
    this.onFilterChange('hotelName', hotel);
    this.calculateFilterText();
  }
  onFilterChange(filterKey: string, filterValue: string) {
    this.filterCriteria[filterKey] = filterValue?.trim().toLowerCase() || '';
    this.applyFilters();
  }

  applyFilters() {
    this.recordList = this.originalRecordList.filter(record => {
      return Object.keys(this.filterCriteria).every(key => {
        const filterValue = this.filterCriteria[key];
        if (!filterValue) return true; // Skip empty filters

        const recordValue = record[key]?.toString().trim().toLowerCase() || '';
        return recordValue === filterValue;
      });
    });

    this.updateRecordCount();
  }

  updateRecordCount() {

    this.isSearchTypeChanged = false;
    this.totalRecords = this.recordList?.length;
    // this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize);
    this.recordList.forEach(x => {
      x.errMsg = "";
      x.loading = false;
    });
    if (this.recordList.length > 1) {
      this.totalRecordsText = this.totalRecords.toString() + " Records Found";
    }
    else {
      this.totalRecordsText = this.totalRecords.toString() + " Record Found";
    }
  }

  onSearchTypeFilter() {
    this.recordList = this.applySearchFilter();
  }
         
 

  applySearchFilter() {
    
    let textSearch = this.f.text_search.value;
    let filterBy = this.f.filter_by.value;
    if (!textSearch) {
    return this.originalRecordList;  
    }

    return this.originalRecordList.filter(record => {
      switch (filterBy) {
        case 'clientName':
          return record.customerName?.toLowerCase().includes(textSearch.toLowerCase());
        case 'customerReference':
          return record.customerTripID?.toLowerCase().includes(textSearch.toLowerCase());
        case 'registration':
          return record.registration?.toLowerCase().includes(textSearch.toLowerCase());
        case 'airport':
          return record.icao?.toLowerCase().includes(textSearch.toLowerCase());
        case 'tripCode':
          return record.tripCode?.toLowerCase().includes(textSearch.toLowerCase());
        case 'vendorName':
          return record.vendorName?.toLowerCase().includes(textSearch.toLowerCase());
        case 'airportCountry':
          return record.airportCountryName?.toLowerCase().includes(textSearch.toLowerCase());
        case 'overFlightCountry':
          return record.countryName?.toLowerCase().includes(textSearch.toLowerCase());
        case 'hotelName':
          return record.hotelName?.toLowerCase().includes(textSearch.toLowerCase());
        case 'confirmationNum':
          return record.fpiConfirmationReference?.toLowerCase().includes(textSearch.toLowerCase());
        default:
          return false;
      }
    });

  }

 

  onfilterTypeChange() {
    this.recordList = this.originalRecordList;

    switch (this.f.filter_by.value) {
      case 'customerName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.customerName);

        break;
      case 'tripCode': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.tripCode);

        break;
      case 'customerReference': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.customerReference);

        break;
      case 'registration': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.registration);

        break;
      case 'icao': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.icao);

        break;
      case 'airportCountryName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.airportCountryName);

        break;
      case 'fuelSupplierName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.fuelSupplierName);

        break;
      case 'intoPlaneAgent': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.intoPlaneAgent);

        break;


    }
    this.searchByList = [...new Set(this.searchByList)];

    this.searchByList = this.searchByList.filter(sl => sl != '' && sl != null);

  }



  clickAssignMe(e: any, item: GroundStopTaskModel) {
    if (item.loading == false)
      item.loading = true;
    else
      return;

    let req = new GroundStopTaskModel();
    req.isAssignMe = false;
    req.groundStopTaskGUID = item.groundStopTaskGUID
    if (item.assignMe == "") {
      req.isAssignMe = true;
    }

    this._groundStopAdvancedService.updateGroundStopTaskAssignMeBygstId<ResponseModel<GroundStopTaskModel>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let obj = new GroundStopTaskModel();
          obj = response.model;
          if (obj.isAssignMe) {
            item.assignMe = obj.assignMe;
          }

        }
        else {
          item.errMsg = "Failed to update the task"
        }
        item.loading = false;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the task.";
        }
      }
      item.loading = false;
    });

  }

  clickReset() {
    this._authService.updateAccessTime();
    this.showServiceFilter = false;
    this.showTaskStatusFilter = false;
    this.showOnHold = false;
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.recordList = [];
    this.filteredRecordList = [];
    this.originalRecordList = [];
    this.date_type = 'duedate'; //'leadtime';  
    this.customerGUID = "";

    this.f.filteredCustomerName.setValue('');
    this.f.filteredTripCode.setValue('');
    this.f.filteredClientTripId.setValue('');
    this.f.filteredRegistration.setValue('');
    this.f.filteredVendor.setValue('');
    this.f.filteredConfirmationNumber.setValue('');
    this.f.filteredAirport.setValue('');
    this.f.filteredCountry.setValue('');
    this.f.filteredOvfCountry.setValue('');
    this.f.filteredHotel.setValue('');
    this.filterCriteria = {
      customerName: '',
      tripCode: '',
      customerTripId: '',
      registration: '',
      vendorName: '',
      fpiConfirmationReference: '',
      icao: '',
      airportCountryName: '',
      countryName: '',
      hotelName: ''
    };
    this.resetAdditionalFilters();

    this.selectedFilterType = '';
    this.selectedFilterValue = '';

    this.applyFilters();
    //this.startDate = new Date();

    //this.startDate.setDate(this.startDate.getDate() - 7);
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 14);
    //this.f.date_type.setValue(this.date_type);
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 24 * 60 * 60 * 1000;
    let hendnumber: number = 96 * 60 * 60 * 1000;
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;
    let m1: number = dt1.getMinutes();
    let m1s: string = m1.toString();
    if (m1 < 10) {
      m1s = "0" + m1.toString();
    }
    //this.startTime = dt1.getHours().toString() + ":" + m1s;
    let m2: number = dt2.getMinutes();
    let m2s: string = m2.toString();
    if (m2 < 10) {
      m2s = "0" + m2.toString();
    }
    //this.endTime = dt2.getHours().toString() + ":" + m2s;
    this.startTime = "0:00";
    this.endTime = "23:59";
    this.f.startDate.setValue(this.startDate);
    this.f.endDate.setValue(this.endDate);
    this.f.startTime.setValue(this.startTime);
    this.f.endTime.setValue(this.endTime);
    this.selectedServiceClassList = [];
    //this.orgServiceTypeList = [];
    //this.serviceTypeList = [];
    this.selectedServiceTypeList = [];
    //this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    this.filter_by = "clientName";
    this.f.filter_by.setValue(this.filter_by);
    this.text_search = "";
    this.serviceClassList.forEach(x => {
      x.selected = false;
    });
    this.setServiceClassList();
    this.serviceTypeList.forEach(x => {
      x.selected = false;
      x.serviceClassSelected = false;
    });
    //this.setServiceTypeList(false);
    //this.taskStatusList.forEach(x => {
    //  if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
    //    x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
    //    x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
    //    x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
    //    x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
    //    x.selected = true;
    //  }
    //});
    if (this.userServiceTypeList != "") {
      this.serviceTypeList.forEach(x => {
        if (("," + this.userServiceTypeList + ",").indexOf("," + x.serviceTypeID.toString() + ",") > -1) {
          x.selected = true;
        }
        else {
          x.selected = false;
        }
      });
      this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);

    }
    else
      this.setServiceTypeList(false);
    this.updateAllComplete(1);
    this.updateAllComplete(2);
    if (this.userTaskStatusList != "") {
      this.taskStatusList.forEach(x => {
        if ((this.userTaskStatusList).indexOf(x.taskStatusGUID) > -1) {
          x.selected = true;
        }
        else {
          x.selected = false;
        }
      });
    }
    else {
      this.taskStatusList.forEach(x => {
        if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
          x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
          x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
          x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
          x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
          x.selected = true;
        }
      });
    }
    this.setTaskStatusList();
    this.f.includeClosedBilledRecords.setValue(false);
    this.f.includeDemo.setValue(false);
    this.f.filterTripRevision.setValue(false);
    this.f.includeTBATask.setValue(false);
    this.f.text_search.setValue('');
    this.excludeImmediateActionOps = false;
    this.excludeImmediateActionWx = false;
    this.showAssignMe = false;
    this.isDateChanged = false;
    this.getData();

  }

  clickSubmit() {
    this.getData();

  }

  getDataOnDateChange() {
    this.isDateChanged = false;
    this.getData();
  }

  clickOpen(e: any, item: GroundStopTaskModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&gsId=" + item.groundStopGUID + "&gstId=" + item.groundStopTaskGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }

  //clickVendorName(e: any, itemLeg: TripLegModel, itemVendor: VendorModel, stopType: string) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.disableClose = true;
  //  dialogConfig.panelClass = "custom-dialog-container";
  //  //    dialogConfig.maxWidth = "70em";
  //  let icao: string;
  //  if (stopType == "D") {
  //    icao = itemLeg.departureICAO;
  //  }
  //  else {
  //    icao = itemLeg.nextArrivalICAO;
  //  }

  //  dialogConfig.data = {
  //    vendorId: itemVendor.vendorGUID,
  //    icao: icao
  //  };
  //  this._dialog.open(VendorDetailsComponent, dialogConfig);
  //}

  //clickICAO(e: any, gs: TripLegModel, legType: string) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.disableClose = true;
  //  dialogConfig.panelClass = "custom-dialog-container";
  //  //    dialogConfig.maxWidth = "70em";
  //  if (legType == "D") {
  //    dialogConfig.data = {
  //      icao: gs.departureICAO,
  //      icaoIata: gs.departureICAO_IATA,
  //      groundStopGUID: gs.groundStopGUID,
  //      tripCodeGUID: gs.tripCodeGUID
  //    };
  //  }
  //  if (legType == "A") {
  //    dialogConfig.data = {
  //      icao: gs.nextArrivalICAO,
  //      icaoIata: gs.nextArrivalICAO_IATA,
  //      groundStopGUID: gs.nextArrivalGroundStopGUID,
  //      tripCodeGUID: gs.tripCodeGUID
  //    };
  //  }

  //  this._dialog.open(AirportDetailsComponent, dialogConfig);
  //}

  clickServiceFilter() {
    this.showServiceFilter = !this.showServiceFilter;
    this.showTaskStatusFilter = false;
  }
  clickTaskStatusFilter() {
    this.showTaskStatusFilter = !this.showTaskStatusFilter;
    this.showServiceFilter = false;
  }

  allCompleteOps: boolean = false;
  allCompleteWx: boolean = false;
  allCompleteStatus: boolean = false;

  updateAllComplete(group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;
      case 2:
        this.allCompleteWx = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;

    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
      this.selectedServiceCountText = ": All";
    else {
      if (this.selectedServiceTypeList.length == 0)
        this.selectedServiceCountText = "";
      else
        this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
    }
  }

  someComplete(group: number): boolean {
    switch (group) {
      case 1:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteOps;
      case 2:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteWx;
    }
  }

  selectAllServiceType(completed: boolean, group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
      case 2:
        this.allCompleteWx = completed;
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;

        });
        break;
    }
    this.updateAllComplete(group);
  }

  clickResetServiceType() {
    this.serviceTypeList.every(t => t.selected = false);
    this.setServiceTypeList(false);
    this.showServiceFilter = false;
    this.currentPageIndex = 0;
    this.selectedServiceCountText = "";
    this.clickSubmit();
  }

  clickDoneServiceType() {
    this.showServiceFilter = false;
    this.currentPageIndex = 0;

    this.setImmediateActionFilter();
    this.clickSubmit();
  }

  setImmediateActionFilter() {

    this.immediateActionOps = false; 
    this.immediateActionWx = false;

    if (this.selectedServiceTypeList.some(x => x.filterGroup === 1)) {
      this.immediateActionOps = true; 
    }

    if (this.selectedServiceTypeList.some(x => x.filterGroup === 2)) {
      this.immediateActionWx = true;
    } 

  }

  updateAllCompleteStatus() {
    this.allCompleteStatus = this.taskStatusList.every(t => t.selected);
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    if (this.selectedTaskStatusList.length == this.taskStatusList.length)
      this.selectedStatusCountText = ": All";
    else {
      if (this.selectedTaskStatusList.length == 0)
        this.selectedStatusCountText = "";
      else
        this.selectedStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
    }
  }

  someCompleteStatus(): boolean {
    return this.taskStatusList.filter(t => t.selected).length > 0 && !this.allCompleteStatus;

  }

  selectAllTaskStatus(completed: boolean) {

    this.allCompleteStatus = completed;
    this.taskStatusList.forEach(t => {
      t.selected = completed;
    });
    this.updateAllCompleteStatus();
  }

  clickResetTaskStatus() {
    this.taskStatusList.forEach(t => t.selected = false);
    this.setTaskStatusList();
    this.showTaskStatusFilter = false;
    this.selectedStatusCountText = "";
    this.clickSubmit();
  }

  clickDoneTaskStatus() {
    this.showTaskStatusFilter = false;
    this.clickSubmit();
  }

  clickSaveSelections(type: string) {
    this._authService.updateAccessTime();
    let request = new UserModel();
    if (type.toLowerCase() == 'service') {
      request.taskQueueServiceIDList = "";
      request.taskQueueServiceIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
      request.taskQueueStatusList = null;
    }
    else {
      request.taskQueueStatusList = "";
      request.taskQueueServiceIDList = null;
      request.taskQueueStatusList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    }
    this._identityService.saveUserProfile<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this._snackBar.openFromComponent(SuccessComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            data: type,
            panelClass: ['green-snackbar']
          });

        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this._snackBar.open(response.message, '', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
    })
  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getData();

  }

  buildCustomPageSizeOption() {
    this.customPageSizeOptionList = [];
    let obj: CustomPageSizeOptionModel;
    if (this.totalRecords > 0 && this.totalRecords > this.minPageSize) {
      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.minPageSize.toString();
      obj.pageSizeValue = this.minPageSize;
      this.customPageSizeOptionList.push(obj);

      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.maxPageSize.toString();
      obj.pageSizeValue = this.maxPageSize;
      this.customPageSizeOptionList.push(obj);

      if (this.totalRecords > this.maxPageSize) {
        obj = new CustomPageSizeOptionModel();
        obj.pageSizeText = "All";
        obj.pageSizeValue = 10000;
        this.customPageSizeOptionList.push(obj);
      }

    }
  }

  customPageSizeChange(e: any, controlNumber: number) {
    this.currentPageIndex = 0;
    if (controlNumber == 1) {
      this.pageSize = this.f.custom_page_size_select1.value;
      this.f.custom_page_size_select2.setValue(this.pageSize);
    }
    else {
      this.pageSize = this.f.custom_page_size_select2.value;
      this.f.custom_page_size_select1.setValue(this.pageSize);
    }
    this.getData();
  }

  setupRefreshInterval() {
   this.refreshTasks = setInterval(() => { this.getData(); }, 2 * 60000); // 2 min
  }

  hideOnHoldChange(e: any) {
    this.showOnHold = e.checked;
    this.hideOnHold = !this.showOnHold;
    this.getData();
  }

  immediateActionOpsChange(e: any) {
    this.immediateActionOps = e.checked;
    this.getData();
  }
  
  immediateActionWxChange(e: any) {
    this.immediateActionWx = e.checked;
    this.getData();
  }
  
  showAssignMeChange(e: any) {
    this.showAssignMe = e.checked;
    this.getData();
  }
  
   
  
  excludeImmediateActionOpsChange(e: any) {
    this.setImmediateActionFilter();
    
    if (e.checked) {
      this.immediateActionOps = false; 
    } 
    this.getData();
  }
  
  excludeImmediateActionWxChange(e: any) {
    this.setImmediateActionFilter();
    
    if (e.checked) {
      this.immediateActionWx = false; 
    } 
    this.getData();
  }

  toggleStatus(index: number) {

    this.statuses.forEach(x => x.selected = false);
    this.statuses[index].selected = true;

    this.f.date_type.setValue(this.statuses[index].value);  

    this.getData();
  }

 

  clickAssignWx(e: any, item: GroundStopTaskModel) {
    if (item.loading == false)
      item.loading = true;
    else
      return;

    let req = new GroundStopTaskModel();
    req.isAssignMe = false;
    req.groundStopTaskGUID = item.groundStopTaskGUID
    if (item.assignMe == "") {
      req.isAssignMe = true;
    }

    this._groundStopAdvancedService.updateGroundStopTaskAssignMeBygstId<ResponseModel<GroundStopTaskModel>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let obj = new GroundStopTaskModel();
          obj = response.model;
          if (obj.isAssignMe) {
            item.assignMe = obj.assignMe;
          }

        }
        else {
          item.errMsg = "Failed to update the task"
        }
        item.loading = false;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the task.";
        }
      }
      item.loading = false;
    });

  }

  clickAssignOps(e: any, item: GroundStopTaskModel) {
    if (item.loading == false)
      item.loading = true;
    else
      return;

    let req = new GroundStopTaskModel();
    req.isAssignMeOps = false;
    req.groundStopTaskGUID = item.groundStopTaskGUID
    if (item.assignMeOps == "" || item.assignMeOps == null) {
      req.isAssignMeOps = true;
    }

    this._groundStopAdvancedService.updateGroundStopTaskAssignMeOPSBygstId<ResponseModel<GroundStopTaskModel>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let obj = new GroundStopTaskModel();
          obj = response.model;
          if (obj.isAssignMeOps) {
            item.assignMeOps = obj.assignMeOps;
          }

        }
        else {
          item.errMsg = "Failed to update the task"
        }
        item.loading = false;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the task.";
        }
      }
      item.loading = false;
    });

    
  }


  removeAssignedWx(item: GroundStopTaskModel) {
    alert('wx');
    
  }

  removeAssignedOps(item: GroundStopTaskModel) {
    alert('ops');
  }

}
// statuses = [
//   { label: 'Task Due date', selected: true, value: 'duedate' },
//   { label: 'Task Lead Time', selected: false, value: 'leadtime' },
//   { label: 'Trip Itinerary', selected: false, value: 'tripdate' }
// ];

@Component({
  selector: 'success-component',
  template: `<div class="small d-flex align-items-center">
    <i class= "material-icons">check_circle</i>
      <div>{{data}} Selections Saved</div>
</div>`,
  styles: [],
})
export class SuccessComponent { constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { } }
