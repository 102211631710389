import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { FlightFollowingEditDialogComponent } from '../ground-stop-advanced/flight-following-edit-dialog.component';
import { TripRevisionModel } from '../models/trip-revision.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from '../ground-stop-advanced/task-revision-required-edit-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { BusinessRulesEditComponent } from '../business-rules/business-rules-edit.component';
import { DialogService } from '../services/dialog.service';
import { TripMessageCenterComponent } from '../ground-stops/trip-message-center.component';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { FuelTaskEditDialogComponent } from '../fuel-quote/fuel-task-edit-dialog.component';
import { OverFlightPermitTaskComponent } from '../ground-stop-advanced/overflight-permit-task.component';
import { LandingPermitPrivateTaskComponent } from '../ground-stop-advanced/landing-permit-private-task.component';
import { SlotArrivalTaskComponent } from '../ground-stop-advanced/slot-arrival-task.component';
import { InternalGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-ground-stop-task-edit-dialog.component';
import { InternalPublicGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-public-ground-stop-task-edit-dialog.component';
import { FlightPlanTaskComponent } from '../ground-stop-advanced/flight-plan-task.component';
import { UkGarTaskComponent } from '../ground-stop-advanced/uk-gar-task.component';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';

//export class TableElement {
//  legItem: TripLegModel;
//  expanded: boolean;
//}

@Component({
  selector: 'app-ground-stop-leg-tasklist',
  templateUrl: './ground-stop-leg-tasklist.component.html',
  styleUrls: ['./ground-stop-leg-tasklist.component.css'],

})

export class GroundStopLegTasklistComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  //@Input() reloadChildPage: boolean;
  @Input() legItem: TripLegModel;
  @Input() refreshChildColumn: string;
  @Input() refreshChildRow: boolean;
  @Input() updateTask: boolean;
  @Input() hidePastStop: boolean;
  @Input() includeActiveStopOnly: boolean;
  @Input() lockdownTrip: boolean;


  request: TripModel;
  ground_stop_leg_tasklist_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;

  //recordList: TripLegModel[];
  usernameList: TripRevisionModel[] = [];
  @Output() public updateParentPage = new EventEmitter();
  depTaskList: GroundStopTaskModel[] = [];
  arrTaskList: GroundStopTaskModel[] = [];
  scrHeight: number;
  upPII: boolean = false;
  //displayedColumn: string[] = ['flag', 'service',  'task_status', 'message_in_count', 'message_out_count'];
  //displayedColumn2: string[] = ['blank1', 'blank', 'message']; // 2,1,2
  displayedColumn: string[] = ['flag', 'service', 'brID', 'message_in_count', 'message_out_count'];
  displayedColumn2: string[] = ['blank1', 'br', 'message']; // 2,2
  clientName: any;
  
  excludeImmediateActionList = ['ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8', '6aaa0374-7279-4edb-84cb-927918a85584', '483d7593-d276-443c-bf25-bba400265727'];
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _tripLegService: TripLegService,
    private readonly _dialogService: DialogService
  ) {

  }

  getAllData(): Observable<any[]> {
    let req = new TripRevisionModel();
    req.groundStopGUID = this.legItem.groundStopGUID;
    req.nextArrivalGroundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    req.pastHours = this.request.pastHours;
    let getUsernameResponse = this._tripLegService.getTripRevisionUserNameListBydgId_agId(req);

    let reqD = new GroundStopModel();
    reqD.groundStopGUID = this.legItem.groundStopGUID;
    reqD.taskType = "D";
    reqD.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqD.serviceTypeIDList = this.request.serviceTypeIDList;
    reqD.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqD.includeDisabledTask = this.request.includeDisabledTask;
    if (this.legItem.departureGroundStopTypeGUID.toUpperCase() == GlobalConstant.contingencyGroundStopTypeGUID || this.legItem.nextArrivalGroundStopTypeGUID.toUpperCase() == GlobalConstant.contingencyGroundStopTypeGUID)
      reqD.contingencyGroundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    let depTaskResponse;
    depTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqD);

    let reqA = new GroundStopModel();
    reqA.groundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    reqA.taskType = "A";
    reqA.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    reqA.serviceTypeIDList = this.request.serviceTypeIDList;
    reqA.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    reqA.includeDisabledTask = this.request.includeDisabledTask;
    if (this.legItem.departureGroundStopTypeGUID.toUpperCase() == GlobalConstant.contingencyGroundStopTypeGUID || this.legItem.nextArrivalGroundStopTypeGUID.toUpperCase() == GlobalConstant.contingencyGroundStopTypeGUID)
      reqA.contingencyGroundStopGUID = this.legItem.groundStopGUID;
    let arrTaskResponse;
    arrTaskResponse = this._tripLegService.getTaskListBygIdTaskType(reqA);
    return forkJoin([getUsernameResponse, depTaskResponse, arrTaskResponse]);

  }


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //if (!this.reloadChildPage && this.refreshChildColumn == "") {     
    //    return;      
    //}
    if (!this.refreshChildRow && (this.refreshChildColumn == "" || changes["updateTask"] != undefined)) {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));

    if (this.refreshChildColumn == "") {
      this.showSpin = true;
      this.getAllData().subscribe(responses => {
        if (responses[0] != null) {
          if (responses[0].code == "200" && responses[0].message == "") {
            this.usernameList = responses[0].model;

          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.depTaskList = responses[1].model;
          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }

        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            this.arrTaskList = responses[2].model;
          }
          else {
            if (responses[2].code == "401") {
              this._authService.signOut();
            }
          }
        }

        this.initControls();

        this.showSpin = false;
        this.refreshChildColumn = "";

      });
    }
    else {
      this.getTripRevisionUsernameListBygIdList();
    }
  }

  initControls() {
    this.ground_stop_leg_tasklist_form = this._formBuilder.group({

    })
  }


  get f() { return this.ground_stop_leg_tasklist_form.controls; }

  getTripRevisionUsernameListBygIdList() {
    let obj = new TripRevisionModel();
    obj.groundStopGUID = this.legItem.groundStopGUID;
    obj.nextArrivalGroundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    obj.pastHours = this.request.pastHours;
    this._tripLegService.getTripRevisionUserNameListBydgId_agId<ResponseModel<TripRevisionModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        this.usernameList = response.model;
      }
    })

  }

  clickServiceType(e: any, gstItem: GroundStopTaskModel, taskType: string) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.minWidth = "54em";
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      dialogConfig.minHeight = "37em";
    }
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = this.legItem.departureICAO;
    leg += "-" + this.legItem.nextArrivalICAO;
    let icao: string = "";
    let gsId: string = "";
    let countryId: string = "";
    if (taskType == 'D') {
      icao = this.legItem.departureICAO;
      gsId = this.legItem.groundStopGUID;
      countryId = this.legItem.departureCountryGUID;
    }
    else {
      icao = this.legItem.nextArrivalICAO;
      gsId = this.legItem.nextArrivalGroundStopGUID;
      countryId = this.legItem.nextArrivalCountryGUID;
    }

    dialogConfig.data = {
      groundStopGUID: gsId, tripCodeGUID: this.legItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: countryId, icao: icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: this.legItem.customerGUID, leg: leg, nextGroundStopGUID: this.legItem.nextArrivalGroundStopGUID,
      depGroundStopGUID: this.legItem.groundStopGUID, clientName: this.clientName
    };

    //if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70 && gstItem.serviceTypeID != 44 && gstItem.serviceTypeID != 45 && gstItem.serviceTypeID != 46
    //  && gstItem.serviceTypeID != 48 && gstItem.serviceTypeID != 49 && gstItem.serviceTypeID != 60 && gstItem.serviceTypeID != 61 && gstItem.serviceTypeID != 62) {

    let dialogRef;
    switch (gstItem.formType) {
      case 1: //ops legacy
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 2: //OpsOverflightPermitForm
        dialogRef = this._dialog.open(OverFlightPermitTaskComponent, dialogConfig);
        break;
      case 3: //OpsSlotForm
        dialogRef = this._dialog.open(SlotArrivalTaskComponent, dialogConfig);
        break;
      case 4: //InternalOnly
        dialogRef = this._dialog.open(InternalGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 5: //InternalPublic
        dialogRef = this._dialog.open(InternalPublicGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 7: //FuelTaskForm
        if (gstItem.relatedAirportICAO != "" && gstItem.relatedAirportICAO != null && gstItem.additionalDescription == "") {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("Select ICAO for fuel quote:", false, icao, gstItem.relatedAirportICAO, "Select ICAO");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();

            dialogConfig.data = {
              groundStopGUID: gsId, tripCodeGUID: this.legItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
              serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: countryId, icao: icao,
              serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
              customerGUID: this.request.customerGUID, leg: leg, nextGroundStopGUID: this.legItem.nextArrivalGroundStopGUID,
              depGroundStopGUID: this.legItem.groundStopGUID, clientName: this.clientName, additionalDescription: result ? icao : gstItem.relatedAirportICAO
            }
            dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);
          });
        }
        else
          dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);
        break;
      case 8: //landing
        dialogRef = this._dialog.open(LandingPermitPrivateTaskComponent, dialogConfig);
        break;
      case 9: //flight plan
        dialogRef = this._dialog.open(FlightPlanTaskComponent, dialogConfig);
        break;
      case 10: //gar
        dialogRef = this._dialog.open(UkGarTaskComponent, dialogConfig);
        break;
      default:
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result.isModified) {
        //this.showSpin = true;
        this.getTaskListByTaskType(taskType);
        this.updateParentPage.emit({ updateSection: 'leg' });
      }
    });

    // else {
    //   const dialogRef = this._dialog.open(FlightFollowingEditDialogComponent, dialogConfig);
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       //this.showSpin = true;
    //       this.getTaskListByTaskType(taskType);
    //       this.updateParentPage.emit({ updateSection: 'leg' });         
    //     }
    //   });
    // }

  }

  getTaskListByTaskType(taskType: string) {
    let req = new GroundStopModel();
    if (taskType == "D") {
      req.groundStopGUID = this.legItem.groundStopGUID;
    }
    if (taskType == "A") {
      req.groundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    }

    req.taskType = taskType;
    req.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    req.serviceTypeIDList = this.request.serviceTypeIDList;
    req.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    req.includeDisabledTask = this.request.includeDisabledTask;
    if (taskType == "D") {
      this.depTaskList = [];
    }
    if (taskType == "A") {
      this.arrTaskList = [];
    }
    let taskList: GroundStopTaskModel[] = [];
    this.showSpin = true;
    this._tripLegService.getTaskListBygIdTaskType<ResponseModel<GroundStopTaskModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (taskType == "D") {
          this.depTaskList = [];
          this.depTaskList = response.model;
        }
        if (taskType == "A") {
          this.arrTaskList = [];
          this.arrTaskList = response.model;
        }

      }
      this.showSpin = false;

    })

  }


  clickPointOfContact(e: any, itemGs: GroundStopModel, itemGst: GroundStopTaskModel) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: itemGst.vendorGUID,
      icao: itemGs.icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);

  }


  clickAudit(username: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.legItem.customerGUID, aircraftGUID: this.legItem.aircraftGUID, tripCodeGUID: this.legItem.tripCodeGUID, groundStopGUIDList: this.legItem.groundStopGUID + "," + this.legItem.nextArrivalGroundStopGUID,
      pastHours: this.request.pastHours,
      userName: username, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickTripRevisionRequired(e: any, gstItem: GroundStopTaskModel, taskType: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    let groundStopGUID: string = "";
    if (taskType == "D") {
      groundStopGUID = this.legItem.groundStopGUID;
    }
    if (taskType == "A") {
      groundStopGUID = this.legItem.nextArrivalGroundStopGUID;
    }
    dialogConfig.data = {
      dialogTitle: s, groundStopGUID: groundStopGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, isRevisionRequiredValue: gstItem.isRevisionRequiredValue,
      v: this._authService.getCurrentTimeNumber()
    };
    this.updateParentPage.emit({ updateSection: 'clearRefreshTaskStatus' });
    const dialogRef = this._dialog.open(TaskRevisionRequiredEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.updateParentPage.emit({ updateSection: 'allLegTask' });
      }

    });


  }

  openBusinessRule(brID: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    let businessRulesID = 0;
    let s = "Add Business Rule";
    businessRulesID = brID
    s = "View Business Rule " + businessRulesID.toString();
    let isReadOnly = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: businessRulesID, title: s, readonly: isReadOnly };


    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);

  }

  openMessages(e: any, gstItem: GroundStopTaskModel, inOrout: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "92em";//"1000px";
    dialogConfig.width = "89em";//"1000px";
    // dialogConfig.height = "42.5em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      tripCodeGUID: this.legItem.tripCodeGUID, groundStopGUID: gstItem.groundStopGUID,
      nextGroundStopGUID: gstItem.nextGroundStopGUID, serviceTypeID: gstItem.serviceTypeID,
      aircraftGUID: this.legItem.aircraftGUID, customerGUID: this.legItem.customerGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, hidePastStop: this.hidePastStop,
      registration: this.legItem.registration, tripCodeText: this.legItem.tripCode, source: inOrout,
      includeActiveStopOnly: this.includeActiveStopOnly, selectedLegIndex: this.legItem.tripLegOrder - 1, lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  newMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.legItem.tripCodeGUID, groundStopGUID: '', groundStopTaskGUID: '', nextGroundStopGUID: '', serviceTypeID: 0,
      aircraftGUID: this.legItem.aircraftGUID, customerGUID: this.legItem.customerGUID, taskGroundStopGUID: '', v: this._authService.getCurrentTimeNumber(),
      lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }

    });

  }
}
