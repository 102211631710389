import { Component, Inject, OnInit } from '@angular/core';
import { AirportModel } from '../models/airport.model';
import { CommonService } from '../services/common.service';
import { DialogService } from '../services/dialog.service';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthenticateService } from '../services/authenticate.service';
import { HotelModel } from '../models/hotel.model';
import { ResponseModel } from '../models/response.model';
import { AzureMapDistanceResults, AzureMapGeometry, AzureMapProperties, AzureMapRouteFeatures, AzureMapRouting } from '../models/azure-map-routing';
import { AirportBriefComponent } from '../trip-planner/airport-brief.component';

@Component({
  selector: 'app-closest-airports',
  templateUrl: './closest-airports.component.html',
  styleUrls: ['./closest-airports.component.css']
})
export class ClosestAirportsComponent implements OnInit {
  latitude: number;
  longitude: number;
  airportList: AirportModel[];
  countryGUID: string;
  hotelName: string;
  displayedColumn: string[] = ['distance', 'drive_distance', 'drive_time', 'airport']


  constructor(private readonly _dialogRef: MatDialogRef<ClosestAirportsComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService,
    private readonly _dialog: MatDialog
  ) {

    this.latitude = _data.latitude;
    this.longitude = _data.longitude;
    this.countryGUID = _data.countryGUID;
    this.hotelName = _data.hotelName;
    }
    ngOnInit(): void {
      this._authService.updateAccessTime();
      let request = new HotelModel();
      request.latitude = this.latitude;
      request.longitude = this.longitude;
      request.countryGUID = this.countryGUID;
      request.distanceFromAirport = 150;
      this._commonService.getClosestAirportForHotel<ResponseModel<AirportModel[]>>(request, 5).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.airportList = response.model;
            this.getDriveTime();
          }
        }
      })
    }


  getDriveTime() {
    let request = new AzureMapRouting();
    request.type = "FeatureCollection";
    let origin = new AzureMapRouteFeatures();
    origin.type = "Feature";
    let oGeo = new AzureMapGeometry();
    oGeo.type = "MultiPoint";
    oGeo.coordinates = [[this.longitude, this.latitude]];
    origin.properties = new AzureMapProperties();
    origin.properties.pointType = "origins";
    origin.geometry = oGeo;
    var destination = new AzureMapRouteFeatures();
    destination.type = "Feature";
    var dGeo = new AzureMapGeometry();
    dGeo.type = "MultiPoint";
    dGeo.coordinates = [];
    this.airportList.forEach(x => {
      dGeo.coordinates.push([x.longitude, x.latitude]);
    });
    destination.properties = new AzureMapProperties()
    destination.properties.pointType = "destinations";
    destination.geometry = dGeo;
    request.features = [];
    request.features.push(origin);
    request.features.push(destination);
    this._commonService.getMapDistance<ResponseModel<AzureMapDistanceResults>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          response.model.properties.matrix.forEach(x => {
            this.airportList[x.destinationIndex].driveDistance = Number((x.distanceInMeters / 1690).toFixed(1));
            this.airportList[x.destinationIndex].driveTime = Number((x.durationInSeconds / 60).toFixed(1));
          })

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });

  }

  openAirport(airport: AirportModel) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.width = "80em";

      dialogConfig.data = {
        selectedAirport: airport,
        airportID: airport.airportID,
        icao: airport.icao,
        customerGUID:"",
        farTypeID: 1,
        farType: "Part 91",
        route: '',
        handlerGUID: null,
        ssnGUID: null,
        callFrom: 'alternate'
      };

      this._dialog.open(AirportBriefComponent, dialogConfig);
    
  }

  
}
