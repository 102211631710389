<!--<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">-->
<div *ngIf="login && isShown" color="primary"
  style="background-color: #000000; display: flex; justify-content: space-between; margin-left: 0em; padding-bottom: 0.3125em;">
  <div style="float: left;width: 14.5em">
    <div class="col-9 align-self-center" style="display: inline-block; padding-top: 0em;">
      <img src="../../assets/images/FlightPro-Logo-Final-BlackBG-Transparent.png"
        style="width: 14.375em;height:5em;border:0em" />
    </div>
  </div>
  <div
    style="align-items:center; color: white; margin-top: auto; margin-bottom: auto;background-color: #000000;white-space:nowrap;">
    <h3 style="color: white;">{{customerName}}</h3>
  </div>
  <div style="float: right; display: flex; min-width: 10.5em; max-width: 40.5em">
    <div *ngIf="websiteEnvironment!=''" style="width: 12em;padding-top:1em; font-weight:bold">
      <div *ngIf="websiteEnvironment=='DEV'" style="background-color:mistyrose;text-align: center">
        <div style="font-size: medium">DEV ENVIRONMENT</div>
        <div style="font-size: small; margin-top:-0.25em">DO NOT DISTRIBUTE</div>
        <div style="font-size: small; margin-top: -0.25em">SAMPLE DATA</div>
      </div>
      <div *ngIf="websiteEnvironment=='QA'" style="background-color:lightgreen;text-align: center">
        <div style="font-size: medium">QA ENVIRONMENT</div>
        <div style="font-size: small; margin-top:-0.25em">DO NOT DISTRIBUTE</div>
        <div style="font-size: small; margin-top: -0.25em">VALIDATION IN PROGRESS</div>
      </div>
      <div *ngIf="websiteEnvironment=='LOCAL'" style="background-color:hotpink;text-align: center">
        <div style="font-size: medium">LOCAL HOST</div>
      </div>
    </div>
    <div style="margin-top:1.25em">
      <a href="https://apps.apple.com/us/app/flight-pro-international/id6447209261" target="_blank"
        rel="noopener noreferrer"><img src="../../assets/images/app-store-badge-sz-300x89.png" width="150"
          height="45" /></a>
    </div>
    <div style="margin-top:1.25em;margin-right:1em">
      <a href="https://play.google.com/store/apps/details?id=com.fpc.flightprointl" target="_blank"
        rel="noopener noreferrer"><img src="../../assets/images/google-play-badge-sz-300x89.png" width="150"
          height="45" /></a>
    </div>
    <div
      style="color: white; margin-top: auto; margin-bottom: auto; margin-right: 0.625em;width:10.5em;background-color: #000000;">
      <div style="text-align:left; float: right">
        <ul class="toolbar">
          <li>
            <span style="color: darkorange; font-size: medium">Hello {{username}}</span>
          </li>
          <li>
            <span style="color: antiquewhite; font-size: small;">{{userAccess}}</span>
          </li>
          <li>
            <div ngbDropdown class="d-inline-block">
              <!--<button class="btn btn-outline-secondary btn-sm" id="dropdownMyAccount" ngbDropdownToggle style="font-size: 12px;">Your account</button>-->
              <a class="menuALink" id="dropdownMyAccount" ngbDropdownToggle style="font-size: small;color: white">
                Your
                Account
              </a>
              <div ngbDropdownMenu attr.aria-labelledby="dropdownMyAccount"
                style="text-align: left; background-color: black; border-color: black; cursor: pointer;">
                <ul style="padding-left:0em; margin-left: 0em;">
                  <li class="menuItem">
                    <a ngbDropdownItem (click)="openManageAccount()">Manage Account</a>
                  </li>
                  <!-- <li class="menuItem">
                  <a ngbDropdownItem routerLink="user-accounts/change-password">Change Password</a>
                </li> -->
                  <!-- <li class="menuItem" >
                  <a ngbDropdownItem (click)="clickSignature()" style="cursor: pointer">Signature</a>
                </li> -->
                  <li class="menuItem">
                    <a ngbDropdownItem (click)="openPrivacy()" style="cursor: pointer">Privacy Notice</a>
                  </li>
                  <li class="menuItem">
                    <a ngbDropdownItem (click)="openTerm()" style="cursor: pointer">Terms of Use</a>
                  </li>
                  <!--<li class="menuItem">-->
                  <!--<a ngbDropdownItem routerLink="user-accounts/feedback">Feedback</a>-->
                  <!--<a ngbDropdownItem href="https://forms.office.com/Pages/ResponsePage.aspx?id=Zi70qra04EOrzaGaYm_w1rpghgC4jdhFqoiICarogHhUQ0oyU0ZCTzE0TkZaUUI3VkdTNENMRkVKNCQlQCN0PWcu" target="_blank">Feedback</a>
                </li>-->
                  <!--<li class="menuItem">
                  <a ngbDropdownItem routerLink="" (click)="openContactUs()">Contact Us</a>
                </li>-->
                  <li class="menuItem">
                    <a ngbDropdownItem routerLink="user-accounts/logout">Sign out</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!--<div *ngIf="login && isMoboile && isShown" style="background-color: grey; height: 2.5em; vertical-align:middle;padding-left:0.3125em; display: flex; justify-content: space-between">-->
<!--<div class="d-inline-block" style="padding-left: 15px;margin-left:5px;">
    <a style="cursor: pointer;color:white" (click)="onToggleTopBanner()" title="show / hide top banner">&#9776;</a>
  </div>-->
<!--<div>
    <div *ngIf="menus.length==0" style="color: white; margin-left: 0.5em">
      Reset Password
    </div>
    <div *ngFor="let m of menus" class="d-inline-block" style="vertical-align:middle; padding-right:0.625em; padding-top:0.1875em; padding-bottom:0.1875em; margin-top: 0.3125em;">
      <div class="menuDiv" style="display: flex;">
        <a *ngIf="m.route!=null && m.route!=''" routerLink="{{m.route}}">{{m.display}}</a>
        <nav-menu-item *ngIf="m.route==null || m.route==''" [menu]="m" [level]="0"></nav-menu-item>
      </div>
    </div>
  </div>
  <div *ngIf="utcText!=''" style="color: white; font-size: small; margin-right: 1.25em; margin-top: 0.35em;">
    UTC <span style="font-size: large">{{utcText}}</span>
  </div>
</div>-->

<div *ngIf="login && isShown"
  style="background-color: grey; height: 2.5em; vertical-align: middle; padding-left: 0.3125em; display: flex; justify-content: space-between">
  <div>
    <div *ngIf="menus.length==0" style="color: white; margin-left: 0.5em;margin-top: 0.5em;">
      Reset Password
    </div>
    <div *ngIf="menus.length>0 && userType=='i'">
      <div cdkMenuBar>
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="home"><span style="display:flex">Home
            <span class="material-icons">arrow_drop_down</span></span></button>
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="trips"><span style="display:flex">Manage
            Trips <span class="material-icons">arrow_drop_down</span></span></button>
        <button class="example-menu-bar-item" [cdkMenuTriggerFor]="fake"
          (click)="openTripPlanner()" cdkMenuItem>Trip Planner</button>
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="messages"><span
            style="display:flex">Messages <span class="material-icons">arrow_drop_down</span></span></button>
        <button class="example-menu-bar-item" [cdkMenuTriggerFor]="fake"
          (click)="openSearchComponent()" cdkMenuItem>Search</button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="maintenance"><span style="display:flex">Maintenance <span class="material-icons">arrow_drop_down</span></span></button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
          (click)="openMaintenanceComponent()"><span style="display:flex">Maintenance </span></button>
        <!-- <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"  (click)="openAccountingDashboardComponent()"><span style="display:flex">Acct </span></button> -->
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="admin"><span style="display:flex">Admin Services <span class="material-icons">arrow_drop_down</span></span></button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="reports"><span style="display:flex">Reports <span class="material-icons">arrow_drop_down</span></span></button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="clickRoute('airport-locator-dialog','')">Airport</button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="clickRoute('country-locator-dialog','')">Country</button>
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="clickRoute('vendor-locator-dialog','')">Vendor</button>
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="openAccountingDashboardComponent()">Accounting</button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="clickAdvisoryDetails()">Advisory</button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake" (click)="openFuelComponent()">Fuel</button>
        <!--<button class="example-menu-bar-item" [cdkMenuTriggerFor]="fake" (click)="clickRoute('/common-utility/blank','')" cdkMenuItem>Ops Brief</button>-->
        <!--<button class="example-menu-bar-item" [cdkMenuTriggerFor]="fake" (click)="openDocumentTypeList()" cdkMenuItem>Doc Type</button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="help"><span style="display:flex">Help <span class="material-icons">arrow_drop_down</span></span></button>
      </div>

      <ng-template #fake>
        <div cdkMenu>

        </div>
      </ng-template>

      <ng-template #home>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" (click)="clickRoute('/current-events/current-events-dashboard','')"
            cdkMenuItem>Home Dashboard</button>
          <button class="example-menu-item" (click)="clickRoute('/current-events-client/current-events-dashboard','')"
            cdkMenuItem>Client Home Dashboard</button>
          <button class="example-menu-item" cdkMenuItem [cdkMenuTriggerFor]="blogs">
            Blogs <span class="material-icons">arrow_right</span>
          </button>
        </div>
      </ng-template>

      <ng-template #trips>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" (click)="clickRouteNew('/ground-stop-advanced/task-queuelist','')"
            cdkMenuItem>Task Queue</button>
          <button class="example-menu-item" (click)="clickRouteNew('/trip-legs/trip-leglist','')" cdkMenuItem>Daily
            View</button>
          <button class="example-menu-item"
            (click)="clickRouteNew('/ground-stops/trip-quick-search-control-internal','Trip Schedules')"
            cdkMenuItem>Trip Schedules</button>
          <!--<button class="example-menu-item" (click)="clickRoute('ground-stops/trip-code-search','')" cdkMenuItem>Trip Search</button>-->
          <button class="example-menu-item" (click)="clickRoute('add-new-trip-dialog','')" cdkMenuItem>Add New
            Trip</button>
          <!--<button class="example-menu-item" (click)="clickRoute('/ground-stops/trip-audit-dashboard','')"
            cdkMenuItem>Audit Trail Dashboard</button>-->
          <button class="example-menu-item" (click)="clickRoute('document-downloads-dialog','')" cdkMenuItem>Document
            Downloads</button>
        </div>
      </ng-template>

      <ng-template #messages>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" (click)="clickRouteNew('/messages/message-center','')"
            cdkMenuItem>Messages</button>

          <!--<button cdkMenuItem class="example-menu-item" (click)="clickRoute('/messages/message-center','')">Ops Message Center</button>-->
          <button cdkMenuItem class="example-menu-item" (click)="clickRouteNew('/messages/message-import','')">Import
            Outlook Messages</button>
          <button cdkMenuItem class="example-menu-item" (click)="addNewMessageFolder()">Add Message Folder</button>

        </div>
      </ng-template>

      <ng-template #maintenance>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="client">Client <span
              class="material-icons">arrow_right</span></button>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="vendor">Vendor <span
              class="material-icons">arrow_right</span></button>
          <!--<button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="fuel">Fuel <span class="material-icons">arrow_right</span></button>-->
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="hotels">Hotels <span
              class="material-icons">arrow_right</span></button>
          <button cdkMenuItem class="example-menu-item"
            (click)="clickRouteNew('/business-rules/manage-business-rules','')">Business Rules</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRoute('apis-dialog','')">APIS Credentials</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('template/template-dashbord','')">Templates</button>
          <button class="example-menu-item" cdkMenuItem (click)="openCountryDocumentsPicklistComponent()">Country
            Documents Editor</button>
          <!-- <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('country-document-type-update','')">Update Country Documents</button> -->

        </div>
      </ng-template>

      <ng-template #admin>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="clientadmin">Client <span
              class="material-icons">arrow_right</span></button>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="opsadmin">FPI Operations <span
              class="material-icons">arrow_right</span></button>
        </div>
      </ng-template>

      <ng-template #reports>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="bi">BI Reports <span
              class="material-icons">arrow_right</span></button>
          <!-- <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="reportsmenu">Reports <span
              class="material-icons">arrow_right</span></button> -->
          <button class="example-menu-item" (click)="clickRoute('trip-summary-report','')" cdkMenuItem>Trip Summary
            Report</button>
          <button cdkMenuItem class="example-menu-item"  (click)="openCriticalAlertsComponent()" >Missing Customs & Permits Tasks  </button>
          <!--<button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="utilities">Utilities <span class="material-icons">arrow_right</span></button>-->
        </div>
      </ng-template>


      <ng-template #help>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" [cdkMenuTriggerFor]="helpguides">
            Help Guides <span class="material-icons">arrow_right</span>
          </button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('help-desk','')">Help Desk - Home</button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('client-feedback','')">
            Help Desk
            Tickets
          </button>
          <!-- <button cdkMenuItem class="example-menu-item" (click)="clickRoute('feedback-dialog','')">Feedback</button> -->
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('engineering-tickets','')">
            Engineering
            Tickets
          </button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('dev-environment','')">
            Open Dev
            Environment
          </button>
          <!--<button cdkMenuItem class="example-menu-item" (click)="clickRoute('fee-schedule','')">FPI Fee Schedule</button>-->
        </div>
      </ng-template>
      <ng-template #helpguides>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('help-guides','ops')">Ops</button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('help-guides','client')">Client</button>
        </div>
      </ng-template>

      <ng-template #blogs>
        <div class="example-menu" cdkMenu>
          <!-- <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('/current-events/company-events-list','')">Manage Company Events</button> -->
          <button class="example-menu-item" cdkMenuItem
            (click)="openFPCNewsListComponent()">Manage FPC News</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRoute('/current-events/headline-list','')">Manage
            Headlines</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRoute('submit-current-event','')">Submit a
            Story</button>
        </div>
      </ng-template>

      <ng-template #client>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('/customers/customer-profile-setup','')">Client Profile Setup</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('/customers/customer-commlist','')">Manage Client Back Office</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('/persons/customer-personlist','')">Manage Crew/PAX Profiles</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('/aircraft/manage-aircraft-profile','')">Manage Aircraft Profiles</button>
        </div>
      </ng-template>

      <ng-template #vendor>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('/vendors/vendor-profile-list','')">Vendor Profile Setup</button>
        </div>
      </ng-template>

      <ng-template #fuel>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('fuelpricing/fuelpricing','')">Import
            Fuel Price</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('fuelmenu/fuel-supplier-profile','')">Fuel Supplier Profiles</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('fuelmenu/fuel-pricing-summary','')">Fuel
            Pricing</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRouteNew('fuelmenu/revise-fuel-pricing','')">Invalid Airports</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('taxesandfees/taxesandfees','')">Taxes
            and Fees</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('currency/currency','')">Exchange
            Rates</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('fuelmenu/fuel-client-markup','')">Fuel
            Client Markup</button>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('fuelmenu/fuel-commissions','')">Fuel
            Commissions</button>
        </div>
      </ng-template>

      <ng-template #hotels>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('/hotels/hotellist','')">Manage Hotel
            Profiles</button>
        </div>
      </ng-template>

      <ng-template #utilities>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem (click)="clickRoute('dialog','')">Generate Template</button>


        </div>
      </ng-template>

      <ng-template #clientadmin>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/register-user','Register new User - Client')">Register New Users</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/reset-password-admin','')">Reset Passwords</button>
          <button class="example-menu-item" cdkMenuItem (click)="openAccountGrouping()">Account Grouping</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/user-permission-client','')">Manage User Permissions</button>
        </div>
      </ng-template>

      <ng-template #opsadmin>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/register-user','Register new User - FPC Operations')">Register New
            Users</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/reset-password-admin','')">Reset Passwords</button>
          <button class="example-menu-item" cdkMenuItem
            (click)="clickRoute('user-accounts/user-permission-admin','')">Manage User Permissions</button>
        </div>
      </ng-template>

      <ng-template #bi>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/customers/customer-bi-dashboard','')">
            Client Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/bi-dashboards/fpi-marketing-bi','')">
            Marketing Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/bi-dashboards/finance-bi-dashboard','')">
            Finance Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/bi-dashboards/trip-quote-bi-dashboard','')">
            Trip Quotes Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/bi-dashboards/leg-match-bi-dashboard','')">
            Leg Match Dashboard
          </button>
          <!--<button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('/vendors/fuel-bi-dashboard','')">Fuel Dashboard</button>-->
          <button class="example-menu-item" cdkMenuItem (click)="clickRouteNew('/bi-dashboards/airport-notes-bi-dashboard','')">Airport Notes</button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/vendors/vendor-bi-dashboard','')">
            Vendor Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/airports/airport-bi-dashboard','')">
            Airport Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('/bi-dashboards/message-bi-dashboard','')">
            Message Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('bi-dashboards/dev-tickets-bi-dashboard','')">
            Dev Tickets Dashboard
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRouteNew('bi-dashboards/team-metrics-bi-dashboard','')">
            Team Metrics
          </button>
        </div>
      </ng-template>

      <ng-template #reportsmenu>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" (click)="clickRoute('trip-summary-report','')" cdkMenuItem>Trip Summary
            Report</button>
        </div>
      </ng-template>
    </div>
    <div *ngIf="menus.length>0 && userType=='c'">
      <div cdkMenuBar>
        <button class="example-menu-bar-item" cdkMenuItem
                (click)="clickRoute('/current-events-client/current-events-dashboard','')">
          <span style="display:flex">Home</span>
        </button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="trips">
    <span style="display:flex">Schedules <span class="material-icons">arrow_drop_down</span></span>
  </button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
                (click)="openSchedulesComponent()">
          <span style="display:flex">Schedules </span>
        </button>
        <button class="example-menu-bar-item" [cdkMenuTriggerFor]="fake"
                (click)="openTripPlanner()" cdkMenuItem>
          Trip Planner
        </button>
        <!-- <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="messages">
    <span style="display:flex">Messages <span class="material-icons">arrow_drop_down</span></span>
  </button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
                (click)="openMessageCenterClientComponent()">
          <span style="display:flex">Messages </span>
        </button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="maintenance">
    <span style="display:flex">Data Maintenance <span class="material-icons">arrow_drop_down</span></span>
  </button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
                (click)="openDataMaintenanceComponent()">
          <span style="display:flex">Data Maintenance </span>
        </button>
        <!--  <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="admin">
      <span style="display:flex">
        Admin
        Services <span class="material-icons">arrow_drop_down</span>
      </span>
    </button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
                (click)="openAdminServicesClientDashboard()">
          <span style="display:flex">Admin Services </span>
        </button>
        <!--<button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="help">
          <span style="display:flex">
            Help
            <span class="material-icons">arrow_drop_down</span>
          </span>
        </button>-->
        <button class="example-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="fake"
                (click)="openHelpDashboardClient()">
          <span style="display:flex">Help </span>
        </button>
      </div>

      <ng-template #trips>
        <div class="example-menu" cdkMenu>

          <button class="example-menu-item"
                  (click)="clickRouteNew('/ground-stops-client/trip-quick-search-control-client','Trip Schedules')"
                  cdkMenuItem>
            Trip Schedules
          </button>
          <button class="example-menu-item" (click)="clickRouteNew('/trip-legs-client/trip-leglist-client','')"
                  cdkMenuItem>
            Daily View
          </button>
          <button class="example-menu-item" (click)="clickRoute('trip-creator-client-dialog','')" cdkMenuItem>
            Submit a
            Trip
          </button>
          <button class="example-menu-item" (click)="clickRoute('trip-summary-report','')" cdkMenuItem>
            Trip Summary
            Report
          </button>
        </div>
      </ng-template>

     <!-- <ng-template #messages>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" (click)="clickRouteNew('/messages-client/message-center-client','')"
                  cdkMenuItem>
            Messages
          </button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('email-client-dialog','')">
            New
            Message
          </button>
        </div>
      </ng-template>-->

    <!--  <ng-template #maintenance>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item"
                  (click)="clickRoute('/persons/manage-contact-details','')">
            Manage Crew, PAX & Trip Support Profiles
          </button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('/customers/customer-commlist','')">
            Company
            Profile
          </button>
          <button cdkMenuItem class="example-menu-item"
                  (click)="clickRoute('/aircraft/manage-aircraft-profile','')">
            Aircraft Profile
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRoute('/current-events/company-events-list','')">
            Manage Company Events
          </button>
        </div>
      </ng-template>-->

      <ng-template #admin>
        <div class="example-menu" cdkMenu>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRoute('user-accounts/register-user','Add new User')">
            Add new User
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRoute('user-accounts/reset-password-admin','')">
            Reset Password
          </button>
          <button class="example-menu-item" cdkMenuItem
                  (click)="clickRoute('user-accounts/user-permission-client','')">
            User Access & Permissions
          </button>
        </div>
      </ng-template>

      <!--
      <ng-template #help>
        <div class="example-menu" cdkMenu>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('help-guides','client')">
            Help
            Guides
          </button>-->
          <!-- <button cdkMenuItem class="example-menu-item" (click)="clickRoute('client-feedback','')">Submit Feedback</button> -->
         <!-- <button cdkMenuItem class="example-menu-item" (click)="clickRoute('feedback-dialog','')">
            Submit
            Feedback
          </button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('contact-us','')">Contact Us</button>
          <button cdkMenuItem class="example-menu-item" (click)="clickRoute('fee-schedule','')">FPI Fee Schedule</button>-->
       <!-- </div>
      </ng-template>-->
      <ng-template #fake>
        <div cdkMenu>

        </div>
      </ng-template>

    </div>
  </div>

  <div style="display: flex;">
    <!-- <div  *ngIf="userType=='i'" style="margin: 0.3em 1em 0.3em 0;"><button class="btn-warn" mat-button (click)="openCriticalAlertsComponent()"> 
        Critical Alerts</button>
    </div> -->
    <div  *ngIf="userType=='i'" style="margin: 0.3em 1em 0.3em 0;"><button class="btn-low" mat-button (click)="clickAddAdvisory()">+
        Advisory</button>
    </div>
    <div   style="margin: 0.3em 1em 0.3em 0;" (click)="openRecentTrips()">
      <button class="btn-low" mat-button >
        Recent Trips
      </button>
  </div>
    <div>
      <button class="btn-low" *ngIf="userType=='c'" (click)="clickRoute('feedback-dialog','')" mat-button
              style="background-color:grey; color: white; margin-left: 1em; height: 2em; color: red !important;   margin-right: 1.5em; margin-top: 0.5em; ">
        <i class="material-icons-outlined"
          style="font-size: large;  color: black; margin-right: 0.3em; cursor: pointer; ">campaign</i>Feedback
      </button>
    </div>

    <div *ngIf="utcText!=''" style="color: white; font-size: small; margin-right: 1.25em; margin-top: 0.35em;">
      <span style="font-size: large">{{utcText}}</span> Z
    </div>
  </div>
</div>
