<div style="display: flex;padding-left:1em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="margin-top:0.3125em; display: flex; justify-content: space-between; width: 100%;">
    <div style="display: flex;">
    <span class="material-icons-two-tone yellow" style="font-size: x-large; margin-right: 0.25em; margin-left: -0.5em;">local_fire_department</span>
    <h5> Missing Customs & Permits Tasks</h5>

    </div>
    <button mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
  </div>
</div>
<div  style="background-color:#f8f8f8;  height: 100%;">
  <div style="padding: 0.5em; background-color:white ; height: 36em;">
    <form *ngIf="critical_alerts_form" [formGroup]="critical_alerts_form">



      <div  >


        <!-- <div style="margin-right:1em; margin-left:1.5em; ">
          <div><label for="date_type" style="font-weight:bold">Filter by </label>  </div>
          <div style="margin-top:-0.25em; display: flex;">


            <div style="display: flex; margin-left:0em;">
              <div style="margin-right:0.25em">
                <input formControlName="date_type" type="radio" name="date_type" value="currentWeek"
                  (change)="dateTypeChange($Event)" />
              </div>
              <div style="margin-top:-0.2em;white-space: nowrap">Current Week</div>
            </div>


            <div style="display: flex; margin-left: 0.5em;">
              <div style="margin-right:0.25em">
                <input formControlName="date_type" type="radio" name="date_type" value="15"
                  (change)="dateTypeChange($Event)" />
              </div>
              <div style="margin-top:-0.2em;white-space: nowrap ">15 days out <output></output></div>
            </div>


            <div style="display: flex; margin-left: 0.5em;">
              <div style="margin-right:0.25em">
                <input formControlName="date_type" type="radio" name="date_type" value="30"
                  (change)="dateTypeChange($Event)" />
              </div>
              <div style="margin-top:-0.2em; white-space: nowrap">30 days out</div>
            </div>

            <div style="display: flex; margin-left: 0.5em;">
              <div style="margin-right:0.25em">
                <input formControlName="date_type" type="radio" name="date_type" value="90"
                  (change)="dateTypeChange($Event)" />
              </div>
              <div style="margin-top:-0.2em; white-space: nowrap">90 days out</div>
            </div>

    

            
          </div>
        </div> -->
        
        <div style="display: flex; margin-left: 0.5em; justify-content: end;">
          <div style="margin-left: 2em;"><app-spin style="max-width: 5em;" *ngIf="showSpin"></app-spin></div>
          <button class="btn-low" mat-button style="margin-left: 1em;" (click)="exportToExcel()">
            <mat-icon *ngIf="downloading">
              <svg class="black-spinner" viewBox="0 0 50 50">
                <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
              </svg>
            </mat-icon>
            Export To Excel
          </button> 
          <button class="btn-low" mat-button style="margin-left: 1em;" (click)="getData()">Refresh</button>

        </div>

        <div style="overflow-y: auto; height: 31em; scrollbar-color: #c1c1c1 white; margin-top: 0.5em;">
        
          <table   mat-table [dataSource]="recordList" style="width: 100%;">
            <tr>

              <ng-container matColumnDef="customer_name">
                <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: left !important; width: 27%;">
                  Client Account
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.customerName}}</td>
              </ng-container>

              <ng-container matColumnDef="trip_code">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 10%;">
                  Trip Code 
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important"   >{{element.tripCode}}</td>
              </ng-container>

              <ng-container matColumnDef="service">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 20%;">
                  Missing Service 
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.serviceTypeDescription}}</td>
              </ng-container>

              <ng-container matColumnDef="depIcao">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 10%;">
                  Departure ICAO 
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.departureIcao}}</td>
              </ng-container>

              <ng-container matColumnDef="depDate">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 14%;">
                  Departure Date UTC 
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.departureDateUTC}}</td>
              </ng-container>

              <ng-container matColumnDef="arrIcao">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 10%;">
                  Arrival ICAO
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.arrivalIcao}}</td>
              </ng-container>

              <ng-container matColumnDef="arrDate">
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 14%;">
                  Arrival Date UTC
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.arrivalDateUTC}}</td>
              </ng-container>

              <ng-container matColumnDef="open">
                <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 10%;">
                  Action
                </th>
                <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;">
                    <button class="btn-low" mat-button    >Open</button>

                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickOpenTrip($event, row);"></tr>
          </table>
        </div>

        <!-- <div *ngIf="errMsg!=''" style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;"><label style="color:black; font-size: small">{{errMsg}}</label></div> -->
        <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
            <app-success-message [successMsg]=""></app-success-message>
          </div> -->
      </div>

    </form>
  </div>
</div>
<div style="justify-content:flex-start; padding-top:0.5em; margin-right:1em; ">
  <button class="btn-low" mat-button style="margin-left: 1em;" (click)="clickClose()">Close</button>
</div>
