<div style="background-color:#f0f0f0;overflow-y:hidden " cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div>
      <div style="display: inline-flex;">
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Recent Trips - Last 8 Hours</h3>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button><i class="material-icons"
        style="font-size: x-large;" (click)="getData()">refresh </i></button>
      <button mat-icon-button mat-dialog-close><i class="material-icons"
          style="font-size: x-large;">close </i></button>
    </div> 
  </div>

  <div style="padding: 1em 1em 1em 1em; background-color: white;">
    <div style="overflow-y: auto; height: 33em; scrollbar-color: #c1c1c1 white">
      <app-spin style="width: 15em;" *ngIf="showSpin"></app-spin>
      <table *ngIf="!showSpin" mat-table [dataSource]="filteredRecordList" style="width: 100%;">
        <tr>
          <ng-container matColumnDef="customer_name">
            <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: left !important; width: 22%;">
              Client Account
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left !important" >{{element.customerName}}</td>
          </ng-container>
          <ng-container matColumnDef="trip_code">
            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width: 22%;">
              Trip Code & Tail Number
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left !important" >
              <!-- <div *ngFor="let t of element.tripList"> -->
                <div style="display:flex; height:1.75em">
                  <div style="width:10em; display: inline-flex">
                    <div style="text-align: left; width: 50%; margin-right: 1em;">{{element.tripCode}}</div>
                    <div style="text-align: left; width: 50%;">{{element.registration}}</div>
                  </div>
                </div>
              <!-- </div> -->
            </td>
          </ng-container>
          
          <ng-container matColumnDef="route">
            <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: left !important; width: 48%;">
                Route
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left !important" >{{element.route}}</td>
          </ng-container>

          <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 8%;">
              
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;" >
              <!-- <div *ngFor="let t of element.tripList"> -->
                <div style="display:flex; height:1.75em">
                  <div style=" display: flex">
                    <div style="margin-right:0.25em">
                      <a mat-button class="btn-high grid" style="align-self:center">
                        Open 
                      </a>
                    </div>
                  </div>
                </div>
              <!-- </div> -->
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="clickOpenTrip($event, row);"></tr>
      </table>
    </div>
  </div>
</div>
