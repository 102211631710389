<div class="d-inline-flex" style="margin-bottom:-1.5em;margin-top:-.5em">
  <label style="font-size:small;margin-top:1em;width:10em">Distance in NM: {{distanceFromAirport}}</label>
  <mat-slider style="margin-top:.25em" (mousedown)="$event.stopPropagation()" thumbLabel tickInterval="5" step="1" min="5" max="50" color="primary" [(ngModel)]="distanceFromAirport" [ngModelOptions]="{standalone: true}" (change)="getHotelData()"></mat-slider>
  <mat-checkbox style="transform:scale(.8);font-size:medium;margin-top:.65em" color="primary" [(ngModel)]="inCountry" [ngModelOptions]="{standalone: true}" (change)="getHotelData()">In Country</mat-checkbox>
  <div *ngIf="userType=='i'" class="form-inline mr-2">
    <label style="font-size:small;margin-right:.5em;margin-top:-.25em">Client Account</label>
    <select [(ngModel)]="customerID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="filterHotelList()" (mousedown)="$event.stopPropagation()">
      <option value="0" selected>-------</option>
      <option *ngFor="let c of customerList" [ngValue]="c.customerID">
        {{c.customerName}}
      </option>
    </select>
  </div>
  <div class="form-inline mr-2">
    <label style="font-size:small;margin-right:.5em;margin-top:-.25em">Hotel Chain</label>
    <select [(ngModel)]="hotelChainGUID" style="margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="filterHotelList()" (mousedown)="$event.stopPropagation()">
      <option value="" selected>-------</option>
      <option *ngFor="let h of hotelChainList" [ngValue]="h.hotelChainGUID">
        {{h.hotelChainName}}
      </option>
    </select>
  </div>
  <!--<mat-slide-toggle style="transform:scale(.8);font-size:medium;margin-top:.65em" color="primary" [(ngModel)]="showDriveTime" [ngModelOptions]="{standalone: true}" >Show Drive Time/Distance</mat-slide-toggle>-->
</div>
<div style="user-select: text; max-height: 37em; overflow-y: auto" (mousedown)="$event.stopPropagation()">
  <div style="width:100%" class="d-flex justify-content-end">
    Total Count {{hotelList?.length}}
  </div>
  <div class="d-inline-flex" style="width:100%">
    <div style="width:65%;max-height:34em;overflow-y:auto">
      <table mat-table [dataSource]="hotelList" class="example-container">
        <tr>
          <ng-container matColumnDef="hotelName">
            <th mat-header-cell *matHeaderCellDef style="width:25%; border-bottom:none">
              <div style="margin-top:-0.25em;">Hotel</div>
            </th>
            <td mat-cell *matCellDef="let element" matTooltipClass="tooltipLineBreak" matTooltip="{{element.hotelChainName}}">{{element.hotelName}}</td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
              <div style="margin-top:-0.25em;">Address</div>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap; text-align:left !important">{{element.streetAddress}}</td>
          </ng-container>
          <ng-container matColumnDef="near_airport">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Nearest<span style="display:block">Airport</span></div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.icao}}</td>
          </ng-container>
          <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Point Distance (mi)</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.distanceFromAirport | number: '1.0-0' }}</td>
          </ng-container>
          <ng-container matColumnDef="drive_time">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Drive Distance (mi)</div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.driveDistanceFromAirport | number: '1.0-0'}}</td>
          </ng-container>
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Drive Time</div>
            </th>
            <td mat-cell *matCellDef="let element">{{convertMinutes(element.timeFromAirport)}}</td>
          </ng-container>
          <ng-container matColumnDef="ron">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Past Year<span style="display:block">RON</span></div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.ron}}</td>
          </ng-container>
          <ng-container matColumnDef="crew_count">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:-0.25em;">Past Year<span style="display:block">Crew Count</span></div>
            </th>
            <td mat-cell *matCellDef="let element">{{element.crewCount}}</td>
          </ng-container>
          <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
              <div style="margin-top:-0.25em;">Details</div>
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:left !important"><button mat-button class="btn btn-low grid" (click)="viewDetails(element)">View</button></td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnHotel; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnHotel" (click)="clickRow(row)"
            [class.row-is-clicked]="clickedRows.has(row)"></tr>
      </table>
    </div>
    <ng-template #hotelDetailsTemplate>
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-elevation-z8 p-1 border" style="background-color:#f8f8f8;">
        <div class="p-2">
          <div style="display:flex;justify-content:space-between">
            <h6>{{hotelDetails.hotelName}}</h6>
            <mat-icon mat-dialog-close>close</mat-icon>
          </div>
          <div style="font-size:small;">
            <div style="background-color:white" class="p-2 mt-2">
              <div style="font-weight:bold">
                Location Details
              </div>
              <div *ngIf="hotelDetails.latitude!=null" class="d-flex" style="user-select:text" (mousedown)="$event.stopPropagation()">
                <div class="mr-2">
                  Latitude: {{hotelDetails.latitudeDMS}}
                </div>
                <div class="mr-2">
                  Longitude: {{hotelDetails.longitudeDMS}}
                </div>
                <div>
                  Match Type: {{hotelDetails.matchType}}
                </div>
              </div>
            </div>
            <div *ngIf="hotelDetails.commAddress1!='' && hotelDetails.commAddress12!='' && hotelDetails.commAddress3!=''" style="background-color:white" class="p-2 mt-2">
              <div style="font-weight:bold">
                Comms
              </div>
              <div *ngIf="hotelDetails.commAddress1!=''" class="d-flex" style="user-select:text" (mousedown)="$event.stopPropagation()">
                <div class="mr-1">
                  {{hotelDetails.commAddressType1}}:
                </div>
                <div class="mr-1">
                  {{hotelDetails.commAddress1}}
                </div>
                <div *ngIf="hotelExtension!=''">
                  {{hotelDetails.extension1}}
                </div>

              </div>
              <div *ngIf="hotelDetails.commAddress2!=''" class="d-flex" style="user-select:text" (mousedown)="$event.stopPropagation()">
                <div class="mr-1">
                  {{hotelDetails.commAddressType2}}:
                </div>
                <div class="mr-1">
                  {{hotelDetails.commAddress2}}
                </div>
                <div *ngIf="hotelExtension!=''">
                  {{hotelDetails.extension2}}
                </div>

              </div>
              <div *ngIf="hotelDetails.commAddress3!=''" class="d-flex" style="user-select:text" (mousedown)="$event.stopPropagation()">
                <div class="mr-1">
                  {{hotelDetails.commAddressType3}}:
                </div>
                <div class="mr-1">
                  {{hotelDetails.commAddress3}}
                </div>
                <div *ngIf="hotelExtension!=''">
                  {{hotelDetails.extension3}}
                </div>

              </div>
            </div>
            <div *ngIf="hotelDetails.remarks!=''" style="background-color:white" class="p-2 mt-2">
              <div style="font-weight:bold" >
                Remarks
              </div>
              <div style="white-space:pre-wrap;user-select:text" (mousedown)="$event.stopPropagation()">
                {{hotelDetails.remarks}}
              </div>
              </div>
            </div>


          </div>
      </div>
    </ng-template>
    <div style="width:35%; margin-left:1em">
      <div *ngIf="hotelListWithLocation && hotelListWithLocation.length>0" style="width:100%;">
        <div style="position: relative;" (mousedown)="$event.stopPropagation()">
          <div style="width:100%;height:36em;border-radius:.25em" id="{{'hotel-map'+icao}}"></div>
          <div style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
            fullscreen
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
