<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="divCenter">
  <div>
    <div style="margin-left:2.5em">
      <img src="../../assets/images/FlightProLogoWhiteBG.png" style="cursor: pointer" (click)="openCompanyMarketPage()"/>
    </div>
    <div style="border-style: solid; border-width: 0.0625em; border-radius: 0.3125em; border-color: lightgray;padding-top: 1em;padding-bottom:1em; margin-top:2em;">
      <form *ngIf="passwordForm" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div style="text-align:center">
          <h2>Flight Pro Connect {{websiteEnvironment}}</h2>
        </div>
        <div class="d-flex justify-content-center">
          <div style="margin-top:2em;">
            <h5 class="mt-1">Sign In</h5>
            <div class="d-block" style="height:1.5em"></div>
            <div class="form-group d-block">
              <div style="display:flex; justify-content:space-between;width:18.75em">
                <label for="username" style="margin-bottom:0em">{{username}}</label><a class="ml-2" routerLink="" (click)="clickChange()">Change</a>
              </div>
            </div>
            <div class="form-group d-block">
              <div style="display:flex; justify-content:space-between;width:18.75em">
                <label for="password">Password</label><a routerLink="" (click)="clickForgotPwd()">Forgot your password?</a>
              </div>
              <div>
                <input type="password" formControlName="password" #passwordRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" style="width:18.75em; background-color:#eaf0fd" maxlength="50" />
        <!--<div class="row" style="margin-left:0em">
        <input [type]="hide ? 'password' : 'text'" formControlName="password" #passwordRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" style="width:18.75em" maxlength="50" />
        <i class="material-icons" matSuffix (click)="hide = !hide" style="cursor: pointer;">{{hide ? 'visibility' : 'visibility_off'}}</i>
        </div>-->
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
              </div>
            </div>
          </div>
          <div *ngIf="errMsg!=''" style="white-space: normal; color: red; width: 18.75em; font-size:small ">{{errMsg}}</div>
          <div class="form-group" style="display:block; margin-top:20px">
            <button [disabled]="loading" class="btn btn-primary" style="width:18.75em">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Sign-In
            </button>
          </div>
          <div class="form-group" style="text-align:left; margin-bottom:0; margin-top: 2em">
            <input type="checkbox" formControlName="keepMeSignedIn" [checked]="keepMeSignedIn" (change)="keepMeSignedIn = !keepMeSignedIn" /><label for="saveUsername">&nbsp;Keep me signed in</label>&nbsp;&nbsp;<a [routerLink]="" (click)="showDetails=!showDetails" style="text-decoration:none; font-size:small">Details</a>
          </div>
          <div *ngIf="showDetails" style="display: inline-block; font-size: small; margin-top:0em; margin-left:0em; padding: 0em; vertical-align: middle; border: 0.0625em lightgrey solid;">
            <table style="width:18.75em">
              <tr style="background-color:lightgrey">
                <td style="font-size:small; font-weight:500">
                <td style="font-size:small; font-weight:500; background-color:lightgrey">"Keep Me Signed In" Checkbox</td>
                <td style="text-align:right;font-size:medium; font-weight:500;"><a [routerLink]="" (click)="clickCloseDetails()" style="text-decoration:none;color: black">x</a>&nbsp;</td>
              </tr>
              <tr>
                <td style="font-size:small;margin-left:10px; padding:0.625em" colspan="2">
                  Choosing "Keep me signed in" reduces the number of times you're asked to Sign-In on the device.<br />
                  To keep your account secure, use this option only on your personal devices.
                </td>
              </tr>
            </table>
          </div>

        </div>
        </div>
      </form>
    </div>
    <div style="display:flex;margin-top:1em;">
      <div style="font-size:small;"><a routerLink="" (click)="openTerm()" style="text-decoration: none">Terms of Use</a></div>
      <div style="font-size:small; margin-left: 6em;"><a routerLink="" (click)="openPrivacy()" style="text-decoration: none">Privacy Notice</a></div><div style="font-size:small; margin-left:10em;"><a routerLink="" (click)="openContactUs()" style="text-decoration: none">Help</a></div>
    </div>
  </div>
</div>
