<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="p-1 border" style="background-color:white;">
  <div>
    <div style="display:flex;justify-content:space-between">
      <h6>Closest Airport {{hotelName!=''? 'to':''}} {{hotelName}}</h6>
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="d-inline-flex">
      <div style="max-height:34em;overflow-y:auto">
        <table mat-table [dataSource]="airportList" class="example-container">
          <tr>
            <ng-container matColumnDef="distance">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Point Distance (NM)
              </th>
              <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
            </ng-container>
            <ng-container matColumnDef="drive_distance">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Drive Distance (mi)
              </th>
              <td mat-cell *matCellDef="let element">{{element.driveDistance}}
            </ng-container>
            <ng-container matColumnDef="drive_time">
              <th mat-header-cell *matHeaderCellDef width="20%">
                Drive Time (min)
              </th>
              <td mat-cell *matCellDef="let element">{{element.driveTime}}</td>
            </ng-container>
            <ng-container matColumnDef="airport">
              <th mat-header-cell *matHeaderCellDef width="40%">
                Airport
              </th>
              <td mat-cell style="text-align:left!important" *matCellDef="let element" (click)="openAirport(element)"><a href="javascript:void(0)">{{element.icao}} {{element.iata}} {{element.airportName}}, {{element.airportLocationWithCountryNameAbbr}}</a></td>
            </ng-container>

          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
      
    </div>
  </div>
</div>
