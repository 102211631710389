
export class AzureMapRouting {
  type: string;
  features: AzureMapRouteFeatures[];
}

export class AzureMapRouteFeatures {
  type: string;
  geometry: AzureMapGeometry;
  properties: AzureMapProperties;
}

export class AzureMapGeometry {
  type: string;
  coordinates: number[][];
}

export class AzureMapProperties {
  pointType: string;
}


export class AzureMapDistanceResults {
  type: string;
  geometry: AzureMapGeometry;
  properties: AzureMapDistanceProperties;
}

export class AzureMapDistanceProperties {
  summart: AzureMapDistanceRouteSummary;
  matrix: AzureMapDistanceMatrix[];
    }

export class AzureMapDistanceMatrix {
  statusCode: number;
  originIndex: number;
  destinationIndex: number;
  durationTrafficInSeconds: number;
  durationInSeconds: number;
  distanceInMeters: number;
    }

export class AzureMapDistanceResponse {

  routeSummary: AzureMapDistanceRouteSummary
    }
export class AzureMapDistanceRouteSummary {
  totalCount: number;
  successfulCount: number;
}


