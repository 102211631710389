import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthenticateService } from '../services/authenticate.service'
import { ResponseModel } from '../models/response.model';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service';
import { IdentityRequestModel } from '../models/identity-request.model';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PersonalInfoComponent } from './personal-info.component';
import { ChangePasswordComponent } from './change-password.component';
import { PasswordRecoveryComponent } from './password-recovery.component';
import { MultiFactorAuthComponent } from './multi-factor-auth.component';
import { SignatureEditorComponent } from './signature-editor.component';
import { PushNotificationComponent } from './push-notification.component';

@Component({
  selector: 'app-update-my-account',
  templateUrl: './update-my-account.component.html',
  styleUrls: ['./update-my-account.component.css']
})

export class UpdateMyAccountComponent implements OnInit {
  username: string;
  userType: string;
  customerName: string;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService, private readonly dialog: MatDialog) {

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this.username = localStorage.getItem('un');
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.customerName = localStorage.getItem('cn').toLowerCase();
  }

  openPersonalInfo() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(PersonalInfoComponent, config);
  }

  openChangePassword() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(ChangePasswordComponent, config);
  }

  openPasswordRecovery() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(PasswordRecoveryComponent, config);
  }

  openMFA() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(MultiFactorAuthComponent, config);
  }

  openPushNotifications() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    config.width = "35em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(PushNotificationComponent, config);
  }

  openSignature() {
    const config = new MatDialogConfig();
    config.maxWidth = "92em";
    //config.height = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = true;
    config.disableClose = true;
    config.position = { left: "100px"};
    this.dialog.open(SignatureEditorComponent, config);
  }
}
