import { v4 as uuidv4 } from 'uuid';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { AirportModel } from '../models/airport.model';
import * as atlas from 'azure-maps-control';
import { AirportService } from '../services/airport.service';
import { VendorService } from '../services/vendor.service';
import { ResponseModel } from '../models/response.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { HoursObjModel } from '../models/hoursObj.model';
import { VendorModel } from '../models/vendor.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MapPointsModel } from '../models/map-points.model';
import { AirportMapComponent } from '../airports/airport-map.component';
import { CommonService } from '../services/common.service';
import { CountryModel } from '../models/country.model';
import { CountryContentDocModel } from '../models/country-content-doc.model';
import { CountryDetailsComponent } from '../country/country-details.component';
import { TemplateService } from '../services/template.service';
import { AirportDocumentModel } from '../models/airport-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { VendorDocumentModel } from '../models/vendor-document.model';
import { AdvisoryModel } from '../models/advisory.model';
import { AdvisoryService } from '../services/advisory.service';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { ViewAdvisoryComponent } from '../advisory/view-advisory.component';
import { HotelModel } from '../models/hotel.model';
import { AircraftModel } from '../models/aircraft.model';
import { AircraftService } from '../services/aircraft.service';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { HotelEditDialogComponent } from '../hotels/hotel-edit-dialog.component';
import { TripPlannerModel } from '../models/trip-planner.model';
import { DocumentModel } from '../models/document.model';
import { VendorProfileSetupComponent } from '../vendors/vendor-profile-setup.component';
import { VendorProfileListComponent } from '../vendors/vendor-profile-list.component';
import { AirportDetailsEditComponent } from '../airports/airport-details-edit.component';
import { CountryDocumentModel } from '../models/country-document.model';
import { CountryService } from '../services/country.service';
import { VendorNotesModel } from '../models/vendor-notes.model';
import { HotellistComponent } from '../hotels/hotellist.component';
import { CustomerModel } from '../models/customer.model';
import { AzureMapDistanceResults, AzureMapGeometry, AzureMapProperties, AzureMapRouteFeatures, AzureMapRouting } from '../models/azure-map-routing';
import { offset } from '@popperjs/core';
import { WeatherService } from '../services/weather.service';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { AirportNotesModel } from '../models/airport-notes.model';

@Component({
  selector: 'app-airport-brief',
  templateUrl: './airport-brief.component.html',
  styleUrls: ['./airport-brief.component.css']
})
export class AirportBriefComponent implements OnInit {
  userType: string;
  selectedAirport: AirportModel;
  airportID: number
  icao: string;
  latitude: number;
  longitude: number;
  customerGUID: string;
  customerID: number=0;
  farTypeID: number;
  farType: string;
  route: string;
  selectedTab: string = "handling";
  points: any = [];
  point: any;
  map: atlas.Map;
  nearbyMap: atlas.Map;
  tripMap: atlas.Map;
  handlerGUID: string;
  ssnGUID: string;
  vendorDetails: VendorModel;
  relatedVendorDetails: VendorModel;
  vendorHours: VendorHoursModel[];
  hoursData: HoursObjModel[];
  briefType: string = "client";
  nearbyAirports: AirportModel[];
  errMsg: string = "";
  latLongs: MapPointsModel[] = [];
  radius: number = 50;
  distance: number = 150;
  isPreferred: boolean = true;
  countryDetails: CountryModel;
  countryNotes: string;
  landingPermitNotes: string = "";
  landingRequiredDocuments: CountryContentDocModel[] = [];
  landingPermitValidity: string = "";
  landingPermitLeadTime: string = "";
  landingPermitDueDate: string = "";
  showValidity: boolean = false;
  showLeadTime: boolean = false;
  showDueDate: boolean = false;
  showHandler: boolean = true;
  countryPermitNotRequired: boolean = true;
  displayedColumn: string[] = ['preferred', 'airport', 'distance', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit'];
  advisoryColumn: string[] = ['rating', 'dates', 'area', 'type', 'issue', 'hasDocument', 'hasLink', 'action'];
  homebaseColumn: string[] = ['customer', 'registration'];
  tripActivityColumn: string[] = ['customer', 'trip_code', 'registration', 'departureDateTime', 'leg', 'arrivalDateTime', 'action'];
  callFrom: string;
  uniqueId = uuidv4();
  briefMapId = uuidv4();
  nearbyMapId = uuidv4();
  isMinimized: boolean = false;
  customerName: string = "";
  isLoadingVendor: boolean = false;
  isLoadingAirport: boolean = false;
  isLoadingNearby: boolean = false;
  isLoadingDoc: boolean = false;
  isLoadingAdvisory: boolean = false;
  isLoadingAdditional: boolean = false;
  isLoadingHotels: boolean = false;
  advisoryList: AdvisoryModel[] = [];
  transportationVendors: VendorModel[] = [];
  cateringVendors: VendorModel[] = [];
  homebaseAircraft: AircraftModel[];
  isLoadingHomebase: boolean = false;
  isLoadingWeather: boolean = false;
  isLoadingTrips: boolean = false;
  tripActivity: TripLegModel[];
  filteredtripActivity: TripLegModel[];
  timeSpan: string = "current";
  tripLatLongs: MapPointsModel[] = [];
  tripsFiltered: boolean = false;
  grantAccessVendorProfiles: boolean = false;
  latestWeatherReport: string = "Welcome To Weather Report";
  grantAccessAirport: boolean = false;
  countryDocumentList: CountryDocumentModel[];
  vendorOtherNotes: VendorNotesModel[];
  customsVendors: VendorModel[];
  inCountry: boolean = true;
  distanceFromAirport: number = 50;
  customerHotelList: HotelModel[];
  customerList: CustomerModel[];
  showDriveTime: boolean = false;
  hotelDataSource: any;
  clickedRows = new Set<HotelModel>();
  clickedShape: any;
  hotelPoints: any[];
  refreshHotelData: boolean;
  vID: string;
  toScrollInto;
  folderType: string = "3h-7d";
  no_of_days: number = 1;
  weatherDataList: any[] = [];
  selectedStatusIndex: number | null = 1;
  selectedGeoStatusIndex: number | null = null;
  displayedColumns: string[] = ['validDayDate','validTime', 'tmp', 'dpt', 'dir', 'spd', 'gust', 'pop', 'wxPhraseShort', 'mslp', 'ceiling', 'vis', 'tcc', 'qpf', 'sn', 'qpfIce', 'cProb_TSRA', 'cProb_FZRA',];
  statuses = [
    { label: '3h-7d', displayName: '12z/00z', selected: true },
    { label: '3h-7d-alt', displayName: '18z/06z', selected: false },
    { label: '6h-16d', displayName: '12z/00z', selected: false } 
    
  ];
  geo_statuses = [
    { label: 1, displayName: '1 Day', selected: false },
    { label: 2, displayName: '2 Day', selected: false }, 
    { label: 3, displayName: '3 Day', selected: false },
    { label: 10, displayName: '10 Day', selected: false },
    { label: 15, displayName: '15 Day', selected: false }

  ];

  constructor(private _dialogRef: MatDialogRef<AirportBriefComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialog: MatDialog, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _airportService: AirportService, private readonly _vendorService: VendorService, private sanitizer: DomSanitizer, private readonly _templateService: TemplateService,
    private readonly _advisoryService: AdvisoryService, private readonly _aircraftService: AircraftService, private readonly _tripLegService: TripLegService, private readonly _countryService:CountryService, private readonly _weatherService:WeatherService) {
    this.selectedAirport = _data.selectedAirport,
      this.airportID = _data.airportID;
    this.icao = _data.icao;
    this.customerGUID = _data.customerGUID;
    this.farTypeID = _data.farTypeID;
    this.farType = _data.farType;
    this.route = _data.route;
    this.handlerGUID = _data.handlerGUID;
    this.ssnGUID = _data.ssnGUID;
    this.callFrom = _data.callFrom;
    if (_data.customerName != null && _data.customerName != undefined)
      this.customerName = _data.customerName;
    this.vID = _data.vID;
  }
  ngOnInit(): void {
    this.grantAccessVendorProfiles = false;
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessVendorProfiles = true;
    }
    this.grantAccessAirport = false;
    if (localStorage.getItem('up.airportDoc') == 'true') {
      this.grantAccessAirport = true;
    }
    this.userType = localStorage.getItem('ut').toLowerCase();
    if (this.userType == "c" && this.customerName == "")
      this.customerName = localStorage.getItem('cn');
    this._authService.updateAccessTime();
    this.getData();
  }
  scrollIntoView(el: HTMLElement) {
    el.scrollIntoView(true);
    setTimeout(() => {
   
      this.toScrollInto = -1;

    },2000)
  }

  getData() {
    this.getAirportData();
    if (this.callFrom == 'planner' || this.callFrom == 'alternate') {
      if (this.handlerGUID != null && this.handlerGUID != undefined) {
        this.getVendorData();
        this.getAdvisoryData();
      }
      else {
        if (this.handlerGUID == null || this.handlerGUID == undefined) {
          this.selectedTab = 'notes';
          this.showHandler = false;
          this.changeTab();
        }
      }
    }
    if (this.callFrom == 'trip' || this.callFrom == 'slots') {
      if (this.handlerGUID != null && this.handlerGUID != undefined && this.handlerGUID != "") {
        this.getVendorData();
        if (this.callFrom == 'slots')
          this.selectedTab = 'notes';      
      }
      else {
        this.selectedTab = 'notes';
        this.showHandler = false;
        this.changeTab();
      }
    }
    this.getNearbyAirports();
    if (this.userType == 'i') {
      this.getCountryNotes();
      this.getAdditionalVendorData();
      this.getHomebase();
      this.getTripActivity();
      this.getLatestWeatherFile();
    }
    if (this.callFrom == 'customs')
      this.selectedTab = 'customs';
  }

  getAirportData() {
    this.isLoadingAirport = true;
    this._airportService.getAirportDataByAirportID<ResponseModel<AirportModel>>(this.airportID, this.customerGUID, this.farTypeID, false).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.isLoadingAirport = false;
          this.selectedAirport = response.model;
          this.selectedAirport.ciqDocumentList = this.selectedAirport.documentList?.filter(x => !x.isExpired && x.documentTypeGUIDList.toLowerCase() == '49b9a70f-074d-4567-af09-63843cd2cd7c');
          this.selectedAirport.documentList = this.selectedAirport.documentList?.filter(x => !x.isExpired && x.documentTypeGUIDList.toLowerCase() != '49b9a70f-074d-4567-af09-63843cd2cd7c');
          this.selectedAirport.restrictionNotes = this.selectedAirport.airportNotes.filter(x => x.noteType.toLowerCase().indexOf("restriction") > -1);
          if (this.callFrom == 'alternate') {
            this.handlerGUID = this.selectedAirport.handler?.vendorGUID;
            this.ssnGUID = this.selectedAirport.ssn?.vendorGUID;
            if (this.handlerGUID != null && this.handlerGUID != undefined && this.handlerGUID != '')
              this.getVendorData();
          }
          if (this.callFrom == "trip") {
            this.getAdvisoryData();
          }
          if (this.handlerGUID == null || this.handlerGUID == undefined || this.handlerGUID == '') {
            this.showHandler = false;
          }
          else {
            this.showHandler = true;
            this.selectedTab = this.callFrom=="slots"? "notes" : this.callFrom=="customs"? "customs" : "handling";
            this.changeTab();
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          this.isLoadingAirport = false;
        }
        //if (this.handlerGUID != null && this.handlerGUID != undefined && this.handlerGUID != '') { }
        //  this.createVendorMap();
        //this.createMap();
        //  this.showSpin = false;
      }
    });
  }

  getVendorData() {
    this.isLoadingVendor = true;
    if (this.handlerGUID != null && this.handlerGUID != undefined) {
      this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(this.handlerGUID).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.vendorDetails = response.model;
            if (this.vendorDetails != null) {
              this.vendorDetails.vendorDocuments = this.vendorDetails.vendorDocuments?.filter(x => !x.isExpired)
              if (this.vendorDetails.vendorNotes!=null)
              this.vendorOtherNotes = this.vendorDetails.vendorNotes.filter(x => x.includeInBrief == false);
              this.vendorHours = this.vendorDetails.vendorHours;
              if (this.vendorHours != null) {
                this.vendorHours.forEach(h => {
                  this.initHoursData();
                  if (!h.is24Hours) {
                    let sameHours = true;
                    let openTime = h.sun_Open;
                    let closeTime = h.sun_Close;
                    let utcLocal = "Local";
                    if (h.isUTC)
                      utcLocal = "UTC";
                    closeTime += ' ' + utcLocal;
                    this.hoursData.forEach(v => {
                      let utcLocal = "Local";
                      if (h.isUTC)
                        utcLocal = "UTC";
                      switch (v.dayOfWeek) {
                        case "Sunday":
                          if (h.is24Hours || h.sunClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sun_Open;
                          v.closeTime = h.sun_Close + ' ' + utcLocal;
                          v.isClosed = h.sunClosed;
                          v.hasHours = h.hasSun;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Monday":
                          if (h.is24Hours || h.monClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.mon_Open;
                          v.closeTime = h.mon_Close + ' ' + utcLocal;
                          v.isClosed = h.monClosed;
                          v.hasHours = h.hasMon;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Tuesday":
                          if (h.is24Hours || h.tueClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.tue_Open;
                          v.closeTime = h.tue_Close + ' ' + utcLocal;
                          v.isClosed = h.tueClosed;
                          v.hasHours = h.hasTue;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Wednesday":
                          if (h.is24Hours || h.wedClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.wed_Open;
                          v.closeTime = h.wed_Close + ' ' + utcLocal;
                          v.isClosed = h.wedClosed;
                          v.hasHours = h.hasWed;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Thursday":
                          if (h.is24Hours || h.thuClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.thu_Open;
                          v.closeTime = h.thu_Close + ' ' + utcLocal;
                          v.isClosed = h.thuClosed;
                          v.hasHours = h.hasThu;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Friday":
                          if (h.is24Hours || h.friClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.fri_Open;
                          v.closeTime = h.fri_Close + ' ' + utcLocal;
                          v.isClosed = h.friClosed;
                          v.hasHours = h.hasFri;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Saturday":
                          if (h.is24Hours || h.satClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sat_Open;
                          v.closeTime = h.sat_Close + ' ' + utcLocal;
                          v.isClosed = h.satClosed;
                          v.hasHours = h.hasSat;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                      }
                      if (openTime != v.openTime || closeTime != v.closeTime)
                        sameHours = false;
                    });
                    h.sameHours = sameHours;
                    h.openTime = openTime;
                    h.closeTime = closeTime;
                    h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
                  }
                });
              }

              if (this.vendorDetails.relatedVendors != null && this.ssnGUID != "" && this.ssnGUID != null && this.ssnGUID != undefined) {
                this.relatedVendorDetails = this.vendorDetails.relatedVendors.find(x => x.vendorGUID == this.ssnGUID);
                this.relatedVendorDetails.vendorDocuments = this.relatedVendorDetails.vendorDocuments?.filter(x => !x.isExpired)


              }
              this.createVendorMap();
              this.isLoadingVendor = false;
            }
          }
          else {
            if (response.code == "401")
              this._authService.signOut();
            this.isLoadingVendor = false;
          }
        }

      }

      );
    }
  }

  initHoursData() {
    this.hoursData = [
      { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
      { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
      { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
      { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
      { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
      { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
      { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    ]
  }

  getNearbyAirports() {
    this.isLoadingNearby = true;
    let request = new AirportModel();
    request.airportID = this.airportID;
    request.countryId = 0;
    request.distance = this.distance;
    request.isPreferred = this.isPreferred
    request.farTypeID = this.farTypeID;
    this.latLongs = [];
    this._airportService.getNearbyAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.nearbyAirports = response.model;
          let latLong = new MapPointsModel();
          latLong.latitude = this.selectedAirport.latitude;
          latLong.longitude = this.selectedAirport.longitude;
          latLong.title = this.selectedAirport.icao;
          latLong.isMainPoint = true;
          this.latLongs.push(latLong);
          this.nearbyAirports.forEach(x => {
            let latLong = new MapPointsModel();
            latLong.latitude = x.latitude;
            latLong.longitude = x.longitude;
            latLong.title = x.icao;
            latLong.isMainPoint = false;
            latLong.airport = x;
            latLong.isPreferred = x.isPreferredAlternate;
            this.latLongs.push(latLong);
          });
          this.isLoadingNearby = false;
          if (this.selectedTab == "alternates")
            this.createNearbyMap();
        }
        else {
          if (response.code == "401")
            this._authService.logout();
          else
            this.errMsg = "An error occurred.";
          this.isLoadingNearby = false;
        }
      }
    });
  }

  openInNew() {
    this._dialogRef.close();
    window.open('trip-planner/airport-brief', "_blank");

  }

  goToHandling() {
    this.selectedTab = 'handling';
    this.changeTab();
  }
  changeTab() {
    if (this.selectedTab == 'handling')
      this.createVendorMap();
    if (this.selectedTab == 'details')
      this.createMap();
    if (this.selectedTab == 'alternates' && this.nearbyAirports)
      this.createNearbyMap();
    if (this.selectedTab == 'trips' && this.tripActivity)
      this.createTripMap();
  
  }
  createMap() {
    if (this.map && this.map != undefined && !this.map.isDisposed)
      this.map.dispose();
    let mapName;
    if (this.selectedTab == 'handling')
      mapName = 'brief-map' + this.airportID.toString();
    else
      mapName = 'details-map' + this.airportID.toString();
    this.map = new atlas.Map(mapName, {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "satellite_road_labels",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.StyleControl({ mapStyles: ['satellite_road_labels', 'road_shaded_relief'] })
      ], {
        position: atlas.ControlPosition.TopRight
      });



      dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.selectedAirport.longitude, self.selectedAirport.latitude]), {
        title: self.selectedAirport.icao,
        color: '#cc0000'
      }));
      self.points.push([self.selectedAirport.longitude, self.selectedAirport.latitude]);

      var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'color'],
        strokeWidth: 6,
        color: "white",
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.map.layers.add(bubbleLayer);

      //Add a layer for rendering point data.
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: [0, -1],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      })
      self.map.layers.add(symbolLayer);


      self.map.setCamera({
        center: [self.selectedAirport.longitude, self.selectedAirport.latitude],
        zoom: 14
      });


    });

  }

  createVendorMap() {
    if (this.map)
      this.map.dispose();
    this.map = new atlas.Map('map' + this.handlerGUID, {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "satellite_road_labels",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.StyleControl({ mapStyles: ['satellite_road_labels', 'road_shaded_relief'] })
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.vendorDetails.vendorMapProfiles?.length == 0) {

        dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.selectedAirport.longitude, self.selectedAirport.latitude]), {
          title: self.selectedAirport.icao,
          color: '#cc0000'
        }));
        self.points.push([self.selectedAirport.longitude, self.selectedAirport.latitude]);

        var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: 4,
          strokeColor: ['get', 'color'],
          strokeWidth: 6,
          color: "white",
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add(bubbleLayer);

        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            //Hide the icon image.
            image: "none"
          },

          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1],
            allowOverlap: true,
            size: 16,
            haloColor: '#ffffff',
            haloWidth: 2,
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        })
        self.map.layers.add(symbolLayer);
      }
      else {
        self.vendorDetails.vendorMapProfiles?.forEach((v, i) => {
          var marker = new atlas.HtmlMarker({
            text: v.markerText,
            color: v.iconColor,
            position: [v.longitude, v.latitude],
            htmlContent: atlas.getImageTemplate('marker-square-rounded'),// '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
            //popup: new atlas.Popup({
            //  content: '<div style="padding:10px">' + v.mapLocationDescription + '</div>',
            //  pixelOffset: [0, -30],
            //  closeButton:false
            //})         
          });

          self.map.markers.add(marker);
          self.map.events.add('click', marker, () => {
            marker.togglePopup();
          });
          self.points.push([v.longitude, v.latitude]);
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            name: v.mapLocationDescription,
            note: v.notes
          }));
        });


        var popupTemplate = "<div style='padding: 10px'>{note}</div>";

        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            //Hide the icon image.
            image: "none"
          },

          textOptions: {
            textField: ['get', 'name'],
            offset: [1, -1.5],
            justify: 'auto',
            allowOverlap: false,
            size: 14,
            haloColor: '#ffffff',
            haloWidth: 2,
            variableAnchor: ['left', 'right', 'top', 'bottom']
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        })

        self.map.layers.add(symbolLayer);
        var popup = new atlas.Popup({
          pixelOffset: [0, -30],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();
              if (properties.note != null && properties.note != "") {
                //If the shape is a point feature, show the popup at the points coordinate.
                if (e.shapes[0].getType() === 'Point') {
                  coordinate = e.shapes[0].getCoordinates();
                  content = popupTemplate.replace(/{note}/g, properties.note);
                  offset = [0, -18];
                }
              }
            } else {
              properties = e.shapes[0].properties;
              if (properties.note != null && properties.note != "") {
                //If the shape is a point feature, show the popup at the points coordinate.
                if (e.shapes[0].type === 'Point') {
                  coordinate = e.shapes[0].geometry.coordinates;
                  content = popupTemplate.replace(/{note}/g, properties.note);
                  offset = [0, -18];
                }
              }
            }

            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);

          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });
      }

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      let ctr = [self.selectedAirport.longitude, self.selectedAirport.latitude];
      if (self.vendorDetails?.vendorMapProfiles?.length <= 1) {
        ctr = [self.selectedAirport.longitude, self.selectedAirport.latitude];
        if (self.vendorDetails?.vendorMapProfiles?.length == 1)
          ctr = [self.vendorDetails.vendorMapProfiles[0].longitude, self.vendorDetails.vendorMapProfiles[0].latitude];
        self.map.setCamera({
          center: ctr,
          zoom: 15
        });
      }
      else {
        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

    });

  }

  createNearbyMap() {
    const container = document.getElementById('nearby-map' + this.airportID.toString())
    if (container) {

      if (this.nearbyMap && !this.nearbyMap.isDisposed)
        this.nearbyMap.dispose();
      this.nearbyMap = new atlas.Map('nearby-map' + this.airportID.toString(), {

        language: 'en-US',
        view: 'Auto',
        showBuildingModels: true,
        showLogo: false,
        style: "road_shaded_relief",
        showFeedbackLink: false,
        //zoom: 11,
        //center: new atlas.data.Position(6.7735, 51.2277),
        //Add your Azure Maps subscription client ID to the map2 SDK.
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });

      var self = this;
      self.points = [];
      self.nearbyMap.events.add('ready', function () {
        var dataSource = new atlas.source.DataSource();
        self.nearbyMap.sources.add(dataSource);
        self.nearbyMap.controls.add([
          new atlas.control.ZoomControl()
        ], {
          position: atlas.ControlPosition.TopRight
        });

        //Create a circle
        if (self.distance <= 100)
          self.radius = 25;
        if (self.distance > 100 && self.distance <= 200)
          self.radius = 50;
        if (self.distance > 200 && self.distance <= 500)
          self.radius = 100;
        if (self.distance > 500)
          self.radius = 150;
        if (self.latLongs.length > 1) {
          let maxRadius = self.distance * 1852;
          let increment = self.radius * 926;
          for (let i = increment; i <= maxRadius; i = i + increment) {
            var data = new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
              title: i / 926 / 2 + " NM",
              display: "radius",
              subType: "Circle",
              radius: i,
              color: "transparent",
              strokecolor: "transparent",
              offset: [0, -.5]
            });
            let r = i / 926 / 2;
            if (r < 25 || r <= self.nearbyAirports[self.nearbyAirports.length - 1].distanceFromAirport + (self.radius / 2))
              dataSource.add(data);

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent',
              filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 1.5
            });

            var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: 'none'
              },
              textOptions: {
                textField: ['get', 'label']
              },
              filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
            })
            /*Create and add a polygon layer to render the polygon to the map*/
            self.nearbyMap.layers.add([polygonLayer, lineLayer, symbolLayer]);
          }


        }
        var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
        dataSource.add(labels);
        self.latLongs.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.title,
            strokecolor: v.isMainPoint ? '#cc0000' : v.isPreferred ? '#ffd800' : 'DodgerBlue',
            color: "white",
            display: "dot",
            offset: [0, -1],
            airport: v.airport
          }));
          self.points.push([v.longitude, v.latitude]);


        });
        var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: 4,
          strokeColor: ['get', 'strokecolor'],
          strokeWidth: 6,
          color: ['get', 'color'],
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.nearbyMap.layers.add(bubbleLayer);


        self.nearbyMap.events.add('mouseover', bubbleLayer, function (e) {
          var properties;
          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();
            if (properties.airport != null && properties.airport != undefined)
              self.nearbyMap.getCanvasContainer().style.cursor = 'pointer';
          }
        });

        self.nearbyMap.events.add('mouseleave', bubbleLayer, function () {
          self.nearbyMap.getCanvasContainer().style.cursor = 'grab';
        });

        self.nearbyMap.events.add('click', bubbleLayer, function (e) {
          var properties;
          var airport;
          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              airport = properties.airport;
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              airport = properties.airport;
            }
          }
          if (airport != null && airport != undefined)
            self.openAirportBrief(airport);
        });



        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            //Hide the icon image.
            image: "none"
          },

          textOptions: {
            textField: ['get', 'title'],
            offset: ['get', 'offset'],
            allowOverlap: true,
            size: 16
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        })
        self.nearbyMap.layers.add(symbolLayer);


        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if (self.latLongs.length == 1) {

          self.nearbyMap.setCamera({
            center: [self.latLongs[0].longitude, self.latLongs[0].latitude],
            zoom: 12
          });
        }
        else {
          self.nearbyMap.setCamera({
            bounds: bbox,
            padding: 40
          });
        }

      });
    }
  }

  getFlag(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${image}`
    );
  }


  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95vw";
    dialogConfig.height = "95vh";
    let mapData: any;
    let latLongs: MapPointsModel[] = [];
    let x = new MapPointsModel();
    switch (this.selectedTab) {
      case "alternates":
        latLongs = this.latLongs;
        break;
      case "details":
        x.latitude = this.selectedAirport.latitude;
        x.longitude = this.selectedAirport.longitude;
        x.title = this.selectedAirport.icao;
        x.isMainPoint = true;
        latLongs.push(x);
        break;
      case "trips":
        mapData = this.tripLatLongs;
        break;
      case "handling":
        x.latitude = this.selectedAirport.latitude;
        x.longitude = this.selectedAirport.longitude;
        x.title = this.selectedAirport.icao;
        x.isMainPoint = true;
        latLongs.push(x);
        mapData = this.vendorDetails?.vendorMapProfiles;
        break;
    }

    dialogConfig.data = {
      latLongs: latLongs,
      distance: this.distance,
      radius: this.radius,
      maxDistance: this.nearbyAirports[this.nearbyAirports.length - 1]?.distanceFromAirport,
      from: this.selectedTab,
      mapData: mapData
    };

    this._dialog.open(AirportMapComponent, dialogConfig);

  }

  getCountryNotes() {
    this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.selectedAirport.countryId).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.countryDetails = response.model;
          this.countryNotes = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == 15 && x.countryContentCategory.toLowerCase() == 'notes')[0]?.countryContentDescription.trim();
          this.countryPermitNotRequired = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == (this.farTypeID == 1 ? 'landing - private' : 'landing - charter'))[0]?.countryContentDescription.trim().toLowerCase() == 'no';
          this.landingPermitNotes = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'notes')[0]?.countryContentDescription.trim();
          this.landingRequiredDocuments = this.countryDetails.countryContentSegmentDocs?.filter(x => x.countryContentSegmentId == (this.farTypeID == 1 ? 3 : 4));
          this.landingPermitValidity = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'validity time')[0]?.countryContentDescription.trim();
          this.landingPermitLeadTime = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'lead time')[0]?.countryContentDescription.trim();
          this.landingPermitDueDate = this.countryDetails.countryContent?.filter(x => x.countryContentSegmentID == (this.farTypeID == 1 ? 3 : 4) && x.countryContentCategory.toLowerCase() == 'due date')[0]?.countryContentDescription.trim();
          if (this.landingPermitValidity != '' && this.landingPermitValidity != null && this.landingPermitValidity.toLowerCase().indexOf("see note") == -1)
            this.showValidity = true;
          if (this.landingPermitLeadTime != '' && this.landingPermitLeadTime != null && this.landingPermitLeadTime.toLowerCase().indexOf("see note") == -1)
            this.showLeadTime = true;
          if (this.landingPermitDueDate != '' && this.landingPermitDueDate != null && this.landingPermitDueDate.toLowerCase().indexOf("see note") == -1)
            this.showDueDate = true;
          this.countryDocumentList = this.countryDetails.documentList?.filter(x => x.documentTypeGUIDList.toLowerCase() == '49b9a70f-074d-4567-af09-63843cd2cd7c' || x.documentTypeGUIDList.toLowerCase() == 'c7a387d6-4f4c-4640-adaf-6a4002602440' || x.documentTypeGUIDList.toLowerCase() == '70729e22-0d66-4a17-99be-3884aaa80d88');
        }

      }
    });
  }


  getCountryDocuments(item: CountryDocumentModel) {
    this.errMsg = "";
    this._authService.updateAccessTime();


    this._countryService.getCountryDocument<ResponseModel<CountryDocumentModel[]>>(item).subscribe(response => {
      if (response != null && response.code == "200") {

        // let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        // let file = new Blob([byteArray], { type: item.mimeType });
        // saveAs(file, item.documentName);

        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: item.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = item.documentName;
        link.click();
        // this.downloading1 = false;

      }


    });


  }

  openAirportBrief(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: airport.airportID,
      icao: airport.icao,
      customerGUID: this.customerGUID,
      farTypeID: this.farTypeID,
      farType: this.farType,
      route: '',
      handlerGUID: airport.handler?.vendorGUID,
      ssnGUID: airport.ssn?.vendorGUID,
      callFrom: 'alternate'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  openCountryDetails() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.position = { right: "100px", top: "40px" };

    dialogConfig.data = { countryID: this.selectedAirport.countryId };

    this._dialog.open(CountryDetailsComponent, dialogConfig);

  }

  minimizeDialog() {
    this.isMinimized = true;

    if (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != undefined &&
      document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') != null)
      (document.getElementById(this.uniqueId).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.transform = 'none'
  }

  maximizeDialog() {
    this.isMinimized = false;
  }

  createAirportBrief() {
    let request = new TripPlannerModel();
    request.airportID = this.airportID;
    request.customerGUID = this.customerGUID;
    request.farTypeID = this.farTypeID;
    request.vendorGUID = this.handlerGUID;
    request.ssnVendorID = this.relatedVendorDetails?.vendorID;
    this.isLoadingDoc = true;
    this._airportService.createAirportBrief<ResponseModel<DocumentModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          if (response.model != null) {
            let docObj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
            let file = new Blob([byteArray], { type: docObj.mimeType });
            var fileURL = URL.createObjectURL(file);
            var link = document.createElement('a');
            link.href = fileURL;
            link.download = docObj.documentName;
            link.click();
            if (docObj.bFile == null || docObj.bFile == "")
              this.errMsg = "No document returned.";
          }
          else {
            this.errMsg = "No document returned.";
          }
        }
        this.isLoadingDoc = false;
      }

    });
  }

  downloadReport() {
    this.isLoadingDoc = true;
    this._templateService.generateTripPlannerBrief('pdf', 'client', this.vendorDetails, this.relatedVendorDetails, this.vendorHours, this.customerName, this.farType, this.selectedAirport, this.advisoryList).subscribe(r => {
      if (r.remarks != "") {
        this.errMsg = r.remarks;
      }
      this.isLoadingDoc = false;

    });
  }

  getAirportDocuments(item: AirportDocumentModel) {
    this.errMsg = "";

    this._authService.updateAccessTime();


    this._airportService.getAirportDocument<ResponseModel<AirportDocumentModel[]>>(item).subscribe(response => {
      if (response != null && response.code == "200") {

        // let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        // let file = new Blob([byteArray], { type: item.mimeType });
        // saveAs(file, item.documentName);

        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: item.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = fileURL;
        link.download = item.documentName;
        link.click();
        // this.downloading1 = false;

      }

    });


  }

  getVendorDocuments(doc: VendorDocumentModel) {
    let vendorDocumentID = doc.vendorDocumentGUID;
    this._vendorService.getVendorDocumentByvdId<ResponseModel<VendorDocumentModel>>(vendorDocumentID).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          var link = document.createElement('a');
          link.href = fileURL;
          link.download = doc.documentName;
          link.click();

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    });

  }

  getAdvisoryData() {
    this.isLoadingAdvisory = true;
    let request = new AdvisoryModel();
    request.countryID = this.selectedAirport.countryId;
    request.airportID = this.airportID;
    request.stateProvinceID = this.selectedAirport.stateProvinceID;
    request.vendorGUID = this.handlerGUID;
    request.ssnVendorGUID = this.ssnGUID;

    // request.advisoryId = this.currentAdvisoryId;
    this._advisoryService.getAdvisoriesByAirportAndVendors<ResponseModel<AdvisoryModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.advisoryList = response.model;


      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.isLoadingAdvisory = false;
    });
  }

  newMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: "", isDraft: false, v: this._authService.getCurrentTimeNumber()
    };

    if (this.userType == 'i')
      this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    else
      this._dialog.open(EmailClientDialogComponent, dialogConfig);

  }

  viewAdvisory(item: AdvisoryModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container"
    dialogConfig.width = "60em"

    dialogConfig.data = {
      advisoryID: item.advisoryId
    };

    this._dialog.open(ViewAdvisoryComponent, dialogConfig);
  }

  getAdditionalVendorData() {
    this.isLoadingAdditional = true;
    this._vendorService.getVendorDetailsByAirportID<ResponseModel<VendorModel[]>>(this.airportID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.transportationVendors = response.model.filter(x => x.vendorRoleID == 9);
          this.cateringVendors = response.model.filter(x => x.vendorRoleID == 11);
          this.customsVendors = response.model.filter(x => x.vendorRoleID == 2);
          if (this.callFrom == 'customs') {
            this.toScrollInto = this.customsVendors.findIndex(x => x.vendorGUID == this.vID);

          }
        }
        else {
          if (response.code == "401")
            this._authService.logout;


        }
        this.isLoadingAdditional = false;
      }
      else
        this.isLoadingAdditional = false;
    })
  }


  getHomebase() {
    this.isLoadingHomebase = true;
    this._aircraftService.getCustomerHomebaseByAirportID<ResponseModel<AircraftModel[]>>(this.airportID).subscribe(response => {
      if (response != null && response.code == "200") {
        this.homebaseAircraft = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.isLoadingHomebase = false;
    });
  }

  getLatestWeatherFile() {
    this.isLoadingWeather = true;
    const formattedStationCode = `GFS_${this.icao}`;
   this._weatherService.getLatestWeatherFile<ResponseModel<string>>(formattedStationCode, this.folderType).subscribe(response => {
     if (response != null && response.code == "200") {
       this.latestWeatherReport = response.model;
     }
     else {
       if (response.code == "401") {
         //this.errMsg = response.message
         this._authService.signOut();
       }
 
     }
     this.isLoadingWeather = false;
   });
 }

 getWeatherByGeoCode() {
  this.isLoadingWeather = true;
  this.latitude = this.selectedAirport.latitude;
  this.longitude = this.selectedAirport.longitude;
 this._weatherService.getWeatherByGeoCode<ResponseModel<any[]>>(this.latitude, this.longitude, this.no_of_days).subscribe(response => {
   if (response != null && response.code == "200") {
     this.weatherDataList = response.model;
   }
   else {
     if (response.code == "401") {
       //this.errMsg = response.message
       this._authService.signOut();
     }

   }
   this.isLoadingWeather = false;
 });
}

 toggleStatus(index: number) { 

  this.selectedStatusIndex = index; 
  this.selectedGeoStatusIndex = null; 
  this.statuses.forEach((x, i) => x.selected = i === index);
  this.geo_statuses.forEach(x => x.selected = false);
  this.weatherDataList = []; 
  this.latestWeatherReport = "";
  this.folderType = this.statuses[index].label;
  this.getLatestWeatherFile();
}

geo_toggleStatus(index: number) { 
  this.selectedGeoStatusIndex = index; 
  this.selectedStatusIndex = null; 
  this.statuses.forEach(x => x.selected = false);
  this.geo_statuses.forEach((x, i) => x.selected = i === index);
  this.latestWeatherReport = ""; 
  this.weatherDataList = [];
  this.no_of_days = this.geo_statuses[index].label;
  this.getWeatherByGeoCode();
}

openLegend() {;
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let legendType: string = "weather";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
   
  }



  getTripActivity() {
    this.tripLatLongs = [];
    this.isLoadingTrips = true;
    this._tripLegService.getTripActivityByAirportIDTimeSpan<ResponseModel<TripLegModel[]>>(this.airportID, this.timeSpan).subscribe(response => {
      if (response != null && response.code == "200") {
        this.tripActivity = response.model;
        this.filteredtripActivity = response.model;
        this.tripActivity.forEach((x, index) => {
          let i1 = this.tripLatLongs.findIndex(i => i.latitude == x.departureLatitude && i.longitude == x.departureLongitude && i.nextLatitude == x.nextArrivalLatitude && i.nextLongitude == x.nextArrivalLongitude)
          let i2 = this.tripLatLongs.findIndex(i => i.latitude == x.nextArrivalLatitude && i.longitude == x.nextArrivalLongitude && i.nextLatitude == x.departureLatitude && i.nextLongitude == x.departureLongitude)
          if (i1 == -1 && i2 == -1) {
            let point = new MapPointsModel();
            point.latitude = x.departureLatitude;
            point.longitude = x.departureLongitude;
            point.nextLatitude = x.nextArrivalLatitude;
            point.nextLongitude = x.nextArrivalLongitude;
            point.icao = x.departureICAO;
            point.nextICAO = x.nextArrivalICAO;
            point.title = x.departureICAO != this.icao || index == 0 ? x.departureICAO : '';
            point.nextTitle = x.nextArrivalICAO != this.icao || index == 0 ? x.nextArrivalICAO : '';
            this.tripLatLongs.push(point);
          }
        });
        this.createTripMap();
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.isLoadingTrips = false;
    });
  }

  openTrip(trip: TripLegModel) {
    window.open('/ground-stops/trip-details-internal?tripId=' + trip.tripCodeGUID + '&aircraftId=' + trip.aircraftGUID + '&customerId=' + trip.customerGUID + '&pf=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }


  //editHotel(p: HotelModel) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.disableClose = false;
  //  //dialogConfig.width = "58em";//"1000px";
  //  //dialogConfig.height = "25em";//"500px";
  //  dialogConfig.disableClose = true;

  //  let hotelGUID = "";
  //  if (p != null) {
  //    hotelGUID = p.hotelGUID;
  //  }
  //  dialogConfig.data = { countryId: "", hotelChainId: "", hotelGUID: hotelGUID };
  //  const dialogRef = this._dialog.open(HotelEditDialogComponent, dialogConfig);
  //  dialogRef.afterClosed().subscribe(result => {
  //    if (result) {
  //      if (result.refresh) {
  //        this.getHotelData();
  //      }

  //    }
  //  });

  //}

  createTripMap() {
    const container = document.getElementById('trip-map' + this.airportID.toString())
    if (container) {

      if (this.tripMap && !this.tripMap.isDisposed)
        this.tripMap.dispose();
      this.tripMap = new atlas.Map('trip-map' + this.airportID.toString(), {

        language: 'en-US',
        view: 'Auto',
        showBuildingModels: true,
        showLogo: false,
        style: "road_shaded_relief",
        showFeedbackLink: false,
        //zoom: 11,
        //center: new atlas.data.Position(6.7735, 51.2277),
        //Add your Azure Maps subscription client ID to the map2 SDK.
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });

      var self = this;
      var tempID = "";
      self.points = [];
      self.tripMap.events.add('ready', function () {
        var dataSource = new atlas.source.DataSource();
        self.tripMap.sources.add(dataSource);
        self.tripMap.controls.add([
          new atlas.control.ZoomControl()
        ], {
          position: atlas.ControlPosition.TopRight
        });


        if (self.tripLatLongs.length > 0) {
          self.tripLatLongs.forEach(v => {
            var startPosition = [v.longitude, v.latitude];
            var endPosition = [v.nextLongitude, v.nextLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.title,
              icao1: v.icao,
              icao2: v.nextICAO,
              leg: v.icao + '-' + v.nextICAO,
              clicked: false,
              color: 'black'
            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextTitle,
              icao1: v.icao,
              icao2: v.nextICAO,
              leg: v.icao + '-' + v.nextICAO,
              clicked: false,
              color: 'black'
            });
            self.points.push(startPosition);
            self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.Feature(new atlas.data.LineString(path), {
              title: v.title,
              icao1: v.icao,
              icao2: v.nextICAO,
              leg: v.icao + '-' + v.nextICAO,
              clicked: false,
              color: 'black'
            });

            dataSource.add([poly, startPoint, endPoint]);

          });
          var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
            iconOptions: {
              image: 'pin-round-darkblue',
              cluster: false,
              optional: true,
              allowOverlap: true,
              size: .75
            },
            textOptions: {
              textField: ['get', 'title'],
              offset: [0, -1.5],
              cluster: false,
              allowOverlap: true,
              size: 12
            },
            filter: ['any', ['==', ['geometry-type'], 'Point']]
          });
          var lineLayer = new atlas.layer.LineLayer(dataSource, null, {
            strokeWidth: 2,
            strokeColor: ['get', 'color']
          });
          //self.tripMap.sources.add(dataSource);
          self.tripMap.layers.add([lineLayer, symbolLayer]);

          self.tripMap.events.add('mouseover', symbolLayer, function () {
            self.tripMap.getCanvasContainer().style.cursor = 'pointer';
          })
          self.tripMap.events.add('mouseleave', symbolLayer, function () {
            self.tripMap.getCanvasContainer().style.cursor = 'grab';
          })

          self.tripMap.events.add('mouseover', lineLayer, function () {
            self.tripMap.getCanvasContainer().style.cursor = 'pointer';
          })
          self.tripMap.events.add('mouseleave', lineLayer, function () {
            self.tripMap.getCanvasContainer().style.cursor = 'grab';
          })
          self.tripMap.events.add('click', function (e) {

            if (e.shapes && e.shapes.length > 0) {
              var properties;
              var shape;
              if (tempID != "") {
                if (e.shapes[0] instanceof atlas.Shape) {

                  if (tempID != <string>e.shapes[0].getId()) {
                    properties = dataSource.getShapeById(tempID).getProperties();
                    dataSource.getShapeById(tempID).setProperties(({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] }));
                    shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao2"], properties["icao2"] + '-' + properties["icao1"]);
                    if (shape) {
                      shape.setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                    }
                  }
                }
              }

              if (e.shapes[0] instanceof atlas.Shape) {
                properties = e.shapes[0].getProperties();
                if (properties["clicked"] == false) {
                  tempID = <string>e.shapes[0].getId();

                  self.filteredtripActivity = self.tripActivity.filter(x => ((x.departureICAO == properties["icao1"] && x.nextArrivalICAO == properties["icao2"]) || (x.departureICAO == properties["icao2"] && x.nextArrivalICAO == properties["icao1"]))).slice();
                  e.shapes[0].setProperties({ title: properties["title"], clicked: true, color: 'blue', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                  shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao2"], properties["icao2"] + '-' + properties["icao1"]);
                  if (shape) {
                    shape.setProperties({ title: properties["title"], clicked: true, color: 'blue', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                  }
                }
                else {
                  e.shapes[0].setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                  shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao2"], properties["icao2"] + '-' + properties["icao1"]);
                  if (shape) {
                    shape.setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                  }
                  self.filteredtripActivity = self.tripActivity.slice();
                }
                shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao12"], properties["icao2"] + '-' + properties["icao1"]);
                if (shape) {
                  shape.setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                }

              }
              else {
                self.filteredtripActivity = self.tripActivity.slice();
                if (tempID != "") {
                  properties = dataSource.getShapeById(tempID).getProperties();
                  dataSource.getShapeById(tempID).setProperties(({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] }));
                  shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao2"], properties["icao2"] + '-' + properties["icao1"]);
                  if (shape) {
                    shape.setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                  }
                }
              }

            }
            else {
              self.filteredtripActivity = self.tripActivity.slice();
              if (tempID != "") {
                properties = dataSource.getShapeById(tempID).getProperties();
                dataSource.getShapeById(tempID).setProperties(({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] }));
                shape = getShapeByProperty(dataSource, 'leg', properties["icao1"] + '-' + properties["icao2"], properties["icao2"] + '-' + properties["icao1"]);
                if (shape) {
                  shape.setProperties({ title: properties["title"], clicked: false, color: 'black', icao1: properties["icao1"], icao2: properties["icao2"], leg: properties["leg"] });
                }
              }
            }
          });

        }


        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.tripMap.setCamera({
          bounds: bbox,
          padding: 40
        });
      });
    }
  }

  editVendorProfile() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.height = "50em";

    dialogConfig.data = {
      icao: this.icao, pageFrom: 'airportBrief'
    };

    this._dialog.open(VendorProfileListComponent, dialogConfig);
  }

  
  editAirport() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //  dialogConfig.width = "100%";
    dialogConfig.maxHeight = "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { airportID: this.airportID };
    const dialogRef = this._dialog.open(AirportDetailsEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAirportData();
      }

    });
  }

  editHotels() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //  dialogConfig.width = "100%";
    dialogConfig.maxHeight = "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { icao: this.icao };
    const dialogRef = this._dialog.open(HotellistComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshHotelData = !this.refreshHotelData;
      }

    });
  }


}

function calculatePolygonLabels(shapes, labelPropertyName) {
  var labels = [];

  for (var i = 0; i < shapes.length; i++) {

    var circlePolygon = shapes[i].circlePolygon;

    // Get the coordinates of the polygon edge
    if (circlePolygon != undefined) {
      var edgeCoordinates = circlePolygon.geometry.coordinates[0][0];

      var prop = {};
      prop[labelPropertyName] = shapes[i].circlePolygon.properties[labelPropertyName];
      prop["display"] = shapes[i].circlePolygon.properties["display"];
      prop["color"] = shapes[i].circlePolygon.properties["color"];
      prop["strokecolor"] = shapes[i].circlePolygon.properties["strokecolor"];
      prop["offset"] = shapes[i].circlePolygon.properties["offset"];
      labels.push(new atlas.data.Feature(new atlas.data.Point(edgeCoordinates), prop));

    }
  }

  return labels;
}

function getShapeByProperty(dataSource, propertyName, value1, value2) {
  const shapes = dataSource.getShapes();
  return shapes.find(shape => shape.getProperties()[propertyName] === value1 || shape.getProperties()[propertyName] === value2);
}
