import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UserModel } from "../models/user.model";
import { IdentityService } from "../services/identity.service";
import { ResponseModel } from "../models/response.model";
import { AuthenticateService } from "../services/authenticate.service";
import { IdentityRequestModel } from "../models/identity-request.model";
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UtilityFunctions } from "../common-utility/utility.functions";

@Component({
  selector: 'multi-factor-auth',
  templateUrl: './multi-factor-auth.component.html',
  styleUrls: ['./password-recovery.component.css']
})

export class MultiFactorAuthComponent implements OnInit {
  mfa_form: UntypedFormGroup;
  mfaEnabled: boolean = false;
  phoneNumber: string = '';
  email: string = '';
  errMsg: string = '';
  successMsg: string;
  showSuccessMsg: boolean;
  hideOPwd: boolean = true;
  showOPwdEye: boolean = false;
  currentPassword: string;
  passwordValid: boolean = false;
  userType: string;
  internalEmailEndsWithList: string[];
  pin: string;
  submitted: boolean = false;
  user: UserModel;
  isUpdated: boolean = false;

  constructor(private readonly _formBuilder: UntypedFormBuilder,
    private readonly _identityService: IdentityService,
    private readonly _authService: AuthenticateService,
    private readonly _dialogRef: MatDialogRef<MultiFactorAuthComponent>) {

  }

  ngOnInit() {
    
    this.initControls();
    this.loadDefaultInfo();
  }

  initControls() {
    this.mfa_form = this._formBuilder.group({
      password: [null, Validators.required],

    });
  }

  loadDefaultInfo() {
    var request = new IdentityRequestModel();
    this._identityService.getUser<ResponseModel<UserModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.user = response.model;
        this.mfaEnabled = this.user.isMFAActive;
        if (this.user.encryptedPIN == null || this.user.encryptedPIN == "")
          this.pin = Math.floor(100000 + Math.random() * 900000).toString();
        else {
          this._identityService.decryptPIN<ResponseModel<string>>(this.user).subscribe(response => {
            this.pin = response.model;
          });
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
      }
      this.initControls();
    });


  }

  get f() { return this.mfa_form.controls; }

  onKeyup(event, controlflag: number) {
    this.errMsg = "";
    if (event.target.value != "") {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = true;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
    else {
      switch (controlflag) {
        case 1:
          this.showOPwdEye = false;
          this.currentPassword = event.target.value;
          break;
        case 2:
          this.phoneNumber = event.target.value;
          break;
        case 3:
          this.email = event.target.value;
          break;
      }
    }
  }

 
  onSave() {
    this.submitted = true;
    if (this.mfa_form.invalid)
      return;
    this.user.isActive = true;
    this.user.password = this.currentPassword;
    this.user.username = localStorage.getItem('un');
    this.user.isUsingOnlyContactInfo = true;
    this.user.isMFAActive = this.mfaEnabled;
    if (this.mfaEnabled == false)
      this.pin = null;
    this.user.pin = this.pin;
    this._identityService.validatePassword<ResponseModel<boolean>>(this.user).subscribe(response => {
      this.passwordValid = response.model;

      if(!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
      else {
        this._identityService.updateUser<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            this.showSuccessMsg = true;
            this.successMsg = "Personal info successfully updated."
            setTimeout(() => {this._dialogRef.close()}, 2000);
          }
          else {
            this.errMsg = "Error: " + response.message;
          }
        })
      }
    })
  }

  updateMFA() {
    this.isUpdated = true;
  }
  validatePassword() {
    this.submitted = true;
    if (this.currentPassword == null || this.currentPassword == "")
      return;
    let request = new UserModel();
    request.password = this.currentPassword;
    request.username = localStorage.getItem('un');

    this._identityService.validatePassword<ResponseModel<boolean>>(request).subscribe(response => {
      this.passwordValid = response.model;

      if (!this.passwordValid) {
        this.errMsg = "Current password is incorrect.";
        return;
      }
      this.submitted = false;
    })
  }
}
