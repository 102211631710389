import { Component, Inject, OnInit, Optional } from "@angular/core";
import { CountryModel } from "../models/country.model";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { CommonService } from "../services/common.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlobalSettingsDashboardComponent } from "../common-utility/global-settings-dashboard.component";
import { AuthenticateService } from "../services/authenticate.service";
import { AirportService } from "../services/airport.service";
import { ResponseModel } from "../models/response.model";
import { ActivatedRoute } from "@angular/router";
import { AirportDayLightModel } from "../models/airport-daylight-saving.model";
import { StateProvinceModel } from "../models/state-province.model";
import { AirportDayLightSavingEditComponent } from "./airport-daylightsaving-edit.component";
import { finalize } from "rxjs/operators";
import { AirportModel } from "../models/airport.model";
import { IcaoModel } from "../models/icao.model";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-airport-daylight-saving',
  templateUrl: './airport-daylight-saving.component.html',
  styleUrls: ['./airport-daylight-saving.component.css']
})

export class AirportDayLightSavingComponent implements OnInit {
  airport_day_light_saving_form: UntypedFormGroup;
  isDialog: boolean = true;
  totalRecords: number = 0;
  userType: string;
  username: string;
  errMsg: string;
  msg: string;
  loading: boolean = false;
  showSpin: boolean = true;
  isActive: boolean = true;
  airportSearchKey: string = "";
  countryList: CountryModel[] = [];
  airportList: IcaoModel[] = [];
  selectedIcao: string = "";
  selectedCountry: string = "";
  selectedStateProvince: string = "";
  selectedFilter: string = "";
  selectedRowIndex: number = -1;
  recordList: any[] = [];
  fullRecordList: any[] = [];
  filteredRecordList: any[] = [];
  displayedColumn: string[] = ['countryName', 'stateProvinceName', 'icao', 'startDate', 'endDate', 'option'];
  stateProvinceList: any;
  currentYear: number = new Date().getFullYear();

  statuses = [
    { label: 'active', displayName: 'Active DLS', selected: true },
    { label: 'expired', displayName: 'Expired DLS', selected: false },
    { label: 'disabled', displayName: 'Disabled Records', selected: false },
  ];
 


  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AirportDayLightSavingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly dialog: MatDialog,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService,
    private readonly _dialog: MatDialog,
    private _route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.selectedFilter = 'active'
    this.getAllData().subscribe(responses => {
        if (responses[0]?.code == "200" && responses[0]?.message == "") {
          this.countryList = responses[0].model;
        }
        if (responses[1]?.code == "200" && responses[1]?.message == "") {
          this.recordList = responses[1]?.model;
          this.filteredRecordList = [...this.recordList];
          this.totalRecords = this.filteredRecordList.length;
        }
      });

    this.initControls();
  }



  initControls() {
    this.airport_day_light_saving_form = this._formBuilder.group({
      country: [this.selectedCountry],
      airport_search: [this.airportSearchKey],
      state: [this.selectedStateProvince],
      icao: [this.selectedIcao],
    });
  }
  

  changeCountry(event: any) {
    this.f.airport_search.setValue('');
    this.selectedCountry = event.target.value;
    this.stateProvinceList = [];
      this.selectedStateProvince = "";
      this.airportList = [];
      this.selectedIcao = "";
      this.airport_day_light_saving_form.controls['state'].setValue('');
      this.airport_day_light_saving_form.controls['icao'].setValue('');
    if (!this.selectedCountry) {
      this.applyFilters();
      return;
    }
    const selectedCountry = this.countryList.find(country => country.countryName === this.selectedCountry);
    if (selectedCountry) {
      const countryGUID = selectedCountry.countryGUID;
  
      this._commonService.getStateProvinceList<ResponseModel<StateProvinceModel[]>>(countryGUID).subscribe(response => {
          if (response && response.model) {
            this.stateProvinceList = response.model;
          } else {
            this.stateProvinceList = [];
          }
        });
    } else {
      this.stateProvinceList = [];
    }
    this.refreshData();
  }

  changeState(event: any) {
    this.selectedStateProvince = event.target.value;
    this.airportList = [];
    this.selectedIcao = "";
    this.airport_day_light_saving_form.controls['icao'].setValue('');
    if (!this.selectedStateProvince) {
      this.applyFilters();
      return;
    }
    const selectedState = this.stateProvinceList.find(state => state.stateProvinceName === this.selectedStateProvince);
    
    if (selectedState) {
      const stateProvinceID = selectedState.stateProvinceID;
  
      this._airportService.getAirportListByStateProvinceID<ResponseModel<IcaoModel[]>>(stateProvinceID).subscribe(response => {
          if (response && response.model) {
            this.airportList = response.model;
          } else {
            this.airportList = [];
          }
        });
    } else {
      this.airportList = [];
    }
    this.applyFilters();
  }

  changeIcao(event: any){
    this.selectedIcao = event.target.value;
    this.applyFilters();
  }

  getAllData(): Observable<any[]> {
    this.showSpin = true;
    let getCountryList = this._commonService.getCountryList();
    let getAirportDayLightList = this._airportService.getAirportDayLightList<ResponseModel<AirportDayLightModel[]>>(this.selectedCountry,this.selectedStateProvince,this.airportSearchKey, this.isActive);
    
    return forkJoin([getCountryList, getAirportDayLightList]).pipe(finalize(() => {
      this.showSpin = false;
      this.applyFilters();
      this.formatDatesForDisplay();
  }));
    
  }

  get f() { return this.airport_day_light_saving_form.controls; }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  toggleStatus(index: number) {
    this.statuses.forEach((status, i) => status.selected = (i === index));
    this.selectedFilter = this.statuses[index].label;

    if (this.selectedFilter === 'active' || this.selectedFilter === 'expired') {
      this.isActive = true;  
    } else if (this.selectedFilter === 'disabled') {
      this.isActive = false; 
    }
  
    this.refreshData();
  }

  applyFilters(event?: any) {
    this.showSpin = true;
    let searchValue = event?.target?.value?.toLowerCase() || this.airport_day_light_saving_form.controls['airport_search'].value.toLowerCase();
    let isSearchActive = !!searchValue;
    let isDropdownSelected = !!this.selectedCountry || !!this.selectedStateProvince || !!this.selectedIcao;

    if (isSearchActive) {
      this.selectedCountry = "";
      this.selectedStateProvince = "";
      this.airport_day_light_saving_form.controls['country'].setValue('');
      this.airport_day_light_saving_form.controls['state'].setValue('');
      this.airport_day_light_saving_form.controls['icao'].setValue('');
    } else if (isDropdownSelected) {
      searchValue = ""; 
      this.airport_day_light_saving_form.controls['airport_search'].setValue('');
    }
  
    this.filteredRecordList = this.recordList.filter(record => {
      const matchesCountry = !this.selectedCountry || record.countryName === this.selectedCountry;
      const matchesState = !this.selectedStateProvince || record.stateProvinceName === this.selectedStateProvince;
      const matchesIcao = !this.selectedIcao || record.icao === this.selectedIcao;
  
      const recordStartYear = new Date(record.startDate).getFullYear();
      const recordEndYear = new Date(record.endDate).getFullYear();
      let matchesToggleFilter = false;
      if (this.selectedFilter === 'active') {
        matchesToggleFilter = (recordStartYear === this.currentYear || recordEndYear === this.currentYear);
      } else if (this.selectedFilter === 'expired') {
        matchesToggleFilter = recordEndYear < this.currentYear;
      } else if (this.selectedFilter === 'disabled') {
        matchesToggleFilter = true;
      }
     
  
      const matchesSearch = !searchValue || 
        record.icao?.toLowerCase().includes(searchValue) ||
        record.countryName?.toLowerCase().includes(searchValue) ||
        record.stateProvinceName?.toLowerCase().includes(searchValue) || 
        record.icao?.toLowerCase().includes(searchValue);
  
      this.showSpin = false;
      return matchesCountry && matchesState && matchesIcao && matchesToggleFilter && matchesSearch;
    });
  
    this.totalRecords = this.filteredRecordList.length;
  }

  formatDatesForDisplay() {
    this.recordList.forEach(record => {
      record.formattedStartDate = this.parseAndFormatDate(record.startDate);
      record.formattedEndDate = this.parseAndFormatDate(record.endDate);
    });
  }

  parseAndFormatDate(dateTime: string): string {
    if (!dateTime) return "";

    const { date, time } = this.parseDateForEdit(dateTime);
    if (!date) return "Invalid Date"; 

    const [year, month, day] = date.split('-');
    const formattedYear = year.slice(-2); 
    return `${month}/${day}/${formattedYear} ${time}`;
  }

  parseDateForEdit(dateString: string): { date: string, time: string } {
    if (!dateString) return { date: '', time: '' };
    const [datePart, timePart] = dateString.split('T');
    const time = timePart ? timePart.slice(0, 5) : '';

    return { date: datePart, time: time };
  }

  clickReset() {
    this.airport_day_light_saving_form.reset({
      country: '',
      state: '',
      airport_search: '',
      icao: '',
    });
  
    this.selectedCountry = "";
    this.selectedStateProvince = "";
    this.selectedIcao = "";
    this.airportSearchKey = "";
    this.stateProvinceList = [];
    this.airportList = [];
    this.toggleStatus(0);
  }

  refreshData() {
    this.getAllData().subscribe(responses => {
      if(this.selectedCountry == ""){
      if (responses[0]?.code == "200" && responses[0]?.message == "") {
        this.countryList = responses[0].model;
      }}
      if (responses[1]?.code == "200" && responses[1]?.message == "") {
        this.recordList = responses[1]?.model;
        this.filteredRecordList = [...this.recordList];
        this.applyFilters();
        this.totalRecords = this.filteredRecordList.length;
      }
    }); 
  }


    editDayLightSavingTime(element: any, index: number) {
      this.selectedRowIndex = index;
      const startDateParts = this.parseDateForEdit(element?.startDate);
      const endDateParts = this.parseDateForEdit(element?.endDate);
      const modifiedParts = this.parseAndFormatDate(element?.modifiedDate);
      const config = new MatDialogConfig();
      config.maxWidth = "42em";
      config.height = "24.5em";
      // config.maxHeight = "30.5em";
      config.width = "42em";
      config.restoreFocus = false;
      config.hasBackdrop = true;
      config.disableClose = false;
      config.position = { left: "6em" };
      config.data = { 
          daylightSavingTimeID: element?.daylightSavingTimeID ?? 0,
          dialogTitle: 'Add/Edit Daylight Saving Time', 
          countryID: element?.countryID ?? 0,
          countryName: element?.countryName ?? '',
          stateProvinceID: element?.stateProvinceID ?? 0,
          stateProvinceName: element?.stateProvinceName ?? '',
          icao: element?.icao ?? '', 
          airportID: element?.airportID ?? 0,
          startDate: startDateParts.date,  
          startTime: startDateParts.time,  
          endDate: endDateParts.date,
          endTime: endDateParts.time,
          modifiedBy: element?.modifiedBy ?? '',
          modifiedDate: modifiedParts,
          isActive:  element?.daylightSavingTimeID == null ? true: element.isActive
      };
  
      const dialogRef = this.dialog.open(AirportDayLightSavingEditComponent, config);  
  
      dialogRef.afterClosed().subscribe(response => {
          this.selectedRowIndex = -1;
          if (response?.refresh) {
              this.getAllData().subscribe(responses => {
                  if (responses[1]?.code == "200" && responses[1]?.message == "") {
                      this.recordList = responses[1]?.model;
                      this.filteredRecordList = [...this.recordList];
                      this.totalRecords = this.filteredRecordList.length;
                  }
              });
          }
      });
  }


  exportData() {
    if (!this.filteredRecordList || this.filteredRecordList.length === 0) {
      this.errMsg = "No data available for export.";
      setTimeout(() => { this.errMsg = ''; }, 3000);
      return;
    }
  
    let wb = new Workbook();
    let ws = wb.addWorksheet("Daylight Saving Data");
    let header = ['daylightSavingTimeID', 'Country', 'State / Province', 'ICAO', 'Start Date', 'Start Time', 'End Date', 'End Time', 'Status'];
    ws.addRow(header).font = { bold: true };

    this.filteredRecordList.forEach(record => {
      let status = "Unknown";  
      if (this.selectedFilter === 'active') {
        status = "Active";
      } else if (this.selectedFilter === 'expired') {
        status = "Expired";
      } else if (this.selectedFilter === 'disabled') {
        status = "Disabled";
      }
      ws.addRow([
        record.daylightSavingTimeID,
        record.countryName,
        record.stateProvinceName ?? '-',
        record.icao ?? '-',
        this.parseDateForEdit(record.startDate).date ?? '-',
        this.parseDateForEdit(record.startDate).time ?? '-',
        this.parseDateForEdit(record.endDate).date ?? '-',
        this.parseDateForEdit(record.endDate).time ?? '-',
        status 
      ]);
    });
  
    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Daylight_Saving_Data.xlsx");
    });
  }

  openGlobalSettingsDashboardComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "41.5em";//"1000px";
    config.width = "45em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GlobalSettingsDashboardComponent, config);  
  }
  }


