import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subject, Subscription, from } from 'rxjs';
import { MapPointsModel } from '../models/map-points.model';
import { AirportModel } from '../models/airport.model';

@Component({
  selector: 'app-airport-map',
  templateUrl: './airport-map.component.html',
  styleUrls: ['./airport-map.component.css']
})
/** azure-map2 component*/
export class AirportMapComponent {


  latLongs: MapPointsModel[];
  map2: atlas.Map;
  points: any = [];
  tileLayer: any;
  layerName: string = "";
  distance: number;
  airports: AirportModel[];
  from: string;
  radius: number;
  maxDistance: number;
  weatherTileUrl: string = 'https://{azMapsDomain}/map/tile?api-version=2.0&tilesetId={layerName}&zoom={z}&x={x}&y={y}';
  showRoute: boolean = false;
  mapData: any;
  constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private _dialogRef: MatDialogRef<AirportMapComponent>

  ) {
    this.latLongs = _data.latLongs;
    this.distance = _data.distance;
    this.airports = _data.airports;
    this.from = _data.from;
    this.radius = _data.radius;
    this.maxDistance = _data.maxDistance;
    this.showRoute = _data.showRoute;
    this.mapData = _data.mapData;
  }


  ngOnInit() {
    switch (this.from) {
      case "trips":
        this.createTripMap();
        break;
      case "handling":
        this.createVendorMap();
        break;
      case "hotels":
        this.createHotelMap();
        break;
      default:
        this.createMap();
    }

  }

  //Create an instance of the map2 control and set some options.
  createMap() {
    this.map2 = new atlas.Map('map2', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: this.from == "details" || this.from == "handling" ? "satellite_road_labels" : "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.StyleControl({ mapStyles: ['satellite_road_labels', 'road_shaded_relief'] })
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.from == "details" || self.from == "alternates") {
        if (self.distance <= 100)
          self.radius = 25;
        if (self.distance > 100 && self.distance <= 200)
          self.radius = 50;
        if (self.distance > 200 && self.distance <= 500)
          self.radius = 100;
        if (self.distance > 500)
          self.radius = 150;
        if (self.latLongs.length > 1) {
          let maxRadius = self.distance * 1852;
          let increment = self.radius * 926;
          for (let i = increment; i <= maxRadius; i = i + increment) {
            var data = new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
              title: i / 926 / 2 + " NM",
              display: "radius",
              subType: "Circle",
              radius: i,
              color: "transparent",
              strokecolor: "transparent",
              offset: [0, -.5]
            });
            let r = i / 926 / 2;
            if (r < 25 || r <= self.maxDistance + (self.radius / 2))
              dataSource.add(data);

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent',
              filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 1.5
            });

            var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: 'none'
              },
              textOptions: {
                textField: ['get', 'label']
              },
              filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
            })
            /*Create and add a polygon layer to render the polygon to the map*/
            self.map2.layers.add([polygonLayer, lineLayer, symbolLayer]);
          }


        }
        var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
        dataSource.add(labels);
        self.latLongs.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.title,
            strokecolor: v.isMainPoint ? '#cc0000' : v.isPreferred ? '#ffd800' : 'DodgerBlue',
            color: "white",
            display: "dot",
            offset: [0, -1]
          }));
          self.points.push([v.longitude, v.latitude]);


        });
      }
      else {
        //self.airports.forEach((v, i) => {
        //  dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
        //    title: v.icao,
        //    strokecolor: "darkblue",
        //    color: "white",
        //    display: "dot",
        //    offset: [0, -1]
        //  }));
        //  self.points.push([v.longitude, v.latitude]);


        //});
        self.airports.forEach(v => {
          if (v.longitude != null && v.latitude != null) {
            var startPosition = [v.longitude, v.latitude];
            var endPosition = [v.nextLongitude, v.nextLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.icao,
              name: v.airportName,
              airport: v,
              strokecolor: "darkblue",
              color: "white",
              display: "dot",
              offset: [0, -1]


            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextICAO,
              name: v.nextAirportName,
              strokecolor: "darkblue",
              color: "white",
              display: "dot",
              offset: [0, -1]
            });

            self.points.push(startPosition);
            self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.LineString(path);
            if (v.nextAirportID != null && (self.showRoute))
              dataSource.add([poly, startPoint, endPoint]);
            else
              dataSource.add([startPoint]);
          }
        });
      }

      var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {

        iconOptions: {
          image: ['get', 'image'],
          cluster: false,
          optional: true,
          allowOverlap: true,
          size: 1.25
        },
        textOptions: {
          textField: [
            'match',
            ['get', 'showText'],
            1, '',
            0, ['get', 'title'],
            ''
          ],
          offset: ['get', 'offset'],
          cluster: false,
          allowOverlap: true,
          size: 14
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      });
      self.map2.layers.add([new atlas.layer.LineLayer(dataSource, null, {
        strokeColor: "black",
        strokeWidth: 1.5
      }),
        symbolLayer
      ]);
      var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
        radius: 4,
        strokeColor: ['get', 'strokecolor'],
        strokeWidth: 6,
        color: ['get', 'color'],
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      })
      self.map2.layers.add(bubbleLayer);

      //Add a layer for rendering point data.
      self.map2.layers.add(new atlas.layer.SymbolLayer(dataSource, null, {
        iconOptions: {
          //Hide the icon image.
          image: "none"
        },

        textOptions: {
          textField: ['get', 'title'],
          offset: ['get', 'offset'],
          allowOverlap: true,
          size: 16
        },
        filter: ['any', ['==', ['geometry-type'], 'Point']]
      }));
      var popupTemplate = "<div class='popup'>{name}</div>";
      if (self.from == "manage") {
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map2.events.add('mouseover', bubbleLayer, function (e) {
          self.map2.getCanvasContainer().style.cursor = 'pointer';
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map2);
          }
        });

        self.map2.events.add('mouseleave', bubbleLayer, function () {
          self.map2.getCanvasContainer().style.cursor = 'grab';
          popup.close();
        });

        self.map2.events.add('click', bubbleLayer, function (e) {
          var properties;
          var airport;
          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              airport = properties.airport;
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              airport = properties.airport;
            }
          }

          self._dialogRef.close(airport);
        })


      }
      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      if (((self.from == "details" || self.from == "alternates") && self.latLongs.length == 1) || self.from == "manage" && self.airports.length == 1) {
        let center;
        if (self.from == "details" || self.from == "alternates")
          center = [self.latLongs[0].longitude, self.latLongs[0].latitude]
        else
          center = [self.airports[0].longitude, self.airports[0].latitude]
        self.map2.setCamera({
          center: center,
          zoom: 12
        });
      }
      else {
        self.map2.setCamera({
          bounds: bbox,
          padding: 90
        });
      }
      //   self.updateTileLayer();



    });
  }

  updateTileLayer() {


    var tileUrl = this.weatherTileUrl.replace('{layerName}', this.layerName);

    if (!this.tileLayer) {
      //Create a tile layer and add it to the map below the label layer.
      this.tileLayer = new atlas.layer.TileLayer({
        tileUrl: tileUrl,
        opacity: 0.9,
        tileSize: 256
      });

      this.map2.layers.add(this.tileLayer, 'labels');
    } else {
      this.tileLayer.setOptions({
        tileUrl: tileUrl
      });
    }
  }

  createTripMap() {


    this.map2 = new atlas.Map('map2', {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    var tempID = "";
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });


      if (self.mapData.length > 0) {
        self.mapData.forEach(v => {
          var startPosition = [v.longitude, v.latitude];
          var endPosition = [v.nextLongitude, v.nextLatitude];
          var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
            title: v.title,

          });

          var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
            title: v.nextTitle
          });
          self.points.push(startPosition);
          self.points.push(endPosition);
          var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
          var poly = new atlas.data.Feature(new atlas.data.LineString(path), {
            icao1: v.title,
            icao2: v.nextTitle,
            clicked: false,
            color: 'black'
          });

          dataSource.add([poly, startPoint, endPoint]);

        });
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 14
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        var lineLayer = new atlas.layer.LineLayer(dataSource, null, {
          strokeWidth: 2,
          strokeColor: ['get', 'color'],
        });
        //self.map2.sources.add(dataSource);
        self.map2.layers.add([lineLayer, symbolLayer]);

      }


      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      self.map2.setCamera({
        bounds: bbox,
        padding: 40
      });
    });
  }


  createVendorMap() {
    if (this.map2)
      this.map2.dispose();
    this.map2 = new atlas.Map('map2', {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "satellite_road_labels",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map2.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map2.sources.add(dataSource);
      self.map2.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.StyleControl({ mapStyles: ['satellite_road_labels', 'road_shaded_relief'] })
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.mapData.length == 0) {

        dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
          title: self.latLongs[0].icao,
          color: '#cc0000'
        }));
        self.points.push([self.latLongs[0].longitude, self.latLongs[0].latitude]);

        var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: 4,
          strokeColor: ['get', 'color'],
          strokeWidth: 6,
          color: "white",
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map2.layers.add(bubbleLayer);

        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            //Hide the icon image.
            image: "none"
          },

          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1],
            allowOverlap: true,
            size: 16,
            haloColor: '#ffffff',
            haloWidth: 2,
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        })
        self.map2.layers.add(symbolLayer);
      }
      else {
        self.mapData.forEach((v, i) => {
          var marker = new atlas.HtmlMarker({
            text: v.markerText,
            color: v.iconColor,
            position: [v.longitude, v.latitude],
            htmlContent: atlas.getImageTemplate('marker-square-rounded'),// '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
            //popup: new atlas.Popup({
            //  content: '<div style="padding:10px">' + v.mapLocationDescription + '</div>',
            //  pixelOffset: [0, -30],
            //  closeButton:false
            //})         
          });

          self.map2.markers.add(marker);
          self.map2.events.add('click', marker, () => {
            marker.togglePopup();
          });
          self.points.push([v.longitude, v.latitude]);
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            name: v.mapLocationDescription,
            note: v.notes
          }));
        });


        var popupTemplate = "<div style='padding: 10px'>{note}</div>";

        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            //Hide the icon image.
            image: "none"
          },

          textOptions: {
            textField: ['get', 'name'],
            offset: [1, -1.5],
            justify: 'auto',
            allowOverlap: false,
            size: 14,
            haloColor: '#ffffff',
            haloWidth: 2,
            variableAnchor: ['left', 'right', 'top', 'bottom']
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        })

        self.map2.layers.add(symbolLayer);
        var popup = new atlas.Popup({
          pixelOffset: [0, -30],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map2.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();
              if (properties.note != null && properties.note != "") {
                //If the shape is a point feature, show the popup at the points coordinate.
                if (e.shapes[0].getType() === 'Point') {
                  coordinate = e.shapes[0].getCoordinates();
                  content = popupTemplate.replace(/{note}/g, properties.note);
                  offset = [0, -18];
                }
              }
            } else {
              properties = e.shapes[0].properties;
              if (properties.note != null && properties.note != "") {
                //If the shape is a point feature, show the popup at the points coordinate.
                if (e.shapes[0].type === 'Point') {
                  coordinate = e.shapes[0].geometry.coordinates;
                  content = popupTemplate.replace(/{note}/g, properties.note);
                  offset = [0, -18];
                }
              }
            }

            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map2);

          }
        });

        self.map2.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });
      }

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);
      let ctr = [self.latLongs[0].longitude, self.latLongs[0].latitude];
      if (self.mapData.length <= 1) {
        ctr = [self.latLongs[0].longitude, self.latLongs[0].latitude];
        if (self.mapData.length == 1)
          ctr = [self.mapData[0].longitude, self.mapData[0].latitude];
        self.map2.setCamera({
          center: ctr,
          zoom: 15
        });
      }
      else {
        self.map2.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

    });
  }

  createHotelMap() {
    const container = document.getElementById('map2')
    if (container) {

      if (this.map2 && !this.map2.isDisposed)
        this.map2.dispose();
      this.map2 = new atlas.Map('map2', {

        language: 'en-US',
        view: 'Auto',
        showBuildingModels: true,
        showLogo: false,
        style: "road_shaded_relief",
        showFeedbackLink: false,
        //zoom: 11,
        //center: new atlas.data.Position(6.7735, 51.2277),
        //Add your Azure Maps subscription client ID to the map2 SDK.
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });

      var self = this;
      self.points = [];
      self.map2.events.add('ready', function () {
        var dataSource = new atlas.source.DataSource();
        self.map2.sources.add(dataSource);
        self.map2.controls.add([
          new atlas.control.ZoomControl()
        ], {
          position: atlas.ControlPosition.TopRight
        });

        dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
          title: self.latLongs[0].title,
          strokecolor: '#cc0000',
          color: "white",
          display: "dot",
          offset: [0, -1],
          image: "pin-round",
          isMain: true
        }));
        // self.points.push([self.airportLongitude, self.airportLatitude]);
        var marker = new atlas.HtmlMarker({
          draggable: false,
          color: '#cc0000',
          htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><g transform="translate(0 1)"><circle cx="12.25" cy="11.5833" r="11" fill="{color}"/><path d="m12.25 23.583a12 12 0 1 1 12-12 12 12 0 0 1-12 12zm0-22a10 10 0 1 0 10 10 10 10 0 0 0-10-10z" fill="{secondaryColor}"/><circle cx="12.25" cy="11.5833" r="4.2386" fill="{secondaryColor}"/></g><text x="12.5" y="17" style="font-size:14px;fill:#000;text-anchor:middle">{text}</text></svg>',
          position: [self.latLongs[0].longitude, self.latLongs[0].latitude],

        });
        self.map2.markers.add(marker);
        self.mapData.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.hotelName,
            strokecolor: 'DodgerBlue',
            color: "white",
            display: "dot",
            offset: [0, -1],
            guid: v.hotelGUID,
            cluster: true
          }));
          self.points.push([v.longitude, v.latitude]);

          var marker = new atlas.HtmlMarker({
            position: [v.longitude, v.latitude],
            color: "darkblue",
            text: v.hotelGUID,
            htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 25 25" width="25px" fill="{color}" > <path d="M0 0h25v25H0V0z" fill = "none" /> <path d="M19 9h-6v6h8v-4c0-1.1-.9-2-2-2z" opacity = ".3" /> <circle cx="7" cy = "11" opacity=".3" r="1" /> <path d="M4 11c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3zm4 0c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm11-4h-8v8H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4zm2 8h-8V9h6c1.1 0 2 .9 2 2v4z"/></svg>'
          });

          self.map2.markers.add(marker);
         // self.allMarkers.push(marker);


        });
        //Create a circle
        if (self.distance <= 25)
          self.radius = 10;
        if (self.distance > 25 && self.distance <= 50)
          self.radius = 20;
        if (self.distance > 50 && self.distance <= 100)
          self.radius = 35;
        if (self.distance > 100)
          self.radius = 50;
        if (self.mapData.length > 1) {
          let maxRadius = self.distance * 1852;
          let increment = self.radius * 926;
          for (let i = increment; i <= maxRadius; i = i + increment) {
            var data = new atlas.data.Feature(new atlas.data.Point([self.latLongs[0].longitude, self.latLongs[0].latitude]), {
              title: i / 926 / 2 + " NM",
              display: "radius",
              subType: "Circle",
              radius: i,
              color: "transparent",
              strokecolor: "transparent",
              offset: [0, -.5]
            });
            let r = i / 926 / 2;
            if (r < 25 || r <= self.mapData[self.mapData.length - 1].distanceFromAirport + (self.radius / 2))
              dataSource.add(data);

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent',
              filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 1.5
            });

            var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: 'none'
              },
              textOptions: {
                textField: ['get', 'label']
              },
              filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
            })
            /*Create and add a polygon layer to render the polygon to the map*/
            self.map2.layers.add([polygonLayer, lineLayer, symbolLayer]);
          }


        }
       // self.hotelDataSource = dataSource;
        var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
        dataSource.add(labels);

        var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: 4,
          strokeColor: ['get', 'strokecolor'],
          strokeWidth: 6,
          color: ['get', 'color'],
          filter: ['any', ['==', ['geometry-type'], 'Point']],
          guid: ['get', 'guid']
        });
        // self.map2.layers.add(bubbleLayer);


        //Add a layer for rendering point data.
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: "none"
          },

          textOptions: {
            textField: ['get', 'title'],
            offset: [0, 1],
            allowOverlap: false,
            size: 16,
            cluster: true
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']],
          guid: ['get', 'guid']
        })
        self.map2.layers.add(symbolLayer);


        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        if (self.mapData.length == 1) {

          self.map2.setCamera({
            center: [self.mapData[0].longitude, self.mapData[0].latitude],
            zoom: 12
          });
        }
        else {
          self.map2.setCamera({
            bounds: bbox,
            padding: 40
          });
        }

      });
    }

  }
}

function calculatePolygonLabels(shapes, labelPropertyName) {
  var labels = [];

  for (var i = 0; i < shapes.length; i++) {

    var circlePolygon = shapes[i].circlePolygon;

    // Get the coordinates of the polygon edge
    if (circlePolygon != undefined) {
      var edgeCoordinates = circlePolygon.geometry.coordinates[0][0];

      var prop = {};
      prop[labelPropertyName] = shapes[i].circlePolygon.properties[labelPropertyName];
      prop["display"] = shapes[i].circlePolygon.properties["display"];
      prop["color"] = shapes[i].circlePolygon.properties["color"];
      prop["strokecolor"] = shapes[i].circlePolygon.properties["strokecolor"];
      prop["offset"] = shapes[i].circlePolygon.properties["offset"];
      labels.push(new atlas.data.Feature(new atlas.data.Point(edgeCoordinates), prop));

    }
  }

  return labels;
}
