<div style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em " cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="margin-top:0.15em"><i class="material-icons" style="font-size: x-large;color: gray; margin-top:0.15em;margin-right:.5em">hotel</i></div>
    <div style="margin-top:0.3125em;"><h5>Hotel Property</h5></div>
  </div>
  <div>
   
      <div style="margin-top:0em; padding-left: 1em;padding-right:1em; ">
        <div style="display: flex">
          <!--left-->
          <form *ngIf="hotel_edit_form" [formGroup]="hotel_edit_form">
            <div style="margin-right:0.5em">
              <div style="background-color: white; display: flex; padding: .5em;padding-bottom:.75em ">
                <div style="margin-right:0.5em">
                  <label for="hotelName" style="font-size:small">Hotel Name</label>
                  <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                    <input type="text" formControlName="hotelName" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.hotelName.errors }" maxlength="255" style="width:21.5em;" />
                  </div>
                  <div *ngIf="submitted && f.hotelName.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.hotelName.errors.required">Hotel name is required.</div>
                  </div>
                </div>
                <div>
                  <label for="hotel_chain_select" style="font-size:small">Chain</label>
                  <div style="margin-top:-0.5em; margin-bottom:0em">
                    <select *ngIf="f" formControlName="hotel_chain_select" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.hotel_chain_select.errors }">
                      <option *ngFor="let h of hotelChainList" [ngValue]="h.hotelChainGUID">{{h.hotelChainName}}</option>
                    </select>
                  </div>
                  <div *ngIf="submitted && f.hotel_chain_select.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.hotel_chain_select.errors.required">Hotel chain is required.</div>
                  </div>
                </div>
              </div>
              <div style="display: flex; margin-top: .5em; background-color: white; padding: .5em;">
                <div>
                  <div>
                    <label for="street_address" style="font-size:small">Street Address</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="street_address" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.street_address.errors }" maxlength="255" style="width:41.5em;" />
                    </div>
                    <div *ngIf="submitted && f.street_address.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.street_address.errors.required">Address is required.</div>
                    </div>
                  </div>
                  <div>
                    <label for="city" style="font-size:small">City</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="city" class="form-control inputBox" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" maxlength="255" style="width:41.5em;" />
                    </div>
                    <div *ngIf="submitted && f.city.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.city.errors.required">City is required.</div>
                    </div>
                  </div>
                  <div>
                    <label for="state_province" style="font-size:small">State / Province</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" *ngIf="f && selectedCountryGUID !='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" formControlName="state_province" class="form-control inputBox" maxlength="255" style="width:41.5em;" />
                      <select *ngIf="f && selectedCountryGUID =='6aa9b42f-a693-4d74-83d8-a1500b0f1133'" formControlName="state_province" class="form-control form-control-sm dropdown">
                        <option *ngFor="let state of stateProvinceList" [ngValue]="state.stateProvinceName">{{state.stateProvinceName}}</option>
                      </select>
                    </div>
                  </div>
                  <div style="display: flex">
                    <div style="margin-right: 0.5em">
                      <label for="zipcode" style="font-size:small">Zip/Postal Code</label>
                      <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        <input type="text" formControlName="zipcode" class="form-control inputBox" maxlength="255" style="width:10em;" />
                      </div>
                    </div>
                    <div>
                      <label for="country_select" style="font-size:small">Country</label>
                      <div class="form-group" style="margin-top:-0.5em;">
                        <select *ngIf="f" formControlName="country_select" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.country_select.errors }" (change)="countryOnchange($event)">
                          <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
                        </select>
                      </div>
                      <div *ngIf="submitted && f.country_select.errors" class="alert alert-danger p-0 small">
                        <div *ngIf="f.country_select.errors.required">Country is required.</div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <!--<div>
                <div *ngIf="(submitted || validatedAirport) && f.near_airport.errors" class="invalid-feedback">
                  <div *ngIf="f.near_airport.errors.required" style="background-color:#f9d2de; padding-left:0.25em;margin-top:0em; font-size:small;width:10em">Airport is required</div>
                  <div *ngIf="f.near_airport.errors.pattern" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small;width:8em">Invalid airport</div>
                  <div *ngIf="f.near_airport.errors.wrongAirportValidate==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small;width:8em">{{wrongAirportErrMsg}}</div>
                  <div *ngIf="f.near_airport.errors.duplicateIATAValidate==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small; width:33em">{{duplicateIATAErrMsg}}</div>
                </div>
              </div>-->
              <div style="margin-top: .5em; background-color: white;  padding: .5em;">
                <div style="display: flex">
                  <div style="margin-right:0.5em">
                    <label for="comm_address_type1_select" style="font-size:small">Type</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="comm_address_type1_select" class="form-control form-control-sm dropdown" style="width:14em " (change)="commAddressTypeChange($event, 1)">
                        <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                      </select>
                    </div>
                    <div *ngIf="submitted && f.comm_address_type1_select.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.comm_address_type1_select.errors.required">Comm address type is required.</div>
                    </div>
                  </div>
                  <div style="margin-right:0.5em;">
                    <label for="comm_address1" style="font-size:small">Address</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="comm_address1" class="form-control inputBox" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address1.errors }" style="width:21em;" (change)="addressChange(1)" />
                    </div>
                    <div *ngIf="submitted && f.comm_address1.errors" class="invalid-feedback" style="margin-top:-2em">
                      <div *ngIf="f.comm_address1.errors.required" style="background-color:#f9d2de; padding-left:0.25em; width:21em; margin-top:0.25em">Comm address is required.</div>
                      <div *ngIf="f.comm_address1.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress1ErrMsg}}</div>
                    </div>
                  </div>
                  <div *ngIf="selectedCommAddressType1=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                    <label for="extension1" style="font-size:small">Extension</label>
                    <div style="margin-left: 0em; margin-top:-0.5em">
                      <input type="number" formControlName="extension1" class="form-control inputBox" maxlength="5" style="width:5em;" />
                    </div>

                  </div>
                </div>
                <div style="display: flex">
                  <div style="margin-right:0.5em">
                    <label for="comm_address_type2_select" style="font-size:small">Type</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="comm_address_type2_select" class="form-control form-control-sm dropdown" style="width:14em" (change)="commAddressTypeChange($event, 2)">
                        <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                      </select>
                    </div>
                    <div *ngIf="submitted && f.comm_address_type2_select.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.comm_address_type2_select.errors.required">Comm address type is required.</div>
                    </div>
                  </div>
                  <div style="margin-right:0.5em;">
                    <label for="comm_address2" style="font-size:small">Address</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="comm_address2" class="form-control inputBox" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address2.errors }" style="width:21em;" (change)="addressChange(2)" />
                    </div>
                    <div *ngIf="submitted && f.comm_address2.errors" class="invalid-feedback" style="margin-top:-2em">
                      <div *ngIf="f.comm_address2.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress2ErrMsg}}</div>
                    </div>
                  </div>
                  <div *ngIf="selectedCommAddressType2=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                    <label for="extension2" style="font-size:small">Extension</label>
                    <div style="margin-left: 0em; margin-top:-0.5em">
                      <input type="number" formControlName="extension2" class="form-control inputBox" maxlength="5" style="width:5em;" />
                    </div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="margin-right:0.5em">
                    <label for="comm_address_type3_select" style="font-size:small">Type</label>
                    <div style="margin-top:-0.5em;">
                      <select *ngIf="f" formControlName="comm_address_type3_select" class="form-control form-control-sm dropdown" style="width:14em" (change)="commAddressTypeChange($event, 3)">
                        <option *ngFor="let cat of commAddressTypeList" [ngValue]="cat.commAddressTypeGUID">{{cat.commAddressTypeDesc}}</option>
                      </select>
                    </div>
                  </div>
                  <div style="margin-right:0.5em;">
                    <label for="comm_address3" style="font-size:small">Address</label>
                    <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                      <input type="text" formControlName="comm_address3" class="form-control inputBox" maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.comm_address3.errors }" style="width:21em;" (change)="addressChange(3)" />
                    </div>
                    <div *ngIf="submitted && f.comm_address3.errors" class="invalid-feedback" style="margin-top:-2em">
                      <div *ngIf="f.comm_address3.errors.invalidComm==true" style="background-color:#f9d2de; padding-left:0.25em; margin-top:0em; font-size: small">{{invalidCommAddress3ErrMsg}}</div>
                    </div>
                  </div>
                  <div *ngIf="selectedCommAddressType3=='Office Phone'" style="margin-left: 0em;margin-top:0em;">
                    <label for="extension3" style="font-size:small">Extension</label>
                    <div style="margin-left: 0em; margin-top:-0.5em">
                      <input type="number" formControlName="extension3" class="form-control inputBox" maxlength="5" style="width:5em;" />
                    </div>
                  </div>
                </div>
                <div>
                  <label for="remarks" style="font-size:small">Remarks</label>
                  <div style="margin-left: 0em; margin-top:-0.5em">
                    <textarea formControlName="remarks" class="form-control" maxlength=255 style="width:41.5em; height:6em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
</textarea>
                  </div>
                </div>

              </div>
            </div>
          </form>
          <!--right end-->
          
            <div style="width:30em;margin-left:.5em;background-color:white;padding:.5em">
              <div style="width:100%" class="d-inline-flex justify-content-start">
                <div style="font-size:small;margin-right:.5em">Hotel Location</div>
                <div style="font-size:small;font-style:italic" *ngIf="marker==undefined">Right click to add marker</div>
                <div style="font-size: small; font-style: italic" *ngIf="marker!=undefined">Click and drag to move marker</div>
              </div>
              <div style="position: relative;">
                <div style="width:100%;height:30em;border-radius:.25em" id="hotel-map"></div>
              </div>
              <div style="margin-left:0.5em">
                <div style="margin-top:1.1em;" class="d-inline-flex">
                  <button mat-button class="btn-high mr-2" (click)="getHotelCoordinates()">Locate Hotel</button>
                  <button mat-button class="btn-high" (click)="getCityCenter()">Use City Center</button>
                </div>
                <form *ngIf="hotel_edit_form" [formGroup]="hotel_edit_form">
                  <div class="d-inline-flex mt-1">
                    <div style="margin-right: 2.3em">
                      <label for="latitude" style="font-size:small">Latitude:</label>
                      <div style="font-size: small; margin-left: 0em; margin-top: -0.5em; margin-bottom: 0em">
                        {{latitudeDMS}}
                        <!--<input type="text" formControlName="latitude" class="form-control inputBox" style="width:7em;" (change)="updateMarker()" />-->
                      </div>
                    </div>
                    <div style="margin-right: 2.5em">
                      <label for="longitude" style="font-size:small">Longitude:</label>
                      <div style="font-size:small;margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        {{longitudeDMS}}
                        <!--<input type="text" formControlName="longitude" class="form-control inputBox" style="width:7em;" (change)="updateMarker()" />-->
                      </div>
                    </div>
                    <div style="margin-right: 0.5em">
                      <label for="longitude" style="font-size:small">Matched By:</label>
                      <div style="font-size:small;margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                        {{matchType}}
                        <!--<input type="text" formControlName="longitude" class="form-control inputBox" style="width:7em;" (change)="updateMarker()" />-->
                      </div>
                    </div>
                    <!--<div *ngIf="latitude!=null && longitude!=null" style="margin-top:1.1em">
    <button mat-button (click)="updateCoordinates()" class="btn btn-low grid">Update</button>
  </div>-->
                  </div>
                </form>
           
                <!--<div style="display: flex">
    <div style="margin-right: 0.5em">
      <label for="near_airport" style="font-size:small">Nearest Airport</label>
      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
        <input type="text" formControlName="near_airport" class="form-control inputBox" maxlength=4 style="width:7em;" [ngClass]="{ 'is-invalid': (submitted || validatedAirport) && f.near_airport.errors }" (change)="icaoChange($event)" />
      </div>
    </div>
    <div style="margin-right: 0.5em">
      <label for="distance_from_airport" style="font-size:small">Distance from Airport</label>
      <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em; display:flex">
        <input type="number" formControlName="distance_from_airport" class="form-control inputBox" style="width:7em;" /><div style="font-size: small">Miles</div>
      </div>
    </div>

  </div>-->
              </div>
            </div>
            
            <form *ngIf="hotel_edit_form" [formGroup]="hotel_edit_form">
              <ng-template #hotelCoordinates>
                <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mat-elevation-z8 p-1 border" style="background-color:white;width:82em">
                  <div>
                    <div style="display:flex;justify-content:space-between">
                      <h6>Address/Coordinate Search</h6>
                      <mat-icon mat-dialog-close>close</mat-icon>
                    </div>
                    <div class="d-inline-flex" >
                      <input type="text" formControlName="search_value" class="form-control inputBox" style="width:33em; user-select:text" (mousedown)="$event.stopPropagation()"   />
                      <mat-radio-group class="form-control" (change)="updateSearchValue()" [(ngModel)]="searchType" [ngModelOptions]="{standalone: true}" style="margin-top:-.5em;margin-left:-1em;width:20em;display: flex; flex-direction: row; transform: scale(.8); align-items: flex-start;border:none!important;background-color:transparent;">
                        <mat-radio-button class="mr-3" [checked]="searchType==1" [value]="1">Address</mat-radio-button>
                        <mat-radio-button class="mr-3" [checked]="searchType==2" [value]="2">Hotel Name</mat-radio-button>
                        <mat-radio-button class="mr-3" [checked]="searchType==3" [value]="3">Free Search</mat-radio-button>
                      </mat-radio-group>
                      <div style="margin-top:-.25em">
                        <button type="button" mat-button class="btn-med" (click)="coordinateSearch()">Search</button>
                      </div>
                    </div>
                    <div class="d-inline-flex">
                      <div style="max-height:34em;overflow-y:auto">
                        <table mat-table [dataSource]="results" class="example-container">
                          <tr>
                            <ng-container matColumnDef="type">
                              <th mat-header-cell *matHeaderCellDef width="20%">
                                Type
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.type}} <span *ngIf="element.type=='POI'">{{element.poi.categories}}</span></td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef width="20%">
                                Name
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.poi?.name}}</td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                              <th mat-header-cell *matHeaderCellDef width="20%">
                                Address
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.address.freeformAddress}}</td>
                            </ng-container>
                            <ng-container matColumnDef="latitude">
                              <th mat-header-cell *matHeaderCellDef width="20%">
                                Latitude
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.position.lat}}</td>
                            </ng-container>
                            <ng-container matColumnDef="longitude">
                              <th mat-header-cell *matHeaderCellDef width="20%">
                                Longitude
                              </th>
                              <td mat-cell *matCellDef="let element">{{element.position.lon}}</td>
                            </ng-container>
                            <ng-container matColumnDef="action1">
                              <th mat-header-cell *matHeaderCellDef width="10%">
                                Update Address/Coords
                              </th>
                              <td mat-cell *matCellDef="let element"><button mat-button class="btn btn-low grid" mat-dialog-close (click)="updateAddress(true,element)">Address/Coords</button></td>
                            </ng-container>
                            <ng-container matColumnDef="action2">
                              <th mat-header-cell *matHeaderCellDef width="10%">
                                Update Coords Only
                              </th>
                              <td mat-cell *matCellDef="let element"><button mat-button class="btn btn-low grid" mat-dialog-close (click)="updateAddress(false,element)">Coords</button></td>
                            </ng-container>

                          </tr>
                          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                        </table>
                      </div>
                      <div style="width:30em;margin-left:1em" (mousedown)="$event.stopPropagation()">
                        <div style="position: relative;">
                          <div style="width:100%;height:40em;border-radius:.25em" id="hotel-search-map"></div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </form>
        </div>
    </div>

  </div>
  <div style="display: flex;justify-content:space-between">
    <div>
      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;margin-right:1em">
        <button class="btn btn-high" mat-button [disabled]="loading" (click)="clickSave('')"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>&nbsp;
        <button class="btn btn-low" mat-button (click)="clickClose()">Close</button>
        <button class="btn btn-low ml-3" mat-button (click)="closestAirports()">Show Closest Airports</button>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em; width:19em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
      <div *ngIf="showSuccessMsg" style="margin-top: 0.5em;">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
    <form *ngIf="hotel_edit_form" [formGroup]="hotel_edit_form">
      <div class="d-inline-flex mt-1">

        <div class="form-inline" style="margin-right:0.5em">
          <label for="hotel_status" style="font-size:small; white-space:nowrap;margin-right:.5em">Disable Record</label>
          <div>
            <select *ngIf="f" formControlName="hotel_status" class="form-control form-control-sm dropdown">
              <option value="1" selected>No</option>
              <option value="0">Yes</option>
            </select>
          </div>
        </div>
        <div class="form-inline" style=" margin-right: 0.5em">
          <label for="modifiedBy" style="font-size: small; margin-right: .5em">Modified By</label>
          <div>
            <input type="text" formControlName="modifiedBy" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
          </div>
        </div>
        <div class="form-inline" style=" margin-right: 0.5em">
          <label for="modifiedDate" style="font-size: small; margin-right: .5em">Modified Date</label>
          <div>
            <input type="text" formControlName="modifiedDate" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0em" />
          </div>
        </div>

      </div>
    </form>
  </div>
</div>
  <app-spin *ngIf="showSpin"></app-spin>

