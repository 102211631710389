import { Component, Inject, OnInit, ViewChild, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialogRef as MatDialogRef,MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable } from 'rxjs';
import { CountryService } from '../services/country.service';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CountryDocumentModel } from '../models/country-document.model';
import { saveAs } from 'file-saver';
import { DocumentTypeModel } from '../models/document-type.model';
import { CountryContentModel } from '../models/country-content.model';
import { CountryModel } from '../models/country.model';
import { CountryRequiredPermitDocumentsComponent } from './country-required-permit-documents-dialog.component';
import { CountryContentDocModel } from '../models/country-content-doc.model';
import { SelectionModel } from '@angular/cdk/collections';
import { CountryDocumentsEditDialogComponent } from './country-documents-edit-dialog.component';
import { CountryNotesAndDocumentsEditDialogComponent } from './country-notes-and-documents-edit-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-country-segment-edit-dialog',
  templateUrl: './country-segment-edit-dialog.component.html',
  styleUrls: ['./country-segment-edit-dialog.component.css']
})
/** country-profile-setup-add component*/
export class CountrySegmentEditDialogComponent implements OnInit, OnChanges {
  country_segment_edit_dialog_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() countryID: number;
  @Input() countryNoteID: number;
  @Input() countryDocumentGUID: string;

  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  noteTypeList: NoteTypeModel[];
  country_required_documnet_desc: string;
  selectedNoteTypeId: number;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  // isActive: number;
  showSuccessMsg: boolean = false;
  successMsg: string ='upload Successfull'

  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  serviceArrangementList: ServiceArrangementModel[];
  selectedServiceArrangementID: number;
  totalMessageAttachmentFileSize: number = 0;
  documentList: CountryDocumentModel[]=[];
  documentsListArray: CountryContentDocModel[]=[];
  documentsMiscListArray: CountryContentDocModel[]=[];
  icao: string;
  document_name: string;
  documentTypeList: DocumentTypeModel[];
  avaiDocumentTypeList: DocumentTypeModel[];
  selDocumentTypeList: DocumentTypeModel[];
  selectedDocumentTypeGuid: string="";
  selectedDocumentTypeDescription: string="";
  isActive: boolean = true;
  currentIndex: number ;
  currentMiscIndex: number ;

  @ViewChild('noteTypeList') noteTypeListRef: ElementRef
  isDisabled: boolean;
  isDisabled1: boolean = true;
  isDisabled2: boolean = true;

  action: string;
  countryDocument:CountryDocumentModel;
  isDelete: boolean = false;
  errorMsg: any = "";
  showErrorMsg: boolean = false;
  // prefileFilingTime: string;
  // leadTime: string;
  // validTime: string;
  // dueDate: string;
  // overflightNotes: string;
  // prefileRequirements: string;

  countryData: CountryModel = new CountryModel();
  countrySegmentContent: CountryContentModel[];
  countryContentUpdates: CountryContentModel[]=[];
  //selectedDocTypeList:DocumentTypeModel[] = [];
  displayedColumn: string[] = ['documentType', 'showToClient', 'removeDocument', 'internalNotes', 'removeDocumentRow' ];  //  'documentName',
  displayedColumn2: string[] = ['Type', 'Name', 'Effective_Date', 'Expiration_Date' , 'Action' ];
  displayedColumn3: string[] = ['documentType',   'removeDocument', 'internalNotes', 'removeDocumentRow' ];  //  'documentName',

  segmentDetails: CountryContentModel;
  countryName: string;
  isDisabledControl: boolean = true;
  userType: string;
  selection = new SelectionModel<CountryContentDocModel>(true, []);
  countryDocuments: CountryDocumentModel[];
  isLoading: boolean; 
  excludeList: string[];
  username: string;
  readonly validTimeVar :string = 'validity time';
  readonly dueDateVar :string = 'due date';
  readonly leadTimeVar :string = 'lead time';
  readonly prefileFilingTimeVar :string = 'prefile filing time';
  readonly notesVar :string = 'notes';
  readonly fileVar :string = 'file';
  readonly documentsVar :string = 'documents';
  readonly prefileRequirementsVar :string = 'prefile requirements';
  readonly overflightSec :string = 'overflight';
  readonly overflightNavChargesSec :string = 'overflight - nav charges';
  readonly landingPrivateSec :string = 'landing - private';
  readonly landingCharterSec :string = 'landing - charter';
  readonly countryNotesSec :string = 'country notes';
  readonly docsRequiredVar :string = 'documents required';
  lastModifiedDate: string;
  lastModifiedBy: string;
  isLoading1: boolean = false;
  isDocsRequired: boolean  ;
  isDocsDisabled: boolean = false;
  filteredDocArray: CountryContentDocModel[] = [];
  filteredMiscDocArray: CountryContentDocModel[] = [];
  uniqueId: string = '';
  isMiscellaneous: boolean;
  isMiscellaneousImport: boolean;

  constructor(private readonly _dialogRef: MatDialogRef<CountrySegmentEditDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService,    private readonly _commonService: CommonService,private readonly dialog: MatDialog,
    private readonly _countryService: CountryService,private fb: FormBuilder,
    private readonly _dialogService: DialogService
  ) {
    this.countryID = data.countryID;
    this.icao = data.icao;
    this.action =data.action;
    this.countrySegmentContent = data.countrySegmentContent;
    this.segmentDetails = data.segmentDetails;
    this.countryName = data.countryName;
    this.userType = data.userType;
    this.username = data.username;
    this.countryData = data.countryData;
    if (data.segmentDocs && data.segmentDocs.length > 0)
      this.documentsListArray = data.segmentDocs;
    
    this.documentsListArray.forEach((item) => item.countryID = this.countryID )
    this.documentsListArray.forEach((item) => item.isActive = true);
    this.filteredDocArray = this.documentsListArray;

    if (data.segmentMiscDocs && data.segmentMiscDocs.length > 0)
      this.documentsMiscListArray = data.segmentMiscDocs;
    this.documentsMiscListArray.forEach((item) => item.countryID = this.countryID )
    this.documentsMiscListArray.forEach((item) => item.isActive = true);
    this.documentsMiscListArray.forEach((item) => item.showToClient = false);
    this.filteredMiscDocArray = this.documentsMiscListArray;
  }

  getAllData(): Observable<any[]> {

    // let getNoteType = this._commonService.getNoteTypeListByxId<ResponseModel<NoteTypeModel[]>>(this.countryID.toString(), "Country");
    // let getDocTypeResponse = this._countryService.getCountryDocumentTypeList<ResponseModel<DocumentTypeModel[]>>(this.countryDocumentGUID);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    return forkJoin([getAllowedDocTypeResponse   ]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.totalMessageAttachmentFileSize = 0;

    this.maxTempDocId = 0;
    this.allowedFileTypeList = [];
    this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.maxFileSize = 0;    // 5242880;   // 5 mb
    this.maxFileUploadNumber = 0;
    this.fileList = [];
    this.showSuccessMsg = false;
    this.document_name = "";

    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.isActive = true;


    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false,
      // maxFileSize:  5242880, //5mb
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {

      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[0].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;   // 5242880;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      // if (responses[1] != null) {
      //   if (responses[1].code == "200" && responses[1].message == "") {
      //     this.documentTypeList = responses[1].model;
      //   }
      //   else {
      //     if (responses[1].code == "401") {
      //       this._authService.signOut();
      //     }
      //   }
      // }
      this.getData();
      this.isDisabled1 = this.filteredDocArray.length>0 ? true: false;
      this.initControls(); 
      this.country_segment_edit_dialog_form.controls.document_name.setValue(this.countryDocument?.documentName); 
      this.country_segment_edit_dialog_form.controls.size.setValue(this.countryDocument?this.countryDocument.fileSize+ " KB":"");
      this.country_segment_edit_dialog_form.controls.country_required_documnet_desc.setValue(this.countryDocument?.documentDescription);
      this.country_segment_edit_dialog_form.controls.document_type.setValue(this.countryDocument?.documentTypeGUIDList);

      this.showSpin = false;  
    });
  }

  ngOnChanges( ): void {

    // this.setAllDetails();
    this.isDisabled1 = this.documentsListArray.length>0 ? true: false;
    this.isDisabled2 = this.documentsMiscListArray.length>0 ? true: false;


  }

  initControls() {
    let prefileFilingTime: string;
    let leadTime: string;
    let validTime: string;
    let dueDate: string;
    let segmentNotes: string;
    let prefileRequirements: string;
    let isRequired: string;
    let document: any;
    let docsRequired: string;

    if (this.countrySegmentContent != undefined) {
      prefileFilingTime = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.prefileFilingTimeVar)))[0]?.countryContentDescription;
      leadTime = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.leadTimeVar)))[0]?.countryContentDescription;
      dueDate = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.dueDateVar)))[0]?.countryContentDescription;
      validTime = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.validTimeVar)))[0]?.countryContentDescription;
      segmentNotes = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.notesVar)))[0]?.countryContentDescription;
      prefileRequirements = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.prefileRequirementsVar)))[0]?.countryContentDescription;
      isRequired = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.segmentDetails.countryContentSegment.toLowerCase())))[0]?.countryContentDescription;
      docsRequired = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(this.docsRequiredVar)))[0]?.countryContentDescription;
       
      this.isDocsRequired = docsRequired == 'Y';
      // docsRequired = docsRequired == 'Y';

      if (isRequired != 'Yes' && isRequired != 'No' && isRequired != 'Restricted' && isRequired != undefined) { 
          
        isRequired = 'See Notes'; 
      }
      // if(isRequired == undefined || isRequired == null){
      //   isRequired = 'n/a';
      // }
      // else if(segmentNotes !=null && segmentNotes.length>0){
      //   isRequired = 'See Notes';
      // }
    }

    this.country_segment_edit_dialog_form = this._formBuilder.group({
      country_required_documnet_desc: this.country_required_documnet_desc,
      // note_status: this.isActive,
      // modifiedBy: this.modifiedBy,
      // modifiedDate: this.modifiedDate,
      prefileFilingTime: [{ value: prefileFilingTime, disabled: this.isDisabled }],
      leadTime: [{ value: leadTime, disabled: this.isDisabled }],
      validTime: [{ value: validTime, disabled: this.isDisabled }],
      dueDate: [{ value: dueDate, disabled: this.isDisabled }],
      segmentNotes: [{ value: segmentNotes, disabled: this.isDisabled }],
      prefileRequirements: [{ value: prefileRequirements, disabled: this.isDisabled }],
      document_name: [{ value: this.document_name, disabled: this.isDisabled }, Validators.required],
      size: [{ value: this.docSize, disabled: this.isDisabled }],
      document_type: [{ value: this.selectedDocumentTypeGuid, disabled: this.isDisabled }, Validators.required],
      isRequired: [isRequired],
      isDocsRequired: [{value: this.isDocsRequired, disabled: this.isDisabled  } ],
      isDocsDisabled: [{value: this.isDocsDisabled, disabled: true  } ],


      documents:  this.fb.array<FormGroup>([]) ,
      document: [null],

    });
    this.country_segment_edit_dialog_form.controls.isRequired.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.segmentDetails.countryContentSegment);
      // this.isDisabledControl = val =='NO' ? true : false ;
    });
    this.country_segment_edit_dialog_form.controls.prefileFilingTime.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.prefileFilingTimeVar);
    });
    this.country_segment_edit_dialog_form.controls.leadTime.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.leadTimeVar);
    });
    this.country_segment_edit_dialog_form.controls.validTime.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.validTimeVar);
    });
    this.country_segment_edit_dialog_form.controls.dueDate.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.dueDateVar);
    });
    this.country_segment_edit_dialog_form.controls.segmentNotes.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.notesVar);
    });
    this.country_segment_edit_dialog_form.controls.prefileRequirements.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val, this.prefileRequirementsVar);
    });
    this.country_segment_edit_dialog_form.controls.isDocsRequired.valueChanges.subscribe(val=>{
      this.setUpdatedDetails(val? 'Y': 'N', this.docsRequiredVar);
    });
  }

  setUpdatedDetails(val: string, cat:string){

    let countrycontent: CountryContentModel = new CountryContentModel();
    countrycontent.countryContentSegmentID = this.segmentDetails.countryContentSegmentID;
    countrycontent.countryContentCategory = cat;
    countrycontent.countryID = this.countryID;
    countrycontent.countryContentDescription = val;
    countrycontent.modifiedBy = this.username;

    // let cc = (this.countrySegmentContent?.filter(cs => cs.countryContentCategory.toLowerCase().includes(cat.toLowerCase())));
    // if (cc != undefined) {
    //   countrycontent.countryContentID = cc[0]?.countryContentID;
    //   if (!countrycontent.countryContentCategoryOrig)
    //     countrycontent.countryContentCategoryOrig = cc[0].countryContentDescription;
    // }

    let idx = this.countryContentUpdates?.findIndex(c => c.countryContentID == countrycontent.countryContentID && c.countryContentCategory.toLowerCase().includes(cat.toLowerCase()));
    if (idx >= 0) {
      this.countryContentUpdates.splice(idx, 1);
      this.countryContentUpdates.push(countrycontent);
    }
    else
      this.countryContentUpdates.push(countrycontent);
  }

  
  clickSave(){
    this.isLoading1 = true;
    this.isLoading = true;
    this._countryService.saveCountryContent<ResponseModel<number>>(this.countryContentUpdates).subscribe(resp=>{
      if (resp.code == "200" && resp.model != -1){ 

        // let documentsListUpdates = this.documentsL istArray.filter(dla => dla.isModified == true);
        this.documentsMiscListArray.forEach((item) => item.showToClient = false);

        let documentsListUpdates = this.documentsListArray.filter(dla => dla.isModified == true);
        let documentsMiscListUpdates = this.documentsMiscListArray.filter(dla => dla.isModified == true);
        
        let combinedUpdates = documentsListUpdates.concat(documentsMiscListUpdates);
        
        
        if (combinedUpdates?.length > 0)
          this._countryService.uploadCountryContentDoc<ResponseModel<string>>(combinedUpdates).subscribe(response => {
            if (response.code == "200" && response.message == "") {
              this.loading = false;
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.successMsg = 'Upload Successful'
                this.showSuccessMsg = false;
                // this.clickClose();
              }, 1000);
              this.isLoading1 = false;
              this._dialogRef.close(true);
            }
          });
        else {
          this.isLoading1 = false;
          this._dialogRef.close(true);
        }

        // setTimeout(() => { 
        // }, 1000);
      }
      else if(resp.code=="401")
        this._authService.signOut();
    });
 


  }

  get f() { return this.country_segment_edit_dialog_form.controls; }





  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.countryNoteID = 0;
    this.selectedNoteTypeId = null;
    this.country_required_documnet_desc = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.noteTypeList = [];
    this.document_name = "";
    this.avaiDocumentTypeList = [];
    this.selDocumentTypeList = [];
    this.selectedDocumentTypeGuid = "";
    this.countryDocument = null;
    this.action = 'add';
    this.country_segment_edit_dialog_form.reset();

    this.documentTypeList.forEach(x => {
      x.selected = false;
    })
    this.setDocumentTypeList(); 
    this.initControls();
  }

  clickClose() {
    this._dialogRef.close(this.isModified);
  }


  // upload file
  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false;
            }, 2000);
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }

        }
        else {

          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);

        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  importFile(event: any, element, index: number) {
    
    this.currentIndex = index;
    this.isMiscellaneousImport = false; 
    this.errMsg = "";
    this.documentList = [];
    this.documentsListArray[index]
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) { 
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
            this.errMsg = 'File size is over the limit of 25 MB.';
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false;
            }, 2000);
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);
            this.f.document_name.setValue(filename);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }

      
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  importMiscFile(event: any, element, index: number) {
    
    this.currentMiscIndex = index;
    this.isMiscellaneousImport = true;
    this.errMsg = "";
    this.documentList = [];
    this.documentsMiscListArray[index]
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) { 
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
            this.errMsg = 'File size is over the limit of 25 MB.';
            this.showErrorMsg = true;
            setTimeout(() => {
              this.showErrorMsg = false;
            }, 2000);
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);
            this.f.document_name.setValue(filename);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }

      
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {

        if (result.model.length > 0) {
          result.model.forEach((item) => {
 
            if(!this.isMiscellaneousImport){

              let obj2 = this.documentsListArray[this.currentIndex]; 
              obj2.fileExtension = item.fileType; 
              obj2.mimeType = item.mimeType;
              obj2.bFile = item.bFile;
              obj2.countryID = this.countryID;
              obj2.containerName = 'countrydocuments';            
              obj2.containerPath = this.countryName + '/' + this.segmentDetails.countryContentSegment ;
              obj2.documentName = obj2.documentType + '-' + this.getUniqueNumber();  
              obj2.isModified = true; 
              obj2.isMiscellaneous = false;
              
              this.documentsListArray[this.currentIndex] = obj2;
            }
            else{

              let obj2 = this.documentsMiscListArray[this.currentMiscIndex]; 
              obj2.fileExtension = item.fileType; 
              obj2.mimeType = item.mimeType;
              obj2.bFile = item.bFile;
              obj2.countryID = this.countryID;
              obj2.containerName = 'countrydocuments';            
              obj2.containerPath = this.countryName + '/' + this.segmentDetails.countryContentSegment ;
              obj2.documentName = obj2.documentType + '-' + this.getUniqueNumber();  
              obj2.isModified = true; 
              obj2.isMiscellaneous = true;
              obj2.showToClient = false;

              this.documentsMiscListArray[this.currentMiscIndex] = obj2;

            }
            
 
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }
  getUniqueNumber(): string {
    return uuidv4();
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
  }

  clickPreviewDocument1() {
    this._countryService.getCountryDocument<ResponseModel<CountryDocumentModel[]>>(this.countryDocument).subscribe(response => {
      if (response != null && response.code == "200") {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], { type: this.countryDocument.mimeType });
        file.size
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
      this.loading = false;
      this.showSpin = false;

    })
  }

  onFileClick(event, index) {
    this.currentIndex = index;
    this.errMsg = "";
    event.target.value = '';
  }

  onMiscFileClick(event, index) {
    this.currentMiscIndex = index;
    this.errMsg = "";
    event.target.value = '';
  }
  // upload file end

  clickSave2() {


    this.loading = true;
    this.errorMsg = ""; 
    this.documentList[0].documentTypeGUIDList = "";
    this.documentList[0].documentTypeGUIDList = this.f.document_type.value;
    this.documentList[0].documentDescription =  this.f.country_required_documnet_desc.value;
    this.documentList[0].isActive = this.isActive;
    this.documentList[0].documentName =this.f.document_name.value;
    // this.documentList.push(obj);


    this.isModified = true;

    this.errorMsg = this.documentList[0].documentName ? this.errorMsg  :  "Please Enter Document Name"   ;
    this.errorMsg = this.documentList[0].documentTypeGUIDList ? this.errorMsg  : "Please Select Document Type"   ;

    this.documentList[0].isActive =  this.f.isActive.value == 'false' ? false : true;

    if ( this.errorMsg != "" &&  this.errorMsg != null){
      this.loading = false;
      this.showErrorMsg = true;
      setTimeout(() => {
        this.showErrorMsg = false;
      }, 2000);
    }
    else{

      this._countryService.uploadCountryDocument<ResponseModel<string>>(this.documentList[0]).subscribe(response => {
        if (response.code == "200" && response.message == "") {
          this.loading = false;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.successMsg = 'Upload Successfull'
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);

        }
      }
      );
    }

  }

  clickUpdate(){

    let obj = new CountryDocumentModel();

    obj.countryDocumentGUID = this.countryDocument.countryDocumentGUID;
    obj.documentName = this.f.document_name.value;
    obj.fileExtension = this.countryDocument.fileExtension;
    // obj.fileSize = item.fileSize;
    obj.mimeType = this.countryDocument.mimeType;
    // obj.bFile = item.bFile;
    obj.countryID = this.countryID;
    obj.containerName = 'countrydocuments';
    obj.containerPath = this.icao;
    obj.fileSize = this.countryDocument.fileSize;


    obj.documentDescription =  this.f.country_required_documnet_desc.value  ;
    obj.documentTypeGUIDList =  this.f.document_type.value;


    obj.isActive = this.f.isActive.value == 'false' ? false : true;

    if(this.country_segment_edit_dialog_form.invalid){
      this.errorMsg = "Fill All The Required Fields Correctly";
      this.showErrorMsg = true;
      return;
    }
    else{
      this.errorMsg = "";
      this.showErrorMsg = false;
    }

    this.isModified = true;

    this.errorMsg = obj.documentName ? "" :  "Please Enter Document Name"   ;
    this.errorMsg = obj.documentTypeGUIDList ? "" : "Please Select Document Type"   ;

    if ( this.errorMsg != "" &&  this.errorMsg != null){
      this.showErrorMsg = true;
      setTimeout(() => {
        this.showErrorMsg = false;
      }, 2000);
    }
    else{

      this.documentList.push(obj);

      this._countryService.uploadCountryDocument<ResponseModel<string>>(this.documentList[0]).subscribe(response => {
        if (response.code == "200" && response.message == "") {
          this.successMsg = this.isDelete == true? 'Deleted Succesfully' : 'Updated Successfull'
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;

            this.clickClose();
          }, 1000);

        }
      });
    }
  }

  clickDelete(){
    this.isActive = false;
    this.isDelete = true;
    this.clickUpdate();
  }




  setDocumentTypeList() {
    this.errMsg = "";
    this.avaiDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == false) === i
    );
    this.selDocumentTypeList = this.documentTypeList.filter(
      (thing, i, arr) => arr.findIndex(t => t.documentTypeGUID === thing.documentTypeGUID && t.selected == true) === i
    );
    this.selectedDocumentTypeGuid = this.selDocumentTypeList[0].documentTypeGUID;
    this.selectedDocumentTypeDescription = this.selDocumentTypeList[0].documentTypeDescription;
    this.f.document_type.setValue(this.selectedDocumentTypeGuid);
    this.setValidation(this.selectedDocumentTypeDescription);
  }

  docTypeChange(docType: any) {
    this.selectedDocumentTypeDescription = docType[docType.selectedIndex].text;
    this.setValidation(this.selectedDocumentTypeDescription);

  }

  setValidation(docType: string) {
    // this.dateLabel = "Effective Date"; 
  } 

  preventFocus(event: Event) {
    event.preventDefault(); 
  }


  addDocumentTypeRows(isMisc: boolean){

    this.loading = false;
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true; 
    dialogConfig.hasBackdrop = false; 
    let tempdocs = this.documentsListArray.filter(dla => dla.isActive == true)
    let tempMiscdocs = this.documentsMiscListArray.filter(dla => dla.isActive == true)
    dialogConfig.data = {
      dialogTitle: s,  countrySegmentContent:this.countrySegmentContent, selectedDocTypes: [],  //tempdocs, 
      userType: this.userType, isMisc: isMisc
    };

    const dialogRef = this.dialog.open(CountryRequiredPermitDocumentsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        
          result.forEach((element:DocumentTypeModel) => {
 
            let doc: CountryContentDocModel = new CountryContentDocModel();
            doc.documentTypeId = element.documentTypeID;
            doc.documentType = element.documentTypeDescription;
            doc.countryID = this.countryID;
            doc.countryContentSegmentId = this.segmentDetails.countryContentSegmentID;
            doc.RequiredDocId = 0;
            doc.isActive = true;
            doc.modifiedBy = this.username;
            doc.isModified = true;
            doc.isMiscellaneous = element.isMiscellaneous

            if(!element.isMiscellaneous){
              this.documentsListArray.push(doc);
              this.documentsListArray = [...this.documentsListArray];
            }
            else{
              doc.showToClient = false;
              this.documentsMiscListArray.push(doc);
              this.documentsMiscListArray = [...this.documentsMiscListArray];
            }

          });
 
          this.isDocsRequiredOnChange();
          this.isMiscDocsRequiredOnChange();
      }
    });
  }
 
 

  removeDocumentRow(element: any, index1) { // Replace 'any' with the actual type of your items
    const index = this.documentsListArray.indexOf(element);
    this.documentsListArray[index].isActive = false;
    this.documentsListArray[index].isModified = true;
    if (index !== -1 && this.documentsListArray[index].RequiredDocId == 0) {
      this.documentsListArray.splice(index, 1);
      this.documentsListArray = [...this.documentsListArray];

    }
    this.filteredDocArray = this.documentsListArray.filter(dl => dl.isActive == true);
  }

  removeMiscDocumentRow(element: any, index1) { // Replace 'any' with the actual type of your items
    const index = this.documentsMiscListArray.indexOf(element);
    this.documentsMiscListArray[index].isActive = false;
    this.documentsMiscListArray[index].isModified = true;
    if (index !== -1 && this.documentsMiscListArray[index].RequiredDocId == 0) {
      this.documentsMiscListArray.splice(index, 1);
      this.documentsMiscListArray = [...this.documentsMiscListArray];

    }
    this.filteredMiscDocArray = this.documentsMiscListArray.filter(dl => dl.isActive == true);
  }
  
  downloadDocument(doc: CountryContentDocModel){
    let countryDoc:CountryDocumentModel = new CountryDocumentModel();
    countryDoc.containerName = doc.containerName;
    countryDoc.containerPath = doc.containerPath;

    if(doc.bFile != null){
      let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
      let file = new Blob([byteArray], { type: doc.mimeType });
      saveAs(file, doc.documentName);
    }
    else{

      this._countryService.downloadCountryDoc<ResponseModel<string>>(countryDoc).subscribe(dc=>{
        if (dc.code == "200" && dc.model != '') {
          let byteArray = UtilityFunctions.base64ToArrayBuffer(dc.model);
          let file = new Blob([byteArray], { type: doc.mimeType });
          saveAs(file, doc.documentType);
        }
      });
    }
  } 

 

  clickPreviewDocument(doc: CountryContentDocModel) {
    let countryDoc: CountryDocumentModel = new CountryDocumentModel();
    countryDoc.containerName = doc.containerName;
    countryDoc.containerPath = doc.containerPath;

    if (doc.bFile != null) {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(doc.bFile);
      let file = new Blob([byteArray], { type: doc.mimeType });
      file.size
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    else {

      this._countryService.downloadCountryDoc<ResponseModel<string>>(countryDoc).subscribe(response => {
        if (response != null && response.code == "200") {
          let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
          let file = new Blob([byteArray], { type: doc.mimeType });
          file.size
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        this.loading = false;
        this.showSpin = false;

      });
    }
  }
 
  downloadAttachment(  item: CountryDocumentModel) {
    let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
    let file = new Blob([byteArray], { type: item.mimeType });
    saveAs(file, item.documentName);
  }

  downloadAllAttachments() {
    // Assuming documentsListArray is an array of objects with properties RequiredDocId and isactive
    const filteredArray = this.documentsListArray.filter(item => item.containerName != null && item.isActive === true);
  
    filteredArray.forEach(item => {
      this.downloadDocument(item);
    });
  }

  downloadAllMiscAttachments() {
    // Assuming documentsMiscListArray is an array of objects with properties RequiredDocId and isactive
    const filteredArray = this.documentsMiscListArray.filter(item => item.containerName != null && item.isActive === true);
  
    filteredArray.forEach(item => {
      this.downloadDocument(item);
    });
  }
  
  checkDocumentsExistence(): boolean{
    const filteredArray = this.documentsListArray.filter(item => item.containerName != null && item.isActive === true);
 
    if (filteredArray == undefined)
      return false
    if(filteredArray.length > 0){
      return true;
    }
    else return false;
  }

  checkMiscDocumentsExistence(): boolean{
    const filteredArray = this.documentsMiscListArray.filter(item => item.containerName != null && item.isActive === true);
 
    if (filteredArray == undefined)
      return false
    if(filteredArray.length > 0){
      return true;
    }
    else return false;
  }


  checkExistence(value: boolean, segName: string, segId: number, cat: string  ): boolean {
    let flag = false;
    let validList = this.countryData.countryContenCategoryList.filter(ctl => ctl.countryContentCategory.toLowerCase().includes(cat.toLowerCase()) && ctl.countryContentSegmentID === segId);
    
    this.excludeList = [this.overflightSec, this.leadTimeVar, this.dueDateVar, this.validTimeVar, this.fileVar, this.documentsVar,this.prefileFilingTimeVar, this.landingPrivateSec, this.landingCharterSec, this.overflightNavChargesSec, 'update']
    if (!([this.countryNotesSec].includes(segName.toLowerCase())) && value == true) {
      // Additional filtering for 'Main 4 segments'
      this.excludeList = this.excludeList.map(value => value.toLowerCase());
      validList = validList.filter(item => !this.excludeList.includes(item.countryContentCategory.toLowerCase()));
    }
    if (([this.countryNotesSec].includes(segName.toLowerCase())) && value == true) {
      // Additional filtering for this.countryNotesSec segment
      this.excludeList = this.excludeList.map(value => value.toLowerCase());
      validList = validList.filter(item => !this.excludeList.includes(item.countryContentCategory.toLowerCase()));
    }
  
    if (validList.length > 0) {
      flag = true;
    }
    return flag;
  }
  
  
  editCountryDocument(doc: CountryDocumentModel) {
    let s = "";
    let action = "";
    let countryDocumentGUID = "";
    if (doc == null) {
      s = "ADD New Record";
      action = "add";
    }
    else {
      s = "EDIT Record";
      action = "edit";
      countryDocumentGUID = doc.countryDocumentGUID;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    // dialogConfig.width = "52em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, countryID: this.countryID,   countryName: this.countryData.countryName,
      action: action,  countryDocumentGUID: countryDocumentGUID, document: doc
    };
    const dialogRef = this.dialog.open(CountryDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
        this.isModified = true;
      }

    });
  }
  
  getData() {
    this.isLoading = true;
    this._commonService.getCountryDetailsByCountryId<ResponseModel<CountryModel>>(this.countryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          // this.countryData = response.model; 
          // this.countryContent = this.countryData.countryContent; 
          this.countryDocuments = response.model.documentList;
          this.isLoading = false;
          this.getLastModified();

          // this.countryMapLink = "https://maps.google.com/maps?q=" + this.countryData.countryName.replace(" ", "+") + "&t=k&z=5&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
        }
      } 
    });  
 
    
  }

  removeDocument(element, index: number) {
     
    let obj2 = this.documentsListArray[index];
    // obj2.documentName = item.fileName;
    obj2.fileExtension = null;
    // obj2.fileSize = element.fileSize;
    obj2.mimeType = null;
    obj2.bFile = null;
    obj2.countryID = this.countryID;
    obj2.containerName = null;            
    obj2.containerPath = null ;
    obj2.documentName = null;
    obj2.isModified = true;
 
    //this.documentList.push(obj2);
    this.documentsListArray[index] = obj2;
    
  }
  
  removeMiscDocument(element, index: number) {
     
    let obj2 = this.documentsMiscListArray[index];
    // obj2.documentName = item.fileName;
    obj2.fileExtension = null;
    // obj2.fileSize = element.fileSize;
    obj2.mimeType = null;
    obj2.bFile = null;
    obj2.countryID = this.countryID;
    obj2.containerName = null;            
    obj2.containerPath = null ;
    obj2.documentName = null;
    obj2.isModified = true;
 
    //this.documentList.push(obj2);
    this.documentsMiscListArray[index] = obj2;
    
  }

  addDocumentNotes(element, index, internalNotes) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.width = "50em";//"1000px";
    //  dialogConfig.height = "28em";//"500px";
    // dialogConfig.minHeight = "35em";
    dialogConfig.maxHeight = "44em";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, countryID: this.countryID, documentName:element.documentType, countryData: this.countryData, internalNotes: internalNotes 
    };

    const dialogRef = this.dialog.open(CountryNotesAndDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result!= '') {
        //  this.ngOnInit();
        this.documentsListArray[index].internalNotes = result;
        this.documentsListArray[index].isModified = true;
      }
    });

  }

  addMiscDocumentNotes(element, index, internalNotes) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.width = "50em";//"1000px";
    //  dialogConfig.height = "28em";//"500px";
    // dialogConfig.minHeight = "35em";
    dialogConfig.maxHeight = "44em";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, countryID: this.countryID, documentName:element.documentType, countryData: this.countryData, internalNotes: internalNotes 
    };

    const dialogRef = this.dialog.open(CountryNotesAndDocumentsEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result!= '') {
        //  this.ngOnInit();
        this.documentsMiscListArray[index].internalNotes = result;
        this.documentsMiscListArray[index].isModified = true;
        this.documentsMiscListArray[index].showToClient = false;
      }
    });

  }

  changeShowToClientValue(element){
    element.isModified = true;
  }

  getLastModified(){
    this.isLoading = true;
    this._countryService.getLastModifiedForCountrySegment<ResponseModel<CountryContentModel>>(this.countryID, this.segmentDetails.countryContentSegmentID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          // this.countryData = response.model; 
          // this.countryContent = this.countryData.countryContent; 
          let alld  = response.model;
          this.lastModifiedBy = response.model.modifiedBy;
          this.lastModifiedDate = response.model.modifiedDate; 
          this.isLoading = false;
 
          // this.countryMapLink = "https://maps.google.com/maps?q=" + this.countryData.countryName.replace(" ", "+") + "&t=k&z=5&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
        }
      } 
    });  
 
  }

  isDateExpired(dateString: string): boolean {
    if(dateString != null)
      return new Date(dateString) < new Date(); 
   return false; 
  }

  isDocsRequiredOnChange( ){
    // this.isDis abled1 = this.isDis abled1 == true?false:true;
    // this.isDis abled1 = true;
    this.filteredDocArray = this.documentsListArray.filter(dl => dl.isActive == true);
    this.isDisabled1 = this.filteredDocArray.length>0 ? true: false;
    // this.f.isDocsRequired.setValue(this.isDocsRequired);

  }

  isMiscDocsRequiredOnChange( ){
    // this.isDis abled1 = this.isDis abled1 == true?false:true;
    // this.isDis abled1 = true;
    this.filteredMiscDocArray = this.documentsMiscListArray.filter(dl => dl.isActive == true);
    this.isDisabled2 = this.filteredMiscDocArray.length>0 ? true: false;
    // this.f.isDocsRequired.setValue(this.isDocsRequired);

  }
}
