<div style="background: #f8f8f8 !important; padding:1em; height: 100%; width: 100%; position: relative;">
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="d-inline-flex" style="width: 100%; justify-content: space-between; align-items: center;">
      <div style="display: flex; align-items: center; gap: 0.3em;">
        <span class="material-icons-outlined" style="font-size: 1.5em;">schedule</span>
        <h4 style="margin: 0; padding: 0;">
          <span *ngIf="isAdd">Add new Daylight Saving Entry</span>
          <span *ngIf="!isAdd">Edit Daylight Saving Entry</span>
        </h4>
      </div>
      <mat-icon (click)="clickClose()" class="pointer"
        style="cursor: pointer; font-size: 1.5em; margin-left: auto;">close</mat-icon>
    </div>
  </div>

  <form *ngIf="airport_daylightsaving_edit_form" [formGroup]="airport_daylightsaving_edit_form"
    style="display: flex; flex-direction: column; gap: 1em; flex-grow: 1;">
    <div style="background-color: white !important; height: 100%;">
      <div style="background-color: white; margin-left:1em; margin-right:1em; padding-bottom: 2em;">

        <div class="example-menu" style="justify-content: center;align-items: center;">
          <div style="display: flex; margin-left: 115px; margin-bottom: 1em; margin-top:1.5em; align-items: center;">
            Country
            <select formControlName="countryName" class="form-control form-control-sm dropdown"
              (change)="onCountryChange($event)" style="width: 18em; height: 2.2em;margin-left: 0.5em">
              <option value="" selected>-----</option>
              <option *ngFor="let country of countryList" [value]="country.countryName">{{country.countryName}}</option>
            </select>
            <div *ngIf="submitted && f.countryName.errors" class="alert alert-danger p-0 small"
              style="margin-bottom:  0em !important; margin-left: 9px;">
              <div *ngIf="f.countryName.errors.required">Required</div>
            </div>
          </div>

          <div style="display: flex; margin-left: 60px; margin-bottom: 1em; align-items: center;">
            State / Province
            <select formControlName="stateProvinceName" class="form-control form-control-sm dropdown"
              (change)="onStateChange($event)" style="width: 18em; height: 2.2em;margin-left: 0.5em">
              <option value="" selected>-----</option>
              <option *ngFor="let state of stateProvinceList" [value]="state.stateProvinceName">
                {{state.stateProvinceName}}</option>
            </select>
          </div>

          <div style="display: flex; margin-left: 135px; margin-bottom: 1em; align-items: center;">
            ICAO
            <input type="text" formControlName="icao" class="form-control inputBox"
              style="width: 5em; height: 2.2em;margin-left: 0.5em" />
          </div>

          <div style="display: flex; margin-left: 28px; margin-bottom: 1em; align-items: center;">
            Start Date / Time (L)
            <div style="display: flex; gap: 1em; align-items: center;margin-left: 0.5em">
              <input type="date" formControlName="startDate" class="form-control inputBox"
                style="width: 8em; height: 2.2em; gap: 1em" />
              <input type="text" formControlName="startTime" class="form-control inputBox"
                (blur)="timeChange('S', $event)" placeholder="HH:MM" maxlength="5" style="width: 5em; height: 2.2em;" />
            </div>
            <div *ngIf="submitted" class="alert alert-danger p-0 small"
              style="margin-bottom:  0em !important; margin-left: 9px;">
              <div *ngIf="f.startDate.errors?.required && f.startTime.errors?.required">Required</div>
              <div *ngIf="f.startDate.errors?.required && !f.startTime.errors?.required">Date Required</div>
              <div *ngIf="!f.startDate.errors?.required && f.startTime.errors?.required">Time Required</div>
            </div>
          </div>

          <div style="display: flex; margin-left: 33px; margin-bottom: 1em; align-items: center;">
            End Date / Time (L)
            <div style="display: flex; gap: 1em; align-items: center;margin-left: 0.5em">
              <input type="date" formControlName="endDate" class="form-control inputBox"
                style="width: 8em; height: 2.2em;" />
              <input type="text" formControlName="endTime" class="form-control inputBox"
                (blur)="timeChange('E', $event)" placeholder="HH:MM" maxlength="5" style="width: 5em; height: 2.2em;" />
            </div>
            <div *ngIf="submitted" class="alert alert-danger p-0 small"
              style="margin-bottom:  0em !important; margin-left: 9px;">
              <div *ngIf="f.endDate.errors?.required && f.endTime.errors?.required">Required</div>
              <div *ngIf="f.endDate.errors?.required && !f.endTime.errors?.required">Date Required</div>
              <div *ngIf="!f.endDate.errors?.required && f.endTime.errors?.required">Time Required</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-inline-flex" style=" margin-top: -0.5em;">
      <div
        style="justify-content: flex-start; padding-left: 1em; padding-top: 0.5em; padding-bottom: 0.5em; min-width: 10%;">
        <button formcontrolname="savebutton" [disabled]="!controlValueChanged   ? 'disabled' : null" mat-button
          class="btn-high" (click)="clickSave()">+ Save</button>&nbsp;

        <button mat-button class="btn-low" (click)="clickClose()">
          <span style="margin-right: 0.2em; font-size: 17px; margin-top: -0.2em;"
            class="material-symbols-outlined">close</span>Close</button>&nbsp;
      </div>
      <div class="mt-2">
        <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display: inline-block; white-space: pre-wrap;">
          <label style="font-size: small; padding: 0em; margin-left: 0em;">{{errMsg}}</label>
        </div>
        <div *ngIf="showSuccessMsg" class="alert p-0"
          style="display: inline-block; white-space: pre-wrap; color: green;">
          <label style="font-size: small; padding: 0em; margin: 0em;">{{successMsg}}</label>

        </div>
      </div>
      <div *ngIf="!isAdd"
        style="position: absolute; bottom: 1em; right: 1em; display: flex; align-items: center; gap: 0.5em;">
        <div style="display: flex; flex-direction: column; align-items: flex-start;">
          <label style="font-size: small; margin-bottom: 0 !important;">Disable Record</label>
          <select formControlName="isDisableRecord" class="form-control form-control-sm dropdown"
            style="width: 60px; height: 20px;">
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
        <div style="text-align: left;">
          <span style="display: block; font-size: small;">Modified By: {{modifiedBy}}</span>
          <span style="display: block; font-size: small;">Modified Date: {{modifiedDate}}</span>
        </div>
      </div>
    </div>
  </form>
</div>