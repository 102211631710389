import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { ServiceClassModel } from '../models/service-class.model';
import { ServiceTypeModel } from '../models/service-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { UserModel } from '../models/user.model';
import { IdentityService } from '../services/identity.service';
import { IdentityRequestModel } from '../models/identity-request.model';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { CustomPageSizeOptionModel } from '../models/custom-page-size-option.model';
import { Title } from '@angular/platform-browser';
import { FuelTaskEditDialogComponent } from '../fuel-quote/fuel-task-edit-dialog.component';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from '../ground-stop-advanced/task-revision-required-edit-dialog.component';
import { FuelMenuComponent } from './fuel-menu.component';
import { AirportLocatorComponent } from '../airports/airport-locator.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Sort } from '@angular/material/sort';
import { InternalGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-ground-stop-task-edit-dialog.component';
import { AirportBriefComponent } from '../trip-planner/airport-brief.component';
import { AirportModel } from '../models/airport.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';



@Component({
  selector: 'app-fuel-task-queuelist',
  templateUrl: './fuel-task-queuelist.component.html',
  styleUrls: ['./fuel-task-queuelist.component.css'],
  //providers: [
  //  { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  //],
})

export class FuelTaskQueuelistComponent implements OnInit, OnDestroy {
  userType: string;
  customerGUID: string;
  fuel_task_queue_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  v: number = this._authService.getCurrentTimeNumber();
  totalRecordsText: string;
  recordList: GroundStopTaskModel[];
  //displayedColumn: string[] = ['service', 'businessStop', 'leadtime', 'duedate','arrival', 'departure','registration','picName','tripCode', 'clien tName', 'action'];
  //displayedColumn: string[] = ['leadtime_duedate', 'service', 'registration', 'departure','ete', 'arrival',  'internalNote', 'tripCode','clientRef', 'assigned', 'action'];
  displayedColumn: string[] = ['leadtime_duedate', 'service', 'requotedate',  'arricao', 'arrdatetime',   'depdatetime',   'fsAndIPA', 'registration',  'tripCode', 'internalNote',   'action'];
  displayedColumn2: string[] = ['blank1', 'departure', 'arrival', 'blank2', 'blank3']
  date_type: string;
  service_type: string;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  serviceClassList: ServiceClassModel[];
  selectedServiceClassList: ServiceClassModel[];
  orgServiceTypeList: ServiceTypeModel[];
  serviceTypeList: ServiceTypeModel[];
  userServiceTypeList: string;
  selectedServiceTypeList: ServiceTypeModel[];
  selectedServiceTypeList2: ServiceTypeModel[];
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  taskStatusList: TripTaskStatusModel[];
  userTaskStatusList: string;
  selectedTaskStatusList: TripTaskStatusModel[];
  text_search: string;
  filter_by: string;
  includeClosedBilledRecords: boolean;
  includeDemo: boolean;
  showServiceFilter: boolean = false;
  selectedServiceCountText: string = "";
  showTaskStatusFilter: boolean = false;
  selectedStatusCountText: string = "";
  //selectedTaskStatusTitle: string;
  upPII: boolean = false;
  totalRecords: number = 0;
  pageSize: number = 50;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  customPageSizeOptionList: CustomPageSizeOptionModel[] = [];
  minPageSize: number = 50;
  maxPageSize: number = 100;
  hideOnHold: boolean = true;
  subcription: Subscription;
  searchByList: string[] =[];
  originalRecordList: GroundStopTaskModel[] = [];
  isSearchOptionChange: boolean = false;
  // filteredRecordList: GroundStopTaskModel[] = [];
  //orgRecordList: GroundStopTaskModel[] = [];

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    [Breakpoints.Medium, 'medium'],
    [Breakpoints.Large, 'large'],
    [Breakpoints.XLarge, 'xLarge'],
  ]);
  destroyed = new Subject<void>();
  currentScreenSize: string;
  taskRefresh: any;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,  
    private readonly _identityService:IdentityService, private _snackBar: MatSnackBar, private _titleService:Title  , breakpointObserver: BreakpointObserver) {
      breakpointObserver
        .observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
          Breakpoints.Medium,
          Breakpoints.Large,
          Breakpoints.XLarge,
        ])
        .pipe(takeUntil(this.destroyed))
        .subscribe(result => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.currentScreenSize = this.displayNameMap.get(query) ?? 'unknown';
            }
          }
        });

  }
    ngOnDestroy(): void {
      clearInterval(this.taskRefresh);
    }

  getAllData(): Observable<any[]> {
    //let getClientListResponse;
    //if (this.userType == "internal") {
    //  getClientListResponse = this._groundStopService.getClientListWithTrip();     
    //}
    //else {
    //  getClientListResponse = of(null);

    //}
    let getServiceClass = this._groundStopAdvancedService.getServiceClassList();
    let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);

    return forkJoin([getServiceClass, getService, getTaskStatusResponse, userResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.recordList = [];
    this.date_type = 'arrivaldate'; //'leadtime';  
    this.service_type = '56,57,101'; //'leadtime';  
    this.customerGUID = "";
    /*this.startDate = new Date();*/
    //this.startDate.setDate(this.startDate.getDate() - 2);
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 7);
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();    
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 336 * 60 * 60 * 1000;  // 2 weeks back
    let hendnumber: number = 672 * 60 * 60 * 1000;    // 4 weeks front
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;   
    let m1: number = dt1.getMinutes();
    let m1s: string=m1.toString();
    if (m1 < 10) {
      m1s = "0" + m1.toString();
    }
    //this.startTime = dt1.getHours().toString() + ":" + m1s;
    let m2: number = dt2.getMinutes();
    let m2s: string = m2.toString();
    if (m2 < 10) {
      m2s = "0" + m2.toString();
    }
    //this.endTime = dt2.getHours().toString() + ":" + m2s;
    this.startTime = "0:00";
    this.endTime = "23:59";
    this.serviceClassList = [];
    this.selectedServiceClassList = [];
    this.orgServiceTypeList = [];
    this.serviceTypeList = [];
    this.selectedServiceTypeList = [];
    this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    this.filter_by = "customerName";
    this.text_search = "";
    this.customPageSizeOptionList = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
  
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.serviceClassList = responses[0].model;
          this.serviceClassList = this.serviceClassList.filter(x => x.serviceClassID != 0);
          //this.serviceClassList.forEach(x => {
          //  x.selected = true;
          //});
          this.setServiceClassList();
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.orgServiceTypeList = responses[1].model;
          this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
          this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
          //this.serviceTypeList.forEach(x => {
          //  x.selected = true;
          //  x.serviceClassSelected = true;
          //});
          this.setServiceTypeList(false);
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.taskStatusList = responses[2].model;
          this.taskStatusList.forEach(x => {
            if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
              x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
              x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
              x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
              x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
              x.selected = true;
            }
                          
          });

          this.setTaskStatusList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          let user = responses[3].model;
          this.userServiceTypeList = user.taskQueueServiceIDList;
          if (user.taskQueueServiceIDList != "") {
            this.serviceTypeList.forEach(x => {
              if ((","+user.taskQueueServiceIDList+",").indexOf(","+x.serviceTypeID.toString()+",") > -1) {
                x.selected = true;
              }
            });
            this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
          }
          this.userTaskStatusList = user.taskQueueStatusList;
          if (user.taskQueueStatusList != "") {
            this.taskStatusList.forEach(x => {
              if ((user.taskQueueStatusList).indexOf(x.taskStatusGUID) > -1) {
                x.selected = true;
              }
              else {
                x.selected = false;
              }
            });
            this.setTaskStatusList();
          }          
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      this.initControls();
      if (this.selectedServiceTypeList != null) {
        if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
          this.selectedServiceCountText = ": All";
        else {
          if (this.selectedServiceTypeList.length == 0)
            this.selectedServiceCountText = "";
          else
            this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
        }
      }
      else
        this.selectedServiceCountText = ": All";
      if (this.selectedTaskStatusList != null) {
        if (this.selectedTaskStatusList.length == this.taskStatusList.length)
          this.selectedStatusCountText = ": All";
        else {
          if (this.selectedTaskStatusList.length == 0)
            this.selectedStatusCountText = "";
          else
            this.selectedStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
        }
      }
      else
        this.selectedStatusCountText = ": All";
      this.getData();

      // this.setupRefreshInterval();

    });


  }

  initControls() {
    this.fuel_task_queue_form = this._formBuilder.group({
      date_type: [this.date_type],
      service_type: [this.service_type],
      startDate: [this.startDate, Validators.required],
      endDate: [this.endDate, Validators.required],
      startTime: [this.startTime],
      endTime: [this.endTime],
      text_search: [this.text_search],
      filter_by: [this.filter_by],
      includeClosedBilledRecords: [this.includeClosedBilledRecords],
      includeDemo: [false],
      filterTripRevision: [false],
      includeTBATask: [false],
      custom_page_size_select1:[this.pageSize],
      custom_page_size_select2: [this.pageSize],
      hideOnHold: [this.hideOnHold]

    });

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
  }

  get f() { return this.fuel_task_queue_form.controls; }

  dateTypeChange(e: any) {
    this.getData();
  }

  dateOnchange(dateType: string, e: any) {
   if (dateType == "S") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidStartDate = false;
          this.f.startDate.setErrors({ isValidDate: false });
        }
        else {
          this.isValidStartDate = true;
        }
      }
      else {
        this.isValidStartDate = true;
      }
    }

    if (dateType == "E") {
      if (e.target._elementRef.nativeElement.value != "") {
        if (e.target.value == null) {
          this.isValidEndDate = false;
          this.f.endDate.setErrors({ isValidDate: false })
        }
        else {
          this.isValidEndDate = true;
        }
      }
      else {
        this.isValidEndDate = true;
      }
    }

  }

  timeChange(dateType: string, e: any) {
    if (dateType == "S") {
      if (e.target.value == "") {
        this.f.startTime.setErrors(null);
        this.f.startTime.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);        
        if (!timeObj.isValidDateTime) {
          this.f.startTime.setErrors({ pattern: true });
        }
        else {
          this.f.startTime.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;          
        }
      }
    }
    if (dateType == "E") {
      if (e.target.value == "") {
        this.f.endTime.setErrors(null);
        this.f.endTime.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        if (!timeObj.isValidDateTime) {
          this.f.endTime.setErrors({ pattern: true });
        }
        else {
          this.f.endTime.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;
        }
      }
    }
  }

  onServiceClassChange(e: any, item: ServiceClassModel) {
    item.selected = e.checked;
    this.setServiceClassList();
    if (this.selectedServiceClassList.length == 0) {
      this.orgServiceTypeList.forEach(x => {
        x.serviceClassSelected = false;
        x.selected = false;
      });
      this.setServiceTypeList(false);
    }
    else {
      this.orgServiceTypeList.forEach(x => {
        if (item.serviceClassID == x.serviceClassID) {
          x.serviceClassSelected = item.selected;
          x.selected = false;// item.selected;
        }
      });
      this.setServiceTypeList(true);
    }
    //this.checkServiceTypeArray = new FormControl(this.selectedServiceTypeList); // for mat-select


  }

  onServiceTypeChange(e: any, item: ServiceTypeModel) {
    //item.selected = e.source.selected;
    item.selected = e.checked;
    //this.setServiceTypeList();
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.updateAllComplete(item.filterGroup);

  }

  setServiceClassList() {
    this.selectedServiceClassList = this.serviceClassList.filter(u => u.selected == true);
  }

  setServiceTypeList(selected: boolean) {
    if (this.selectedServiceClassList.length == 0) {
      this.serviceTypeList = this.orgServiceTypeList.filter(x => x.filterGroup != null);
      this.serviceTypeList.sort((a, b) => a.filterGroup - b.filterGroup || a.filterColumn - b.filterColumn || a.filterOrderBy - b.filterOrderBy);
      this.serviceTypeList.forEach(x => {
        x.selected = false;
        x.serviceClassSelected = false;
      });
    }
    else {
      this.serviceTypeList = this.orgServiceTypeList.filter(u => u.serviceClassSelected == selected);
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);

    this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    this.updateAllComplete(1);
    this.updateAllComplete(2);
  }

  setTaskStatusList() {
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
    //this.selectedTaskStatusTitle = this.selectedTaskStatusList.map(function (item) { return item.taskStatusDescription }).join(",");

    this.updateAllCompleteStatus();
  }

  onTaskStatusChange(e: any, item: TripTaskStatusModel) {

    //if (this.matCheckInitialLoad == false) {
    item.selected = e.checked;
    this.setTaskStatusList();
    //}
  }

  filterTripRevisionChange(e: any) {
    if (e.checked) {
      this.serviceClassList.forEach(x => {
        x.selected = false;
      });
      this.setServiceClassList();
      this.setServiceTypeList(false);
      this.taskStatusList.forEach(x => {
        x.selected = false;
      });
      this.setTaskStatusList();
      this.f.text_search.setValue('');
      this.fuel_task_queue_form.get('text_search').disable();
      this.fuel_task_queue_form.get('filter_by').disable();
    }
    else {
      this.fuel_task_queue_form.get('text_search').enable();
      this.fuel_task_queue_form.get('filter_by').enable();
    }
  }

  clickServiceType(e: any, gstItem: GroundStopTaskModel) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "54em";
    dialogConfig.hasBackdrop = false;
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1   || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      dialogConfig.minHeight = "37em";
    }
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;

    let leg: string = "";
    leg = gstItem.departureICAO;
    leg += "-" + gstItem.arrivalICAO;
    
    dialogConfig.data = {
      groundStopGUID: gstItem.groundStopGUID, tripCodeGUID: gstItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: gstItem.countryGUID, icao: gstItem.icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: gstItem.customerGUID, leg: leg, nextGroundStopGUID: gstItem.arrivalGroundStopGUID,
      depGroundStopGUID: gstItem.departureGroundStopGUID
    };

    //if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70 && gstItem.serviceTypeID != 44 && gstItem.serviceTypeID != 45 && gstItem.serviceTypeID != 46
    //  && gstItem.serviceTypeID != 48 && gstItem.serviceTypeID != 49 && gstItem.serviceTypeID != 60 && gstItem.serviceTypeID != 61 && gstItem.serviceTypeID != 62) {
    if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70) {
      let dialogRef;
      if (gstItem.serviceTypeID == 57) {
        dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);

        if (gstItem.relatedAirportICAO != "" && gstItem.relatedAirportICAO != null && gstItem.additionalDescription == "") {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("Select ICAO for fuel quote:", false, gstItem.icao, gstItem.relatedAirportICAO, "Select ICAO");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();

            dialogConfig.data = {
              groundStopGUID: gstItem.groundStopGUID, tripCodeGUID: gstItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
              serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: gstItem.countryGUID, icao: gstItem.icao,
              serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
              customerGUID: gstItem.customerGUID, leg: leg, nextGroundStopGUID: gstItem.arrivalGroundStopGUID,
              depGroundStopGUID: gstItem.departureGroundStopGUID,additionalDescription: result ? gstItem.icao : gstItem.relatedAirportICAO
            }
            const fuelDialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);

            fuelDialogRef.afterClosed().subscribe(result => {
              if (result.isModified) {
                this.getData();
              }
            });
          });
        }
        else
          dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);






      }
      else {
        dialogRef = this._dialog.open(InternalGroundStopTaskEditDialogComponent, dialogConfig);
      }
    
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getData();
        }
      });
    }
   

  }

  clickTripRevisionRequired(e: any, gstItem: GroundStopTaskModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, groundStopGUID: gstItem.groundStopGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, isRevisionRequiredValue: gstItem.isRevisionRequiredValue,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TaskRevisionRequiredEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.getData();

      }

    });

  }

  getData() {
    this._authService.updateAccessTime();
    if (this.subcription)
      this.subcription.unsubscribe();
    if (this.fuel_task_queue_form.invalid == true) {
      return;
    }
    this.showServiceFilter = false;
    this.showTaskStatusFilter = false;
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new TripModel();
    request.searchDateType = this.f.date_type.value;
    request.startDate = this.f.startDate.value;
    request.startDateText = CustomValidators.formatDateToMMDDYYYY(request.startDate);
    //let templocal1: Date = new Date(this.f.startDate.value);
    //let temputc1: Date;
    //temputc1 = new Date(templocal1.getUTCFullYear(), templocal1.getUTCMonth(), templocal1.getUTCDate(), templocal1.getUTCHours(), templocal1.getUTCMinutes(), templocal1.getUTCSeconds());
    //request.startDateText = CustomValidators.formatDateToMMDDYYYY(temputc1);

    request.endDate = this.f.endDate.value;
    request.endDateText = CustomValidators.formatDateToMMDDYYYY(request.endDate);
    //let templocal2: Date = new Date(this.f.endDate.value);
    //let temputc2: Date;
    //temputc2 = new Date(templocal2.getUTCFullYear(), templocal2.getUTCMonth(), templocal2.getUTCDate(), templocal2.getUTCHours(), templocal2.getUTCMinutes(), templocal2.getUTCSeconds());
    //request.endDateText = CustomValidators.formatDateToMMDDYYYY(temputc2);


    // if (this.f.startTime.value == "") {
    //   request.startDateText = request.startDateText + " 0:00"
    // }
    // else {
    //   request.startDateText = request.startDateText + " " + this.f.startTime.value;
    // }
    // if (this.f.endTime.value == "") {
    //   request.endDateText = request.endDateText + " 23:59"
    // }
    // else {
    //   request.endDateText = request.endDateText + " " + this.f.endTime.value;
    // } 
    request.startDateText = request.startDateText + " 0:00" 
    request.endDateText = request.endDateText + " 23:59"
    
    request.serviceTypeIDList = "";
    if (this.selectedServiceTypeList.length > 0) {
      request.serviceTypeIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    }
    else {
      if (this.selectedServiceClassList.length > 0) {
        request.serviceTypeIDList = Array.prototype.map.call(this.serviceTypeList, s => s.serviceTypeID).toString();
      }
    }
    request.serviceTypeIDList = this.f.service_type.value;


    request.tripTaskStatusGUIDList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    
    request.filterBy = this.f.filter_by.value;
    // if(!this.isSearchOptionChange){
    //   this.f.text_search.setValue('');

    // }
    // else{
      
    // }
    // request.textSearch = this.f.text_search.value;
    request.textSearch = '';

    if (this.f.includeClosedBilledRecords.value == true) {
      request.tripCloseOutID = 0;
    }
    else {
      request.tripCloseOutID = 1;
    }
    request.includeDemo = false;
    if (this.f.includeDemo.value == true) {
      request.includeDemo = this.f.includeDemo.value;
    }
    request.filterTripRevision = false;
    if (this.f.filterTripRevision.value == true) {
      request.filterTripRevision = this.f.filterTripRevision.value;
    }
    request.includeTBATask = false;
    if (this.f.includeTBATask.value == true) {
      request.includeTBATask = this.f.includeTBATask.value;
    }
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.hideOnHold = this.hideOnHold;
    //request.totalRecords = this.totalRecords;

    // this.f.text_search.setValue('');
    this.subcription = this._groundStopAdvancedService.getFuelTaskQueueListByConditionsPage<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
      /*this.orgRecordList = [];*/
      this.recordList = [];
      this.totalRecordsText = "";
      this.totalRecords = 0;
      this.totalPageNumber = 0;
      this.date_type = request.searchDateType;
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          //this.orgRecordList = response.model;
          
          this.recordList = response.model;
          // this.searchByList = Array.prototype.map.call(this.recordList, s => s.customerName);
          // if(this.searchByList?.length == 0){
            // }
            this.sortItemsByArrivalDate();

            this.originalRecordList = this.recordList;

            // this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.customerName);
            // this.searchByList = [...new Set(this.searchByList)];
            // this.searchByList = this.searchByList.filter(sl => sl != '' && sl != null);

            this.onfilterTypeChange();


          

          this.totalRecords = this.recordList[0].totalRecords;
          this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize);
          this.recordList.forEach(x => {
            x.errMsg = "";
            x.loading = false;
          });
          if (this.recordList.length > 1) {
            this.totalRecordsText = this.totalRecords.toString() + " Records Found";
          }
          else {
            this.totalRecordsText = this.totalRecords.toString() + " Record Found";
          }
          this.buildCustomPageSizeOption();
        }
        else {
          this.totalRecordsText = "No record found";
          this.originalRecordList = [];
          this.recordList = [];
          this.searchByList = [];
        }
         
        this.showSpin = false;
        //this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText });
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;

      if( this.f.text_search.value != ''){

        // this.recordList = this.originalRecordList.filter(rl => rl.customerName == this.f.text_search.value );
        this.onSearchByOptionChange();
      }
      else{
        this.recordList = this.originalRecordList;
      }

    });

    
  }

  clickAssignMe(e: any, item: GroundStopTaskModel) {
    if (item.loading == false)
      item.loading = true;
    else
      return;

    let req = new GroundStopTaskModel();
    req.isAssignMe = false;
    req.groundStopTaskGUID=item.groundStopTaskGUID
    if (item.assignMe == "") {
      req.isAssignMe = true;
    }

    this._groundStopAdvancedService.updateGroundStopTaskAssignMeBygstId<ResponseModel<GroundStopTaskModel>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model!=null) {
          let obj = new GroundStopTaskModel();
          obj = response.model;
          if (obj.isAssignMe) {
            item.assignMe = obj.assignMe;
          }

        }
        else {
          item.errMsg="Failed to update the task"
        }
        item.loading = false;
        
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the task.";
        }
      }
      item.loading = false;
    });

  }

  clickReset() {
    this._authService.updateAccessTime();
    this.showServiceFilter = false;
    this.showTaskStatusFilter = false;
    this.errMsg = "";
    this.msg = "";
    this.totalRecordsText = "";
    this.recordList = [];
    this.date_type = 'arrivaldate'; //'leadtime';  
    this.f.date_type.setValue('arrivaldate');
    this.service_type = '56,57,101'; //'leadtime';  
    this.customerGUID = "";
   
    //this.startDate = new Date();

    //this.startDate.setDate(this.startDate.getDate() - 7);
    //this.endDate = new Date();
    //this.endDate.setDate(this.endDate.getDate() + 14);
    //this.f.date_type.setValue(this.date_type);
    let d = new Date();
    let uyear: number = d.getUTCFullYear();
    let umonth: number = d.getUTCMonth();
    let udate: number = d.getUTCDate();
    let uhour: number = d.getUTCHours();
    let uminute: number = d.getUTCMinutes();
    let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
    let hstartnumber: number = 336 * 60 * 60 * 1000;
    let hendnumber: number = 672 * 60 * 60 * 1000;
    let dt1: Date = new Date(d1.getTime() - hstartnumber);
    let dt2: Date = new Date(d1.getTime() + hendnumber);
    this.startDate = dt1;
    this.endDate = dt2;
    let m1: number = dt1.getMinutes();
    let m1s: string = m1.toString();
    if (m1 < 10) {
      m1s = "0" + m1.toString();
    }
    //this.startTime = dt1.getHours().toString() + ":" + m1s;
    let m2: number = dt2.getMinutes();
    let m2s: string = m2.toString();
    if (m2 < 10) {
      m2s = "0" + m2.toString();
    }
    //this.endTime = dt2.getHours().toString() + ":" + m2s;
    this.startTime = "0:00";
    this.endTime = "23:59";
    this.f.startDate.setValue(this.startDate);
    this.f.endDate.setValue(this.endDate);
    this.f.startTime.setValue(this.startTime);
    this.f.endTime.setValue(this.endTime);
    this.selectedServiceClassList = [];
    //this.orgServiceTypeList = [];
    //this.serviceTypeList = [];
    this.selectedServiceTypeList = [];
    //this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    this.filter_by = "customerName";
    this.f.filter_by.setValue(this.filter_by);
    this.text_search = "";
    this.serviceClassList.forEach(x => {
      x.selected = false;
    });
    this.setServiceClassList();
    this.serviceTypeList.forEach(x => {
      x.selected = false;
      x.serviceClassSelected = false;
    });
    //this.setServiceTypeList(false);
    //this.taskStatusList.forEach(x => {
    //  if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
    //    x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
    //    x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
    //    x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
    //    x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
    //    x.selected = true;
    //  }
    //});
    if (this.userServiceTypeList != "") {
      this.serviceTypeList.forEach(x => {
        if (("," + this.userServiceTypeList + ",").indexOf("," + x.serviceTypeID.toString() + ",") > -1) {
          x.selected = true;
        }
        else {
          x.selected = false;
        }
      });
      this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected);
      
    }
    else
      this.setServiceTypeList(false);
    this.updateAllComplete(1);
    this.updateAllComplete(2);
    if (this.userTaskStatusList != "") {
      this.taskStatusList.forEach(x => {
        if ((this.userTaskStatusList).indexOf(x.taskStatusGUID) > -1) {
          x.selected = true;
        }
        else {
          x.selected = false;
        }
      });
    }
    else {
      this.taskStatusList.forEach(x => {
        if (x.taskStatusGUID.toLowerCase() == 'b9ef8633-56f4-4fc7-9367-f7bd7760bf4a' ||
          x.taskStatusGUID.toLowerCase() == 'f083b51b-075e-4e5e-a59c-6559c6535a17' ||
          x.taskStatusGUID.toLowerCase() == '144b6b7d-94cb-44ff-bcf4-4006001c5ab6' ||
          x.taskStatusGUID.toLowerCase() == '3a966d43-3aa3-43e5-a3c3-6bc817df244f' ||
          x.taskStatusGUID.toLowerCase() == 'd07b2e7c-c070-4f2b-95f5-718936640a74') {
          x.selected = true;
        }
      });
    }
    this.setTaskStatusList(); 
    this.f.includeClosedBilledRecords.setValue(false);
    this.f.includeDemo.setValue(false);
    this.f.filterTripRevision.setValue(false);
    this.f.includeTBATask.setValue(false);
    this.f.text_search.setValue('');
    this.getData();

  }

  clickSubmit() {
    this.getData();

  }

  clickOpen(e: any, item: GroundStopTaskModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "tripId": item.tripCodeGUID,
    //    "aircraftId": item.aircraftGUID,
    //    "customerId": item.customerGUID,
    //    "pf": "",
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&gsId="+item.groundStopGUID+"&gstId="+item.groundStopTaskGUID+"&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }

  //clickVendorName(e: any, itemLeg: TripLegModel, itemVendor: VendorModel, stopType: string) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.disableClose = true;
  //  dialogConfig.panelClass = "custom-dialog-container";
  //  //    dialogConfig.maxWidth = "70em";
  //  let icao: string;
  //  if (stopType == "D") {
  //    icao = itemLeg.departureICAO;
  //  }
  //  else {
  //    icao = itemLeg.nextArrivalICAO;
  //  }

  //  dialogConfig.data = {
  //    vendorId: itemVendor.vendorGUID,
  //    icao: icao
  //  };
  //  this._dialog.open(VendorDetailsComponent, dialogConfig);
  //}

  //clickICAO(e: any, gs: TripLegModel, legType: string) {
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  //dialogConfig.disableClose = true;
  //  dialogConfig.panelClass = "custom-dialog-container";
  //  //    dialogConfig.maxWidth = "70em";
  //  if (legType == "D") {
  //    dialogConfig.data = {
  //      icao: gs.departureICAO,
  //      icaoIata: gs.departureICAO_IATA,
  //      groundStopGUID: gs.groundStopGUID,
  //      tripCodeGUID: gs.tripCodeGUID
  //    };
  //  }
  //  if (legType == "A") {
  //    dialogConfig.data = {
  //      icao: gs.nextArrivalICAO,
  //      icaoIata: gs.nextArrivalICAO_IATA,
  //      groundStopGUID: gs.nextArrivalGroundStopGUID,
  //      tripCodeGUID: gs.tripCodeGUID
  //    };
  //  }

  //  this._dialog.open(AirportDetailsComponent, dialogConfig);
  //}

  clickServiceFilter() {
    this.showServiceFilter = !this.showServiceFilter;
    this.showTaskStatusFilter = false;
  }
  clickTaskStatusFilter() {
    this.showTaskStatusFilter = !this.showTaskStatusFilter;
    this.showServiceFilter = false;
  }

  allCompleteOps: boolean = false;
  allCompleteWx: boolean = false;
  allCompleteStatus: boolean = false;

  updateAllComplete(group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = this.serviceTypeList.filter(x=>x.filterGroup==group).every(t => t.selected);
        break;
      case 2:
        this.allCompleteWx = this.serviceTypeList.filter(x => x.filterGroup == group).every(t => t.selected);
        break;
     
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length)
      this.selectedServiceCountText = ": All";
    else {
      if (this.selectedServiceTypeList.length == 0)
        this.selectedServiceCountText = "";
      else
        this.selectedServiceCountText = ": " + this.selectedServiceTypeList.length.toString();
    }
  }

  someComplete(group: number): boolean {
    switch (group) {
      case 1:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup==group).length > 0 && !this.allCompleteOps;
      case 2:
        return this.serviceTypeList.filter(t => t.selected && t.filterGroup == group).length > 0 && !this.allCompleteWx;     
    }
  }

  selectAllServiceType(completed: boolean, group: number) {
    switch (group) {
      case 1:
        this.allCompleteOps = completed;
        this.serviceTypeList.filter(x=>x.filterGroup==group).forEach(t => {
          t.selected = completed;
         
        });        
        break;
      case 2:
        this.allCompleteWx = completed;        
        this.serviceTypeList.filter(x => x.filterGroup == group).forEach(t => {
          t.selected = completed;
          
        });
        break;     
    }
    this.updateAllComplete(group);
  }

  clickResetServiceType() {
    this.serviceTypeList.every(t => t.selected = false);
    this.setServiceTypeList(false);
    this.showServiceFilter = false;
    this.currentPageIndex = 0;
    this.selectedServiceCountText = "";
    this.clickSubmit();
  }

  clickDoneServiceType() {
    this.showServiceFilter = false;
    this.currentPageIndex = 0;
    this.clickSubmit();
  }

  updateAllCompleteStatus() {
    this.allCompleteStatus = this.taskStatusList.every(t => t.selected);    
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    if (this.selectedTaskStatusList.length == this.taskStatusList.length)
      this.selectedStatusCountText = ": All";
    else {
      if (this.selectedTaskStatusList.length == 0)
        this.selectedStatusCountText = "";
      else
        this.selectedStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
    }
  }

  someCompleteStatus(): boolean {
    return this.taskStatusList.filter(t => t.selected).length > 0 && !this.allCompleteStatus;
    
  }

  selectAllTaskStatus(completed: boolean) {

    this.allCompleteStatus = completed;
    this.taskStatusList.forEach(t => {
      t.selected = completed;
    });
    this.updateAllCompleteStatus();
  }

  clickResetTaskStatus() {
    this.taskStatusList.forEach(t => t.selected = false);
    this.setTaskStatusList();
    this.showTaskStatusFilter = false;
    this.selectedStatusCountText="";
    this.clickSubmit();
  }

  clickDoneTaskStatus() {
    this.showTaskStatusFilter= false;
    this.clickSubmit();
  }

  clickSaveSelections(type: string){
    this._authService.updateAccessTime();    
    let request = new UserModel();    
    if (type.toLowerCase() == 'service') {
      request.taskQueueServiceIDList = "";
      request.taskQueueServiceIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
      request.taskQueueStatusList = null;
    }
    else {
      request.taskQueueStatusList = "";
      request.taskQueueServiceIDList = null;
      request.taskQueueStatusList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    }
    this._identityService.saveUserProfile<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this._snackBar.openFromComponent(SuccessComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            data: type,
            panelClass: ['green-snackbar']
          });
        
        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this._snackBar.open(response.message, '', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
              panelClass: ['red-snackbar']
            });
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this._snackBar.open('Error Saving', '', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 2000,
            panelClass: ['red-snackbar']
          });

        }
      }
    })
}

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    this.getData();

  }

  buildCustomPageSizeOption() {   
    this.customPageSizeOptionList = [];
    let obj: CustomPageSizeOptionModel;
    if (this.totalRecords > 0 && this.totalRecords > this.minPageSize) {
      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.minPageSize.toString();
      obj.pageSizeValue = this.minPageSize;
      this.customPageSizeOptionList.push(obj);

      obj = new CustomPageSizeOptionModel();
      obj.pageSizeText = this.maxPageSize.toString();
      obj.pageSizeValue = this.maxPageSize;
      this.customPageSizeOptionList.push(obj);

      if (this.totalRecords > this.maxPageSize) {
        obj = new CustomPageSizeOptionModel();
        obj.pageSizeText = "All";
        obj.pageSizeValue = 10000;
        this.customPageSizeOptionList.push(obj);
      }

    }
  }

  customPageSizeChange(e: any, controlNumber: number) {
    this.currentPageIndex = 0;
    if (controlNumber == 1) {
      this.pageSize = this.f.custom_page_size_select1.value;
      this.f.custom_page_size_select2.setValue(this.pageSize);
    }
    else {
      this.pageSize = this.f.custom_page_size_select2.value;
      this.f.custom_page_size_select1.setValue(this.pageSize);
    }
    this.getData();
  }
  
  setupRefreshInterval() {   
    this.taskRefresh = setInterval(() => { this.getData(); }, 2*60000); // 2 min
  }

  hideOnHoldChange(e: any) {
    this.hideOnHold = e.checked;
    this.getData();
  }

  
  serviceTypeChange2(e: any ) {

    
    // this.selectedServiceTypeList2 = this.serviceTypeList.filter(u => u.selected == true);
    // this.updateAllComplete(item.filterGroup);
    this.getData();

  }

  onSearchByOptionChange(){

    this.isSearchOptionChange = true;
    // this.getData();

    if( this.f.text_search.value != ''){

      this.recordList = this.originalRecordList.filter(rl => rl[this.f.filter_by.value] == this.f.text_search.value );
    }
    else{
      this.recordList = this.originalRecordList;
    }
  
  }

  onfilterTypeChange(){
    this.recordList = this.originalRecordList;

    switch (this.f.filter_by.value) {
      case 'customerName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.customerName);
        
        break;
      case 'tripCode':  this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.tripCode);
        
        break;
      case 'customerReference': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.customerReference);
        
        break;
      case 'registration': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.registration);
        
        break;
      case 'icao': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.icao);
        
        break;
      case 'airportCountryName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.airportCountryName);
        
        break;
      case 'fuelSupplierName': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.fuelSupplierName);
        
        break;
      case 'intoPlaneAgent': this.searchByList = Array.prototype.map.call(this.originalRecordList, s => s.intoPlaneAgent);
        
        break;
    
       
    }
    this.searchByList = [...new Set(this.searchByList)];

    this.searchByList = this.searchByList.filter(sl => sl != '' && sl != null);

  }

  
  clickRoute(routePage: string) {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "", v: this._authService.getCurrentTimeNumber() };
    // this.clickClose(); 
    // if(this.isDialog)
    //   this._dialogRef.close(true);

    this._dialog.open(FuelMenuComponent, config);   
  }

sortItemsByArrivalDate() {
    switch (this.f.date_type.value) {
        case 'duedate':
            this.recordList = this.recordList.sort((a, b) => {
                return (new Date(a.dueDateUTC)).getTime() - (new Date(b.dueDateUTC)).getTime();
            });
            break;
        case 'arrivaldate':
            this.recordList = this.recordList.sort((a, b) => {
                return (new Date(a.arrivalDateTimeUTC)).getTime() - (new Date(b.arrivalDateTimeUTC)).getTime();
            });
            break;
        case 'departuredate':
            this.recordList = this.recordList.sort((a, b) => {
                return (new Date(a.departureDateTimeUTC)).getTime() - (new Date(b.departureDateTimeUTC)).getTime();
            });
            break;
        case 'requote':
            this.recordList = this.recordList.sort((a, b) => {
                return (new Date(b.fQExpirationDateUTC)).getTime() - (new Date(a.fQExpirationDateUTC)).getTime();
            });
            break;
        default:
            break;
    }
}

  
  openAirportDetailsByIcao( e: any, element:  GroundStopTaskModel ){
    e.stopPropagation();

    //const config = new MatDialogConfig();
    //// config.panelClass = "custom-dialog-container" 
    //config.maxWidth = "92em";//"1000px";
    ////config.height = "40em";//"1000px";
    //config.autoFocus = true;
    //config.hasBackdrop = false;
    //config.autoFocus = true;

    //const inputElement = document.getElementById('myInput');

    ////  config.scrollStrategy = new NoopScrollStrategy();

    //config.data = { gsId: "", tripCodeId: "", fuelTaskIcao: element.arrivalICAO ,v: this._authService.getCurrentTimeNumber() };
    //const dialogRef = this._dialog.open(AirportLocatorComponent, config);
        
    //dialogRef.afterOpened().subscribe(() => {
    //  const inputElement = document.getElementById('myInput');
    //  if (inputElement) {
    //    inputElement.focus();
    //  }
    //});
    let airport = new AirportModel();
    airport.airportID = element.airportID;
    airport.latitude = element.latitude;
    airport.longitude = element.longitude;
    airport.icao = element.icao;
    airport.iata = element.iata;
    airport.countryId = element.countryID,
      airport.airportName = element.airportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: element.airportID,
      icao: element.icao,
      customerGUID: element.customerGUID,
      farTypeID: element.farTypeID,
      farType: element.farTypeDescription,
      route: '',
      handlerGUID: element.handlerGUID,
      ssnGUID: element.ssnguid,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  
  sortData(sort: Sort) {
    const data = this.recordList.slice();
    if (!sort.active || sort.direction === '') {
      this.recordList = data;
      return;
    }

    this.recordList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'trip_code':
          return compare(a.tripCode, b.tripCode, isAsc);
        case 'registration':
      return compare(a.registration, b.registration, isAsc);
        case 'depdatetime':
          return compare(new Date(a.departureDateTimeUTC), new Date(b.departureDateTimeUTC), isAsc);
        case 'leadtime_duedate':
          return compare(new Date(a.dueDateUTC), new Date(b.dueDateUTC), isAsc);
      
        case 'requotedate':
          return compare(new Date(a.fQExpirationDateUTC), new Date(b.fQExpirationDateUTC), isAsc);
      
        case 'arrdatetime':
          return compare(new Date(a.arrivalDateTimeUTC), new Date(b.arrivalDateTimeUTC), isAsc);
        default:
          return 0;
      }
    });

    function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }


  checkRequoteExpiry(reQuoteDate: Date, arrivalDate: Date, hasTaskDocument: boolean){
    if(reQuoteDate < arrivalDate && hasTaskDocument){
      return true;
    }
    else{
      return false;
    }

  }
 
}

@Component({
  selector: 'success-component',
  template: `<div class="small d-flex align-items-center">
    <i class= "material-icons">check_circle</i>
      <div>{{data}} Selections Saved</div>
</div>`,
  styles: [],
})
export class SuccessComponent { constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { } }
