<div style="background-color:#f8f8f8; overflow-y: hidden; padding-left:1em; padding-right:1em">
  <div style="display: flex; ">
    <div style="display: flex;padding-top:0.25em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div>
        <h5>Legend</h5>
      </div>
    </div>
  </div>
</div>
<div style="background-color: white;  padding: 0.5em 0.5em 0.5em 0.5em">
  <div *ngIf="legendType==''" style="display: flex;">
    <div style="margin-right:1em;margin-top:1.2em;">
      <div style="font-weight: bold;border-bottom:solid thin">Trip Status</div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_orange"
            style="border: none; font-size:x-large">new_releases</i></div>
        <div>New Trip</div>
      </div>
      <div style="display: flex">
        <div
          style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: lightblue">
        </div>
        <div>Estimates</div>
      </div>
      <div style="display: flex">
        <div
          style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: lightgreen">
        </div>
        <div>Consultation</div>
      </div>
    </div>
    <div>
      <div style="font-weight: bold">Operational Service Status</div>
      <div class="d-flex">
        <div *ngFor="let tg of taskGroupList" class="mr-2">
          <div style="font-size:small; font-weight:lighter;border-bottom:solid thin;border-color:lightgray;">
            {{tg.taskGroupDescription}}
          </div>
          <div style="margin-top:0.25em; font-size: small">
            <div *ngFor="let ts of tg.taskStatusList; let i=index">
              <div style="margin-top:0.1em;margin-left:0em; height:1.5em; padding:0em;display:flex ">
                <div style="border-radius: 50%; width: 1.125em; height: 1.125em; border: black solid 0.0625em;"
                  [style.background-color]="ts.hexColor"></div>
                <div style="margin-top:-0.25em; margin-left:0.25em; white-space:nowrap">{{ts.taskStatusDescription}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="legendType==''" class="d-flex mt-2">
    <div style="margin-right:1em">
      <div style="font-weight: bold;border-bottom:solid thin">Airport Status</div>
      <div style="display: flex">
        <div
          style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: orange">
        </div>
        <div>Cancellation</div>
      </div>
      <div style="display: flex">
        <div
          style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: yellow">
        </div>
        <div>Contingency</div>
      </div>
      <!--<div style="display: flex">
          <div style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: pink"></div>
          <div>On Hold</div>
        </div>-->
    </div>
    <div *ngIf="userType=='internal'" style="margin-right:1em">
      <div style="font-weight: bold;border-bottom:solid thin">Leg Status</div>
      <!--<div style="display: flex">
    <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone">radio_button_unchecked</i></div>
    <div>Leg Acknowledeged, in Queue</div>
  </div>
  <div style="display: flex">
    <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green">radio_button_checked</i></div>
    <div>Working on Services</div>
  </div>
  <div style="display: flex">
    <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green">circle</i></div>
    <div>All Services Ready, no issues found</div>
  </div>-->
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_orange" style="border: none; font-size:x-large">square</i>
        </div>
        <div>Attention Required (Prior to Due Date)</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_red" style="border: none; font-size:x-large">warning</i>
        </div>
        <div>Critical Attention Required (Past Due Date)</div>
      </div>
      <div style="display: flex; margin-left:-0.1em">
        <div
          style="margin-left:0.35em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: pink">
        </div>
        <div style="margin-left:0.3em">On Hold</div>
      </div>
    </div>

    <div style="margin-right:1em">
      <div style="font-weight: bold;border-bottom:solid thin">Alert Icons</div>
      <div class="d-inline-flex">
        <div style="margin-right:1em">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">manage_search</i></div>
            <div>Audit Trail</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em">
              <i class="material-icons-two-tone icon_orange"
                style="border: none; font-size:x-large">notifications_active</i>
            </div>
            <div>Revision Required</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em">
              <i class="material-icons-two-tone icon_orange"
                style="border: none; font-size:x-large">notifications_active</i>
            </div>
            <div>Missing Passport</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em">
              <i class="material-icons-two-tone icon_red"
                style="border: none; font-size:x-large">notifications_active</i>
            </div>
            <div>Critical Revision</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em">
              <i class="material-icons-two-tone icon_red" style="border: none; font-size:x-large">alarm_on</i>
            </div>
            <div>Immediate Action Required</div>
          </div>

        </div>

        <div style="margin-right:1em">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_red"
                style="border: none; font-size:x-large">assignment_ind<!--warning_amber--></i></div>
            <div>Minor Onboard</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_red2"
                style="border: none; font-size:x-large">people_alt</i></div>
            <div>PIC / SIC Changed</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_red2"
                style="border: none; font-size:x-large">change_circle</i></div>
            <div>ETE Changed</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green"
                style="border: none; font-size:x-large">content_copy</i></div>
            <div>Two Passports</div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <div *ngIf="legendType==''" class="d-flex mt-2">
    <div style="margin-right:1em">
      <div style="font-weight: bold;border-bottom:solid thin">Action Items</div>
      <div class="d-inline-flex">
        <div class="mr-2">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">flight_land</i></div>
            <div>Arrival Details</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">flight_takeoff</i></div>
            <div>Departure Details</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">airline_stops</i></div>
            <div>Business Stop</div>
          </div>
        </div>
        <div class="mr-2">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">settings</i></div>
            <div>Accounting Trip Closeout</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">task_alt</i></div>
            <div>Services at Business Stop</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">event_available</i></div>
            <div>Trip Itinerary Details</div>
          </div>
        </div>
        <div class="mr-2">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">manage_accounts</i></div>
            <div>Crew/PAX Manifest Details</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large">edit_location_alt</i></div>
            <div>Alternate Airports, ETOP and ETP</div>
          </div>
        </div>
        <div class="mr-2">
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large; transform: rotate(-45deg);">attachment</i></div>
            <div>Attachment</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.25em"><i class="material-icons-outlined"
                style="border: none; color: gray; font-size:x-large;">unpublished</i></div>
            <div>Disabled Task</div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="legendType=='t'" style="font-size: medium; margin-right: 2em;">
    <div style="display: flex">
      <div
        style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: orange">
      </div>
      <div>Cancellation</div>
    </div>
    <div style="display: flex">
      <div
        style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: yellow">
      </div>
      <div>Contingency</div>
    </div>
    <div style="display: flex">
      <div
        style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: lightblue">
      </div>
      <div>Estimates</div>
    </div>
    <div style="display: flex">
      <div
        style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: lightgreen">
      </div>
      <div>Consultation</div>
    </div>
    <div style="display: flex">
      <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_orange"
          style="border: none; font-size:x-large">new_releases</i></div>
      <div>New Trip</div>
    </div>
    <div style="display: flex">
      <div
        style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: pink">
      </div>
      <div>On Hold</div>
    </div>
  </div>
  <div class="d-flex">
    <div *ngIf="legendType=='legStatus'" style="margin-right: 2em">
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-outlined"
            style="border: none; color: gray; font-size:x-large">manage_search</i></div>
        <div>Audit Trail</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_orange"
            style="border: none; font-size:x-large">notifications_active</i>
        </div>
        <div>Revision Required</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_orange"
            style="border: none; font-size:x-large">notifications_active</i>
        </div>
        <div>Missing Passport</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_red" style="border: none; font-size:x-large">notifications_active</i>
        </div>
        <div>Critical Revision</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green"
            style="border: none; font-size:x-large">warning_amber</i></div>
        <div>Minor Onboard</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_red2"
            style="border: none; font-size:x-large">people_alt</i></div>
        <div>PIC / SIC Changed</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_red2"
            style="border: none; font-size:x-large">change_circle</i></div>
        <div>ETE Changed</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_orange"
            style="border: none; font-size:x-large">content_copy</i></div>
        <div>Two Passports</div>
      </div>
    </div>


    <div *ngIf="(legendType=='checkList' || legendType=='legStatus') && userType=='internal'" style="margin-right: 2em">
      <!--<div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone">radio_button_unchecked</i></div>
            <div>Leg Acknowledeged, in Queue</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green">radio_button_checked</i></div>
            <div>Working on Services</div>
          </div>
          <div style="display: flex">
            <div style="margin-right:0.5em; margin-top:0.15em"><i class="material-icons-two-tone icon_green">circle</i></div>
            <div>All Services Ready, no issues found</div>
          </div>-->
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_orange" style="border: none; font-size:x-large">square</i>
        </div>
        <div>Attention Required (Prior to Due Date)</div>
      </div>
      <div style="display: flex">
        <div style="margin-right:0.5em; margin-top:0.15em">
          <i class="material-icons-two-tone icon_red" style="border: none; font-size:x-large">warning</i>
        </div>
        <div>Critical Attention Required (Past Due Date)</div>
      </div>
      <div style="display: flex; margin-left:-0.1em">
        <i class="material-icons-two-tone pink" style="border: none; font-size:x-large">pause_circle</i>
        <div style="margin-left:0.3em">On Hold</div>
      </div>
    </div>
    <div *ngIf="(legendType=='weather') && userType=='internal'" style="margin-right: 2em">
      <div style="display: flex;">
        <div style="margin-right:1em;flex-direction: column;">
          <div style="font-weight: bold;border-bottom:solid thin">Temperature</div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: blue">
            </div>
            <div>Temperature &le; 0C or 32F</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: #E44949">
            </div>
            <div>Temperature &ge; 32C or 90F</div>
          </div>
        </div>
        <div style="margin-right:1em;flex-direction: column">
          <div style="font-weight: bold;border-bottom:solid thin">Visibility</div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: magenta">
            </div>
            <div>Less than 1 mile</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: #E44949">
            </div>
            <div>1 to 3 miles</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: blue">
            </div>
            <div>3 to 5 miles</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: green">
            </div>
            <div>6 miles or higher</div>
          </div>
        </div>
        <div style="margin-right:1em;flex-direction: column">
          <div style="font-weight: bold;border-bottom:solid thin">Ceiling</div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: magenta">
            </div>
            <div>Less than 500 ft</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: #E44949">
            </div>
            <div>500 ft to 1000 ft</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: blue">
            </div>
            <div>1000 ft to 3000 ft</div>
          </div>
          <div style="display: flex">
            <div
              style="margin-left:0.25em;margin-right:0.5em; margin-top:0.25em; width:1em; height:1em; background-color: green">
            </div>
            <div>3000 ft or higher</div>
          </div>
        </div>
        <div style="margin-right:1em;flex-direction: column">
          <div style="font-weight: bold;border-bottom:solid thin">Bolded Data</div>
          <div style="display: flex; align-items: center;">
            <i class="material-icons"
              style="color: black; font-size: 10px; margin-right: 0.5em;">fiber_manual_record</i>
            <div>Wind Speed &ge; 35 kt</div>
          </div>
          <div style="display: flex; align-items: center;">
            <i class="material-icons"
              style="color: black; font-size: 10px; margin-right: 0.5em;">fiber_manual_record</i>
            <div>Wind Gust &ge; 50 kt</div>
          </div>
          <div style="display: flex; align-items: center;">
            <i class="material-icons"
              style="color: black; font-size: 10px; margin-right: 0.5em;">fiber_manual_record</i>
            <div>POP &ge; 75</div>
          </div>
          <div style="display: flex; align-items: center;">
            <i class="material-icons"
              style="color: black; font-size: 10px; margin-right: 0.5em;">fiber_manual_record</i>
            <div>CProbThunder &ge; 50</div>
          </div>
          <div style="display: flex; align-items: center;">
            <i class="material-icons"
              style="color: black; font-size: 10px; margin-right: 0.5em;">fiber_manual_record</i>
            <div>CProbFreezingRain &ge; 30</div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <div style="width: 100%; margin-top: 1em; padding-top: 0.5em;">
          <div style="font-weight: bold; border-bottom: solid thin; text-align: center;">Abbreviations</div>
          <div style="display: flex; justify-content: space-between; padding: 1em; white-space: nowrap;">
            <div style="flex: 1; padding-right: 1em;">
              <ul style="list-style-type: disc; padding-left: 20px;">
                <li>Tmp - Temperature</li>
                <li>Dpt - Dew Point Temperature</li>
                <li>Dir - Wind Direction</li>
                <li>Spd - Wind Speed</li>
                <li>Gust - Wind Gust</li>
                <li>POP - Probability of Precipitation</li>
                <li>MSLP - Mean Sea Level Pressure</li>
                <li>TCC - Total Cloud Cover</li>
                
                
              </ul>
            </div>
            <div style="flex: 1; padding-left: 1em;">
              <ul style="list-style-type: disc; padding-left: 20px;">
                <li>QPF - Quantitative Precipitation Forecast</li>
                <li>Hourly Wx - Hourly weather</li>
                <li>SN - Snow Accumulation</li>
                <li>Vis - Visibility</li>
                <li>CProb TSRA - Conditional Probability Thunder</li>
                <li>CProb FZRA - Conditional Probability Freezing Rain</li>
                <li>z - UTC Time</li> 
                <li>LCL - Local Time</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="padding-top:0.5em; padding-bottom:0.5em;">
    <button mat-button class="btn-low" (click)="clickClose()">
      <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
        <div>Close</div>
      </div>
    </button>
  </div>
</div>