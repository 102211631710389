import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { FileItem, FileUploader, ParsedResponseHeaders, FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import * as DecoupledEditor from '../ckeditor5/ckeditor';
import { MessageAttachmentModel } from '../models/message-attachment';
import { MessageModel } from '../models/message.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { MessageService } from '../services/message.service';
import { IdentityService } from '../services/identity.service';
import { UserModel } from '../models/user.model';
import { IdentityRequestModel } from '../models/identity-request.model';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import { MatTableDataSource } from '@angular/material/table';
import { MessageEmbeddedImageModel } from '../models/message-embedded-image';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
// import { EmailAddressSelectionDialogComponent } from './email-address-selection-dialog.component';
import { EmailAddressSelectionClientDialogComponent } from './email-address-selection-client-dialog.component';
import { MessageLinkModel } from '../models/message-link';
import { DownloadDocumentsComponent } from '../messages/download-documents.component';
//import { EmailAddressSelectionDialogComponent } from './email-address-selection-dialog.component';
import { saveAs } from 'file-saver';

export interface LegGroup {
  tripLeg: string;
  taskList: any;
}
export interface TripGroup {
  tripCode: string;
  tripCodeGUID: string;
  aircraftGUID: string;
  custGUID: string;
  legList: LegGroup[];
}
export interface LinkGroup {
  message: string;
  messageGUID: string;
  custGUID: string;
  custName: string;
  tripList: TripGroup[]
  // tripCode: string;
  // tripCodeGUID: string;
  // aircraftGUID: string;
  // legList: LegGroup[];

}
@Component({
  selector: 'app-email-client-dialog',
  templateUrl: './email-client-dialog.component.html',
  styleUrls: ['./email-client-dialog.component.css']
})

export class EmailClientDialogComponent implements OnInit {
  // ckeditor

  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfig;
  editorIsModified: boolean = false;
  isReply: boolean = false;
  lockdownTrip: boolean = false;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly) {
        return;
      }
      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      content = content.replace(/<p>/gi, '<p class=\"email-body\">')
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    }, { priority: 'high' });


    const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {
      if (this.embeddedImageList == undefined || this.embeddedImageList == null)
        this.embeddedImageList = [];
      let img = new MessageEmbeddedImageModel();
      img.imageBase64String = data.model[0].bFile;
      img.documentName = data.model[0].fileName;
      img.mimeType = data.model[0].mimeType;
      img.isInline = true;
      img.contentId = data.model[0].fileName;
      this.embeddedImageList.push(img);
    });
    let data = "";
    if (!this.isDraft && this.messageGUID == '')
      data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature

    data += this.emailBody;
    editor.setData(data);
    this.reloadBtnVisible = data.indexOf('cid:') > -1;
    //editor.focus();
    editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });

    const toolbarElement = editor.ui.view.toolbar.element;
    if (this.messageGUID != '' && !this.isDraft) {
      toolbarElement.style.display = 'none';
      editor.enableReadOnlyMode('a');
    }
    else {
      toolbarElement.style.display = 'flex';
      editor.disableReadOnlyMode('a');
    }
    // console.log(editor.getData());
  }

  isDisabled: boolean;
  @ViewChild('emailCKEditor') emailCKEditorRef: any;//ElementRef;
  // ckeditor end

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  
  email_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  showSpin: boolean = false;
  userType: string;
  showSuccessMsg: boolean = false;
  successMsg: string = "Email sent successfully";
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  
  controlValueChanged: boolean = false;
  isModified: boolean = false;

  grantAccessToMessages: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  userName: string;
  emailContent: string;
  messageAttachmentList: MessageAttachmentModel[];
  tripCode: string;
  subject: string;
  emailTo: string;
  wrongEmailMsg: string = "";
  personName: string;
  personEmail: string;
  clientName: string;
  userSignature: string = "";
  defaultSignature: string = "";
  totalMessageAttachmentFileSize: number = 0;
  messageGUID: string = "";
  emailBody: string = "";

  hasDefualtEmailTo: string = "";
  hasDefualtEmailCc: string = "";
  hasDefualtEmails: boolean = false;
  emailCc: string = "";
  wrongEmailToMsg: string = "";
  wrongEmailCcMsg: string = "";

  emailFrom: string;
  senderEmail: string;
  opsEmail: string;
  publicNote: string = "";
  taskGroundStopGUID: string;
  taskDocCount: number = 0;
  isInBound: boolean = false;
  mainMessageGUID: string;
  messageData: MessageModel;
  isDraft: boolean = false;
  logoBase64String: string = "";
  sentDateTime: string;
  allMessageData: MessageModel[];
  totalMessageAttachmentFileSizeKB: number = 0;
  totalMessageAttachmentFileSizeMB: number = 0;
  embeddedImageList: MessageEmbeddedImageModel[] = [];
  displayType: string = "html";
  ckEditorData: string;
  assignedTo: string = "";
  isSpam: boolean = false;
  messageList = new MatTableDataSource<MessageModel>();
  scheduleMessage: string = "";
  isPending: boolean = false;
  messageFolderID: number;
  currentUser: string = localStorage.getItem('un');
  farTypeID: number;
  messageKeyId: string = null;
  autoLinkVisible: boolean = false;
  removeLinkVisible: boolean = true;
  printing: boolean = false;
  showAssigned: boolean = false;
  trip_Code: string = null;
  conversationID: string = "";
  outlookMessageID: string = "";
  showDraftBtn: boolean = false;
  arrowDraft: boolean = false;

  textToCopy: string = 'Text to copy';
  copied: boolean = false;
  reloadBtnVisible: boolean = false;
  messagesOpened: number = 0;

  isFirstMessage: boolean = false;
  isLastMessage: boolean = false;

  isFlagged: boolean = false;

  showCommsInTrip: boolean = false;
  linkGroup: LinkGroup[] = [];
  messageLinkList: MessageLinkModel[];
  aircraftGUID: string;
  downloadCount: number = 0;
  embeddedImageListAtt: MessageEmbeddedImageModel[] = [];

  constructor(private readonly _dialogRef: MatDialogRef<EmailClientDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _commonService: CommonService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _dialogService: DialogService, private readonly _messageService: MessageService, private readonly _identityService: IdentityService
  ) {
      
    this.dialogTitle = _data.dialogTitle;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "")
      this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID!="")
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    if (_data.nextGroundStopGUID != null && _data.nextGroundStopGUID != undefined && _data.nextGroundStopGUID != "")
      this.nextGroundStopGUID = _data.nextGroundStopGUID;
    if (_data.customerGUID != null && _data.customerGUID != undefined && _data.customerGUID != "")
      this.customerGUID = _data.customerGUID;
    if (_data.messageGUID != null && _data.messageGUID != undefined && _data.messageGUID != "")
      this.messageGUID = _data.messageGUID
    this.allMessageData = [];
    if (_data.allMessageData != null && _data.allMessageData != undefined && _data.allMessageData != "")
      this.allMessageData = _data.allMessageData
  }

  getAllData(): Observable<any[]> {
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    let tripResponse;
    let taskResponse;
    tripResponse = of(null);
    taskResponse = of(null);
    if (this.tripCodeGUID!="" && this.groundStopGUID == "" && this.groundStopTaskGUID == "") {
      let req = new AircraftTripModel();
      req.tripCodeGUID = this.tripCodeGUID;
      tripResponse = this._groundStopClientService.getTripBytId(req);   
    }
    else {
      if (this.tripCodeGUID != "" && this.groundStopGUID != "" && this.groundStopTaskGUID != "") {
        let req2 = new MessageModel();
        req2.tripCodeGUID = this.tripCodeGUID;
        req2.groundStopGUID = this.groundStopGUID;
        req2.groundStopTaskGUID = this.groundStopTaskGUID;
        taskResponse = this._messageService.getGroundStopTaskEmailSubjectByIds(req2);
      }
      else {
        if (this.tripCodeGUID != "" && this.groundStopGUID != "" && this.nextGroundStopGUID != "" && this.groundStopTaskGUID == "") {
          let req2 = new MessageModel();
          req2.tripCodeGUID = this.tripCodeGUID;
          req2.groundStopGUID = this.groundStopGUID;
          req2.nextGroundStopGUID = this.nextGroundStopGUID;     
          taskResponse = this._messageService.getGroundStopEmailSubjectByIds(req2);
        }
      }
    }
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);
    let messageResponse = of(null);
    if (this.messageGUID != "") {
      messageResponse = this._messageService.getMessageByMessageGUIDClient(this.messageGUID);

    }
    let emailFromResponse = this._messageService.getEmailFromForOps();
    return forkJoin([getAllowedDocTypeResponse, tripResponse, taskResponse, userResponse, emailFromResponse, messageResponse]);  
  }

  ngOnInit() {
    this.isDisabled = false;
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }
    let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    this.isFirstMessage = (i == 0);
    this.isLastMessage = (i + 1 == this.allMessageData.length);

    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close(false);
      return;
    }
    this.showSpin = true;
    this.userName = localStorage.getItem('un');
    this.errMsg = "";

    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showSuccessMsg = false;
    this.fileList = [];
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.tripCode = "";
    this.subject = "";
    this.emailContent = '';
    this.emailTo = GlobalConstant.opsEmail; //'Ops@flightprointl.com';
    this.personName = "";
    this.personEmail = "";
    this.clientName = "";
    this.totalMessageAttachmentFileSize = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.messageAttachmentList = [];
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[0].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let t = new TripModel();
            t = responses[1].model;
            this.subject = "Trip code: " + t.tripCode + " " + t.registration;
            if (t.customerTripID != "")
              this.subject += " / " + t.customerTripID;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model != "") {
            this.subject = responses[2].model;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          if (responses[3].model != null) {
            let user = new UserModel();
            user = responses[3].model;
            // this.emailTo += '; ' + user.email;
            if (!user.excludeCCOptions) {
 
              this.emailCc = user.email;
            }
            this.personName = user.firstName + ' ' + user.lastName;
            this.personEmail = user.email;
            this.clientName = user.customerName;
            this.userSignature = user.signatureInCKEditorFormat;

            if (this.userSignature == "") {
              this.userSignature = this.personName + "<br/>";
              this.userSignature += this.personEmail + "<br/>";
            }
            this.defaultSignature += "<table style=\"border:0; width:25em\" align='left'>";
            this.defaultSignature += "<tr>";
            this.defaultSignature += "<td style=\"background-color:#ffffff;border:0;white-space: nowrap;\" >";
            this.defaultSignature += "<span class='text-small' style='font-family:Tahoma;'>";
            this.defaultSignature += this.userSignature;
            this.defaultSignature += "</span>";
            this.defaultSignature += "</td>";
            this.defaultSignature += "</tr>";
            this.defaultSignature += "</table>";
            this.emailContent = "<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>";
            this.emailContent += this.defaultSignature;
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model != "") {
              this.opsEmail = responses[4].model;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != "") {
            this.messageData = responses[5].model;
            this.subject = this.messageData.subject;
            this.emailTo = this.messageData.sendTo;
            this.emailCc = this.messageData.sendCC;
            this.sentDateTime = this.messageData.sentDateTime;
            if (this.messageData.isInBound == false && this.messageData.bodyHtml != null)
              this.emailContent = this.messageData.bodyHtml;
            else
              this.emailContent = this.messageData.body;

            this.emailBody = this.emailContent;
            //this.bodyText = this.messageData.bodyTxt;
            let sender = this.messageData.sender;
            if (this.messageData.senderName != '')
              sender = this.messageData.senderName + "<" + this.messageData.sender + ">";
            this.emailFrom = this.messageData.sender==this.opsEmail? this.messageData.sender : this.messageData.actualSender;
            this.senderEmail = this.messageData.sender;
            if (this.messageData.messageAttachmentList != null)
              this.messageAttachmentList = this.messageData.messageAttachmentList;
            this.tripCode = this.messageData.tripCodeGUID;
           // this.isActive = this.messageData.isActive;

            this.isFlagged = this.messageData.isFlagged;

            this.embeddedImageList = this.messageData.messageEmbeddedImageList;
            if (this.embeddedImageList != null) {
              this.embeddedImageList.forEach(x => {
                if (x.containerName != null && x.containerName != '') {
                  this.emailBody = this.emailBody.replace("cid:" + x.contentId + '"', "data:image;base64," + x.imageBase64String + '" style="" title="' + x.documentName + '"');
                }
              });
              this.embeddedImageListAtt = this.embeddedImageList.filter(x => x.containerName != null && x.containerName != '');
   
            }
            this.messageLinkList = this.messageData.messageLinkList;
            if ((this.messageLinkList != null) && (this.messageFolderID != 1)) {
              if (this.messageLinkList.length == 1) {
                this.groundStopGUID = this.messageLinkList[0].groundStopGUID;
                this.taskGroundStopGUID = this.groundStopGUID;
                this.groundStopTaskGUID = this.messageLinkList[0].groundStopTaskGUID;
              }

              this.tripCodeGUID = this.messageLinkList[0]?.tripCodeGUID;
              this.customerGUID = this.messageLinkList[0]?.customerGUID;
              this.aircraftGUID = this.messageLinkList[0]?.aircraftGUID;
              this.linkGroup = [];

              this.messageLinkList.forEach(v => {
                this.clientName = v.customerName;
                let client: LinkGroup = {
                  message: '',
                  messageGUID: v.messageGUID,
                  custGUID: v.customerGUID,
                  custName: v.customerName,
                  tripList: null
                }

                let tripdetails = function (msgLinkList: MessageLinkModel[]): TripGroup[] {
                  let triplist: TripGroup[] = [];
                  msgLinkList.forEach(t => {
                    let tripdetail: TripGroup = {
                      tripCode: t.tripCode,
                      tripCodeGUID: t.tripCodeGUID,
                      aircraftGUID: t.aircraftGUID,
                      custGUID: t.customerGUID,
                      legList: null
                    };

                    let legdetails = function (msgLinkList: MessageLinkModel[]): LegGroup[] {
                      let leglist: LegGroup[] = [];
                      msgLinkList.forEach(l => {
                        if (l.leg != "" && l.leg != null && leglist.findIndex(l2 => l2.tripLeg == l.leg) == -1) {
                          leglist.push({ tripLeg: l.leg, taskList: msgLinkList.filter(x => x.leg == l.leg) });
                        }
                      });
                      return leglist;
                    }

                    if (triplist.findIndex(l => l.tripCodeGUID == t.tripCodeGUID) == -1) {
                      tripdetail.legList = legdetails(msgLinkList.filter(l => l.tripCodeGUID == t.tripCodeGUID));
                      triplist.push(tripdetail);
                    }
                  });

                  return triplist;
                }

                if (this.linkGroup.findIndex(l => l.custGUID == v.customerGUID) == -1) {
                  client.tripList = tripdetails(this.messageLinkList.filter(c => c.customerGUID == v.customerGUID));
                  this.linkGroup.push(client);
                }

              });

            }

          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
      


      this.initControls();
      this.showSpin = false;
    });
    

  }

  initControls() {
    this.email_client_form = this._formBuilder.group({
      emailTo: [this.emailTo],
      emailCc: [this.emailCc],
      subject: [this.subject, Validators.required],
      emailCKEditor: [this.emailContent ],
      document: [null],
      
    });
  }

  get f() { return this.email_client_form.controls; }

  // upload file
  dropFile(event) {
    let msg: string = this.emailCKEditorRef.editorInstance.getData();
    this.finishAllUploads = false;
    this.fileList = [];
    this.errMsg = "";

    if (event.length > GlobalConstant.maxUploadFileNum) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
      }
      this.errMsg += 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum + '.';
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
      
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (!a) {
          if (this.errMsg != "") {
            this.errMsg += "\n";
          }
          this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);
        }
        else {
          //if (event[i].size >= this.maxFileSize) {
          //  if (this.errMsg != "") {
          //    this.errMsg += "\n";
          //  }
          //  this.errMsg += 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }
        }
      }
    }


    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }
     
    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.uploadSubmit();
    setTimeout(() => {
      this.setMessageBody(msg),
        1000;
    });
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  importFile(event: any) {
    let msg: string = this.emailCKEditorRef.editorInstance.getData();
    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    
    var files = [];
    this.errMsg = ""
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
    setTimeout(() => {
      this.setMessageBody(msg),
        1000;
    });
    
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
    
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {   
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            this.fileList.push(obj);

            let obj2 = new MessageAttachmentModel();
            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.messageAttachmentGUID = "";
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.messageAttachmentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
    
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  getEmbeddedImage(messageEmbeddedImageGUID: string, isDownload: boolean) {
    this._messageService.getMessageEmbeddedImageByMessageEmbeddedImageGUID<ResponseModel<MessageEmbeddedImageModel>>(messageEmbeddedImageGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.imageBase64String);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {
            saveAs(file, att.documentName);
            this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  clickPreviewDocument(e: any, item: any, isEmbedded: boolean) {

    if (isEmbedded) {
      this.getEmbeddedImage(item.messageEmbeddedImageGUID, false);
    }
    else {
      if (item.messageAttachmentGUID == "") {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
        let file = new Blob([byteArray], { type: item.mimeType });
        if (item.mimeType == "application/octet-stream") {
          saveAs(file, item.documentName);
        }
        else {
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }

      }
      else {
        this.getMessageAttachment(item.messageAttachmentGUID, false);
      }
    }

  }
  clickRemoveDocument(e: any, item: MessageAttachmentModel) {
    this.errMsg = "";
    let msg: string = this.emailCKEditorRef.editorInstance.getData();
    this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document: " + item.documentName + "?");
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.isModified = true;       
          this.fileList.forEach((x, index) => {
            if (x.tempId == item.tempId) {
              this.fileList.splice(index, 1);
            }
          });
          this.messageAttachmentList.forEach(
            (x, index) => {
              if (x.tempId == item.tempId && x.messageAttachmentGUID == "") {
                this.messageAttachmentList.splice(index, 1);
                this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
              }
            }
        )
        this.setMessageBody(msg);
      }
    })


  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }
  // upload file end

  emailCcKeydown(e: any) {
    this.wrongEmailMsg = "";
  }

  clickSend() {
    this.wrongEmailMsg = "";
    this._authService.updateAccessTime();

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }
    
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    let s: string = "";
    if (this.f.emailCc.value != '') {
      let re = /,/gi;
      s = this.f.emailCc.value.trim();
      s = s.replace(re, ";")
      let re1 = / /gi;
      s = s.replace(re1, ";")
      let s1 = s.split(';');
      let s2 = "";
      for (var i = 0; i < s1.length; i++) {
        s1[i] = s1[i].trim();
        if (s1[i] != "") {
          let r = CustomValidators.validateCommonAddress(s1[i], "email");
          if (r == false) {
            this.wrongEmailMsg = "Invalid email: " + s1[i];
            this.f.emailCc.setErrors({ validateEmail: false });
            return;
          }
          else {
            if (s2 != "") {
              s2 += ";";
            }
            s2 += s1[i];
          }
        }        
      }
      
      this.f.emailCc.setValue(s2);
    }
    if (this.email_client_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let request = new MessageModel();
    request.subject = this.f.subject.value;
    request.isInBound = true;
    request.sendTo = this.f.emailTo.value;
    request.sendCC = this.f.emailCc.value;
    request.body = this.emailCKEditorRef.editorInstance.getData();
    request.bodyTxt = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    request.bodyTxt = this.formatEmailBody(request);
    //if (request.bodyTxt != "") {
    //  let re = /Paragraph\n\n/gi;
    //  request.bodyTxt = request.bodyTxt.replace(re, '');
    //  request.bodyTxt = request.bodyTxt.trim();
    //}
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.messageAttachmentList = [];
    //request.customerGUID = this.customerGUID;
    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new MessageAttachmentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.messageAttachmentGUID = "";
        request.messageAttachmentList.push(obj);
      });
    }
    request.customerGUID = this.customerGUID;
    request.replyToEmail = this.personEmail;
    request.replyToName = this.personName;
    request.fromClientEmail = true;

    this._messageService.sendEmailWithAttachments<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let m = new MessageModel();
          m = response.model;
          if (m.messageGUID != "") {
            this.isModified = true;
            //this.returnObj.refresh = this.isModified;
            //this.returnObj.newId = t.tripCodeGUID.toLowerCase();
            //this.returnObj.aircraftGUID = obj.aircraftGUID;
            //this.returnObj.customerGUID = obj.customerGUID;
            if (m.emailSent == true) {
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
                this.clickClose();
              }, 1000);
            }
            else {
              if (m.errMsg != "") {
                this.errMsg = m.errMsg;
              }
              else
              this.errMsg = "Failed to send email at this time. Please try later.";
            }
          }
        }
        else {
          this.errMsg = "Failed to send email at this time. Please try later.";

        }
      }
      else {
        if (response.code == "401") {
          if (response.message != "") {
            this.errMsg = response.message;
          }
          else {
            this._authService.signOut();
          }
        }
        else {
          this.errMsg = "Failed to send email at this time. Please try later.";
        }
      }
      this.loading = false;
    })
   
  }

  clickClose() {
    
    this._dialogRef.close(this.isModified);
  }


  setMessageBody(body: string) {
    this.f.emailCKEditor.setValue('');
    this.f.emailCKEditor.setValue("<pre>" + body + "</pre>");
  }

  formatEmailBody(message: MessageModel): string {
    if (message.bodyTxt != "") {
      let re = /Paragraph\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Styles\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Email Body\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Signature\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 1\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 2\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 3\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
    }
    return message.bodyTxt;
  }



  clickEmailSelection() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: "", groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: "",
      serviceTypeID:  0, openFrom: 'email', showCommsInTrip: this.showCommsInTrip, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailAddressSelectionClientDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        //let msg: string = this.emailCKEditorRef.editorInstance.getData();
        let t: string = "";
        t = this.getUniqueEmailAddess(this.f.emailTo.value, result.to);
        this.f.emailTo.setValue(t);
        let c: string = "";
        c = this.getUniqueEmailAddess(this.f.emailCc.value, result.cc);
        this.f.emailCc.setValue(c);
        // this.setMessageBody(msg);
      }

    });
  }

  getUniqueEmailAddess(controlValue: string, addedValue: string) {
    let returnValue: string = "";
    let s: string = controlValue;
    if (s != "") {
      s = s.trim();
      let re = /,/gi;
      s = s.replace(re, ";")
      let re1 = / /gi;
      s = s.replace(re1, ";")
      returnValue = s;
      if (addedValue != "") {
        let s1 = addedValue.split(';');
        for (var i = 0; i < s1.length; i++) {
          s1[i] = s1[i].trim();
          if (s1[i] != "") {
            if (s.toLowerCase().indexOf(s1[i].toLowerCase()) == -1) {
              if (returnValue != "") {
                returnValue += ";";
              }
              returnValue += s1[i];
            }
          }
        }
      }

    }
    else {
      returnValue = addedValue;
    }
    return returnValue;
  }

  onPrintClick() {
    this.printing = true;

    setTimeout(() => {
      const emailHead = document.getElementById("emailHead").innerHTML;
      let editor = this.emailCKEditorRef.editorInstance;
      const emailBody = editor.data.get(); //document.getElementById("emailCKEditor").innerHTML;
      const printContent = emailHead + emailBody;
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(printContent);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
      this.printing = false;
    }, 50);
  }


  showPrevious() {
    // let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    let msgGUIDs = this.allMessageData.map(m => m.messageGUID);
    let uniqueMsgs = [...new Set(msgGUIDs)];
    let i = uniqueMsgs.findIndex(m => m == this.messageGUID);
    this.messageGUID = uniqueMsgs[i - 1];
    this.arrowDraft = true;
    this.autoLinkVisible = false;
    this.taskGroundStopGUID = "";
    this.ngOnInit();

    this._messageService.setMessageGUID(this.messageGUID);

  }

  showNext() {
    // let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    let msgGUIDs = this.allMessageData.map(m => m.messageGUID);
    let uniqueMsgs = [...new Set(msgGUIDs)];
    let i = uniqueMsgs.findIndex(m => m == this.messageGUID);
    this.messageGUID = uniqueMsgs[i + 1];
    this.arrowDraft = true;
    this.autoLinkVisible = false;
    this.taskGroundStopGUID = "";
    this.ngOnInit();
    this._messageService.setMessageGUID(this.messageGUID);

  }

  clickOpenTrip(tripCodeGUID, custGUID, aircraftGUID, legList) {
    window.open('/ground-stops-client/trip-details-client?tripId=' + tripCodeGUID + '&aircraftId=' + aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  downloadAttachment(item: any, isEmbedded: boolean) {
   
      this.getMessageAttachment(item.messageAttachmentGUID, true);
    
  }

  downloadAllAttachments() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "18em";
    config.width = "26em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), embeddedImageListAtt: this.embeddedImageListAtt, messageAttachmentList: this.messageAttachmentList, };
    this._dialog.open(DownloadDocumentsComponent, config);
  }


  getMessageAttachment(messageAttachmentGUID: string, isDownload: boolean) {
    this._messageService.getMessageAttachmentsByMessageAttachmentGUID<ResponseModel<MessageAttachmentModel>>(messageAttachmentGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.bFile);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {

            // await new Promise(resolve => resolve(saveAs(file, att.documentName))); // Pause for 2.5 seconds

            saveAs(file, att.documentName);
            this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }


}
