<div class="divLeft" style="width: min-content;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div>
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; flex-direction: column;">
                <h3 style="font-weight: 200;">My Account</h3>
                <h3>Security</h3>
              </div>
            <div style="display: flex;">
                <button mat-icon-button mat-dialog-close>
                  <i class="material-icons" style="font-size: x-large;">close </i>
                </button>
              </div>
        </div>
        <div style="font-weight:500">
            <div style="display: inline-flex;">
                <div style="display: inline-flex; width: max-content;">
                    <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.5em;">gpp_maybe</i>
                    <h3 style="font-weight: 200;">Multi Factor Authentication (MFA)</h3>
                </div>
            </div>
            <div style="font-weight: 300; font-size: small; width: 30em; margin-bottom: 1em;">
                Multi-factor authentication (MFA) demands two authentication factors: the password and a one-time code
                sent
                via SMS or email. This adds an extra layer of security, even if the password is compromised, enhancing
                protection against unauthorized access.​
            </div>
       
        </div>

        <form [formGroup]="mfa_form">
          <div *ngIf="!passwordValid" class="form-group">
            <label for="password" style="width: max-content;">Enter your current password</label>
            <div style="display: inline-flex;">
              <div class="row" style="margin-left:0em; white-space:nowrap">
                <input [type]="hideOPwd ? 'password' : 'text'" formControlName="password"
                       class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                       style="width:15.75em;height:1.5625em;background-color:#eaf0fd" maxlength="50"
                       (change)="onKeyup($event, 1)" (keyup)="onKeyup($event, 1)" (mousedown)="$event.stopPropagation()" /><i *ngIf="showOPwdEye"
                                                                                                                              class="material-icons small" matSuffix (click)="hideOPwd = !hideOPwd"
                                                                                                                              style="cursor: pointer;margin-top: 0.625em;margin-left:-1.25em">
                  {{
hideOPwd ?
                            'visibility' :
                            'visibility_off'
                  }}
                </i>
              </div>
              <div class="form-group" style="margin-left: 3em;">
                <button mat-button class="btn-high" (click)="validatePassword()">Submit</button>
              </div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="alert alert-danger p-0 small" style="width:10em">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div [hidden]="!passwordValid">
            <div class="d-inline-flex">
              <div class="mr-3">
                <mat-slide-toggle color="primary" (change)="updateMFA()"  [(ngModel)]="mfaEnabled" [ngModelOptions]="{standalone: true}">Activate MFA</mat-slide-toggle>
              </div>
              <div *ngIf="mfaEnabled"  style="user-select:text" (mousedown)="$event.stopPropagation()">
                Your PIN: {{pin}}
              </div>
            </div>
          </div>
          
        </form>

            

        <div class="mt-2">
          <div *ngIf="errMsg!=''" class="d-block"><label style="color:red">{{errMsg}}</label></div>
          <div *ngIf="passwordValid" class="form-group">
            <button mat-button class="btn-high" style="margin-right: .5em;"
                    [disabled]="!isUpdated" (click)="onSave()">
              <i class="material-icons" style="font-size:medium;margin-top:-.25em">add</i>
              Save
            </button>
            <button mat-button class="btn-low-no-shadow" mat-dialog-close>
              <i class="material-icons" style="font-size: medium; margin-top: -.25em ">close</i>
              Close
            </button>
          </div>
          <div *ngIf="showSuccessMsg">
            <app-success-message [successMsg]="successMsg"></app-success-message>
          </div>
        </div>
    </div>
</div>
