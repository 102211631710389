import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import * as atlas from 'azure-maps-control';
import { ResponseModel } from '../models/response.model';
import { CommonService } from '../services/common.service';
import { HotelModel } from '../models/hotel.model';
import { CustomerModel } from '../models/customer.model';
import { AzureMapDistanceResults, AzureMapGeometry, AzureMapProperties, AzureMapRouteFeatures, AzureMapRouting } from '../models/azure-map-routing';
import { HotelChainModel } from '../models/hotel-chain.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { MapPointsModel } from '../models/map-points.model';
import { AirportMapComponent } from '../airports/airport-map.component';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.css']
})
export class HotelsComponent implements OnInit, OnChanges {
  @Input() icao: string;
  @Input() airportLatitude: number;
  @Input() airportLongitude: number;
  @Input() refreshData: boolean;
  isLoadingHotels: boolean = false;
  inCountry: boolean = true;
  distanceFromAirport: number = 50;
  customerHotelList: HotelModel[];
  customerList: CustomerModel[];
  showDriveTime: boolean = false;
  hotelDataSource: any;
  clickedRows = new Set<HotelModel>();
  clickedShape: atlas.HtmlMarker;
  hotelPoints: any[];
  hotelPointsFiltered: any[];
  hotelList: HotelModel[];
  hotelListAll: HotelModel[];
  hotelListWithLocation: HotelModel[];
  displayedColumnHotel: string[] = ['hotelName', 'address', 'distance', 'drive_time', 'time', 'ron', 'crew_count', 'details'];
  hotelMap: any;
  customerID: number=0;
  points: any[]=[];
  radius: number;
  userType: string;
  hotelChainList: HotelChainModel[];
  allMarkers: atlas.HtmlMarker[] = [];
  hotelChainGUID: string = "";
  hotelDetails: HotelModel;
  symbolLayer: atlas.layer.SymbolLayer;
  @ViewChild('hotelDetailsTemplate') hotelDetailsTemplate: TemplateRef<any>;
  constructor(private readonly _dialog: MatDialog, private readonly _commonService: CommonService, private readonly _authService: AuthenticateService) {
   
  }

  ngOnInit() {
    this.userType = localStorage.getItem('ut').toLowerCase();
    
    this._authService.updateAccessTime();
    this.getHotelData();
    this._commonService.getHotelChainList<ResponseModel<HotelChainModel[]>>().subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.hotelChainList = response.model;
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getHotelData();
  }


  getHotelData() {
    this.isLoadingHotels = true;
    let request = new HotelModel();
    //req3.countryGUID = this.countryGUID;
    request.icao = this.icao;
    request.distanceFromAirport = this.distanceFromAirport;
    request.inCountry = this.inCountry;
    request.isActive = true;
    this._commonService.getHotelByConditions<ResponseModel<HotelModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.hotelListAll = response.model;
          this.hotelList = this.hotelListAll.slice();
          var chain = Array.prototype.map.call(this.hotelListAll, s => s.hotelChainGUID).toString();
          this.hotelChainList = this.hotelChainList.filter(x => chain.includes(x.hotelChainGUID));
          this.hotelListWithLocation = this.hotelList.filter(x => x.latitude != null).slice();
          if (this.hotelListWithLocation.length > 0)
            this.createHotelMap();
          this.getDriveTime();
        }
        else {
          if (response.code == "401")
            this._authService.logout;

        }
        this.isLoadingHotels = false;
      }
      else
        this.isLoadingHotels = false;
    });
    this._commonService.getCustomerHotelByConditions<ResponseModel<HotelModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.customerHotelList = response.model;
          this.customerList = [];
          let cID = 0;
          this.customerHotelList.forEach(x => {
            if (this.customerList.findIndex(y => y.customerID == x.customerID) == -1) {
              let c = new CustomerModel();
              c.customerID = x.customerID;
              c.customerName = x.customerName;
              this.customerList.push(c);
            }

          })
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  filterHotelList() {
    if (this.customerID == 0 && this.hotelChainGUID=="") {
      this.hotelList = this.hotelListAll.slice();
    }
    else {
      if (this.customerID != 0) {
        let customerFilter = this.customerHotelList.filter(x => x.customerID == this.customerID).slice();
        this.hotelList = [];
        this.hotelListAll.forEach(x => {
          if (customerFilter.findIndex(y => y.hotelGUID == x.hotelGUID) > -1)
            this.hotelList.push(x);
        });
      }
      else {
        this.hotelList = this.hotelListAll.slice();
      }

      if (this.hotelChainGUID != "") {
        this.hotelList = this.hotelList.filter(x => x.hotelChainGUID == this.hotelChainGUID);
      }
    }
    this.updateMarkers();
  }

  getDriveTime() {
    let request = new AzureMapRouting();
    request.type = "FeatureCollection";
    let origin = new AzureMapRouteFeatures();
    origin.type = "Feature";
    let oGeo = new AzureMapGeometry();
    oGeo.type = "MultiPoint";
    oGeo.coordinates = [[this.airportLongitude, this.airportLatitude]];
    origin.properties = new AzureMapProperties();
    origin.properties.pointType = "origins";
    origin.geometry = oGeo;
    var destination = new AzureMapRouteFeatures();
    destination.type = "Feature";
    var dGeo = new AzureMapGeometry();
    dGeo.type = "MultiPoint";
    dGeo.coordinates = [];
    this.hotelList.forEach(x => {
      dGeo.coordinates.push([x.longitude, x.latitude]);
    });
    destination.properties = new AzureMapProperties()
    destination.properties.pointType = "destinations";
    destination.geometry = dGeo;
    request.features = [];
    request.features.push(origin);
    request.features.push(destination);
    this._commonService.getMapDistance<ResponseModel<AzureMapDistanceResults>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          response.model.properties.matrix.forEach(x => {
            this.hotelList[x.destinationIndex].driveDistanceFromAirport = Number((x.distanceInMeters / 1690).toFixed(1));
            this.hotelList[x.destinationIndex].timeFromAirport = Number((x.durationInSeconds / 60).toFixed(1));
          })

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });

  }



  createHotelMap() {
    const container = document.getElementById('hotel-map' + this.icao)
 if (container) {

      if (this.hotelMap && !this.hotelMap.isDisposed)
        this.hotelMap.dispose();
      this.hotelMap = new atlas.Map('hotel-map' + this.icao, {

        language: 'en-US',
        view: 'Auto',
        showBuildingModels: true,
        showLogo: false,
        style: "road_shaded_relief",
        showFeedbackLink: false,
        //zoom: 11,
        //center: new atlas.data.Position(6.7735, 51.2277),
        //Add your Azure Maps subscription client ID to the map2 SDK.
        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
        }
      });

      var self = this;
      self.hotelPoints = [];
      self.hotelMap.events.add('ready', function () {
        var dataSource = new atlas.source.DataSource();
        self.hotelMap.sources.add(dataSource);
        self.hotelMap.controls.add([
          new atlas.control.ZoomControl()
        ], {
          position: atlas.ControlPosition.TopRight
        });

        dataSource.add(new atlas.data.Feature(new atlas.data.Point([self.airportLongitude, self.airportLatitude]), {
          title: self.icao,
          strokecolor: '#cc0000',
          color: "white",
          display: "dot",
          offset: [0, -1],
          image: "pin-round",
          isMain:true
        }));
       // self.points.push([self.airportLongitude, self.airportLatitude]);
        var marker = new atlas.HtmlMarker({
          draggable: false,
          color: '#cc0000',
          htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><g transform="translate(0 1)"><circle cx="12.25" cy="11.5833" r="11" fill="{color}"/><path d="m12.25 23.583a12 12 0 1 1 12-12 12 12 0 0 1-12 12zm0-22a10 10 0 1 0 10 10 10 10 0 0 0-10-10z" fill="{secondaryColor}"/><circle cx="12.25" cy="11.5833" r="4.2386" fill="{secondaryColor}"/></g><text x="12.5" y="17" style="font-size:14px;fill:#000;text-anchor:middle">{text}</text></svg>',
          position: [self.airportLongitude, self.airportLatitude],

        });
        self.hotelMap.markers.add(marker);
        self.hotelListWithLocation.forEach((v, i) => {
          dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
            title: v.hotelName,
            strokecolor: 'DodgerBlue',
            color: "white",
            display: "dot",
            offset: [0, -1],
            guid: v.hotelGUID,            
            cluster: true
          }));
          self.hotelPoints.push([v.longitude, v.latitude]);

            var marker = new atlas.HtmlMarker({
              position: [v.longitude, v.latitude],
              color: "darkblue",
              text: v.hotelGUID,
              htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" height="25px" viewBox="0 0 25 25" width="25px" fill="{color}" > <path d="M0 0h25v25H0V0z" fill = "none" /> <path d="M19 9h-6v6h8v-4c0-1.1-.9-2-2-2z" opacity = ".3" /> <circle cx="7" cy = "11" opacity=".3" r="1" /> <path d="M4 11c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3zm4 0c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm11-4h-8v8H3V5H1v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4zm2 8h-8V9h6c1.1 0 2 .9 2 2v4z"/></svg>'
            });

          self.hotelMap.markers.add(marker);
          self.allMarkers.push(marker);


        });
        //Create a circle
        if (self.distanceFromAirport <= 25)
          self.radius = 10;
        if (self.distanceFromAirport > 25 && self.distanceFromAirport <= 50)
          self.radius = 20;
        if (self.distanceFromAirport > 50 && self.distanceFromAirport <= 100)
          self.radius = 35;
        if (self.distanceFromAirport > 100)
          self.radius = 50;
        if (self.hotelListWithLocation.length > 1) {
          let maxRadius = self.distanceFromAirport * 1852;
          let increment = self.radius * 926;
          for (let i = increment; i <= maxRadius; i = i + increment) {
            var data = new atlas.data.Feature(new atlas.data.Point([self.airportLongitude, self.airportLatitude]), {
              title: i / 926 / 2 + " NM",
              display: "radius",
              subType: "Circle",
              radius: i,
              color: "transparent",
              strokecolor: "transparent",
              offset: [0, -.5]
            });
            let r = i / 926 / 2;
            if (r < 25 || r <= self.hotelListWithLocation[self.hotelListWithLocation.length - 1].distanceFromAirport + (self.radius / 2))
              dataSource.add(data);

            //Create a polygon layer to render the filled in area of the polygon.
            var polygonLayer = new atlas.layer.PolygonLayer(dataSource, 'myPolygonLayer', {
              fillColor: 'transparent',
              filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']]
            });

            //Create a line layer for greater control of rendering the outline of the polygon.
            var lineLayer = new atlas.layer.LineLayer(dataSource, 'myLineLayer', {
              strokeColor: 'blue',
              strokeWidth: 1.5
            });

            var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
              iconOptions: {
                image: 'none'
              },
              textOptions: {
                textField: ['get', 'label']
              },
              filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]
            })
            /*Create and add a polygon layer to render the polygon to the map*/
            self.hotelMap.layers.add([polygonLayer, lineLayer, symbolLayer]);
          }


        }
        self.hotelDataSource = dataSource;
        var labels = calculatePolygonLabels(dataSource.getShapes(), 'title');
        dataSource.add(labels);

        var bubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
          radius: 4,
          strokeColor: ['get', 'strokecolor'],
          strokeWidth: 6,
          color: ['get', 'color'],
          filter: ['any', ['==', ['geometry-type'], 'Point']],
          guid: ['get', 'guid']
        });
       // self.hotelMap.layers.add(bubbleLayer);


        //Add a layer for rendering point data.
        self.symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image:"none"
          },

          textOptions: {
            textField: ['get', 'title'],
            offset: [0, 1],
            allowOverlap: false,
            size: 16,
            cluster:true
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']],
          guid: ['get','guid']
        })
        self.hotelMap.layers.add(self.symbolLayer);


        var bbox = atlas.data.BoundingBox.fromLatLngs(self.hotelPoints);

        if (self.hotelListWithLocation.length == 1) {

          self.hotelMap.setCamera({
            center: [self.hotelListWithLocation[0].longitude, self.hotelListWithLocation[0].latitude],
            zoom: 12
          });
        }
        else {
          self.hotelMap.setCamera({
            bounds: bbox,
            padding: 40
          });
        }

      });
    }

  }

  viewDetails(hotel: HotelModel) {
    this._commonService.getHotelByhId<ResponseModel<HotelModel>>(hotel.hotelGUID).subscribe(response => {
      if (response!=null){
        if (response.code == "200" && response.message == "") {
          this.hotelDetails = response.model;
          if (this.hotelDetails.latitude!=null)
          this.hotelDetails.latitudeDMS = UtilityFunctions.convertLatLongFromDecimal(this.hotelDetails.latitude, true, "");
          if (this.hotelDetails.longitude != null)
            this.hotelDetails.longitudeDMS = UtilityFunctions.convertLatLongFromDecimal(this.hotelDetails.longitude, true, "");
          this._dialog.open(this.hotelDetailsTemplate);

        }
        else {
          if (response.code == "401")
            this._authService.signOut();
        }
      }
    })
  }

  clickRow(row: any) {
    if (this.clickedShape) {
      var options = this.clickedShape.getOptions();

      this.clickedShape.setOptions({
        position: options["position"],
        color: "darkblue",
        text: options["text"],
        htmlContent: options["htmlContent"]

      });
    }
    if (this.clickedRows.has(row)) {
      this.clickedRows.delete(row);


      var bbox = atlas.data.BoundingBox.fromLatLngs(this.hotelPoints);

      if (this.hotelPoints.length == 1) {

        this.hotelMap.setCamera({
          center: [this.hotelPoints[0].longitude, this.hotelPoints[0].latitude],
          zoom: 12
        });
      }
      else {
        this.hotelMap.setCamera({
          bounds: bbox,
          padding: 40
        });
      }
    }
    else {
      this.clickedRows = new Set<HotelModel>();
      this.clickedRows.add(row);
      var shape = this.allMarkers.find(x => x.getOptions().text == row.hotelGUID);
//        getShapeByProperty(this.hotelDataSource, "guid", row.hotelGUID, "")
      if (shape) {
        var options = shape.getOptions();

        shape.setOptions({
          position:options["position"],
          color: "green",
          text: options["text"],
          htmlContent: options["htmlContent"]

        });
        this.clickedShape = shape;
      }

      this.hotelMap.setCamera({
        center: [this.hotelListWithLocation.find(x => x.hotelGUID == row.hotelGUID).longitude, this.hotelListWithLocation.find(x => x.hotelGUID == row.hotelGUID).latitude],
        zoom: 12
      });
    }
  }


  updateMarkers() {
    var hotels = Array.prototype.map.call(this.hotelList, s => s.hotelGUID).toString();

    this.allMarkers.forEach(x => {
      var options = x.getOptions();
      if (hotels.includes(options.text))
        this.hotelMap.markers.add(x);
      else {
        this.hotelMap.markers.remove(x);
      }

    });
    this.symbolLayer.setOptions({
      iconOptions: {
        image: "none"
      },

      textOptions: {
        textField: ['case',

          ['in', ['get', 'guid'], hotels],
          ['get', 'title'],
          ['==', ['get', 'isMain'], true],
          ['get', 'title'],
          ''
        ],
        offset: [0, 1],
        allowOverlap: false,
        size: 16,
        cluster: true
      },
      filter: ['any', ['==', ['geometry-type'], 'Point']],
      guid: ['get', 'guid']
    });

    this.hotelPoints = [];
    this.hotelList.forEach(x => {
      this.hotelPoints.push([x.longitude, x.latitude]);
    });

    var bbox = atlas.data.BoundingBox.fromLatLngs(this.hotelPoints);

    if (this.hotelPoints.length == 1) {

      this.hotelMap.setCamera({
        center: [this.hotelPoints[0][0], this.hotelPoints[0][1]],
        zoom: 12
      });
    }
    else {
      this.hotelMap.setCamera({
        bounds: bbox,
        padding: 40
      });
    }
      
  }

  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95vw";
    dialogConfig.height = "95vh";
    let mapData: any;
    let latLongs: MapPointsModel[] = [];
    let x = new MapPointsModel();
    
        x.latitude = this.airportLatitude;
        x.longitude = this.airportLongitude;
        x.title = this.icao;
        x.isMainPoint = true;
        latLongs.push(x);
       
    

    dialogConfig.data = {
      latLongs: latLongs,
      distance: this.distanceFromAirport,
      radius: this.radius,
      maxDistance: this.hotelList[this.hotelList.length - 1]?.distanceFromAirport,
      from: "hotels",
      mapData: this.hotelList
    };

    this._dialog.open(AirportMapComponent, dialogConfig);
  }

  convertMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    if (hours == 0)
      return `${remainingMinutes}m`;
    else
      return `${hours}h${remainingMinutes}m`;
  }

}


function calculatePolygonLabels(shapes, labelPropertyName) {
  var labels = [];

  for (var i = 0; i < shapes.length; i++) {

    var circlePolygon = shapes[i].circlePolygon;

    // Get the coordinates of the polygon edge
    if (circlePolygon != undefined) {
      var edgeCoordinates = circlePolygon.geometry.coordinates[0][0];

      var prop = {};
      prop[labelPropertyName] = shapes[i].circlePolygon.properties[labelPropertyName];
      prop["display"] = shapes[i].circlePolygon.properties["display"];
      prop["color"] = shapes[i].circlePolygon.properties["color"];
      prop["strokecolor"] = shapes[i].circlePolygon.properties["strokecolor"];
      prop["offset"] = shapes[i].circlePolygon.properties["offset"];
      labels.push(new atlas.data.Feature(new atlas.data.Point(edgeCoordinates), prop));

    }
  }

  return labels;
}

function getShapeByProperty(dataSource, propertyName, value1, value2) {
  const shapes = dataSource.getShapes();
  return shapes.find(shape => shape.getProperties()[propertyName] === value1 || shape.getProperties()[propertyName] === value2);
}
