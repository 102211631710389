<div style=" margin-top:-.2em;margin-bottom:-.2em;     background-color: #f0f0f0;" cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

  <div style="   background-color: #f0f0f0;  display: flex; justify-content: space-between;">
    <div style="display: inline-flex;">
      <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.4em;">engineering</i>
      <h3>Maintenance</h3>
    </div>
    <div *ngIf="isDialog" style="display: flex;">

      <button mat-icon-button (click)="openMaintenanceComponent()"> <i class="material-icons-outlined"
          style="font-size:xx-large;  ">arrow_circle_left</i> </button>
      <button mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i>
      </button>
    </div>
  </div>
  <div class="divLeft" style="background-color: white;padding: 01em;">
    <div>
      <div>
        <div style="display:flex">
          <div>
            <div style=" margin-top: 1em;">
              <h4>Global Settings</h4>
              <h6>Manage Message Template</h6>
              <hr />
              <!-- <button formcontrolname="fuelsupplierprofiles" mat-button class="btn-menu"  style="font-size: small;"  (click)="clickRouteNew('template/template-dashbord','')">
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                    <span class="material-symbols-outlined">
                      concierge
                    </span>
                  </span>Message Template
                </button>&nbsp; -->

              <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;"
                (click)="openMessageTemplate()">
                <table style="margin-bottom: -0.6em;">
                  <tr>
                    <td> <span class="material-symbols-outlined"
                        style="font-size: xx-large; margin-left: -0.3em;">dataset_linked</span> </td>
                    <td>
                      <div style="margin-top:-0.5em; margin-left: 0.5em">
                        <span class="button-name">Message Templates</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </button>&nbsp;

            </div>
            <div style=" margin-top: 2em;">
              <h6>Database tables</h6>
              <hr />
              <!-- <button formcontrolname="exchangerate" mat-button class="btn-menu"  style="font-size: small;" >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">fact_check</span>Manage FPC Services
                </button>&nbsp; -->

              <div style="display: inline-flex;">
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;">
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">fact_check</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Manage FPC Services</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>&nbsp;

                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;">
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">flag_circle</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Manage Countries</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>
              </div>
              
              <div style="margin-top: 0.65em; display: inline-flex;">
                <!-- (click)="openCountryDocumentsPicklistComponent()" -->
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                    <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                      flag_circle
                    </span>Manage Countries
                  </button>&nbsp; -->

                <button mat-button class="btn-menu" style="font-size: small;" (click)="openAirportRevisionModal(true)">
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">flight_takeoff</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <div class="button-name">Airport Revision</div><div class="button-name">Notes Queue</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>&nbsp;

                <button mat-button class="btn-menu" style="font-size: small;"(click)="openManageAirports()"> 
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">flight_takeoff</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Manage Airports</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>&nbsp;

                
              </div>

              <div style="margin-top: 0.65em; display: inline-flex;">
                <!-- <button formcontrolname="fuelpricing" mat-button class="btn-menu" style="font-size: small;margin-top: 1em;" >
                    <span style=" margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">
                      flight_takeoff
                    </span>Manage Airports
                  </button>&nbsp; -->
  
                

                <button mat-button class="btn-menu" style="font-size: small;" (click)="openAirportRevisionModal(false)">
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">flight_takeoff</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <div class="button-name">Airport Document</div><div class="button-name">Expiration Queue</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>&nbsp;

                <button mat-button class="btn-menu" style="font-size: small;"(click)="openDayLightSaving()"> 
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">schedule</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">Daylight Savings</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>&nbsp;
              </div>
            </div>
          </div>

          <div>
            <div style=" margin-top: 3.3em; margin-left: 2.1em;">
              <h6>Automation Of Services</h6>
              <hr />
              <!-- <button formcontrolname="managefuelalerts" mat-button class="btn-menu" style="font-size: small;"  (click)="clickRouteNew('/business-rules/manage-business-rules','')" >
                  <span style="margin-right: 0.2em; " class="material-symbols-outlined">dynamic_form</span>Customize Business Rules
                </button>&nbsp; -->

              <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;"
                (click)="clickRouteNew('/business-rules/manage-business-rules','')">
                <table style="margin-bottom: -0.6em;">
                  <tr>
                    <td> <span class="material-symbols-outlined"
                        style="font-size: xx-large; margin-left: -0.3em;">dynamic_form</span> </td>
                    <td>
                      <div style="margin-top:-0.5em; margin-left: 0.5em">
                        <span class="button-name">Customize Business Rules</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </button>&nbsp;

            </div>
            <div style="display: flex; margin-top: 2em; margin-left: 2em;">
              <div>
                <h6>3rd Party API</h6>
                <hr />
                <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;"
                  (click)="openAPISComponent()">
                  <table style="margin-bottom: -0.6em;">
                    <tr>
                      <td> <span class="material-symbols-outlined"
                          style="font-size: xx-large; margin-left: -0.3em;">hotel_class</span> </td>
                      <td>
                        <div style="margin-top:-0.5em; margin-left: 0.5em">
                          <span class="button-name">APIS Credentials</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </button>
                &nbsp;
              </div>
            </div>
            <div style="margin-left: 2em;">
              <h6>Audit Reports</h6>
              <hr />
              <!-- <button formcontrolname="exchangerate" mat-button class="btn-menu"  style="font-size: small;"  (click)="clickRouteNew('/ground-stops/trip-audit-dashboard','')"   >
                  <span style="margin-right: 0.2em; font-size: medium; " class="material-symbols-outlined">contacts</span>Audit Reports
                </button>&nbsp; -->

              <button formcontrolname="exchangerate" mat-button class="btn-menu" style="font-size: small;"
                (click)="clickRouteNew('/ground-stops/trip-audit-dashboard','')">
                <table style="margin-bottom: -0.6em;">
                  <tr>
                    <td> <span class="material-symbols-outlined"
                        style="font-size: xx-large; margin-left: -0.3em;">contacts</span> </td>
                    <td>
                      <div style="margin-top:-0.5em; margin-left: 0.5em">
                        <span class="button-name">User Login</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </button>&nbsp;

              <button mat-button class="btn-menu" style="font-size: small;"
                (click)="openPersonAuditReport()">
                <table style="margin-bottom: -0.6em;">
                  <tr>
                    <td> <span class="material-symbols-outlined"
                        style="font-size: xx-large; margin-left: -0.3em;">description</span> </td>
                    <td>
                      <div style="margin-top:-0.5em; margin-left: 0.5em">
                        <span class="button-name">Person Profile Changes</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </button>&nbsp;

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
