<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<div class="divCenter">
  <div>
    <div style="margin-left:2.5em">
      <img src="../../assets/images/FlightProLogoWhiteBG.png" style="cursor: pointer" (click)="openCompanyMarketPage()" />
    </div>
    <div style="border-style: solid; border-width: 0.0625em; border-radius: 0.3125em; border-color: lightgray;padding-top: 1em;padding-bottom:1em; margin-top:2em;">
      <form *ngIf="mfaForm" [formGroup]="mfaForm" (ngSubmit)="onSubmit()">
        <div style="text-align:center">
          <h2>Flight Pro Connect {{websiteEnvironment}}</h2>
        </div>
        <div class="d-flex justify-content-center">
          <div style="margin-top:2em;">
            <h5 class="mt-1">Sign In</h5>
            <div class="d-block" style="height:1.5em"></div>
            <div class="form-group d-block">
              <div style="display:flex; justify-content:space-between;width:18.75em">
                <label for="username" style="margin-bottom:0em">{{username}}</label>
              </div>
            </div>
            <div class="form-group d-block">
              <div style="display:flex; justify-content:space-between;width:18.75em">
                <label for="pin">PIN</label><!--<a routerLink="" (click)="resetPIN()">Forgot your PIN?</a>-->
              </div>
              <div>
                <input type="password" formControlName="pin" #passwordRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pin.errors }" style="width:18.75em; background-color:#eaf0fd" maxlength="6" />
                <!--<div class="row" style="margin-left:0em">
                <input [type]="hide ? 'password' : 'text'" formControlName="password" #passwordRef class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" style="width:18.75em" maxlength="50" />
                <i class="material-icons" matSuffix (click)="hide = !hide" style="cursor: pointer;">{{hide ? 'visibility' : 'visibility_off'}}</i>
                </div>-->
                <div *ngIf="submitted && f.pin.errors" class="invalid-feedback">
                  <div *ngIf="f.pin.errors.required">PIN is required</div>
                </div>
              </div>
            </div>
            <div *ngIf="errMsg!=''" style="white-space: normal; color: red; width: 18.75em ">{{errMsg}}</div>
            <div class="form-group" style="display:block; margin-top:20px">
              <button [disabled]="loading" class="btn btn-primary" style="width:18.75em">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Sign-In
              </button>
            </div>
            

          </div>
        </div>
      </form>
    </div>
    <div style="display:flex;margin-top:1em;">
      <div style="font-size:small;"><a routerLink="" (click)="openTerm()" style="text-decoration: none">Terms of Use</a></div>
      <div style="font-size:small; margin-left: 6em;"><a routerLink="" (click)="openPrivacy()" style="text-decoration: none">Privacy Notice</a></div><div style="font-size:small; margin-left:10em;"><a routerLink="" (click)="openContactUs()" style="text-decoration: none">Help</a></div>
    </div>
  </div>
</div>
